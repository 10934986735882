import { Fragment } from 'react';
import {useAuthorized} from './useAuthorized';

const Authorized = ({children, roles, exclude }) => {
    const {isAuthorized, isNotAuthorized} = useAuthorized()
    if (isAuthorized(roles) && !isNotAuthorized(exclude)) {
        return children
    }
    return <Fragment />
}

export default Authorized;
