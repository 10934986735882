import { createSlice } from '@reduxjs/toolkit'


export const templatesSlice = createSlice({
    name: 'template',
    initialState: {
        template: {
            id :''
        }
    },
    reducers: {
        selectedTemplate: (state, action) => {
            state.template.id = action.payload
        }
    }
})

export const {selectedTemplate} = templatesSlice.actions

export default templatesSlice.reducer