import React from 'react'
import { Button } from "reactstrap";
import Icon from "../Icon/Icon"


const ClearFilterButton = ({ agGridApi }) => {

    const clearFilters = () => {
        if (agGridApi)
            agGridApi.setFilterModel(null);
    };

    return (
        <Button
            color="primary"
            className="d-flex align-items-center px-4"
            onClick={clearFilters}
            size="sm"
            title="Clear All Filters"
        >
            {"Clear"}
            <div className="pl-1 fa-xs"><Icon icon="filter" /></div>
        </Button>
    )
}

export default ClearFilterButton
