import React from 'react'
import { Row } from 'reactstrap';
import styled from 'styled-components';
import { currencyFormatter } from '../../../../utilities/ag-grid-utilities/AgGridUtilities';

const DollarValue = styled.div`
    font-weight: 600;
    font-size: larger;
`
const TotalCol = styled.div`
    padding: 1.5rem;
`
const FlashTrendTotals = ({ totals }) => {
    const { a, b, ac, r, d } = totals
    return (
        <Row style={{ background: "#EDF2F5" }} className="border mt-2 text-right justify-content-around">
            <TotalCol><h6>{"A - Above Line In CR"}</h6><DollarValue>{currencyFormatter(a, '$', 2)}</DollarValue></TotalCol>
            <TotalCol><h6>{"B - Below Line In CR"}</h6><DollarValue>{currencyFormatter(b, '$', 2)}</DollarValue></TotalCol>
            <TotalCol><h6>{"AC - Approved Change In Budget"}</h6><DollarValue>{currencyFormatter(ac, '$', 2)}</DollarValue></TotalCol>
            <TotalCol><h6>{"R - On Radar"}</h6><DollarValue>{currencyFormatter(r, '$', 2)}</DollarValue></TotalCol>
            <TotalCol><h6>{"D - Deleted"}</h6><DollarValue>{currencyFormatter(d, '$', 2)}</DollarValue></TotalCol>
        </Row>
    )
}

export default FlashTrendTotals
