import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import Page from '../../../../../UI/Page';
import { Controller } from "react-hook-form"
import PCRService from "../../api/pcrService"
import LogService from "../../../api/logService"
import { useEffect, useRef, useState } from 'react';
import { LogRoute } from '../../../../../setup/routes'
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage } from '@hookform/error-message';
import { HISTORY_TYPE } from "../../../../../setup/constants";
import { Container, Row, Col, Form, Label, Input } from 'reactstrap';
import PCRBasicInfoForm from '../../pcr-basic-info-form/PCRBasicInfoForm';
import FeatureTitle from '../../../../../components/FeatureTitle/FeatureTitle';
import ApproveButton from "../../../../../components/ApproveButton/ApproveButton"
import { openSuccess } from '../../../../success/redux/successSlice';
import { Summary } from '../../../summary/Summary';
import Spinner from '../../../../../components/Spinner/Spinner'
import useSelectedProject from '../../../../../hooks/useSelectedProject'

const ApprovePCRByPE = ({ match }) => {

    const logId = match.params.id
    const pcrId = useRef()
    const history = useHistory();
    const dispatch = useDispatch()
    const [pcrData, setPcrData] = useState(null)
    const [attachments, setAttachments] = useState([])
    const [changeNumber, setChangeNumber] = useState(null)
    const [statusAndAreas, setStatusAndAreas] = useState(null)
    const userId = useSelector(state => state.user.user.userId);
    const [summaryData, setSummaryData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [setSelectedProjectDropdown] = useSelectedProject();
    const [selectedProjectId, setSelectedProjectId] = useState(null)
    const [requestInProgress, setRequestInProgress] = useState(false)

    const { setValue, getValues, formState: { errors }, control, handleSubmit, reset } = useForm({
        defaultValues: {
            status: "",
            title: "",
            originator: "",
            scheduleImpact: false,
            incorporatedInSchedule: false,
            describe: "",
            comments: "",
            variance: false,
            sbiEnable: false
        },
        mode: "onChange"
    });

    useEffect(() => {
        async function getPCRByLogId() {
            const response = await LogService.getPCRForPEApproval(logId)
            if (response.data.data) {
                const { areas, statuses, templates, log: { changeNumber }, attachedFiles, id, summary, projectId } = response.data.data
                setChangeNumber(changeNumber)
                setAttachments(attachedFiles ?? [])
                setStatusAndAreas({ areas: areas ?? [], statuses, templates })
                setPcrData(response.data.data)
                setSummaryData(summary)
                pcrId.current = id
                setSelectedProjectId(projectId)
            }
            setLoading(false)
        }
        getPCRByLogId()
    }, [logId, setAttachments])

    useEffect(() => {
        setSelectedProjectDropdown(selectedProjectId)
    }, [selectedProjectId, setSelectedProjectDropdown])

    useEffect(() => {
        if (pcrData !== null) {
            const { log: { status, area, title, originator, variance }, contractType, description, comments, scheduleImpact, incorporatedInSchedule, scheduleImpactDescription, templateId, sbiEnable } = pcrData
            reset({
                "status": status, "area": area.toString(), "title": title, "originator": originator, "variance": variance,
                "description": description, "scheduleImpactDescription": scheduleImpactDescription ?? "", "contractType": contractType,
                "comments": comments ?? "", "scheduleImpact": scheduleImpact, "incorporatedInSchedule": incorporatedInSchedule, "template": templateId, "sbiEnable": sbiEnable
            })
        }
    }, [pcrData, setValue, reset])


    const onApproval = handleSubmit(async (data) => {
        try {
            setRequestInProgress(true)
            const response = await PCRService.approvePCRByPE(
                {
                    pcrId: pcrData.id,
                    approved: true,
                    userId,
                    comments: data.pcrApprovalComments
                });

            if (history.action !== HISTORY_TYPE.NO_HISTORY) {
                history.goBack();
            } else {
                history.push(LogRoute);
            }
            if (response.status === 200) {
                const message = "The PCR was Approved, and an email has been sent to " +
                    response.data.data.notified.map(name => ` ${name}`) + "."
                setTimeout(() => {
                    dispatch(openSuccess(message))
                }, 4000)
            }
            setRequestInProgress(false)
        } catch (e) {
            console.log(e)
            setRequestInProgress(false)
        }
    })

    const onNotApproval = handleSubmit(async (data) => {
        try {
            setRequestInProgress(true)
            const response = await PCRService.approvePCRByPE(
                {
                    pcrId: pcrData.id,
                    approved: false,
                    userId,
                    comments: data.pcrApprovalComments
                }
            );
            if (history.action !== HISTORY_TYPE.NO_HISTORY) {
                history.goBack();
            } else {
                history.push(LogRoute);
            }
            if (response.status === 200 && !response.data.data.approved) {
                const message = response.data.data.notified.map(name => ` ${name}`) + " were notified. "
                setTimeout(() => {
                    dispatch(openSuccess(message))
                }, 4000)
            }
            setRequestInProgress(false)
        } catch (e) {
            console.log(e)
            setRequestInProgress(false)
        }
    })

    if (loading) {
        return (<Spinner />)
    }

    return (
        <Page>
            { pcrData && <Container className="pb-3">
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title={"Project Change: " + changeNumber} />
                            <hr />
                        </Container>
                    </Col>
                </Row>
                <Form className="pb-5" onSubmit={onApproval} >
                    <Row>
                        {statusAndAreas && <PCRBasicInfoForm
                            control={control} errors={errors} statusAndAreas={statusAndAreas} readOnly={true}
                            getValues={getValues} attachments={attachments} />}
                    </Row>
                    <hr />
                    <Row className="mt-md-3">
                        <Col sm="1" lg="1" className="text-right">
                            <Label for="pcrApprovalComments">Approval Comments</Label>
                        </Col>
                        <Col sm="4" lg="6">
                            <Controller
                                control={control}
                                name="pcrApprovalComments"
                                render={({ field }) => <Input type="textarea" {...field} data-testid="pcrApprovalComments" />}
                                rules={{ maxLength: { message: "Approval Comments must be no longer than 4000 characters.", value: 4000 } }}
                            />
                            <ErrorMessage className="text-danger small" errors={errors} name={"pcrApprovalComments"} as="p" key={"pcrApprovalComments"} />
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <Summary id={logId} pcrSummaryData={summaryData} />
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <ApproveButton onNotApprove={onNotApproval} requestInProgress={requestInProgress}></ApproveButton>
                        </Col>
                    </Row>
                   
                </Form>
            </Container> }
        </Page>
    )
}


export default ApprovePCRByPE
