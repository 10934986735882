import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

/* istanbul ignore file */

interface IGroup {
    id: string
    type: string
    profile: {
        name: string
        description: string
    }
}

interface IOktaGroupResponse {
    currentPage: {
        items: IGroup[]
    }
}
export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: window.location.origin === 'https://cms.ipims2.dev.sbec.com' ? 'https://cms.ipims2.dev.sbec.com/cost/api/' : `${window.location.origin}/cost/api`,
        mode: 'cors',
        prepareHeaders: (headers) => {
            headers.set('Access-Control-Allow-Origin', '*')
            headers.set('Content-Type', 'application/json')
            headers.set('Accept', ' application/json')
            return headers
        },
    }),
    tagTypes: ['awaitingClientApproval'],
    endpoints: (builder) => ({
        getAwaitingClientApproval: builder.query<string[], string[]>({
            query: (projectNos: string[]) => `/log/awaitingClientApproval?projectNos=${projectNos.join(',')}`,
            providesTags: ['awaitingClientApproval'],
        })
    }),
})


const permissionsHost = 'https://permissions.ipims2.sbec.com/api/'
const permissionsToken =
    'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJTJkIgRW5naW5lZXJzIGFuZCBDb25zdHJ1Y3RvcnMgQ3VzdG9tIE9rdGEgUm9sZXMuIiwiaXNzIjoiU0JFQyIsImV4cCI6NDgwOTk0NTIwNywiaWF0IjoxNjU0MjcxNjA3fQ.mVH4sx-lNt6vVACQwpdmoKy529LtgGjKTRmSsyArWWE'
export const permissionsApiSlice = createApi({
    reducerPath: 'permissionsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: permissionsHost,
        prepareHeaders: (headers) => {
            headers.set('Access-Control-Allow-Origin', '*')
            headers.set('Content-Type', 'application/json')
            headers.set('Accept', ' application/json')
            headers.set('Authorization', permissionsToken)
            return headers
        },
    }),
    tagTypes: ['permissions'],
    endpoints: () => ({}),
})

const permissionApi = permissionsApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUserGroups: builder.query<IOktaGroupResponse[], string>({
            query: (userId: string) => `/groups/get/${userId}`,
            providesTags: ['permissions']
        })
    })
})

export interface IDistributionResponse {
    type: string
    name: string
    email: string
    title: string
}

const distributionHost = 'https://api.epimsdev.sbec.com/fco-api/v1/fco/'
const distributionToken = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJTJkIgRW5naW5lZXJzIGFuZCBDb25zdHJ1Y3RvcnMgV2ViIERldiBUZWFtIiwiaXNzIjoiU0JFQyIsImV4cCI6NDgyMTA5OTE3NywiaWF0IjoxNjY1NDI1NTc3fQ.Vdh6Gscy1xIVfCt-s5FkGJ8zzmJUzyhcE6dSTXW7nX8'
export const distributionSlice = createApi({
    reducerPath: 'distribution',
    baseQuery: fetchBaseQuery({
        baseUrl: distributionHost,
        mode: 'cors',
        prepareHeaders: (headers) => {
            headers.set('Access-Control-Allow-Origin', '*')
            headers.set('Content-Type', 'application/json')
            headers.set('Accept', ' application/json')
            headers.set('Authorization', distributionToken)
            return headers
        },
    }),
    tagTypes: ['distributions'],
    endpoints: () => ({}),
})

const distributionApi = distributionSlice.injectEndpoints({
    endpoints: (builder) => ({
        getFCODistributionList: builder.query<IDistributionResponse[], string>({
            query: (jobNo: string) => `/distribution?jobNo=${jobNo}`,
            providesTags: ['distributions']
        })
    })
})

export const {useLazyGetUserGroupsQuery} = permissionApi
export const {useGetFCODistributionListQuery} = distributionApi
export const {useGetAwaitingClientApprovalQuery} = apiSlice

