// import { lazy } from "react";
import { Switch } from "react-router";
import routes from '../../setup/routes';
import Page from "../../UI/Page";
import AuthorizedRoute from "../auth/Authorized/AuthorizedRoute";
import { TEMPLATE_USERS } from "../logs/logs-roles/Roles";
import CreateTemplate from './create-template/CreateTemplate';
import UpdateTemplate from './update-template/UpdateTemplate';
import Rates from './rates/Rates';
import Templates from './list-templates/Templates';

// const Templates = lazy(() => import('./list-templates/Templates'));
// const CreateTemplate = lazy(() => import('./create-template/CreateTemplate'));
// const UpdateTemplate = lazy(() => import('./update-template/UpdateTemplate'));
// const Rates = lazy(() => import('./rates/Rates'));

const AllowedRoles = [...TEMPLATE_USERS]

const TemplatesPage = () => {
    return (
        <Page>
            <Switch>
                <AuthorizedRoute path={routes.CreateTemplateRoute} component={CreateTemplate} roles={AllowedRoles} redirectIfNoAuth={routes.TemplatesRoute} />
                <AuthorizedRoute path={routes.UpdateTemplateRoute} component={UpdateTemplate} roles={AllowedRoles} redirectIfNoAuth={routes.TemplatesRoute} />
                <AuthorizedRoute path={routes.RatesRoute} component={Rates} roles={AllowedRoles} redirectIfNoAuth={routes.TemplatesRoute} />
                <AuthorizedRoute path={routes.TemplatesRoute} component={Templates} roles={AllowedRoles} redirectIfNoAuth={routes.HomeRoute} />
            </Switch>
        </Page>
    );
}

export default TemplatesPage;