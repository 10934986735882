import axios from '../../../setup/axios';

class reportService {

    BASE_ROUTE = "/reports";

    getReportDataByProjectId(projectId) {
        return this.get(`project/${projectId}`)
    }

    downloadReport(report, params) {
        return this.get(`${report}`, { params })
    }

    get(route, params) {
        return axios.get(`${this.BASE_ROUTE}/${route}`, params)
    }
}

export default new reportService()