import '../Log.css'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import {AgGridReact} from 'ag-grid-react'
import {Container, Row, Col} from 'reactstrap';
import Icon from '../../../../components/Icon/Icon'
import AgGridOptionButtons from '../../../../components/AgGridOptionButtons/AgGridOptionButtons';
import {
    getRowStyle,
    defaultGridOptions,
    currencyFormatter
} from '../../../../utilities/ag-grid-utilities/AgGridUtilities'
import routes, {getUpdatePCIRoute, getUpdatePCRRoute, getUpdateFCORoute} from '../../../../setup/routes';

const NOTIFICATION_TYPES = {
    ERROR: {type: 'error', icon: 'exclamation-triangle', color: '#E76143', size: 'sm'},
    WARNING: {type: 'warning', icon: 'exclamation-triangle', color: '#DE5145', size: 'sm'},
    SUCCESS: {type: 'success', icon: 'exclamation-triangle', color: '#27A745', size: 'sm'},
    DEFAULT: {type: 'default', icon: 'info-circle', color: '#2B7DE9', size: 'sm'}
}

const dateFormatter = (date) => {
    return date ? date.toUpperCase() : date;
}

const getUpdateChangeLink = (params) => {
    const {changeType, id, changeNumber, notifications} = params.data;
    if (changeType === 'PCI') {
        return [changeNumber, getUpdatePCIRoute(id), notifications]
    }
    if (changeType === 'PCR') {
        return [changeNumber, getUpdatePCRRoute(id), notifications]
    }
    if (changeType === 'FCO') {
        return [changeNumber, getUpdateFCORoute(id), notifications]
    }
    return null;
}

const hideColumn = (changeType, fieldName) => {
    var isHidden = false;

    if (fieldName === 'pcReviewed') {
        if (changeType === 'PCI' || changeType === 'PCR') {
            isHidden = true;
        }
    }

    if (fieldName === 'cmApproved') {
        if (changeType === 'PCI' || changeType === 'PCR') {
            isHidden = true;
        }
    }

    return isHidden;
}

function dateComparator(date1, date2) {
    //console.log("---> Comparing date: " + date1 + " " + date2);
    var date1Number = date1 && new Date(date1).getTime();
    var date2Number = date2 && new Date(date2).getTime();

    if (date1Number == null && date2Number == null) {
        return 0;
    }

    if (date1Number == null) {
        return -1;
    } else if (date2Number == null) {
        return 1;
    }

    return date1Number - date2Number;
}

const getNotificationIcons = (notifications) => {
    if (notifications) {
        return notifications.map((notification, id) => {
            let color, icon, size
            switch (notification.type) {
                case NOTIFICATION_TYPES.WARNING.type:
                    color = NOTIFICATION_TYPES.WARNING.color;
                    icon = NOTIFICATION_TYPES.WARNING.icon
                    size = NOTIFICATION_TYPES.WARNING.size
                    break;
                case NOTIFICATION_TYPES.ERROR.type:
                    color = NOTIFICATION_TYPES.ERROR.color;
                    icon = NOTIFICATION_TYPES.ERROR.icon
                    size = NOTIFICATION_TYPES.ERROR.size
                    break;
                case NOTIFICATION_TYPES.SUCCESS.type:
                    color = NOTIFICATION_TYPES.SUCCESS.color;
                    icon = NOTIFICATION_TYPES.SUCCESS.icon
                    size = NOTIFICATION_TYPES.SUCCESS.size
                    break;
                default:
                    color = NOTIFICATION_TYPES.DEFAULT.color;
                    icon = NOTIFICATION_TYPES.DEFAULT.icon
                    size = NOTIFICATION_TYPES.DEFAULT.size
                    break;
            }
            return <Icon icon={icon} key={id} color={color} size={size} className="px-1"
                         title={notification.description}/>
        })
    } else {
        return null
    }
}

const LogGrid = ({logs, changeType}) => {
    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params) => {
        setGridApi(params.api);
    }

    const agGrid = {
        columnDefs: [
            {
                headerName: 'Type', field: 'changeType', minWidth: 80, headerClass: 'header-grid', cellClassRules: {
                    'fco-tracking': (params) => {
                        return ((params.data?.tracking ?? '') === 'Y')
                    }
                }
            },
            {
                headerName: 'Change No.', field: 'changeNumber', headerClass: 'header-grid',
                valueGetter: getUpdateChangeLink,
                cellRendererFramework: (params) => (params.value &&
                    <div className="d-flex">{getNotificationIcons(params.value[2])} <Link className="pl-2"
                                                                                          to={params.value[1]}>{params.value[0]}</Link>
                    </div>)
            },
            {headerName: 'Originator', field: 'originator', headerClass: 'header-grid'},
            {headerName: 'Area', field: 'area', headerClass: 'header-grid', maxWidth: 110},
            {headerName: 'Title', field: 'title', headerClass: 'header-grid', minWidth: 300},
            {headerName: 'Current Status', field: 'status', minWidth: 300, headerClass: 'header-grid'},
            {
                headerName: 'Contract Type',
                field: 'contractType',
                headerClass: 'header-grid',
                maxWidth: 150,
                valueFormatter: (params) => {
                    switch (params.value) {
                        case 'C':
                            return 'Cost Plus';
                        case 'L':
                            return 'Lump Sum';
                        default:
                            return ''
                    }
                }
            },
            {
                headerName: 'Opened',
                field: 'opened',
                headerClass: 'header-grid',
                comparator: dateComparator,
                valueFormatter: params => dateFormatter(params.value)
            },
            {
                headerName: 'PC Reviewed',
                field: 'pcReviewed',
                headerClass: 'header-grid',
                comparator: dateComparator,
                valueFormatter: params => dateFormatter(params.value),
                hide: hideColumn(changeType, 'pcReviewed')
            },
            {
                headerName: 'PE Reviewed',
                field: 'peReviewed',
                headerClass: 'header-grid',
                comparator: dateComparator,
                valueFormatter: params => dateFormatter(params.value)
            },
            {
                headerName: 'PM Approved',
                field: 'pmApproved',
                headerClass: 'header-grid',
                comparator: dateComparator,
                valueFormatter: params => dateFormatter(params.value)
            },
            {
                headerName: 'CM Approved',
                field: 'cmApproved',
                headerClass: 'header-grid',
                comparator: dateComparator,
                valueFormatter: params => dateFormatter(params.value),
                hide: hideColumn(changeType, 'pcReviewed')
            },
            {
                headerName: 'Issued Client',
                field: 'issuedToClient',
                headerClass: 'header-grid',
                comparator: dateComparator,
                valueFormatter: params => dateFormatter(params.value)
            },
            {
                headerName: 'Client Approved',
                field: 'clientApproved',
                headerClass: 'header-grid',
                comparator: dateComparator,
                valueFormatter: params => dateFormatter(params.value)
            },
            {
                headerName: 'Closed',
                field: 'closed',
                headerClass: 'header-grid',
                comparator: dateComparator,
                valueFormatter: params => dateFormatter(params.value)
            },
            {headerName: 'Estimated Hrs.', field: 'estimatedHours', headerClass: 'header-grid'},
            {
                headerName: 'Estimated Dollars',
                field: 'estimateDollars',
                headerClass: 'header-grid',
                valueFormatter: params => params.value ? currencyFormatter(params.value, '$', 2) : ''
            },
            {headerName: 'Variance', field: 'variance', headerClass: 'header-grid'},
            {headerName: 'Tracking', field: 'tracking', headerClass: 'header-grid'}
        ]
    }

    return (
        <Container className="pb-2">
            <AgGridOptionButtons agGridApi={gridApi}
                                 quickLinkButtons={[{label: 'Flash Trends', route: routes.FTRoute}]}/>
            <Row style={{marginTop: '20px', height: '43vh'}}>
                <Col fluid={'true'}>
                    <AgGridReact
                        getRowStyle={getRowStyle}
                        gridOptions={{...defaultGridOptions}}
                        columnDefs={agGrid.columnDefs}
                        rowData={logs}
                        domLayout="normal"
                        className="ag-theme-bootstrap"
                        suppressMenuHide="true"
                        onGridReady={onGridReady}
                        defaultExcelExportParams={{
                            autoConvertFormulas: false,
                            processCellCallback: params => {
                                const field = params.column.getColDef().field;
                                return field === 'changeNumber' ? params.value[0] : params.value;
                            }
                        }}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default LogGrid
