import Page from '../../../UI/Page';
import FTService from '../api/ftService'
import ftService from '../api/ftService';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { FTRoute } from '../../../setup/routes';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Form } from "reactstrap";
import { removeComma } from '../../../utilities/Numbers';
import FTFinanceForm from '../ft-finance-form/FTFinanceForm';
import FTTrackingForm from '../ft-tracking-form/FTTrackingForm';
import NextCancel from '../../../components/NextCancel/NextCancel';
import FeatureTitle from '../../../components/FeatureTitle/FeatureTitle'
import FTStatusRemarksForm from '../ft-status-remark-form/FTRemarksForm';
import FTGeneralInfoForm from '../ft-general-info-form/FTGeneralInfoForm';
import { openSuccess } from '../../success/redux/successSlice';


const UpdateFT = ({ match }) => {

    const ftId = match.params.id
    const dispatch = useDispatch()
    const { projectId } = useSelector((state) => state)
    const userId = useSelector(state => state.user.user.userId);
    const [ftData, setFtData] = useState(null)
    const [flashTrendNumber, setFlashTrendNumber] = useState(null)
    const { setValue, reset, getValues, formState: { errors }, control, handleSubmit } = useForm({
        defaultValues: {
            status: 1,
            tracking: "A",
            uom: "-1",
            quantity: "",
            changeNumber: "",
            coveredBy: ""
        },
        mode: "onChange"
    });


    useEffect(() => {
        async function getFTByLogId() {
            const response = await ftService.getFlashTredById(ftId)
            const responseData = response.data.data
            if (responseData) {
                setFtData(responseData)
                setFlashTrendNumber(responseData.flashTrendNumber)
            }
        }
        getFTByLogId()
    }, [ftId])

    useEffect(() => {
        if (ftData !== null) {
            reset({
                "description": ftData.description ?? "",
                "uom": ftData.uom ?? "-1",
                "quantity": ftData.quantity ?? "",
                "lowDollars": ftData.lowDollars,
                "romDollars": ftData.romDollars,
                "highDollars": ftData.highDollars,
                "changeNumber": ftData.changeNumber ?? "",
                "tracking": ftData.tracking,
                "coveredBy": ftData.coveredBy ?? "",
                "remarks": ftData.remarks ?? ""
            })
        }
    }, [ftData, setValue, reset])

    const onSave = handleSubmit(async (data) => {
        if (data.uom === "-1") {
            delete data.uom
        }
        const flashTrend = {
            ...data,
            userId,
            projectId: projectId.value,
            id: ftId,
            quantity: removeComma(data.quantity),
            lowDollars: removeComma(data.lowDollars),
            romDollars: removeComma(data.romDollars),
            highDollars: removeComma(data.highDollars),

        }
        FTService.updateFlashTrend(flashTrend).then((response) => {
            if (response.data.data) {
                dispatch(openSuccess("The Flash Trend was Updated."))
            }
        }).catch((error) => {
            console.log(error)
        })
    })

    return (
        <Page>
            <Container className="pb-3">
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title={`Flash Trend: ${flashTrendNumber}`} />
                            <hr />
                        </Container>
                    </Col>
                </Row>
                <Form className="pb-5 mx-4" onSubmit={onSave} >
                    <Row>

                        <FTGeneralInfoForm control={control} getValues={getValues} errors={errors}></FTGeneralInfoForm>
                        <FTFinanceForm control={control} getValues={getValues} errors={errors}></FTFinanceForm>
                        <FTTrackingForm control={control} getValues={getValues} errors={errors}></FTTrackingForm>
                        <FTStatusRemarksForm control={control} getValues={getValues} errors={errors}></FTStatusRemarksForm>
                    </Row>
                    <Row>
                        <Col>
                            <NextCancel cancelRoute={FTRoute} disabled={false} saveText="Save" cancelConfirmText="Are you sure you want to close?" />
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Page>
    );
}

export default UpdateFT;