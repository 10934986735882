// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageFooter_Footer__Oyr8z {\n    position: fixed;\n    left: 0;\n    bottom: 0;\n    width: 100%;\n    height: 50px;\n    background-color: #333333;\n    color: #FFFFFF;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: 14px;\n    font-weight: normal;   \n    text-align: center; \n}\n\n.PageFooter_Footer__Oyr8z p {\n    padding-top: 15px;\n}\n", "",{"version":3,"sources":["webpack://src/components/Layout/PageFooter/PageFooter.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,OAAO;IACP,SAAS;IACT,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,2DAA2D;IAC3D,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".Footer {\n    position: fixed;\n    left: 0;\n    bottom: 0;\n    width: 100%;\n    height: 50px;\n    background-color: #333333;\n    color: #FFFFFF;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: 14px;\n    font-weight: normal;   \n    text-align: center; \n}\n\n.Footer p {\n    padding-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer": "PageFooter_Footer__Oyr8z"
};
export default ___CSS_LOADER_EXPORT___;
