import React, {useCallback, useState} from 'react'
import {useHistory} from 'react-router';
import styled from 'styled-components';
import {
    Row,
    Button,
    Modal, ModalBody, ModalFooter
} from 'reactstrap';
import ApprovalsButton from '../../../components/ApproveButton/ApprovalsButton';
import BusyActionButton from '../../../components/ActionButton/BusyActionButton';
import DistributionControl from '../../distribution/DistributionControl';


const ButtonSection = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`

const FCOActionButtons = ({
                              cancelRoute,
                              isSaveDisabled,
                              isSubmitDisabled,
                              isCloseFCODisabled,
                              fcoStatusId,
                              onSave,
                              onSubmit,
                              approvalRoute,
                              saveInProgress,
                              onCloseFCO,
                          }) => {

    const [openModal, setOpenModal] = useState(false);
    const [closeFCOModalOpen, setCloseFCOModalOpen] = useState(false);
    const [closeStatus, setCloseStatus] = useState('close'); // 'close', 'finalized', 'void'

    const toggle = () => setOpenModal(!openModal);
    let history = useHistory();

    const onConfirmCancel = () => {
        toggle();
        history.push(cancelRoute);
    }

    const onConfirmCloseFCO = useCallback(() => {
        onCloseFCO(closeStatus)
        setCloseFCOModalOpen(false)
    }, [closeStatus])

    return (
        <Row>
            <ButtonSection>
                <Button color="link" data-testid="CancelLink" onClick={toggle} className="m-1">Exit</Button>
                <ApprovalsButton route={approvalRoute}/>
                {onCloseFCO &&
                    <>
                        <BusyActionButton style={{background: 'tomato'}} data-testid="closeFCOButton"
                                          onClick={() => {
                                              setCloseFCOModalOpen(true)
                                              setCloseStatus('close')
                                          }} disabled={isCloseFCODisabled}
                                          busy={saveInProgress}
                        >
                            Close FCO
                        </BusyActionButton>
                        <BusyActionButton style={{background: 'tomato'}}
                                          data-testid="finalizeFCOButton"
                                          onClick={() => {
                                              setCloseFCOModalOpen(true)
                                              setCloseStatus('finalized')
                                          }}
                                          disabled={isCloseFCODisabled}
                                          busy={saveInProgress}
                        >
                            Finalize FCO
                        </BusyActionButton>
                        <BusyActionButton style={{background: 'tomato'}}
                                          data-testid="finalizeFCOButton"
                                          onClick={() => {
                                              setCloseFCOModalOpen(true)
                                              setCloseStatus('void')
                                          }}
                                          disabled={isCloseFCODisabled}
                                          busy={saveInProgress}
                        >
                            Void FCO
                        </BusyActionButton>
                    </>
                }
                <DistributionControl fcoStatusId={fcoStatusId}/>
                <BusyActionButton color="primary" data-testid="SaveChanges" disabled={isSaveDisabled}
                                  onClick={onSave} className="m-1" busy={saveInProgress}
                >Save Changes &amp; Submit Later</BusyActionButton>
                <BusyActionButton color="primary" data-testid="SubmitFCO" disabled={isSubmitDisabled}
                                  onClick={onSubmit} className="m-1" busy={saveInProgress}
                >Submit</BusyActionButton>
            </ButtonSection>
            <Modal isOpen={openModal} data-testid="NextCancel-ConfirmModal">
                <ModalBody>
                    {'Are you sure you want to exit? - Unsaved changes will be lost'}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onConfirmCancel}>Yes</Button>
                    <Button color="primary" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={closeFCOModalOpen} data-testid="closeFco-modal">
                <ModalBody>
                    {`Are you sure you want to update the status of this FCO to ${closeStatus}?`}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onConfirmCloseFCO}>Yes</Button>
                    <Button color="primary" onClick={() => setCloseFCOModalOpen(false)}>No</Button>
                </ModalFooter>
            </Modal>
        </Row>
    );
};

export default FCOActionButtons
