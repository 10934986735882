import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logService from '../../features/logs/api/logService';

export const FileList = ({ setDragAndDropFiles, attachments, setAttachments, disabled }) => {

    const onDelete = (fileName) => {
        const droppedFilesAndSavedFiles = attachments.filter(f => ![fileName].includes(f.fileName ?? f.name))
        const deletedFiles = attachments.filter(f => [fileName].includes(f.fileName ?? f.name))
        const markedAsDeleted = deletedFiles.length === 1 ? deletedFiles.map(deleted => ({ ...deleted, "status": "DELETED" })) : []
        setAttachments([...droppedFilesAndSavedFiles, ...markedAsDeleted])
        setDragAndDropFiles([])
    }

    const onDownload = (fileName) => {        
        const file = attachments.filter(f => fileName === f.fileName ?? f.name)[0]        
        logService.downloadAttachment(file.fileDownloadUri)
            .then((response) => {
                let a = document.createElement("a");
                a.href = `data:application/octet-stream;base64,${response.data.data}`;
                a.download = file.fileName;
                a.click();
            })
    }

    if (attachments.length > 0) {
        const filesHtml = attachments.filter(f => !['NEW', 'DELETED'].includes(f.status)).map(({ name, fileName }) => (
            (<span key={name ?? fileName} className={`font-italic font-weight-light mr-4 ${name ? 'text-success' : 'text-secondary'}`}>
                {name ?? fileName}
                <FontAwesomeIcon style={{ cursor: "pointer" }} icon={['fa', 'download']} color="#2B7DE9" className="ml-1" size="sm" onClick={() => !name ? onDownload(name ?? fileName) : null} />
                <span className="pl-1"> </span>
                <FontAwesomeIcon style={{ cursor: "pointer" }} icon={['far', 'trash-alt']} color="#B1C1CE" className="ml-1" size="sm" onClick={() => !disabled ? onDelete(name ?? fileName) : null} />                
            </span>)
        ));
        return (
            <div className="pt-2">
                <div><FontAwesomeIcon icon={['far', 'file']} color="#2B7DE9" /><span className="pl-1">Attached files:</span></div>
                {filesHtml}
            </div>
        )
    }
    else {
        return null
    }
}
