import { Container, Row, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useHistory } from 'react-router';
import { useState } from 'react';
import ButtonSection from "../../UI/ButtonSection";

export default function NextCancel({ cancelRoute, disabled, saveText, cancelConfirmText }) {

    const [openModal, setOpenModal] = useState(false);
    const toggle = () => setOpenModal(!openModal);

    let history = useHistory();

    const onCancel = () => {
        if (cancelConfirmText) {
            toggle();
        } else {
            history.push(cancelRoute); 
        }
    }

    const onConfirmCancel = () => {        
        toggle();        
        history.push(cancelRoute);
    }

    return (
        <Container>
            <Row>
                <ButtonSection>                  
                    <Button color="link" data-testid="NextCancel-CancelLink" onClick={onCancel}>Close</Button>
                    <Button color="primary" disabled={disabled} data-testid="next">{saveText ? saveText : "Next"}</Button>
                </ButtonSection>
            </Row>
            <Modal isOpen={openModal} data-testid="NextCancel-ConfirmModal">
                <ModalBody>
                    {cancelConfirmText}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onConfirmCancel}>Yes</Button>
                    <Button color="primary" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </Container>
    )
}