import React, { useState, useEffect } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import { Col, Input } from "reactstrap";

const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
        return { background: '#f4faff' };
    } else {
        return { background: '#ffffff' };
    }
}

const sortByRFI = ((a, b) => a.rfiNumber > b.rfiNumber ? 1 : -1)

const AttachmentsGrid = ({ rfiList, selectedRfiList = [], setSelectedRfiList, readOnly = false }) => {
    const [gridApi, setGridApi] = useState(null);
    const [quickFilter, setQuickFilter] = useState("")
    const [initSelectionDone, setInitSelectionDone] = useState(false)

    const onGridReady = (params) => {
        setGridApi(params.api);
    }

    const onFirstDataRendered = (params) => {
        setInitSelectionDone(false)
        params.api.forEachNode(node => {
            if (selectedRfiList && selectedRfiList.includes(node.data.id)) {
                node.setSelected(true);
            }
        });
        setInitSelectionDone(true)
    }

    const updateSelectedValues = () => {
        if (!readOnly && initSelectionDone) {
            let selectedNodes = gridApi.getSelectedNodes();
            let selectedData = selectedNodes.map(node => (node.data.id));
            setSelectedRfiList(selectedData)
        }
    }

    useEffect(() => {
        if (gridApi) {
            gridApi.setQuickFilter(quickFilter);
        }
    }, [quickFilter, gridApi])

    return (
        <div className="fco-attachments-grid">
            <h5>Select RFI number(s) below</h5>
            <Col className="ag-theme-bootstrap" style={{ height: "400px", width:"690px" }}>
                <Input
                    className="w-25 pb-1 pt-1"
                    type="text"
                    bsSize="sm"
                    onInput={(e) => setQuickFilter(e.target.value)}
                    id="quickFilter"
                    placeholder="quick filter..."
                    value={quickFilter}
                    disabled={readOnly} />
                <AgGridReact
                    suppressRowClickSelection={true}
                    domLayout="normal"
                    rowSelection={'multiple'}
                    onGridReady={onGridReady}
                    rowData={rfiList.sort(sortByRFI)}
                    getRowStyle={getRowStyle}
                    suppressCellSelection={readOnly}
                    onFirstDataRendered={onFirstDataRendered}
                    onSelectionChanged={updateSelectedValues}>
                    <AgGridColumn
                        field="rfiNumber"
                        headerName="RFI Number"
                        width={140}
                        sortable
                        headerCheckboxSelection={true}
                        headerCheckboxSelectionFilteredOnly={true}
                        checkboxSelection={true} />
                    <AgGridColumn
                        field="shortDescription"
                        headerName="Subject"
                        minWidth={500}
                        cellRenderer={(params) => {
                            if (params.node.selected) {
                                return `<a href="${params.data.url}" target="_blank" >${params.value}</a>`
                            } else {
                                return params.value
                            }
                        }} />
                    <AgGridColumn field="id" hide />
                    <AgGridColumn field="url" hide />
                </AgGridReact>
            </Col>
        </div>
    )
}
export default AttachmentsGrid
