import { useDispatch, connect } from "react-redux";
import 'ag-grid-community/dist/styles/ag-grid.css';
import { CONTRACT_TYPE } from '../type-and-time/Options'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import React, {useEffect, useRef, useCallback, useMemo} from 'react'
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import { projectManagementSummarySetData } from '../redux/detailsDataAndTotalSlice';
import { currencyFormatter, getRowStyle, getRowSpanForDescriptionTotal, getTotalDescription, defaultGridOptions, sortById, isFooter } from "../../../../utilities/ag-grid-utilities/AgGridUtilities"
import Icon from '../../../../components/Icon/Icon';
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';
import {CAN_OVERRIDE_DETAIL_VALUES} from '../../logs-roles/Roles';

const TOTAL_DESCRIPTION = "Total Management (DIV P)"
const getHoursValue = (originalEstimatePct, totalEngineeringHours) => {
    return Math.ceil(originalEstimatePct * totalEngineeringHours)
}
const getLaborValue = (rate, markup, overlay, hours) => Math.ceil((((rate * markup) + overlay) * hours))
const getSbiLaborValue = (rate, sbiHours) => (rate * sbiHours)

const cellRendererWarning = (params) => {
    return <div className="d-flex override-status"><Icon icon="exclamation-triangle" color="#E76143" size="sm" className="px-1" title="Please verify rates are set" />{params.value}</div>
}

const editableCols = ['hours', 'sbiHours']
const cellClassRules = {
    'discipline-impact-status': params => ["hours", "sbiHours"].includes(params.colDef.field) && !isFooter(params),
    'editable-cell': params => {
        if ((!params.colDef.editable || !params.colDef.editable(params)) || isFooter(params)) {
            return false;
        }
        return editableCols.includes(params.colDef.field);
    }
}

const ProjectManagementSummaryGrid = React.memo(({ data, totals, contractType, totalEngineeringHours, readOnly }) => {
    const dispatch = useDispatch()
    const doUpdate = useRef(false)
    const dataRef = useRef(...data)
    const { isAuthorized } = useAuthorized()
    const canOverrideValues = useMemo(() => {
        return isAuthorized([...CAN_OVERRIDE_DETAIL_VALUES]);
    }, [isAuthorized])

    useEffect(() => {
        dataRef.current = [...data]
    }, [data])

    const applyFormulasToDataModel = useCallback((localData) => {
        const markupType = contractType === CONTRACT_TYPE.LumpSum ? 'lumpSumMarkup' : 'costReimbursibleMarkup'
        const overlayType = contractType === CONTRACT_TYPE.LumpSum ? 'lumpSumOverlay' : 'costReimbursibleOverlay'
        const updatedDataModel = localData.map(row => {
            const { labor, sbiHours, sbiLabor:  { costRate, lumpRate} } = row
            let hours; 

            if(row.hasBeenOverwritten){
                hours = row.hours
              }else{
                hours = getHoursValue(labor.originalEstimatePct, totalEngineeringHours)
              }
            
            return {
                ...row,
                sbiLaborDollars: getSbiLaborValue(contractType === CONTRACT_TYPE.LumpSum ? lumpRate : costRate, sbiHours),
                hours,
                laborDollars: getLaborValue(labor.rate, labor[markupType], labor[overlayType], hours)

            }
        })
        if (updatedDataModel.length > 0) {
            dispatch(projectManagementSummarySetData(updatedDataModel))
        }
    }, [contractType, dispatch, totalEngineeringHours])

    const recalculateFormulas = useCallback(() => {
        applyFormulasToDataModel(data)
    }, [applyFormulasToDataModel, data])

    useEffect(() => {
        if (doUpdate.current) {
            recalculateFormulas()
        }
    }, [contractType, totalEngineeringHours, recalculateFormulas])

    const valueSetter = (params) => {
        
        if (params.colDef.field === "hours") {
            //Validate hour precision and scale
            if (params.newValue > 999999) {
                return params.oldValue
            }
        } 

        const isNewValue = Number(params.oldValue) !== Number(params.newValue)

        if (isNewValue) {
            const value =  Number(params.newValue);
            const updatedRow = { ...params.data, [params.column.colId]: value, edited: true, "hasBeenOverwritten": true }
            const filteredData = dataRef.current.filter(f => f.id !== params.data.id)
            applyFormulasToDataModel([...filteredData, ...[updatedRow]].sort(sortById))
        }
        return isNewValue
    }

    const sortByPactAndActivity = ((a, b) => {
        if (a.pact === b.pact) {
            const startWithNumber = /^\d/.test(a.activityDescription)
            if (!startWithNumber) {
                return -1;
            }
            return a.activityDescription > b.activityDescription ? 1 : -1
        }
        return a.pact > b.pact ? 1 : -1
    })

    const sortedData = [...data].sort(sortByPactAndActivity)

    return (
        <div className="ag-theme-bootstrap" style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <div id="project-management-summary-table" style={{flex: '1 1 auto'}}>
                <AgGridReact
                    getRowStyle={getRowStyle}
                    gridOptions={{...defaultGridOptions}}
                    rowData={sortedData}
                    getRowNodeId={sortedData => sortedData.id}
                    immutableData={true}
                    domLayout="autoHeight"
                    pinnedBottomRowData={[totals]}
                    enableCellChangeFlash
                    singleClickEdit={true}
                    onFirstDataRendered={() => doUpdate.current = true}>
                    <AgGridColumn headerName="Pact" field="pact" headerClass="header-grid" maxWidth={70}
                                  colSpan={getRowSpanForDescriptionTotal}
                                  valueFormatter={params => getTotalDescription(params, TOTAL_DESCRIPTION)}/>
                    <AgGridColumn headerName="Activity" field="activityDescription" headerClass="header-grid"
                                  minWidth={400}
                                  cellRendererFramework={params => Number(params.data.hours) > 0 && Number(params.data.laborDollars) === 0 ? cellRendererWarning(params) : params.value}
                    />
                    <AgGridColumn headerName="Hours" field="hours" headerClass="header-grid right-sortable-header-grid"
                                  type="numericColumn" minWidth={200}
                                  valueFormatter={(params) => currencyFormatter(params.data.hours, '')}
                                  editable={params => canOverrideValues && !readOnly && !isFooter(params)}
                                  valueSetter={valueSetter}
                                  cellClassRules={cellClassRules}
                    />
                    <AgGridColumn headerName="Labor $" field="laborDollars"
                                  headerClass="header-grid right-sortable-header-grid" type="numericColumn"
                                  minWidth={200}
                                  valueFormatter={(params) => currencyFormatter(params.data.laborDollars ?? 0, '$')}/>
                    <AgGridColumn headerName="SBI Hours" field="sbiHours"
                                  headerClass="header-grid right-sortable-header-grid" type="numericColumn"
                                  minWidth={200}
                                  editable={params => canOverrideValues && !readOnly && !isFooter(params)}
                                  valueSetter={valueSetter}
                                  valueFormatter={(params) => currencyFormatter(params.data.sbiHours, '')}
                                  cellClassRules={cellClassRules}
                    />
                    <AgGridColumn headerName="SBI Labor $" field="sbiLaborDollars"
                                  headerClass="header-grid right-sortable-header-grid" type="numericColumn"
                                  minWidth={200}
                                  valueFormatter={(params) => currencyFormatter(params.data.sbiLaborDollars ?? 0, '$')}/>
                </AgGridReact>
            </div>
        </div>
    );

}, (prevProps, nextProps) => {
    return (
        prevProps.contractType === nextProps.contractType &&
        prevProps.totalEngineeringHours === nextProps.totalEngineeringHours &&
        JSON.stringify(prevProps.totals) === JSON.stringify(nextProps.totals)
    )
})

const mapStateToProps = (state) => {
    const { engineeringSummary: { totals: { hours: totalEngineeringHours } }, projectManagementSummary: { data, totals }, contractType, readOnly } = state.detailsDataAndTotal
    return {
        data,
        totals,
        contractType,
        totalEngineeringHours,
        readOnly
    }
}

export default connect(mapStateToProps)(ProjectManagementSummaryGrid)
