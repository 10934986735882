import { useHistory } from "react-router";
import { Container, Row, Button } from "reactstrap";
import { LogRoute } from "../../setup/routes";
import ButtonSection from "../../UI/ButtonSection";
import { HISTORY_TYPE } from "../../setup/constants";
import BusyActionButton from '../ActionButton/BusyActionButton';

export default function ApproveButton({ onNotApprove, requestInProgress }) {

    const history = useHistory();

    const handleCancel = () => {
        if (history.action !== HISTORY_TYPE.NO_HISTORY) {
            history.goBack();
        } else {
            history.push(LogRoute);
        }
    }

    return (
        <Container>
            <Row>
                <ButtonSection>
                    <Button color="link" onClick={handleCancel}>Come Back Later</Button>
                    <BusyActionButton color="danger" data-testid="NotApprove" onClick={onNotApprove} className="m-1"
                                      disabled={requestInProgress} busy={requestInProgress}>Reject</BusyActionButton>
                    <BusyActionButton color="primary" data-testid="next" className="m-1" disabled={requestInProgress}
                                      busy={requestInProgress}>Approve</BusyActionButton>
                </ButtonSection>
            </Row>
        </Container>
    );
}