import {useDispatch, useSelector} from 'react-redux';
import {Route} from 'react-router-dom';
import {openError} from '../../errors/redux/errorSlice';
import {useAuthorized} from './useAuthorized';
import Loading from '../../../components/Loading/Loading';

const AuthorizedRoute = ({component: Component, ...rest}) => {
    const dispatch = useDispatch()
    const {isAuthorized, isNotAuthorized} = useAuthorized();
    const user = useSelector(state => state.user.user);
    return (
        <Route {...rest} render={props => {
            if (!user.userId) {
                return <Loading/>
            }
            if (!isNotAuthorized(rest.exclude) && isAuthorized(rest.roles)) {
                return <Component {...props} />;
            } else {
                dispatch(openError(['You don\'t have access to this area.']));
            }
            return null;
        }}/>
    )
}

export default AuthorizedRoute;