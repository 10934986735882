import {useSelector} from 'react-redux';

const useAuthorized = () => {
    const userRoles = useSelector(state => state.user.user.roles ?? []);

    const isAuthorized = (roles) => {
        if (!userRoles.some(r => ['CanAccessApp', 'ClientCanAccessApp'].includes(r))) {
            return false;
        }
        if (!roles) {
            return true;
        }
        return roles.some((r) => userRoles.includes(r));
    }
    const isNotAuthorized = (excluded) => {
        if (!userRoles.some(r => ['CanAccessApp', 'ClientCanAccessApp'].includes(r))) {
            return true
        }
        if (!excluded) {
            return false
        }
        return userRoles.some((r) => excluded.includes(r));
    }

    return {isAuthorized, isNotAuthorized}
}

export {useAuthorized}
