import { Container, Button, Modal, ModalBody, ModalFooter } from "reactstrap";

export default function DisciplineImpactDeleteConfirmationModal({ confirmCancel, confirmDelete, isOpen = false }) {
    return (
        <Container>
            <Modal isOpen={isOpen} >
                <ModalBody>
                    Are you sure you want to remove impact areas
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={confirmDelete}>Yes</Button>
                    <Button color="primary" onClick={confirmCancel}>No</Button>
                </ModalFooter>
            </Modal>
        </Container>
    )
}