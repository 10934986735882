export const defaultGridOptions = {
    
    defaultColDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        wrapText: true,
        minWidth:165,
        flex: 1
    },
    suppressHorizontalScroll: false
};

export const currencyFormatter = (currency, sign, toFixed = 0) => {
    var sansDec = currency.toFixed(toFixed);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return sign + `${formatted}`;
}

export const getRowStyle = params => {
    if (isFooter(params) || isFooterAgg(params)) {
        return { fontWeight: 'bold', background: '#EDF2F5' };
    } else if (params.node.rowIndex % 2 === 0) {
        return { background: '#ffffff' };
    } else {
        return { background: '#f4faff' };
    }
}

export const decimalParser = (params, length, decimals) => {
    let pattern = '\\d{1,' + length + '}(?:\\.\\d{1,' + decimals + '})?$';
    let validValueRegex = new RegExp(pattern, 'y');
    let value = Number(params.newValue)
    if (!validValueRegex.test(value)) {
        value = Number(params.oldValue)
    }
    return value;
}

export const getRowSpanForDescriptionTotal = (params) => params.node.rowPinned ? 2 : 1
export const getRowSpanForAggregationTotal = (params) => params.node.aggData ? 2 : 1
export const getTotalDescription = (params, totalDescription) => params.node.rowPinned ? totalDescription : params.value
export const getTotalDescriptionForAggregation = (params, totalDescription) => params.node.aggData ? totalDescription : params.value
export const isFooterAgg = (params) => !!params.node.aggData 
export const isFooter = (params) => params.node.rowPinned  === 'bottom'
export const sortById = ((a, b) => a.id > b.id ? 1 : -1)
export const sortByPact = ((a, b) => a.pact > b.pact ? 1 : -1)
export const currencyValueFormatter = (value) => {
    return `$${value ? value : 0}`;
}