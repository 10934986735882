import React from 'react'
import { Button } from "reactstrap";
import Icon from "../Icon/Icon"

const ExportExcelButton = ({agGridApi}) => {

    const onClickExport = () => {
        if (agGridApi)
            agGridApi.exportDataAsExcel();
    };

    return (
        <Button
            color="primary"
            className="d-flex align-items-center px-4 mx-2"
            onClick={onClickExport}
            size="sm"
            title="Export to Excel File"
        >
            {"Export"}
            <div className="pl-1 fa-xs"><Icon icon="file-excel" /></div>
        </Button>
    )
}

export default ExportExcelButton
