import Page from '../../../../UI/Page'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router';
import LogService from "../../api/logService"
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Form } from 'reactstrap';
import DisciplineImpactGrid from './DisciplineImpactGrid'
import { getUpdatePCRRoute } from '../../../../setup/routes'
import PCRDisciplineActionButtons from "./PCRDisciplineActionButtons"
import { useAuthorized } from '../../../auth/Authorized/useAuthorized'
import FeatureTitle from '../../../../components/FeatureTitle/FeatureTitle'
import DisciplineImpactBasicInfoForm from "./DisciplineImpactBasicInfoForm"
import { openSuccess } from "../../../success/redux/successSlice"
import { useEffect, useState, useMemo, useCallback, useRef, useReducer } from 'react'
import { PROJECT_COORDINATORS, DISCIPLINE_LEADS } from '../../logs-roles/Roles'
import { disciplineImpactInitialState, disciplineImpactReducer } from './DisciplineImpactReducerProps'
import Spinner from '../../../../components/Spinner/Spinner'
import { DISCIPLINES_BY_PROJECT } from './constants'

const statusForEditDisciplineLeads = [13]
const statusForEditProjectCoordinators = [0, 8, 9, 13, 21]

const UpdatePCR = ({ match }) => {
    let history = useHistory();
    const pcrId = match.params.id
    const pcrScreenUrl = useRef()
    const dispatch = useDispatch()
    const { isAuthorized } = useAuthorized()
    const [areas, setAreas] = useState(null)
    const [readOnly, setReadOnly] = useState(true)
    const [pactNumber, setPactNumber] = useState(null)
    const [description, setDescription] = useState(null)
    const [logChangeNumber, setlogChangeNumber] = useState(null)
    const [roleDisciplines, setRoleDisciplines] = useState(null)
    const [readOnlyDiscipline, setReadOnlyDiscipline] = useState(true)
    const [logStatusDescription, setLogStatusDescription] = useState(null)
    const [isDisabledSaveAndSubmit, setIsDisabledSaveAndSubmit] = useState(false)
    const [disciplineDropDownSelected, setDisciplineDropDownSelected] = useState(null)
    const isDisciplineLead = useMemo(() => isAuthorized([...DISCIPLINE_LEADS]), [isAuthorized])
    const { projectId } = useSelector((state) => state)
    const userId = useSelector(state => state.user.user.userId);
    const isProjectCoodinator = useMemo(() => isAuthorized([...PROJECT_COORDINATORS]), [isAuthorized])
    const [disciplineImpactState, disciplineImpactDispatch] = useReducer(disciplineImpactReducer, disciplineImpactInitialState)
    const [loading, setLoading] = useState(true)
    const [ disciplineImpactValue, setDisciplineImpactValue ] = useState(null)
    const { selectedProjectDetails } = useSelector((state) => state);

    const { getValues, control, handleSubmit, reset } = useForm({
        defaultValues: {
            disciplineImpact: 'A',
            scheduleImpact: false,
            description: ""
        },
        mode: "onChange"
    });

    const assignPactNumber = useCallback((discipline, roleDisciplines) => {
        if (roleDisciplines.length > 0 && discipline) {
            const pactNumber = roleDisciplines.find(r => r.discipline === discipline)?.pact
            setPactNumber(pactNumber)
        }
    }, [])

    const handleFormState = useCallback((pcrStatus) => {
        if (isProjectCoodinator) {
            if (statusForEditProjectCoordinators.some(s => s === pcrStatus)) {
                setReadOnly(false)
                return
            }
            return
        }
        else if (isDisciplineLead) {
            if (statusForEditDisciplineLeads.some(s => s === pcrStatus)) {
                setReadOnly(false)
                return
            }
            return
        }
    }, [isDisciplineLead, isProjectCoodinator])
    
    const deleteAllDisciplineImpactAreas = (disciplineImpact) => {
        disciplineImpactDispatch({ type: 'DELETE_ALL' })
        setDisciplineImpactValue(disciplineImpact)
    }

    useEffect(() => {
        async function getPcrAreas() {
            const response = await LogService.getPCRStatusesAndAreas(projectId.value)
            const { areas } = response.data.data
            setAreas(areas)
        }
        getPcrAreas()
    }, [projectId])

    useEffect(() => {
        async function getPCRDisciplinesByLogId() {
            const response = await LogService.getDisciplinesByPCR(pcrId, userId, projectId.value, disciplineDropDownSelected)
            if (response.data.data) {
                const { logChangeNumber, description, logStatusDescription, roleDisciplines, pcrDisciplineImpact, logStatusId, logId, pcrManHours } = response.data.data
                setlogChangeNumber(logChangeNumber)
                setDescription(description)
                setLogStatusDescription(logStatusDescription)
                setRoleDisciplines(roleDisciplines ?? [])
                handleFormState(logStatusId)
                disciplineImpactDispatch({ type: "SET_DISCIPLINE_IMPACT", payload: pcrManHours ?? [] })
                pcrScreenUrl.current = getUpdatePCRRoute(logId)
                if (isProjectCoodinator) {
                    setReadOnlyDiscipline(false)
                }
                if (pcrDisciplineImpact) {
                    const { discipline, scheduleImpact, disciplineImpact, description } = pcrDisciplineImpact
                    reset({ discipline, scheduleImpact, disciplineImpact, description: description ?? "" })
                    assignPactNumber(discipline, roleDisciplines)
                    setDisciplineImpactValue(disciplineImpact)
                }
                else if (roleDisciplines.length > 0) {
                    const jobNo = selectedProjectDetails.jobNo;
                    const projectInitial = jobNo.substring(0, 1);
                    const disciplinesByProjectInitial = DISCIPLINES_BY_PROJECT.filter(d => d.initial === projectInitial)?.map(di => di.disciplines)?.[0] || []
                    const discipline = disciplineDropDownSelected ?? (isDisciplineLead ? roleDisciplines[0].discipline : disciplinesByProjectInitial[0])
                    reset({ "discipline": discipline, "scheduleImpact": false, "disciplineImpact": 'A', description: "" })
                    assignPactNumber(discipline, roleDisciplines)
                    setDisciplineImpactValue('A')
                }
                setLoading(false)
            }
        }
        getPCRDisciplinesByLogId()
    }, [assignPactNumber, disciplineDropDownSelected, handleFormState, isProjectCoodinator, pcrId, projectId.value, reset, userId])


    const getPcrManHours = () => disciplineImpactState;
    const pcrManHoursIsValid = useCallback(() => disciplineImpactState.every(({ activityDescription }) => activityDescription !== ""), [disciplineImpactState])

    useEffect(() => {
        setIsDisabledSaveAndSubmit(!pcrManHoursIsValid())
    }, [pcrManHoursIsValid])


    const onSave = handleSubmit(async (data) => {
        if (readOnly) {
            return
        }
        const pcrManHours = getPcrManHours()
        const request = { ...data, ...{ userId, pcrId, pcrManHours } }
        setIsDisabledSaveAndSubmit(true)
        LogService.saveDisciplinesImpact(request).then((response) => {
            if (response.data.data) {
                dispatch(openSuccess("The Discipline Impact was saved."))
                disciplineImpactDispatch({ type: "SET_DISCIPLINE_IMPACT", payload: response.data.data.manHours ?? [] })
                setTimeout(() => {
                    setIsDisabledSaveAndSubmit(false)
                }, 4000)
            }
        }).catch((error) => {
            console.log(error)
        })
    })

    const onSubmitDisciplineImpact = handleSubmit(async (data) => {
        if (readOnly) {
            return
        }
        
        setIsDisabledSaveAndSubmit(true)
        const pcrManHours = getPcrManHours()
        const request = { ...data, ...{ userId, "id": pcrId, pcrManHours } }
        const response = await LogService.submitPCRDisciplines(request)
        if (response.status === 200) {
            if (!response.data.errors || response.data.errors.length === 0) {
                if (response.data.data.submitted) {
                    dispatch(openSuccess("PCR disciplines were submited."))
                    history.push(pcrScreenUrl.current)
                } else {
                    dispatch(openSuccess("The Discipline Impact was submitted."))
                };

                disciplineImpactDispatch({ type: "SET_DISCIPLINE_IMPACT", payload: response.data.data.manHours ?? [] })

                setTimeout(() => {
                    setIsDisabledSaveAndSubmit(false)
                }, 4000)
            }
        }
    })

    if (loading) {
        return (<Spinner />)
    }

    return (
        <Page>
            <Container className="pb-3">
                <Container>
                    <Row>
                        <Col>
                            <FeatureTitle title={logChangeNumber + " - " + description} />
                        </Col>
                        <Col sm="8" lg="8" className="d-flex flex-row-reverse bd-highlight">
                            <div className="p-2 bd-highlight"><b>Client Status:</b> {logStatusDescription}</div>
                        </Col>
                    </Row>
                    <hr />
                </Container>

                <Form className="pb-5" onSubmit={onSave} >
                    <Row>
                        {roleDisciplines &&
                            <DisciplineImpactBasicInfoForm control={control} roleDisciplines={roleDisciplines}
                                readOnly={readOnly} getValues={getValues} readOnlyDiscipline={readOnlyDiscipline}
                                setDisciplineDropDownSelected={setDisciplineDropDownSelected} disciplineImpactState={disciplineImpactState}
                                deleteAllDisciplineImpactAreas={deleteAllDisciplineImpactAreas} setDisciplineImpactConfirmValue={setDisciplineImpactValue}/>}
                    </Row>
                    <hr />
                    {(areas && pactNumber) && <Row className="d-inline">
                        <DisciplineImpactGrid pactNumber={pactNumber} areas={areas} disciplineImpactState={disciplineImpactState}
                            disciplineImpactDispatch={disciplineImpactDispatch} projectId={projectId.value} readOnly={readOnly}
                            disciplineImpactValue={disciplineImpactValue} />
                    </Row>}
                    <Row>
                        <Col>
                            <PCRDisciplineActionButtons onSave={onSave} onSubmit={onSubmitDisciplineImpact} cancelRoute={pcrScreenUrl.current} isSubmitHidden={false}
                                isDisabledSaveAndSubmit={isDisabledSaveAndSubmit} readOnly={readOnly}/>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Page>
    )
}

export default UpdatePCR