import axios from './../setup/axios';

class ProjectService {

    BASE_ROUTE = "/projects";

    getProjectIdByJobNumber(jobNumber) {
        return this.get(`${jobNumber}/projectId`)
    }

    getProjectsByUser(user) {
        return this.get(`${user}/user`)
    }

    get(route) {
        return axios.get(`${this.BASE_ROUTE}/${route}`)
    }
}

export default new ProjectService()