import React from 'react'
import { Button } from "reactstrap";
import ClearFilterButton from '../ClearAgGridFilterButton/ClearFilterButton'
import ExportExcelButton from '../ExportExcelAgGridButton/ExportExcelButton'
import Icon from '../Icon/Icon';
import { useHistory } from 'react-router';

const QuickLinkButton = (label, route) => {
    let history = useHistory();
    return <Button
        key={label}
        color="primary"
        className="d-flex align-items-center px-4"
        onClick={() => history.push(route)}
        size="sm"
        title={`Go to ${label}`}>
        {label}
        <div className="pl-1 fa-xs"><Icon icon="table" /></div>
    </Button>
}

const AgGridOptionButtons = ({ agGridApi, showClear = true, ShowExportExcel = true, quickLinkButtons }) => {
    if (agGridApi) {
        return (
            <div className="d-flex">
                {showClear && <ClearFilterButton agGridApi={agGridApi} />}
                {ShowExportExcel && <ExportExcelButton agGridApi={agGridApi} />}
                {quickLinkButtons && quickLinkButtons.length > 0 && quickLinkButtons.map(q => QuickLinkButton(q.label, q.route))}
            </div>
        )
    }
    else { return null }
}

export default AgGridOptionButtons
