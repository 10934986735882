import {OktaAuth} from '@okta/okta-auth-js';

const hostname = window.location.host;
const oktaClientId = hostname.startsWith('localhost') || hostname.endsWith('.dev.sbec.com' ) || hostname.endsWith('.test.sbec.com' )
    ? '0oa2hwcs9qfKrpNA7697' : '0oa2hwcmzudQaNNF2697';

const options = {
        issuer: 'https://sbec-external.okta.com',
        clientId: oktaClientId,
        redirectUri: `${window.location.origin}/callback`,
        scopes: ['openid', 'email', 'profile', 'groups'],
}

const oktaAuth = new OktaAuth(options)

export default oktaAuth;
