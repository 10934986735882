import React from 'react';
import Icon from '../Icon/Icon';
import styled from 'styled-components';
import FlexHeaderNoSpace from '../../UI/FlexHeaderNoSpace';
import { Container, Row, Col, Collapse, Button } from 'reactstrap';

const CollapsibleRow = styled.div`
    display: grid;
    flex-wrap: wrap;   
    border-left: 3px solid;    
    border-color: ${({ hiddenCol, index }) => hiddenCol ? (index % 2 === 0 ? "#1490D7" : "#0E689A") : "#ffffff"};
`

const TotalRow = ({ totalLabel, totalColumns }) => {
  return (
    <Row style={{ backgroundColor: "#F5FCFF" }} className="ml-2">
      <Col className="p-3 col-3">{totalLabel}</Col>
      {totalColumns.map((column, index) => (
        <Col className="p-3 col-2" key={index}><span className="font-weight-bold">{`${column.label}: `}</span>{column.value}</Col>
      ))}
    </Row>
  )
}

export const CollapsibleSection = ({ title, action, children, toggle, isOpen, totalLabel, totalColumns, index, titleClassName = "h6" }) => {
  return (
    <Container>
      <CollapsibleRow hiddenCol={!isOpen && totalLabel} index={index}>
        <Row className="ml-1">
          <Col className={`${titleClassName}`} sm="11" lg="11">
            <FlexHeaderNoSpace>
              <span>{title}</span>
              {action && <span>{action}</span>}
            </FlexHeaderNoSpace>
          </Col>
          <Col className="text-right" sm="1" lg="1">
            <Row>
              <Col>
                <Button color="link" onClick={toggle}>
                  <Icon icon={isOpen ? "chevron-up" : "chevron-down"} color="#008CBA" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {(!isOpen && totalLabel && totalColumns) && <TotalRow totalLabel={totalLabel} totalColumns={totalColumns} />}
      </CollapsibleRow>
      <Collapse isOpen={isOpen}>
        {children}
      </Collapse>
    </Container>

  )
}
