import {Security} from '@okta/okta-react'
import {ReactElement} from 'react'
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import {useHistory} from 'react-router-dom';
import oktaAuth from '../../setup/Okta';

interface Props {
    children?: ReactElement | null
}

OktaWrapper.defaultProps = {
    children: [],
}
/* istanbul ignore file */
export default function OktaWrapper({children}: Props) {
    const history = useHistory();

    const restoreOriginalUri = async (oktaAuth: OktaAuth, originalUri: string) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin))
    }

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            {children}
        </Security>
    )
}
