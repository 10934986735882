import Page from '../../../../UI/Page';
import {useForm} from 'react-hook-form';
import {useEffect, useState} from 'react';
import LogService from '../../api/logService'
import {History} from '../../history/History';
import PCIActionButtons from '../PCIActionButtons';
import {
    getApprovePCIByClientRoute,
    getApprovePCIByPERoute,
    getApprovePCIByPMRoute,
    LogRoute
} from '../../../../setup/routes'
import {useSelector, useDispatch} from 'react-redux';
import {Container, Row, Col, Form} from 'reactstrap';
import {removeComma} from '../../../../utilities/Numbers';
import Authorized from '../../../auth/Authorized/Authorized';
import {PROJECT_COORDINATORS, CAN_REVERT_STATUS} from '../../logs-roles/Roles'
import PCIBasicInfoForm from '../pci-basic-info-form/PCIBasicInfoForm';
import FeatureTitle from '../../../../components/FeatureTitle/FeatureTitle'
import {openSuccess} from '../../../success/redux/successSlice'
import PCIDetailInformationForm from '../pci-detail-information-form/PCIDetailInformationForm';
import {statuses} from '../../statuses';
import Spinner from '../../../../components/Spinner/Spinner'
import useSelectedProject from '../../../../hooks/useSelectedProject'
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';

const DeclinedValues = ['DEFAULT', '', 0]
const allowedStatus = [1, 3, 5]
const allowedStatusToSubmitManually = 5
const readOnlyStatuses = [7, 8, 9, 10, 11, 6, 45]

const CleanObject = (objectToClean) => (
    Object.keys(objectToClean).reduce(function (r, e) {
        if (!DeclinedValues.includes(objectToClean[e])) r[e] = objectToClean[e]
        return r
    }, {})
)

const UpdatePCI = ({match}) => {
    const logId = match.params.id
    const [attachments, setAttachments] = useState([])
    const {projectId} = useSelector((state) => state)
    const userId = useSelector(state => state.user.user.userId);
    const [pciData, setPciData] = useState(null)
    const [pciStatusAndAreas, setPciStatusAndAreas] = useState(null)
    const [pciDisciplineDropdowns, setDisciplineDropdowns] = useState(null)
    const [pcLogStatusHistories, setPcLogStatusHistories] = useState(null)
    const [changeNumber, setChangeNumber] = useState(null)
    const [formSaved, setFormSaved] = useState(0)
    const [isDisabledSubmitPci, setIsDisabledSubmitPci] = useState(true)
    const [readOnly, setReadOnly] = useState(false)
    const [approvalRoute, setApprovalRoute] = useState()
    const [originalStatus, setOriginalStatus] = useState()
    const [saveInProgress, setSaveInProgress] = useState(false)
    const [isDisabledSubmitManually, setIsDisabledSubmitManually] = useState(true)
    const [loading, setLoading] = useState(true)
    const [setSelectedProjectDropdown] = useSelectedProject();
    const {isAuthorized} = useAuthorized()
    const [selectedProjectId, setSelectedProjectId] = useState(null)

    const dispatch = useDispatch()
    const {setValue, getValues, watch, formState: {errors, isValid}, control, handleSubmit, reset} = useForm({
        defaultValues: {
            pciStatus: '1',
            pciTitle: '',
            clientNumber: '',
            originator: '',
            pciScheduleImpactByText: '',
            pciAnticipatedValue: '',
            pciDisciplineImpactReturnExpected: '',
            variance: false,
            sbiEnable: false

        },
        mode: 'onChange'
    });

    const statusChanged = watch('pciStatus');

    useEffect(() => {
        setIsDisabledSubmitPci(statusChanged !== originalStatus && !allowedStatus.some(s => s === statusChanged));
    }, [statusChanged, originalStatus])

    useEffect(() => {
        async function getPCIByNumber() {
            const response = await LogService.getPCIByLogId(logId)
            const {
                areas,
                statuses,
                templates,
                roms,
                causes,
                pciNumber,
                pcLogStatusHistories,
                attachedFiles,
                log: {status},
                projectId
            } = response.data.data
            setAttachments(attachedFiles ?? [])
            setOriginalStatus(status)
            setChangeNumber(pciNumber)
            statuses.sort((a, b) => {
                return a.description > b.description ? 1 : -1;
            })
            setPciStatusAndAreas({
                areas: areas ?? [], statuses
            })
            templates.sort((a, b) => {
                return a.name > b.name ? 1 : -1;
            })
            setDisciplineDropdowns({templates, roms, causes})
            setPcLogStatusHistories(pcLogStatusHistories)
            setPciData(response.data.data)
            determineApprovalRoute(response.data.data);
            setLoading(false)
            if (readOnlyStatuses.some(s => s === status)) {
                setReadOnly(true)
            }

            let isSubmitPCIDisabled = !allowedStatus.some(s => s === status);
            setIsDisabledSubmitPci(isSubmitPCIDisabled);
            setSelectedProjectId(projectId)
            setIsDisabledSubmitManually(!(status === allowedStatusToSubmitManually));
        }

        getPCIByNumber()
    }, [logId, formSaved])

    useEffect(() => {
        setSelectedProjectDropdown(selectedProjectId)
    }, [selectedProjectId, setSelectedProjectDropdown])

    const determineApprovalRoute = (data) => {

        let route = undefined;
        switch (data.log.status) {
            case statuses.PENDING_PE_REVIEW:
                route = getApprovePCIByPERoute(data.id);
                break;
            case statuses.PENDING_PM_REVIEW:
                route = getApprovePCIByPMRoute(data.id);
                break;
            case statuses.PENDING_CLIENT_APPROVAL:
                route = getApprovePCIByClientRoute(data.log.id);
                break;
            default:
                break;
        }

        setApprovalRoute(route);
    }

    useEffect(() => {
        if (pciData !== null) {
            const {
                log: {status, area, title, originator, variance}, clientNumber, description,
                templateId, pcrDiscipReturnDays, pciRomId, pciValue, causeId,
                pciScheduleId, scheduleImpactedBy, notes, sbiEnable
            } = pciData

            reset({
                'clientNumber': clientNumber ?? '',
                'pciStatus': status,
                'pciArea': area.toString(),
                'pciTitle': title,
                'originator': originator,
                'pciDescription': description,
                'pciBudgetTemplate': templateId,
                'pciDisciplineImpactReturnExpected': pcrDiscipReturnDays,
                'pciAproxTicCostRom': pciRomId,
                'pciAnticipatedValue': pciValue,
                'pciReasonForChange': causeId,
                'pciSchedule': pciScheduleId ?? '1',
                'pciScheduleImpactByText': scheduleImpactedBy ?? '',
                'pciNotes': notes ?? '',
                'variance': variance,
                'sbiEnable': sbiEnable
            })
        }
    }, [pciData, setValue, reset])


    const onSave = handleSubmit(async (data) => {
        const formData = new FormData()
        const command = CleanObject(formatPCIRequestObject({...data, changeNumber}))
        command.attachments = []

        attachments.forEach((file) => {
            if (file.name) {
                formData.append('files', file, file.name)
            } else {
                command.attachments.push({
                    'fileName': file.fileName,
                    'status': file.status,
                    'fileDownloadUri': file.fileDownloadUri
                })
            }
        })

        formData.append('command', new Blob([JSON.stringify(command)], {type: 'application/json'}))
        try {
            setSaveInProgress(true);
            const response = await LogService.updatePCI(formData)
            if (response.data.data) {
                dispatch(openSuccess('The PCI was Updated.'))
                setFormSaved(formSaved + 1)
            }
            setSaveInProgress(false);
        } catch (e) {
            console.log(e)
            setSaveInProgress(false);
        }
    })

    const onSubmitPci = handleSubmit(async (data, submitToClientManually) => {
        const formData = new FormData()
        const command = CleanObject(formatPCIRequestObject({...data, id: pciData.id}))
        command.attachments = []
        command.submitToClientManually = submitToClientManually

        attachments.forEach((file) => {
            if (file.name) {
                formData.append('files', file, file.name)
            } else {
                command.attachments.push({
                    'fileName': file.fileName,
                    'status': file.status,
                    'fileDownloadUri': file.fileDownloadUri
                })
            }
        })

        formData.append('command', new Blob([JSON.stringify(command)], {type: 'application/json'}))
        try {
            setSaveInProgress(true);
            const response = await LogService.submitPCI(formData)
            if (response.status === 200) {
                if (!response.data.errors || response.data.errors.length === 0) {
                    determineApprovalRoute(pciData);
                    dispatch(openSuccess('The PCI was Submited.'))

                    setFormSaved(formSaved + 1)
                }
            }
            setSaveInProgress(false);
        } catch (e) {
            console.log(e)
            setSaveInProgress(false);
        }
    })

    const formatPCIRequestObject = ({
                                        pciStatus,
                                        clientNumber,
                                        pciArea,
                                        pciTitle,
                                        pciDescription,
                                        pciAproxTicCostRom,
                                        pciSchedule,
                                        pciScheduleImpactByText,
                                        pciReasonForChange,
                                        pciAnticipatedValue,
                                        pciNotes,
                                        pciDisciplineImpactReturnExpected,
                                        pciBudgetTemplate,
                                        changeNumber,
                                        variance,
                                        id,
                                        sbiEnable
                                    }) => (
        {
            id,
            changeNumber,
            userId,
            projectId: projectId.value,
            status: pciStatus,
            clientNumber: clientNumber,
            area: pciArea,
            title: pciTitle,
            description: pciDescription,
            romId: pciAproxTicCostRom,
            scheduleId: pciSchedule,
            scheduleImpactedBy: pciScheduleImpactByText,
            causeId: pciReasonForChange,
            anticipatedValue: removeComma(pciAnticipatedValue),
            notes: pciNotes,
            pcrDisciplineReturnDays: pciDisciplineImpactReturnExpected,
            templateId: pciBudgetTemplate,
            variance,
            sbiEnable
        }
    )

    if (loading) {
        return (<Spinner/>)
    }

    const isSaveDisabled = () => {
        if (isAuthorized([...CAN_REVERT_STATUS])) {
            return false
        }
        return !isValid || saveInProgress || readOnly

    }

    return (
        <Page>
            <Container className="pb-3">
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title={'Project Change: ' + changeNumber}/>
                            <hr/>
                        </Container>
                    </Col>
                </Row>
                <Form className="pb-5">
                    <Row>
                        {pciStatusAndAreas && <PCIBasicInfoForm
                            control={control} errors={errors}
                            pciStatusAndAreas={pciStatusAndAreas}
                            setAttachments={setAttachments}
                            attachments={attachments}
                            readOnly={readOnly} getValues={getValues}/>}
                    </Row>
                    <Row>
                        {pciDisciplineDropdowns &&
                            <Authorized roles={[...PROJECT_COORDINATORS]}>
                                <PCIDetailInformationForm control={control}
                                                          getValues={getValues} errors={errors}
                                                          templates={pciDisciplineDropdowns.templates}
                                                          roms={pciDisciplineDropdowns.roms}
                                                          causes={pciDisciplineDropdowns.causes} readOnly={readOnly}/>
                            </Authorized>
                        }

                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            {pcLogStatusHistories && <History historyData={pcLogStatusHistories}/>}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {pciData?.approver && pciData.approver.length > 0 &&
                                <>
                                    <hr/>
                                    <div className="h6 font-italic pt-4 mb-0 d-flex justify-content-end">
                                        {<div className="pr-1">When submitted an approval will be sent to </div>}
                                        {<div>{pciData.approver.map((name, id) => (
                                            <div className="text-capitalize" key={id}>{name}</div>))}</div>}
                                    </div>
                                </>
                            }
                            <hr/>
                            <PCIActionButtons cancelRoute={LogRoute} approvalRoute={approvalRoute} onSave={onSave}
                                              onSubmitPci={onSubmitPci} isValid={isValid}
                                              isDisabledSubmitPci={isDisabledSubmitPci}
                                              isDisabledSubmitManually={isDisabledSubmitManually}
                                              saveInProgress={saveInProgress}
                                              isSaveDisabled={isSaveDisabled()}
                            />
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Page>
    );
}

export default UpdatePCI