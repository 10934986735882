import {createSlice} from '@reduxjs/toolkit'
 
export const projectDetailSlice = createSlice({
    name: 'selectedProjectDetails',
    initialState: {
            jobNo: "",
            projectTitle: "",
            client: "",
            projectId: "",
    },
    reducers: {
        setSelectedProject: (state, action) => {
            if (action.payload) {
                state.jobNo = action.payload.jobNo;
                state.projectTitle = action.payload.projectTitle;
                state.client = action.payload.client;
            }            
        },
        setProjectId: (state, action) => {
            state.projectId = action.payload;
        },
    }
})


export const {setSelectedProject} = projectDetailSlice.actions
 
export default projectDetailSlice.reducer