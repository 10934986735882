import {Button, ButtonProps, Spinner} from 'reactstrap';
import './BusyActionButton.css';
interface IBusyActionButton extends ButtonProps{
    busy?: boolean
}
BusyActionButton.defaultProps = {
    busy: false,
}

export default function BusyActionButton({busy, ...otherProps}: IBusyActionButton) {
    return (
        <Button className={'busy-action-button-root'} {...otherProps} disabled={busy}>
            {busy ? <Spinner/> : otherProps.children}
        </Button>
    )
}