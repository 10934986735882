import { useDispatch, connect } from "react-redux";
import 'ag-grid-community/dist/styles/ag-grid.css';
import { CONTRACT_TYPE } from '../type-and-time/Options'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import React, {useEffect, useRef, useCallback, useMemo} from 'react'
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import { engineeringSummarySetData } from "../redux/detailsDataAndTotalSlice"
import NumericEditor from "../../../../utilities/ag-grid-utilities/cell-editors/NumericEditor"
import { currencyFormatter, getRowStyle, getRowSpanForDescriptionTotal, getTotalDescription, isFooter, defaultGridOptions, sortById } from "../../../../utilities/ag-grid-utilities/AgGridUtilities"
import Icon from '../../../../components/Icon/Icon';
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';
import {CAN_OVERRIDE_DETAIL_VALUES} from '../../logs-roles/Roles';

const NO_EDITABLES = ["PROCESS ENGINEERING", "CIVIL/STRUCTURAL/ARCHITECTURAL", "PIPING", "MECHANICAL ENGINEERING", "ELECTRICAL / INSTRUMENT ENGINEERING", 
"INSTRUMENT ENGINEERING", "INFRASTRUCTURE ENGINEERING", "PROJECT INFORMATION MANAGEMENT", "PIPELINE - PROCESS", "PIPELINE - CIVIL/STRUCTURAL",
"PIPELINE - PIPING", "PIPELINE - MECHANICAL", "PIPELINE - INSTRUMENT/ELECTRICAL", "PIPELINE - GENERAL", "PIPELINE - ENGINEERING", "PIPELINE - COORDINATION"]


const TOTAL_DESCRIPTION = "Total Engineering (DIV R)"
const cellClassRules = {
    'override-status': params => params.data.edited,
    'discipline-impact-status': params => ["hours", "sbiHours"].includes(params.colDef.field) && !isFooter(params),
    'editable-cell': params => {
        if ((!params.colDef.editable || !params.colDef.editable(params)) || isFooter(params)) {
            return false;
        }
        if (params.colDef.field === 'activityDescription' || params.colDef.field === 'hours' || params.colDef.field === 'sbiHours') {
            return params.data.activityDescription.toLowerCase() === 'undefined';
        }
        return true;
    }
}
// const cellRendererEditableField = (params) => `<div><span class="float-left">⊕</span>${currencyFormatter(params.value, '')}</div>`
const getLaborValue = (rate, markup, overlay, hours) => (((rate * markup) + overlay) * hours)
const getSbiLaborValue = (rate, sbiHours) => (rate * sbiHours)

const cellRendererWarning = (params) => {
    return <div className="d-flex override-status"><Icon icon="exclamation-triangle" color="#E76143" size="sm" className="px-1" title="Please verify rates are set" />{params.value}</div>
}

const EngineeringSummaryGrid = React.memo(({ data, totals, contractType, readOnly }) => {
    const dispatch = useDispatch()
    const doUpdate = useRef(false)
    const dataRef = useRef(...data)
    const { isAuthorized } = useAuthorized()
    const canOverrideValues = useMemo(() => {
        return isAuthorized([...CAN_OVERRIDE_DETAIL_VALUES]);
    }, [isAuthorized])
    
    useEffect(() => {
        dataRef.current = [...data]
    }, [data])

    const applyFormulasToDataModel = useCallback((localData) => { 
        const markupType = contractType === CONTRACT_TYPE.LumpSum ? 'lumpSumMarkup' : 'costReimbursibleMarkup'
        const overlayType = contractType === CONTRACT_TYPE.LumpSum ? 'lumpSumOverlay' : 'costReimbursibleOverlay'
        const updatedDataModel = localData.map(row => {
            const { labor, hours, sbiHours, sbiLabor: { costRate, lumpRate} } = row
            return {
                ...row,
                laborDollars: getLaborValue(labor.rate, labor[markupType], labor[overlayType], hours),
                sbiLaborDollars: getSbiLaborValue(contractType === CONTRACT_TYPE.LumpSum ? lumpRate : costRate, sbiHours)
            }
        })
        if (updatedDataModel.length > 0) {
            dispatch(engineeringSummarySetData(updatedDataModel))
        }
    }, [contractType, dispatch])

    const recalculateFormulas = useCallback(() => {
        applyFormulasToDataModel(dataRef.current)
    }, [applyFormulasToDataModel])

    useEffect(() => {
        if (doUpdate.current) {
            recalculateFormulas()
        }
    }, [contractType, recalculateFormulas])

    const valueSetter = (params) => {
        const isNewValue = Number(params.oldValue) !== Number(params.newValue)
        if (isNewValue) {
            const updatedRow = { ...params.data, [params.column.colId]: Number(params.newValue), edited: true }
            const filteredData = dataRef.current.filter(f => f.id !== params.data.id)
            applyFormulasToDataModel([...filteredData, ...[updatedRow]].sort(sortById))
        }
        return isNewValue
    }


    return (
        <div className="ag-theme-bootstrap" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div id="engineering-summary-table" style={{ flex: '1 1 auto' }} >
                <AgGridReact
                    getRowStyle={getRowStyle}
                    gridOptions={{ ...defaultGridOptions }}
                    rowData={data}
                    getRowNodeId={data => data.id}
                    immutableData={true}
                    domLayout="autoHeight"
                    pinnedBottomRowData={[totals]}
                    onFirstDataRendered={() => doUpdate.current = true}
                    enableCellChangeFlash
                    singleClickEdit={true}
                    frameworkComponents={{
                        numericCellEditor: NumericEditor
                    }}>
                    <AgGridColumn hide field="edited" headerName="Edited" valueFormatter={params => params.value ?? false} />
                    <AgGridColumn field="pact" maxWidth={70} headerName="Pact" headerClass="header-grid" cellClassRules={cellClassRules}
                        colSpan={getRowSpanForDescriptionTotal}
                        valueFormatter={params => getTotalDescription(params, TOTAL_DESCRIPTION)} />
                    <AgGridColumn minWidth={400} headerName="Activity" headerClass="header-grid" field="activityDescription" cellClassRules={cellClassRules} 
                    cellRendererFramework={params => Number(params.data.hours) > 0 && Number(params.data.laborDollars) === 0 ? cellRendererWarning(params) : params.value}
                    />
                    <AgGridColumn field="hours" minWidth={200} headerName="Hours" type="numericColumn" valueSetter={valueSetter}
                        cellClassRules={cellClassRules}  editable={params => canOverrideValues && !isFooter(params) &&  !NO_EDITABLES.includes(params.data.activityDescription) && !readOnly}
                        headerClass="header-grid right-sortable-header-grid" />
                    <AgGridColumn minWidth={200} headerName="Labor $" field="laborDollars" headerClass="header-grid right-sortable-header-grid" type="numericColumn" cellClassRules={cellClassRules}
                        valueFormatter={(params) => currencyFormatter(params.data.laborDollars, '$')} />
                    <AgGridColumn headerName="SBI Hours" field="sbiHours" headerClass="header-grid right-sortable-header-grid" 
                    editable={params => (canOverrideValues && !isFooter(params) && !NO_EDITABLES.includes(params.data.activityDescription) && !readOnly)}
                        type="numericColumn" cellClassRules={cellClassRules} minWidth={200}
                        cellRenderer={params => currencyFormatter(params.value, '')}
                        valueSetter={valueSetter} />
                    <AgGridColumn headerName="SBI Labor $" field="sbiLaborDollars" headerClass="header-grid right-sortable-header-grid" type="numericColumn" cellClassRules={cellClassRules} minWidth={200}
                        valueFormatter={(params) => currencyFormatter(params.data.sbiLaborDollars, '$')} />
                </AgGridReact>
            </div>
        </div>
    )

}, (prevProps, nextProps) => {
    return (
        prevProps.contractType === nextProps.contractType &&
        JSON.stringify(prevProps.totals) === JSON.stringify(nextProps.totals)
    )
})

const mapStateToProps = (state) => {
    const { engineeringSummary: { data, totals }, contractType, readOnly } = state.detailsDataAndTotal
    return {
        data,
        totals,
        contractType,
        readOnly
    }
}

export default connect(mapStateToProps)(EngineeringSummaryGrid)
