import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import 'ag-grid-community/dist/styles/ag-grid.css'

const RatesBareLaborGrid = ({
    workHourRates,
    valueFormatter,
    rowStyle,
    decimalParser
}) => {

    const readOnlyPacts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

    const customCellStyle = (pact) => {
        if (readOnlyPacts.includes(pact)) {
            return { backgroundColor: '#e8f6f8' };
        }
    }
    const bareLaborRatesValueSetter = params => {
        params.data.bareLaborPctRate = params.newValue;
        return true;
    };

    const agGrid = {
        columnDefs: [
            { headerName: 'Pact', field: 'workHourRateDefnPactCode', sortable: true, filter: true, headerClass: "header-grid", width: 70, resizable: true, },
            { headerName: 'Prime Account Description', field: 'workHourRateDefnPactDescription', sortable: true, filter: true, headerClass: "header-grid", width: 390 },
            {
                headerName: 'Bare Labor Rates', field: 'bareLaborPctRate', sortable: true, filter: true, headerClass: "header-grid", resizable: true, width: 118,
                editable: params => params.data.workHourRateDefnBrlFlag && !readOnlyPacts.includes(params.data.workHourRateDefnId),
                cellStyle: params => customCellStyle(params.data.workHourRateDefnId),
                valueSetter: params => bareLaborRatesValueSetter(params), valueParser: params => decimalParser(params, 9, 2),
                valueFormatter: params => valueFormatter(params.data.bareLaborPctRate, params.data.workHourRateDefnBlrType, params.data.workHourRateDefnBrlFlag)
            },
            {
                headerName: '% Of Original Estimate As Defined', field: 'originalEstimatePct', sortable: true, filter: true, headerClass: "header-grid",
                editable: params => params.data.workHourRateOrgEstPctFlag, width: 180, resizable: true,
                valueParser: params => decimalParser(params, 3, 2),
                valueFormatter: params => valueFormatter(params.data.originalEstimatePct, params.data.workHourRateDefnOrgEstPctType, params.data.workHourRateOrgEstPctFlag)
            },
            {
                headerName: 'Material Dollar/Direct Labor Hour', field: 'maltUnitRate', sortable: true, filter: true, headerClass: "header-grid",
                editable: params => params.data.workHourRateMtlDlrsFlag, width: 182, resizable: true,
                valueParser: params => decimalParser(params, 9, 2),
                valueFormatter: params => valueFormatter(params.data.maltUnitRate, params.data.workHourRateDefnMtlDlrsType, params.data.workHourRateMtlDlrsFlag)
            },
            {
                headerName: '% Of Total Direct Labor Dollars', field: 'directLaborPctRate', sortable: true, filter: true, headerClass: "header-grid",
                editable: params => params.data.workHourRateDirLbrPctFlag, width: 150, resizable: true,
                valueParser: params => decimalParser(params, 3, 2),
                valueFormatter: params => valueFormatter(params.data.directLaborPctRate, params.data.workHourRateDefnDirLbrPctType, params.data.workHourRateDirLbrPctFlag)
            }
        ]
    }

    return (

        <AgGridReact getRowStyle={rowStyle} columnDefs={agGrid.columnDefs} rowData={workHourRates} suppressMenuHide="true"
            domLayout="autoHeight" className="ag-theme-bootstrap" headerHeight="50" singleClickEdit={true} />

    )
}

export default RatesBareLaborGrid;