import { useDispatch, connect } from "react-redux";
import 'ag-grid-community/dist/styles/ag-grid.css';
import Icon from '../../../../components/Icon/Icon';
import { CONTRACT_TYPE } from '../type-and-time/Options'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import React, { useCallback, useEffect, useRef } from 'react'
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { directLaborSummarySetData } from '../redux/detailsDataAndTotalSlice';
import NumericEditor from "../../../../utilities/ag-grid-utilities/cell-editors/NumericEditor"
import DecimalEditor from "../../../../utilities/ag-grid-utilities/cell-editors/DecimalEditor"
import { currencyFormatter, getRowStyle, getRowSpanForDescriptionTotal, getTotalDescription, isFooter, defaultGridOptions, sortById } from "../../../../utilities/ag-grid-utilities/AgGridUtilities"
import { PREMIUM_TIME_OPTIONS } from "../../../../setup/constants";

const PRORATABLES_PACT = "16"
const PRODUCTIVITY_ADJUSTMENT_PACT = "18"
const UOM_OPTIONS = ["","CY", "TN", "EA", "LF"]
const NOT_EDITABLE_DIRECT_MATERIALS_PACT_ID = ["16"]
const NOT_EDITABLE_DIRECT_LABOR_HOURS_PACT_ID = ["16", "18"]
const TOTAL_DESCRIPTION = "Total Direct Cost (DIV L, D & I)"
const PRORATABLES_SUMMARY_PACTS = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "17"]
const PRODUCTIVITY_ADJUSTMENT_SUMMARY_PACTS = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15"]

const cellClassRules = {
    'override-status': params => (params.colDef.field === "directLaborHours" && params.data.isDirectLaborEditted),
    'editable-cell': params => (!NOT_EDITABLE_DIRECT_LABOR_HOURS_PACT_ID.includes(params.data.pact) || params.colDef.field !== "directLaborHours") && !isFooter(params)
    && !(params.colDef.field === "directLaborHours" && params.data.isDirectLaborEditted)
}

const minWidths = {
    pact: 70,
    uom: 115,
    activity: 380,
    unitRate: 115,
    quantity: 115,
    subDollars: 140,
    directLaborHours: 145,
    directLaborDollars: 115,
    directMaterialDollars: 155,
}

const cellRendererDollar = (params, toFixed = 0) => {
    return `<div><span class="float-left text-primary font-weight-bold">$</span>${currencyFormatter(parseFloat(params.value), '', toFixed)}</div>`
}

const cellRendererDropdown = (params) => {
    return <div><span className="float-left">{params.value ?? ""}</span> <FontAwesomeIcon icon={['fas', 'chevron-down']} className="float-right font-weight-bold mt-1 text-primary" /></div>
}

const cellRendererWarning = (params) => {
    return <div className="d-flex override-status"><Icon icon="exclamation-triangle" color="#E76143" size="sm" className="px-1" title="Please verify rates are set" />{params.value}</div>
}


const getRangeSummary = (params, arrayRange) => params.filter(p => arrayRange.includes(p.pact)).reduce((a, b) => a += Number(b.directLaborHours), 0)

const getOriginalEstimatePctByPact = (list, pact) => {
    const record = list.find(l => l.pact === pact)
    return record?.labor?.originalEstimatePct ?? 0
}

const otDlHours = (directLaborHours, premiumTimeMarkup) => (directLaborHours * premiumTimeMarkup) - directLaborHours
const stDlHours = (directLaborHours, premiumTimeMarkup, pact) => {
    return pact === PRODUCTIVITY_ADJUSTMENT_PACT ? Math.ceil(directLaborHours * 0.8) : directLaborHours - otDlHours(directLaborHours, premiumTimeMarkup);
}
const getDirectLaborValue = (rate, markup, overlay, directLaborHours, premiumTimeMarkup, pact) => Math.ceil((((rate * markup) + overlay) * stDlHours(directLaborHours, premiumTimeMarkup, pact))) + Math.ceil((((rate * markup) + overlay) * otDlHours(directLaborHours, premiumTimeMarkup)))


const DirectLaborSummaryGrid = React.memo(({ data, totals, contractType, markup, productivityAdjustment, readOnly }) => {
    const dispatch = useDispatch()
    const doUpdate = useRef(false)
    const dataRef = useRef(...data)


    useEffect(() => {
        dataRef.current = [...data]
    }, [data])



    const applyFormulasToDataModel = useCallback((editedData) => {
        const markupType = contractType === CONTRACT_TYPE.LumpSum ? 'lumpSumMarkup' : 'costReimbursibleMarkup'
        const overlayType = contractType === CONTRACT_TYPE.LumpSum ? 'lumpSumOverlay' : 'costReimbursibleOverlay'
        const proratableSummary = getRangeSummary(editedData, PRORATABLES_SUMMARY_PACTS)
        const proratableValue = Math.ceil(getOriginalEstimatePctByPact(editedData, PRORATABLES_PACT) * proratableSummary)
        const productivityAdjustmentValue = Math.ceil(getRangeSummary(editedData, PRODUCTIVITY_ADJUSTMENT_SUMMARY_PACTS) * (productivityAdjustment* 0.01))
        console.log(editedData)
        const updatedDataModel = editedData.map(row => {
            const { labor, directLaborHours, pact, quantity, unitRate, calcDirectLaborHour, isDirectLaborHourField } = row
            const unitRateXQuantity = Math.ceil(quantity * unitRate)
            const finalDirectLaborHours = calcDirectLaborHour && unitRateXQuantity > 0 ? unitRateXQuantity : directLaborHours; 
            const isDirectLaborEditted = !!isDirectLaborHourField && unitRateXQuantity > 0  && directLaborHours !== unitRateXQuantity 
            return {
                ...row,
                directLaborDollars: getDirectLaborValue(labor.rate, labor[markupType], labor[overlayType], directLaborHours, markup, pact),
                directLaborHours: !NOT_EDITABLE_DIRECT_LABOR_HOURS_PACT_ID.includes(pact) ? finalDirectLaborHours : (pact === PRODUCTIVITY_ADJUSTMENT_PACT ? productivityAdjustmentValue : proratableValue),
                isDirectLaborEditted
            }
        })

        const totalDirectLaborHours = updatedDataModel.reduce((a, b) => a + b.directLaborHours, 0)
        const updatedDataModelWithTotal = updatedDataModel.map(row => {
            const { directMaterialDollars, pact, labor: { matlUnitRate } } = row
            return {
                ...row,
                directMaterialDollars: pact === PRORATABLES_PACT ? totalDirectLaborHours * matlUnitRate : Number(directMaterialDollars)
            }
        })

        if (updatedDataModelWithTotal.length > 0) {
            dispatch(directLaborSummarySetData(updatedDataModelWithTotal))
        }
    }, [contractType, dispatch, markup, productivityAdjustment])

    const valueSetter = (params) => {
        if (params.colDef.field === "directLaborHours") {
            //Validate hour precision and scale
            if (params.newValue > 999999) {
                return params.oldValue
            }
        } else if (params.colDef.field === "directMaterialDollars") {
            //Validate Direct Material precision and scale
            if (params.newValue > 999999999.99) {
                return params.oldValue
            }
        } else if (params.colDef.field === "subDollars") {
            //Validate Direct Material precision and scale
            if (params.newValue > 999999999.99) {
                return params.oldValue
            }
        }

        const isTextField = ["activityDescription", "uom"].includes(params.colDef.field)
        const isNewValue = isTextField ? params.oldValue !== params.newValue : Number(params.oldValue) !== Number(params.newValue)
        if (isNewValue) {
            const calcDirectLaborHour = ["unitRate", "quantity"].includes(params.colDef.field)
            const isDirectLaborHourField = params.colDef.field === "directLaborHours";
            const value = isTextField ? params.newValue : Number(params.newValue);
            const updatedRow = { ...params.data, [params.column.colId]: value, edited: true, calcDirectLaborHour, isDirectLaborHourField }
            const filteredData = dataRef.current.filter(f => f.id !== params.data.id)
            applyFormulasToDataModel([...filteredData, ...[updatedRow]].sort(sortById))
        }
        return isNewValue
    }


    const recalculateFormulas = useCallback(() => {
        applyFormulasToDataModel(data)
    }, [applyFormulasToDataModel, data])

    useEffect(() => {
        if (doUpdate.current) {
            recalculateFormulas()
        }
    }, [contractType, recalculateFormulas, markup, productivityAdjustment])

    return (

        <div id="direct-labor-summary" className="ag-theme-bootstrap" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ flex: '1 1 auto' }} >
                <AgGridReact
                    getRowStyle={getRowStyle}
                    gridOptions={{ ...defaultGridOptions }}
                    rowData={data}
                    getRowNodeId={data => data.id}
                    immutableData={true}
                    domLayout="autoHeight"
                    pinnedBottomRowData={[totals]}
                    singleClickEdit={true}
                    enableCellChangeFlash
                    onFirstDataRendered={() => doUpdate.current = true}
                    onCellValueChanged={(params) => params.api.refreshCells({ force: true, suppressFlash: true, columns: ["activityDescription"] })}
                    frameworkComponents={{
                        numericCellEditor: NumericEditor,
                        decimalCellEditor: DecimalEditor
                    }}>
                    <AgGridColumn headerName="Edited" field="edited" hide valueFormatter={params => params.value ?? false} />
                    <AgGridColumn headerName="Pact" field="pact" headerClass="header-grid" maxWidth={70} minWidth={minWidths.pact}
                        colSpan={getRowSpanForDescriptionTotal}
                        valueFormatter={params => getTotalDescription(params, TOTAL_DESCRIPTION)} />
                    <AgGridColumn headerName="Activity" field="activityDescription" headerClass="header-grid" minWidth={minWidths.activity}
                        editable={params => !isFooter(params) && params.data.activityDescription.toLowerCase() === 'undefined'}
                        cellRendererFramework={params => Number(params.data.directLaborHours) > 0 &&  Number(params.data.directLaborDollars) === 0 ? cellRendererWarning(params) : params.value}
                        valueSetter={valueSetter} />
                    <AgGridColumn 
                        headerName="D. Labor Hours" 
                        field="directLaborHours" editable={params => !readOnly && !isFooter(params) && !NOT_EDITABLE_DIRECT_LABOR_HOURS_PACT_ID.includes(params.data.pact)}
                        cellEditor="numericCellEditor" 
                        headerClass="header-grid right-sortable-header-grid" 
                        type="numericColumn" 
                        cellClassRules={cellClassRules} 
                        minWidth={minWidths.directLaborHours}
                        valueFormatter={(params) => currencyFormatter(params.data.directLaborHours, '')}
                        valueSetter={valueSetter} />
                    <AgGridColumn headerName="D. Labor $" field="directLaborDollars" headerClass="header-grid right-sortable-header-grid" cellEditor="numericCellEditor" type="numericColumn" minWidth={minWidths.directLaborDollars}
                        valueFormatter={params => currencyFormatter(params.data.directLaborDollars ?? 0, '$')} />
                    <AgGridColumn 
                        headerName="Unit Rate" field="unitRate" 
                        editable={params => !readOnly && !isFooter(params)} 
                        cellEditor="decimalCellEditor"
                        headerClass="header-grid right-sortable-header-grid" 
                        type="numericColumn" 
                        cellClassRules={cellClassRules} 
                        minWidth={minWidths.unitRate}
                        cellRenderer={(params) => isFooter(params) ? '' : cellRendererDollar(params, 2)}
                        valueSetter={valueSetter} />
                    <AgGridColumn headerName="UOM" field="uom" editable={params => !readOnly && !isFooter(params)} headerClass="header-grid right-sortable-header-grid"
                        cellClassRules={cellClassRules} minWidth={minWidths.uom} cellEditor="agRichSelectCellEditor"
                        cellEditorParams={{ values: UOM_OPTIONS }}
                        cellRendererFramework={params => isFooter(params) ? null : cellRendererDropdown(params)}
                        valueSetter={valueSetter} />
                    <AgGridColumn headerName="Quantity" field="quantity" editable={params => !readOnly && !isFooter(params)} cellEditor="numericCellEditor"
                        headerClass="header-grid right-sortable-header-grid" type="numericColumn" cellClassRules={cellClassRules} minWidth={minWidths.quantity}
                        valueSetter={valueSetter} />
                    <AgGridColumn headerName="Direct Materials" field="directMaterialDollars" sortable
                        editable={params => !readOnly && !isFooter(params) && !NOT_EDITABLE_DIRECT_MATERIALS_PACT_ID.includes(params.data.pact)} cellEditor="numericCellEditor"
                        headerClass="header-grid right-sortable-header-grid" type="numericColumn" cellClassRules={cellClassRules} minWidth={minWidths.directMaterialDollars}
                        cellRenderer={params => isFooter(params) ? currencyFormatter(params.value, '$', 0) : cellRendererDollar(params, 0)}
                        valueSetter={valueSetter} />
                    <AgGridColumn headerName="Subcontracts" field="subDollars" editable={params => !readOnly && !isFooter(params)} cellEditor="numericCellEditor"
                        headerClass="header-grid right-sortable-header-grid" type="numericColumn" cellClassRules={cellClassRules} minWidth={minWidths.subDollars}
                        cellRenderer={params => isFooter(params) ? currencyFormatter(params.value, '$') : cellRendererDollar(params)}
                        valueSetter={valueSetter} />
                </AgGridReact>
            </div>
        </div>
    )

}, (prevProps, nextProps) => {
    return (
        prevProps.contractType === nextProps.contractType &&
        prevProps.markup === nextProps.markup &&
        prevProps.productivityAdjustment === nextProps.productivityAdjustment &&
        JSON.stringify(prevProps.totals) === JSON.stringify(nextProps.totals) &&
        JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
    )
})

const mapStateToProps = (state) => {
    const { directLaborSummary: { data, totals }, contractType, productivityAdjustment, premiumTime: { markup, value }, premiumTimeOther, readOnly } = state.detailsDataAndTotal
    return {
        data,
        totals,
        markup: value === PREMIUM_TIME_OPTIONS.OTHER ? premiumTimeOther : markup,
        contractType,
        productivityAdjustment,
        readOnly
    }
}

export default connect(mapStateToProps)(DirectLaborSummaryGrid)
