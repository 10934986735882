import axios from '../../../../setup/axios'

class PCRService {
    BASE_ROUTE = "/log/pcr";

    getApprovePCRByPMInfo(pcrId) {
        return this.get(`${pcrId}/approvedByPM`)
    }

    approvePCRByPE(approveData) {
        return this.post(`approveByPE`, approveData)
    }

    approvePCRByPM(approveData) {
        return this.post(`approveByPM`, approveData)
    }

    approvePCRByClient(approveData) {
        return this.post(`approveByClient`, approveData)
    }

    get(route) {
        return axios.get(`${this.BASE_ROUTE}/${route}`)
    }

    post(route, data) {
        return axios.post(`${this.BASE_ROUTE}/${route}`, data);
    }

    put(route, data) {
        return axios.put(`${this.BASE_ROUTE}/${route}`, data);
    }
}

export default new PCRService()