import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Popover, PopoverBody, Row, Col } from "reactstrap";

const ActionButton = ({ label, icon, placement, links }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  const buttonId = `action-button-${label}`;

  return (
    <>
      <Button
        color="primary"
        className="d-flex align-items-center px-4"
        id={buttonId}
      >
        {label}
        {icon && <div className="pl-1 fa-xs">{icon}</div>}
      </Button>

      <Popover
        trigger="legacy"
        placement={placement ? placement : "bottom"}
        isOpen={popoverOpen}
        target={buttonId}
        toggle={toggle}
      >
        <PopoverBody>
          {links &&
            links.map((link, index) => {
              return (
                <Row key={index}>
                  <Col>
                    <Link to={link.path}>{link.label}</Link>
                  </Col>
                </Row>
              );
            })}
        </PopoverBody>
      </Popover>
    </>
  );
};

export default ActionButton;
