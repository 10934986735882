// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectDetails_ProjectDetails__2q-6F {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: 10px;\n    font-weight: normal; \n    margin-right:20px  \n}", "",{"version":3,"sources":["webpack://src/components/ProjectDetails/ProjectDetails.module.css"],"names":[],"mappings":"AAAA;IACI,2DAA2D;IAC3D,eAAe;IACf,mBAAmB;IACnB;AACJ","sourcesContent":[".ProjectDetails {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: 10px;\n    font-weight: normal; \n    margin-right:20px  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProjectDetails": "ProjectDetails_ProjectDetails__2q-6F"
};
export default ___CSS_LOADER_EXPORT___;
