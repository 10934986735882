export const STANDARD_DISCIPLINES = ["CIVIL", "ELECTRICAL", "INSTRUMENT", "MECHANICAL", "PIPING", "PROCESS"]
const PIPELINE_DISCIPLINES = ["PIPELINE - PROCESS", "PIPELINE - CIVIL / STRUCTURAL", "PIPELINE - PIPING", "PIPELINE - MECHANICAL",
    "PIPELINE - INSTRUMENT / ELECTRICAL", "PIPELINE - GENERAL", "PIPELINE - ENGINEERING", "PIPELINE - COORDINATION"]
const INFRASTRUCTURE_DISCIPLINES = ["INFRASTRUCTURE - ELECTRICAL & INSTRUMENTAL", "INFRASTRUCTURE - ENGG",
    "INFRASTRUCTURE INFORMATION MANAGEMENT"]
export const SBI_DISCIPLINES = ["SBI - ACCOUNTING", "SBI - CIVIL", "SBI - ELECTRICAL", "SBI - ESTIMATING", "SBI - INSTRUMENTS", "SBI - INFORMATION MANAGEMENT",
    "SBI - MATERIAL CONTROL", "SBI - MECHANICAL", "SBI - PROCESS", "SBI - PROCUREMENT", "SBI - PROJECT CONTROLS", "SBI - PROJECT ENGINEER", "SBI - PROJECT MANAGER",
    "SBI - PIPING","SBI - QA / QC"]

export const DISCIPLINES_BY_PROJECT = [{ initial: "A", disciplines: STANDARD_DISCIPLINES }, { initial: "C", disciplines: STANDARD_DISCIPLINES },
    { initial: "D", disciplines: [] }, { initial: "E", disciplines: STANDARD_DISCIPLINES }, { initial: "M", disciplines: PIPELINE_DISCIPLINES},
    { initial: "P", disciplines: [] }, { initial: "R", disciplines: INFRASTRUCTURE_DISCIPLINES }, { initial: "U", disciplines: INFRASTRUCTURE_DISCIPLINES },
    { initial: "V", disciplines: STANDARD_DISCIPLINES }, { initial: "W", disciplines: STANDARD_DISCIPLINES}, { initial: "Z", disciplines: STANDARD_DISCIPLINES }]