import { Controller, useFormState } from "react-hook-form";
import FormFieldError from '../../../../UI/FormFieldError';

import classes from './RatesCopyForm.module.css';
import Select from 'react-select'
import TemplateService from '../../api/templatesService'
import ProjectService from '../../../../services/projectsService'
import {
    Container,
    Row,
    Col,
    FormGroup,
    Label,
    Input
} from "reactstrap";

import { useEffect, useState } from "react";
import { StarIndicator } from "../../../../components/StarIndicator";
import {useSelector} from 'react-redux';

const RatesCopyForm = ({
    control,
    getValues,
    errors,
    setValue,
    watch,
    register,
    trigger
}) => {
    const [projectOptions, setprojectOptions] = useState([]);
    const [templateOptions, setTemplateOptions] = useState([]);
    const userId = useSelector(state => state.user.user.userId);
    const radioRatesSelected = watch("rates");
    const { dirtyFields } = useFormState({ control });
    const sortByLabel = ((a, b) => {
        return a.label > b.label ? 1 : -1
    })
    useEffect(() => {
        register("rates");
        setValue("rates", "manually")
    }, [register, setValue]);

    useEffect(() => {
        ProjectService.getProjectsByUser(userId)
            .then(response => {
                const projectOptions = response.data.data ?
                    response.data.data.map(project => { return { value: project.jobNo, label: `${project.jobNo}-${project.projectTitle}` } }) :
                    [];
                setprojectOptions(projectOptions.sort(sortByLabel))
            }).catch(error => console.log(error))
    }, []);

    const handleSelectedJobChange = selectedJob => {
        setTemplateOptions([]);
        setValue("templateToCopy", " ")
        trigger("templateToCopy");

        TemplateService.getTemplatesByJobNumber(selectedJob.value)
            .then((response) => {
                const templateOptions = response.data.data ?
                    response.data.data.map(template => { return { value: template.id, label: template.name } }) :
                    [];
                setTemplateOptions(templateOptions.sort(sortByLabel));
            }).catch(error => console.log(error))
    };

    const handleRadioChange = e => {
        setValue("rates", e.target.value)
        trigger("templateToCopy");
    };

    return (
        <Container>
            <h5>Rates</h5>
            <Row className={classes.rowPadding}>
                <Row>
                    <Col>
                        <FormGroup className={classes.radioButtonWidth}>
                            <Input type="radio" name="rates" value="copy" onChange={handleRadioChange} />{' '}
                            Copy Rates
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup className={classes.radioButtonWidth}>
                            <Input type="radio" name="rates" value="manually" defaultChecked="manually" onChange={handleRadioChange} />{' '}
                            Add Rates Manually
                        </FormGroup>
                    </Col>
                </Row>
            </Row>

            <Row className="mt-md-3">
                <Col sm="3" lg="2">
                    <Label for="sourceTemplate">Source Job Number<StarIndicator /></Label>
                </Col>
                <Col sm="3" lg="4" >
                    <Select options={projectOptions} onChange={handleSelectedJobChange} isDisabled={radioRatesSelected === "manually"} />
                </Col>
                <Col sm="3" lg="2">
                    <Label for="sourceTemplate">Source Template<StarIndicator /></Label>
                </Col>
                <Col sm="3" lg="4">
                    <Controller
                        control={control}
                        name="templateToCopy"
                        render={({ field }) =>
                            < Select {...field} options={templateOptions} data-testid="templateToCopy" isDisabled={radioRatesSelected === "manually"} />}
                        rules={{
                            validate: value => validateTemplateToCopy(value, getValues),
                            required: getValues("rates") === "copy" ? true : false
                        }}
                    />
                    {((errors.templateToCopy && dirtyFields.templateToCopy) && getValues("rates") === "copy") && <FormFieldError className="text-danger">Required when copy rates is checked</FormFieldError>}
                </Col>
            </Row>
        </Container>
    )
}

export default RatesCopyForm

function validateTemplateToCopy(value, getValues) {
    if (getValues("templateToCopy") !== " " && (getValues("rates") === "copy"))
        return true;
    if (getValues("rates") === "manually")
        return true;
    return "Error";
}
