// import { lazy } from "react";
import { Switch } from "react-router";
import routes from '../../setup/routes';
import Page from "../../UI/Page";
import AuthorizedRoute from "../auth/Authorized/AuthorizedRoute";
import {
    CLIENT, CONSTRUCTION_MANAGER,
    FCO_USERS,
    PROJECT_CONTROLS,
    PROJECT_COORDINATOR,
    PROJECT_ENGINEER,
    PROJECT_MANAGER
} from './logs-roles/Roles';
import Log from './list-logs/Log';
import UpdatePCI from './pci/update-pci/UpdatePCI';
import CreatePCI from './pci/create-pci/CreatePCI';
import UpdatePCR from './pcr/update-pcr/UpdatePCR';
import CreatePCR from './pcr/create-pcr/CreatePCR';
import UpdateFCO from './fco/update-fco/UpdateFCO';
import CreateFCO from './fco/create-fco/CreateFCO';
import DisciplineImpact from './pcr/disciplines/DisciplineImpact';
import DetailsPage from './details/DetailsPage';
import ApprovePCIByPE from './pci/approve-pci/by-pe/ApprovePCIByPE';
import ApprovePCIByClient from './pci/approve-pci/by-client/ApprovePCIByClient';
import ApproveFCOByClient from './fco/approve-fco/by-client/ApproveFCOByClient';
import ApprovePCRByClient from './pcr/approve-pcr/by-client/ApprovePCRByClient';
import ApproveFCOByCM from './fco/approve-fco/by-cm/ApproveFCOByCM';
import ApprovePCIByPM from './pci/approve-pci/by-pm/ApprovePCIByPM';
import ApprovePCRByPE from './pcr/approve-pcr/by-pe/ApprovePCRByPE';
import ApprovePCRByPM from './pcr/approve-pcr/by-pm/ApprovePCRByPM';

const LogsPage = () => {
    return (
        <Page>
            <Switch>
                <AuthorizedRoute exclude={[CLIENT]} path={routes.UpdatePCIRoute} component={UpdatePCI}></AuthorizedRoute>
                <AuthorizedRoute exclude={[CLIENT]} path={routes.CreatePCIRoute} component={CreatePCI}></AuthorizedRoute>
                <AuthorizedRoute exclude={[CLIENT]} path={routes.UpdatePCRRoute} component={UpdatePCR}></AuthorizedRoute>
                <AuthorizedRoute exclude={[CLIENT]} path={routes.CreatePCRRoute} component={CreatePCR}></AuthorizedRoute>
                <AuthorizedRoute roles={[...FCO_USERS]} path={routes.UpdateFCORoute} component={UpdateFCO}></AuthorizedRoute>
                <AuthorizedRoute roles={[...FCO_USERS]} path={routes.CreateFCORoute} component={CreateFCO}></AuthorizedRoute>
                <AuthorizedRoute exclude={[CLIENT]} path={routes.DisciplinesPCRRoute} component={DisciplineImpact}></AuthorizedRoute>
                <AuthorizedRoute exclude={[CLIENT]} path={routes.EditDetailsRoute} component={DetailsPage}></AuthorizedRoute>
                <AuthorizedRoute path={routes.ApprovePCIByPERoute} component={ApprovePCIByPE} roles={[PROJECT_CONTROLS, PROJECT_MANAGER, PROJECT_ENGINEER]}></AuthorizedRoute>
                <AuthorizedRoute path={routes.ApprovePCIByClientRoute} component={ApprovePCIByClient} roles={[PROJECT_MANAGER, PROJECT_ENGINEER, PROJECT_CONTROLS, CLIENT]}></AuthorizedRoute>
                <AuthorizedRoute path={routes.ApproveFCOByClientRoute} component={ApproveFCOByClient} roles={[CONSTRUCTION_MANAGER, PROJECT_COORDINATOR, PROJECT_MANAGER, PROJECT_ENGINEER, PROJECT_CONTROLS, CLIENT]}></AuthorizedRoute>
                <AuthorizedRoute path={routes.ApprovePCRByClientRoute} component={ApprovePCRByClient} roles={[PROJECT_MANAGER, PROJECT_CONTROLS, PROJECT_ENGINEER, CLIENT]}></AuthorizedRoute>
                <AuthorizedRoute path={routes.ApproveFCOByCMRoute} component={ApproveFCOByCM} roles={[PROJECT_COORDINATOR, PROJECT_MANAGER, PROJECT_ENGINEER, PROJECT_CONTROLS, CONSTRUCTION_MANAGER]}></AuthorizedRoute>
                <AuthorizedRoute path={routes.ApprovePCIByPMRoute} component={ApprovePCIByPM} roles={[PROJECT_MANAGER, PROJECT_CONTROLS, PROJECT_ENGINEER]}></AuthorizedRoute>
                <AuthorizedRoute path={routes.ApprovePCRByPERoute} component={ApprovePCRByPE} roles={[PROJECT_MANAGER, PROJECT_CONTROLS, PROJECT_ENGINEER]}></AuthorizedRoute>
                <AuthorizedRoute path={routes.ApprovePCRByPMRoute} component={ApprovePCRByPM} roles={[PROJECT_MANAGER, PROJECT_CONTROLS, PROJECT_ENGINEER]}></AuthorizedRoute>
                <AuthorizedRoute exact path={routes.LogRoute} component={Log} exclude={[CLIENT]}></AuthorizedRoute>
            </Switch>
        </Page>
    );
}

export default LogsPage;