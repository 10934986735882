import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {openSuccess} from '../../../success/redux/successSlice'
import Page from '../../../../UI/Page';
import {LogRoute, getUpdatePCIRoute} from '../../../../setup/routes'
import FeatureTitle from '../../../../components/FeatureTitle/FeatureTitle'
import PCIBasicInfoForm from '../pci-basic-info-form/PCIBasicInfoForm';
import PCIActionButtons from '../PCIActionButtons';
import LogService from '../../api/logService'
import {
    Container,
    Row,
    Col,
    Form
} from 'reactstrap';

const CreatePCI = (params) => {

    const queryParams = new URLSearchParams(params.location.search);
    const isVar = queryParams.get('isVar') || false;
    const {projectId} = useSelector((state) => state)
    const userId = useSelector(state => state.user.user.userId);
    const [pciStatusAndAreas, setPciStatusAndAreas] = useState(null)
    const [attachments, setAttachments] = useState([])
    const history = useHistory();
    const dispatch = useDispatch()
    const [saveInProgress, setSaveInProgress] = useState(false)

    useEffect(() => {
        async function getPciStatusAndAreas() {
            const response = await LogService.getPCIStatusesAndAreas(projectId.value)
            setPciStatusAndAreas(response.data.data)
        }

        getPciStatusAndAreas()
    }, [projectId])

    const {getValues, formState: {errors, isValid}, control, handleSubmit} = useForm({
        defaultValues: {
            pciStatus: '1',
            pciTitle: '',
            clientNumber: '',
            originator: userId,
            variance: isVar
        },
        mode: 'onChange'
    });

    const onSave = handleSubmit(async (data) => {
        const formData = new FormData()
        const command = formatPCIRequestObject(data)
        command.attachments = []
        attachments.forEach((file) => {
            if (file.name) {
                formData.append('files', file, file.name)
            } else {
                command.attachments.push({
                    'fileName': file.fileName,
                    'status': file.status
                })
            }
        })
        formData.append('command', new Blob([JSON.stringify(command)], {type: 'application/json'}))
        try {
            setSaveInProgress(true)
            const response = await LogService.savePCI(formData)
            if (response.data.data) {
                const redirectUrl = getUpdatePCIRoute(response.data.data.logId)
                dispatch(openSuccess('The PCI was saved.'))
                history.push(redirectUrl);
            }
            setSaveInProgress(false)
        } catch (e) {
            setSaveInProgress(false)
            console.log(e)
        }

    })

    const onSubmitPci = handleSubmit(async (data) => {
        const formData = new FormData()
        const command = formatPCIRequestObject({...data, submit: true})
        command.attachments = []
        attachments.forEach((file) => {
            if (file.name) {
                formData.append('files', file, file.name)
            } else {
                command.attachments.push({
                    'fileName': file.fileName,
                    'status': file.status
                })
            }
        })
        formData.append('command', new Blob([JSON.stringify(command)], {type: 'application/json'}))
        try {
            setSaveInProgress(true)
            const response = await LogService.savePCI(formData)
            if (response.status === 200) {
                if (!response.data.errors || response.data.errors.length === 0) {
                    const redirectUrl = getUpdatePCIRoute(response.data.data.logId)
                    dispatch(openSuccess('The PCI was created and submitted.'))
                    history.push(redirectUrl);
                }
            }
            setSaveInProgress(false)
        } catch (e) {
            setSaveInProgress(false)
            console.log(e)
        }
    })

    const formatPCIRequestObject = ({
                                        pciStatus,
                                        clientNumber,
                                        pciArea,
                                        pciTitle,
                                        pciDescription,
                                        variance,
                                        submit,
                                        sbiEnable
                                    }) => (
        {
            userId,
            projectId: projectId.value,
            status: pciStatus,
            clientNumber: clientNumber,
            area: pciArea,
            title: pciTitle,
            description: pciDescription,
            variance,
            submit,
            sbiEnable
        }
    )

    return (
        <Page>
            <Container>
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title="Project Change: New"/>
                            <hr/>
                        </Container>
                    </Col>
                </Row>
                <Form>
                    <Row>
                        <Col>
                            {pciStatusAndAreas && <PCIBasicInfoForm
                                control={control} errors={errors} pciStatusAndAreas={pciStatusAndAreas}
                                setAttachments={setAttachments}
                                attachments={attachments} getValues={getValues}/>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr/>
                            <PCIActionButtons cancelRoute={LogRoute} onSave={onSave} isValid={isValid}
                                              isDisabledSubmitPci={!isValid} onSubmitPci={onSubmitPci}
                                              saveInProgress={saveInProgress}
                                              isDisabledSubmitManually={true}
                            />
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Page>
    );
}

export default CreatePCI