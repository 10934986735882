import logo from './../../../assets/images/sbec.png'
import styles from './PageHeader.module.css'
import ProjectDetails from '../../ProjectDetails/ProjectDetails'
import ProjectDropdown from '../../ProjectDropdown/ProjectDropdown'
import Authorized from '../../../features/auth/Authorized/Authorized';
import {CLIENT} from '../../../features/logs/logs-roles/Roles';
import SlideoutMenu from '../../SlideoutMenu/SlideoutMenu';

const PageHeader = () => {
    return (
        <>
            <header className={styles.header}>
                <table style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td style={{width: '70px', padding: '10px'}}>
                            <SlideoutMenu/>
                        </td>
                        <td>
                            <div>
                                <img
                                    className="img-fluid"
                                    src={logo}
                                    width="60px"
                                    alt="S&B Engineers and Constructors, Ltd."
                                />
                            </div>
                        </td>
                        <td style={{width: '55%'}}>
                        </td>
                        <td style={{width: '15%'}}>
                            <Authorized exclude={[CLIENT]}>
                                <ProjectDropdown/>
                            </Authorized>
                        </td>
                        <td style={{width: '15%'}}>
                            <ProjectDetails/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </header>

        </>
    )
}

export default PageHeader