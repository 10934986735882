export const statuses = {
    PCR_CREATED: 0,
    PENDING_PE_REVIEW: 2,
    PENDING_PM_REVIEW: 4,
    PENDING_CLIENT_APPROVAL: 7,
    PENDING_DISCIPLINE_IMPACT: 13,
    PENDING_PE_REVIEW_PCR: 21,

    PENDING_CM_APPROVAL: 29,
    PENDING_CLIENT_APPROVAL_FCO: 32
}

export const PCR_STATES = {
    PENDING_ESTIMATING_REVIEW: 14,
    PENDING_PROJECT_MANAGER_REVIEW: 23,
    PENDING_CLIENT_REVIEW: 25,
    APPROVED_BY_CLIENT: 26
}