import { createSlice } from '@reduxjs/toolkit' 
 
export const pcrSlice = createSlice({
    name: 'pcrDetails',
    initialState: {
        sbiEnable: false     
    },
    reducers: {
        setSbiEnable: (state, action) => {
            state.sbiEnable = action.payload
        }
    }
})

export const {setSbiEnable} = pcrSlice.actions
 
export default pcrSlice.reducer 