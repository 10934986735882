import { useState, useEffect } from "react";
import { Controller } from "react-hook-form"
import { useSelector, useDispatch } from 'react-redux';
import { ErrorMessage } from '@hookform/error-message';
import NumberFormat from "react-number-format";
import { Container, Label, Input, Row, Col, InputGroup, InputGroupAddon, } from "reactstrap";
import { contractTypeSetData, premiumTimeSetData, premiumTimeOtherSetData, productivityAdjustmentSetData } from "../redux/detailsDataAndTotalSlice";
import { PREMIUM_TIME_OPTIONS } from "../../../../setup/constants";

const maxAllowedValue = (values) => {
    const { formattedValue, floatValue } = values;
    return formattedValue === "" || (floatValue <= 100 && floatValue >= 0);
}


const TypeAndTime = ({ control, errors, readOnly = false }) => {

    const dispatch = useDispatch()
    const { contractType, premiumTime, productivityAdjustment, premiumTimeOther } = useSelector(state => state.detailsDataAndTotal)
    const [premiumTimeDdOptions, setPremiumTimeDdOptions] = useState([])

    useEffect(() => {
        setPremiumTimeDdOptions(premiumTime.options.length > 0 ? premiumTime.options.map(({ id, description }) => (
            <option value={id} key={id}>{description}</option>
        )) : [])
    }, [premiumTime.options])

    const updateContractType = (newValue) => {
        dispatch(contractTypeSetData(newValue))
    }
    const updatePremiumTime = (newValue) => {
        dispatch(premiumTimeSetData(newValue))
    }
    const updatePremiumTimeOther = (newValue) => {
        dispatch(premiumTimeOtherSetData(Number(newValue) ?? 0))
    }
    const updateproductivityAdjustment = (newValue) => {
        dispatch(productivityAdjustmentSetData(Number(newValue) ?? 0))
    }

    return (
        <Container>
            <h5>{"Type & Time"}</h5>
            <Row className="mt-md-3">
                <Col sm={1}>
                    <Label for="contractType" className="font-weight-bold" >Budget Type</Label>
                </Col>
                <Col sm={2} className="d-flex">
                    <Controller
                        control={control}
                        name="contractType"
                        render={({ field }) =>
                            <div  {...field} className="d-flex">
                                <div>
                                    <Input
                                        id="contractTypeLumpSum"
                                        type="radio"
                                        value="L"
                                        checked={contractType === "L"}
                                        onChange={() => updateContractType("L")}
                                        disabled={readOnly}
                                    />
                                    <Label for="contractTypeLumpSum">Lump Sum</Label>
                                </div>

                                <div className="ml-5">
                                    <Input
                                        id="contractTypeCostPlus"
                                        type="radio"
                                        value="C"
                                        checked={contractType === "C"}
                                        onChange={() => updateContractType("C")}
                                        disabled={readOnly}
                                    />
                                    <Label for="contractTypeCostPlus">Cost Plus</Label>
                                </div>
                            </div>
                        } />
                </Col>

                <Col sm={5} >
                    <Row >
                        <Col className="text-right">
                            <Label for="premiumTime" className="font-weight-bold">Premium Time</Label>
                        </Col>
                        <Col  >
                            <Controller
                                control={control}
                                name="premiumTime"
                                render={({ field }) => <Input
                                    {...field}
                                    className="custom-blue-select"
                                    type="select"
                                    data-testid="premiumTime"
                                    bsSize="sm"
                                    value={premiumTime.value ?? ""}
                                    required={true}
                                    onChange={(e) => updatePremiumTime(Number(e.target.value))}
                                    disabled={readOnly}
                                >
                                    {premiumTimeDdOptions}
                                </Input>} />
                        </Col>
                    </Row>

                    <Row className="pt-3" hidden={premiumTime.value !== PREMIUM_TIME_OPTIONS.OTHER}>
                        <Col className="text-right">
                            <Label for="premiumTimeOther" className="font-weight-bold">Premium Time Percentage</Label>
                        </Col>
                        <Col >
                            <Controller
                                control={control}
                                name="premiumTimeOther"
                                disabled={readOnly}
                                render={({ field }) =>
                                    <InputGroup className="input-group-sm">
                                        <InputGroupAddon addonType="prepend" className="dollar-input-group-addon">%</InputGroupAddon>
                                        <NumberFormat  {...field} className="dollar-input-group-addon text-center form-control-sm form-control" decimalScale={2} fixedDecimalScale value={premiumTimeOther}
                                            onChange={(e) => updatePremiumTimeOther(e.target.value ?? 0)} isAllowed={maxAllowedValue} />
                                    </InputGroup>}
                            />
                            <ErrorMessage className="text-danger small" errors={errors} name={"premiumTimeOther"} as="p" key={"premiumTimeOther"} />
                        </Col>
                    </Row>
                </Col>

                <Col sm={4} className="d-flex" >
                    <Col className="text-right">
                        <Label for="productivityAdjustment" className="font-weight-bold">Fill in Productivity Adjustment</Label>
                    </Col>
                    <Col>
                        <Controller
                            control={control}
                            name="productivityAdjustment"
                            disabled={readOnly}
                            render={({ field }) =>
                                <InputGroup className="input-group-sm">
                                    <InputGroupAddon addonType="prepend" className="dollar-input-group-addon">%</InputGroupAddon>
                                    <NumberFormat {...field} className="dollar-input-group-addon text-center form-control-sm form-control" decimalScale={2} fixedDecimalScale value={productivityAdjustment ?? 0} type="text"
                                        onChange={(e) => updateproductivityAdjustment(e.target.value ?? 0)} isAllowed={maxAllowedValue} disabled={readOnly} />
                                </InputGroup>}

                        />
                        <ErrorMessage className="text-danger small" errors={errors} name={"productivityAdjustment"} as="p" key={"productivityAdjustment"} />
                    </Col>
                </Col>

            </Row>

        </Container>
    )
}

export default TypeAndTime
