import {useState, useEffect, useMemo} from 'react';
import {
    Container,
    Label,
    Input,
    Row,
    Col
} from 'reactstrap';
import {Controller} from 'react-hook-form'
import {StyledColumn} from '../../../../components/StyledColumn';
import styled from 'styled-components';
import {ErrorMessage} from '@hookform/error-message';
import {StarIndicator} from '../../../../components/StarIndicator'
import {Attachments} from '../../attachments';
import {PROJECT_CONTROLS, PROJECT_COORDINATOR} from '../../logs-roles/Roles';
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';

const RadioContainer = styled.div`
  border-style: dashed;
  border-color: #B1C1CE;
  border-width: 1px;
  border-radius: 2px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  display: flex;
`
const stringToBoolean = (string) => string === 'false' ? false : !!string

const FCOBasicInfoForm = ({
                              dropdownsData,
                              control,
                              readOnly = false,
                              errors,
                              attachments,
                              setAttachments,
                              getValues
                          }) => {
    const [fcoTypeOptions, setFcoType] = useState([])
    const [fcoCauseOptions, setFcoCauseOptions] = useState([])
    const [fcoStatusOptions, setFcoStatusOptions] = useState([])
    const [areaOptions, setAreaOptions] = useState([])
    const [scheduleImpact, setScheduleImpact] = useState(false)
    const [projectType, setProjectType] = useState('L')
    const [tracking, setTracking] = useState(false)
    //const [variance, setVariance] = useState(false)
    const [templateOptions, setTemplateOptions] = useState([])

    const projectTypeValue = getValues('projectType') ?? 'L'
    const scheduleImpactValue = stringToBoolean(getValues('scheduleImpact')) ?? false
    const statusValue = getValues('status') ?? 28
    const trackingValue = stringToBoolean(getValues('tracking')) ?? false
    //const varianceValue = stringToBoolean(getValues("variance")) ?? false
    const {isAuthorized} = useAuthorized();

    const statusDisabled = useMemo(() => {
        // Awaiting Client Approval & Client Approved
        let disabled = statusValue === 33 || statusValue === 32 || readOnly

        if (isAuthorized([PROJECT_CONTROLS, PROJECT_COORDINATOR])) {
            disabled = false
        }
        return disabled
    }, [statusValue, readOnly])

    useEffect(() => {
        setTracking(trackingValue)
    }, [trackingValue])


    useEffect(() => {
        setProjectType(projectTypeValue)
    }, [projectTypeValue])

    useEffect(() => {
        setScheduleImpact(scheduleImpactValue)
    }, [scheduleImpactValue])

    /*useEffect(() => {
        setVariance(varianceValue)
    }, [varianceValue])*/

    const sortByDescription = ((a, b) => {
        return a.description > b.description ? 1 : -1
    })
    const sortByName = ((a, b) => {
        return a.name > b.name ? 1 : -1
    })
    const sortByCauseCategory = ((a, b) => {
        return a.causeCategory > b.causeCategory ? -1 : 1
    })

    useEffect(() => {
        if (dropdownsData) {
            setFcoType(dropdownsData.types ? dropdownsData.types.sort(sortByDescription).map(({code, description}) => (
                <option value={code} key={code}>{description}</option>
            )) : [])

            setFcoCauseOptions(dropdownsData.causes ? dropdownsData.causes.sort(sortByCauseCategory).map(({
                                                                                                              id,
                                                                                                              description
                                                                                                          }) => (
                <option value={id} key={id}>{description}</option>
            )) : [])

            setAreaOptions(dropdownsData.areas ? dropdownsData.areas.map(({taskNumber}) => (
                <option value={taskNumber} key={taskNumber}>{taskNumber}</option>
            )) : [])
            setFcoStatusOptions(dropdownsData.statuses ? dropdownsData.statuses.sort(sortByDescription).map(({
                                                                                                                 id,
                                                                                                                 description
                                                                                                             }) => (
                <option value={id} key={id}>{description}</option>
            )) : [])
            setTemplateOptions(dropdownsData.templates ? dropdownsData.templates.sort(sortByName).map(({id, name}) => (
                <option value={id} key={id}>{name}</option>
            )) : [])
        }
    }, [dropdownsData])
    return (
        <Container>
            <h5>General Information</h5>
            <Row className="d-flex">
                <Col lg="7">
                    {dropdownsData.statuses &&
                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="status">Status</Label>
                            </StyledColumn>
                            <Col sm="4" lg="6" className="pr-5">
                                <Controller
                                    control={control}
                                    name="status"
                                    render={({field}) => <Input
                                        type="select"
                                        data-testid="status"
                                        bsSize="sm"
                                        disabled={statusDisabled}
                                        required={true}
                                        {...field}>
                                        {fcoStatusOptions}
                                    </Input>}/>
                            </Col>
                        </Row>}
                    <Row className="mt-md-3">
                        <StyledColumn w={14}>
                            <Label for="type">Type<StarIndicator/></Label>
                        </StyledColumn>
                        <Col sm="1" lg="2">
                            <Controller
                                control={control}
                                name="type"
                                rules={{required: 'Type is required.'}}
                                render={({field}) => <Input
                                    style={{width: '90px'}}
                                    type="select"
                                    bsSize="sm"
                                    defaultValue="DEFAULT"
                                    disabled={readOnly}
                                    data-testid="type"
                                    {...field}
                                >
                                    {[...fcoTypeOptions,
                                        <option value="DEFAULT" key="DEFAULT" disabled hidden>Choose here</option>]}
                                </Input>}
                            />
                            <ErrorMessage className="text-danger small" errors={errors} name={'type'} as="p" key={'type'}/>
                        </Col>

                        <Col sm="2" lg="1" className="text-right">
                            <Label for="title">Cause<StarIndicator/></Label>
                        </Col>
                        <Col sm="3" lg="7">
                            <Controller
                                control={control}
                                name="cause"
                                rules={{required: 'Cause is required.'}}
                                render={({field}) => <Input
                                    style={{width: '200px'}}
                                    type="select"
                                    bsSize="sm"
                                    disabled={readOnly}
                                    defaultValue="DEFAULT"
                                    data-testid="cause"
                                    {...field}
                                >
                                    {[...fcoCauseOptions,
                                        <option value="DEFAULT" key="DEFAULT" disabled hidden>Choose here</option>]}
                                </Input>}
                            />
                            <ErrorMessage className="text-danger small" errors={errors} name={'cause'} as="p"
                                          key={'cause'}/>
                        </Col>
                    </Row>
                    <Row className="mt-md-3">
                        <StyledColumn w={14}>
                            <Label for="template">Budget Template<StarIndicator/> </Label>
                        </StyledColumn>
                        <Col sm="1" lg="2">
                            <Controller
                                control={control}
                                name="template"
                                rules={{required: 'Budget Template is required.'}}
                                render={({field}) => <Input
                                    style={{width: '200px'}}
                                    type="select"
                                    bsSize="sm"
                                    disabled={readOnly}
                                    defaultValue="DEFAULT"
                                    data-testid="template"
                                    {...field}
                                >
                                    {[...templateOptions,
                                        <option value="DEFAULT" key="DEFAULT" disabled hidden>Choose here</option>]}
                                </Input>}
                            />
                            <ErrorMessage className="text-danger small" errors={errors} name={'template'} as="p"
                                          key={'template'}/>
                        </Col>
                    </Row>
                    <Row className="mt-md-3">
                        <StyledColumn w={14}>
                            <Label for="area">Area<StarIndicator/> </Label>
                        </StyledColumn>
                        <Col sm="1" lg="2">
                            <Controller
                                control={control}
                                name="area"
                                rules={{required: 'Area is required.'}}
                                render={({field}) => <Input
                                    style={{width: '90px'}}
                                    type="select"
                                    bsSize="sm"
                                    disabled={readOnly}
                                    defaultValue="DEFAULT"
                                    data-testid="area"
                                    {...field}
                                >
                                    {[...areaOptions,
                                        <option value="DEFAULT" key="DEFAULT" disabled hidden>Choose here</option>]}
                                </Input>}
                            />
                            <ErrorMessage className="text-danger small" errors={errors} name={'area'} as="p"
                                          key={'area'}/>
                        </Col>

                    </Row>
                    <Row className="mt-md-3">
                        <StyledColumn w={14}>
                            <Label for="requestedBy">Requested By<StarIndicator/></Label>
                        </StyledColumn>
                        <Col sm="9" lg="10">
                            <Controller
                                placeholder="requestedBy"
                                control={control}
                                name="requestedBy"
                                render={({field}) => <Input disabled={true} bsSize="sm" {...field}
                                                            data-testid="requestedBy"/>}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-md-3">
                        <StyledColumn w={14}>
                            <Label for="projectType">Budget Type<StarIndicator/></Label>
                        </StyledColumn>
                        <Col lg="4" className="d-flex">
                            <Controller
                                control={control}
                                name="projectType"
                                render={({field}) =>
                                    <RadioContainer  {...field}>
                                        <div className="ml-3">
                                            <Input
                                                disabled={readOnly}
                                                id="projectTypeLumpSum"
                                                type="radio"
                                                value="L"
                                                checked={projectType === 'L'}
                                                onChange={() => setProjectType('L')}
                                            />
                                            <Label for="projectTypeLumpSum">Lump Sum</Label>
                                        </div>

                                        <div className="ml-4">
                                            <Input
                                                disabled={readOnly}
                                                id="projectTypeCostPlus"
                                                type="radio"
                                                value="C"
                                                checked={projectType === 'C'}
                                                onChange={() => setProjectType('C')}
                                            />
                                            <Label for="projectTypeCostPlus">Cost Plus</Label>
                                        </div>
                                    </RadioContainer>
                                }/>
                        </Col>
                    </Row>

                    <Row className="mt-md-3">
                        <StyledColumn w={14}>
                            <Label for="tracking">Tracking <StarIndicator/></Label>
                        </StyledColumn>
                        <Col lg="3" className="d-flex">
                            <Controller
                                control={control}
                                name="tracking"
                                render={({field}) =>
                                    <RadioContainer  {...field}>
                                        <div className="ml-3">
                                            <Input
                                                disabled={readOnly}
                                                id="trackingYes"
                                                type="radio"
                                                value={true}
                                                checked={tracking}
                                                onChange={() => setTracking(true)}
                                            />
                                            <Label for="trackingYes">Yes</Label>
                                        </div>

                                        <div className="ml-5">
                                            <Input
                                                disabled={readOnly}
                                                id="trackingNot"
                                                type="radio"
                                                value={false}
                                                checked={!tracking}
                                                onChange={() => setTracking(false)}
                                            />
                                            <Label for="trackingNot">No</Label>
                                        </div>
                                    </RadioContainer>
                                }/>
                        </Col>
                    </Row>

                    <Row className="mt-md-3">
                        <StyledColumn w={14}>
                            <Label for="scheduleImpact">Schedule Impact<StarIndicator/></Label>
                        </StyledColumn>
                        <Col lg="3" className="d-flex">
                            <Controller
                                control={control}
                                name="scheduleImpact"
                                render={({field}) =>
                                    <RadioContainer  {...field}>
                                        <div className="ml-3">
                                            <Input
                                                disabled={readOnly}
                                                id="scheduleImpactTrue"
                                                type="radio"
                                                value={true}
                                                checked={scheduleImpact}
                                                onChange={() => setScheduleImpact(true)}
                                            />
                                            <Label for="scheduleImpactTrue">Yes</Label>
                                        </div>

                                        <div className="ml-5">
                                            <Input
                                                disabled={readOnly}
                                                id="scheduleImpactFalse"
                                                type="radio"
                                                value={false}
                                                checked={!scheduleImpact}
                                                onChange={() => setScheduleImpact(false)}
                                            />
                                            <Label for="scheduleImpactFalse">No</Label>
                                        </div>
                                    </RadioContainer>
                                }/>
                        </Col>

                        <StyledColumn w={9}>
                            <Label for="scheduleHours">Schedule Days</Label>
                        </StyledColumn>
                        <Col sm="3" lg="3">
                            <Controller
                                control={control}
                                name="scheduleHours"
                                render={({field}) => <Input disabled={!scheduleImpact || readOnly}
                                                            bsSize="sm" {...field} data-testid="scheduleHours"/>}
                            />
                        </Col>
                    </Row>

                    {/* <Row className="mt-md-3">
                        <StyledColumn w={14}>
                            <Label for="variance">Variance<StarIndicator/></Label>
                        </StyledColumn>
                        <Col lg="3" className="d-flex">
                            <Controller
                                control={control}
                                name="variance"
                                render={({field}) =>
                                    <RadioContainer  {...field}>
                                        <div className="ml-3">
                                            <Input
                                                disabled={readOnly}
                                                id="varianceYes"
                                                type="radio"
                                                value={true}
                                                checked={variance}
                                                onChange={() => setVariance(true)}
                                            />
                                            <Label for="varianceYes">Yes</Label>
                                        </div>

                                        <div className="ml-5">
                                            <Input
                                                disabled={readOnly}
                                                id="varianceNot"
                                                type="radio"
                                                value={false}
                                                checked={!variance}
                                                onChange={() => setVariance(false)}
                                            />
                                            <Label for="varianceNot">No</Label>
                                        </div>
                                    </RadioContainer>
                                }/>
                        </Col>
                    </Row> */}
                </Col>
                <Col lg="5">
                    <Row className="mt-md-3">
                        <Attachments attachments={attachments} setAttachments={setAttachments} readOnly={readOnly}/>
                    </Row>
                </Col>
            </Row>
            <hr/>
        </Container>

    );
}

export default FCOBasicInfoForm

