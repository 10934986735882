import { closeError, openError } from '../features/errors/redux/errorSlice'
import { closeSuccess } from '../features/success/redux/successSlice'

const interceptor = (axiosInstance, store) => {

  axiosInstance.interceptors.response.use(
    (response) => {
      const errors = response.data.errors
      store.dispatch(closeSuccess())
      if (errors.length !== 0) {
        store.dispatch(openError(errors));
      } else if (store.getState().error.showError) {
        store.dispatch(closeError())
      }
      return Promise.resolve(response);
    },
    (error) => {
      let errors = [];

      if (error.response) {
        errors = error.response.data.errors;
      } else {
        errors = [error.message];
      }
      
      store.dispatch(openError(errors));
      return Promise.reject(errors);
    }
  );
};

export default interceptor;