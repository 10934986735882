import {useGetAwaitingClientApprovalQuery} from '../../api/apiSlice'
import {AgGridReact} from 'ag-grid-react';
import {GridOptions, ICellRendererParams} from 'ag-grid-enterprise';
import './ClientApprovalLog.css'
import { useMemo} from 'react';
import {Link} from 'react-router-dom';
import {useAppSelector} from '../../app/hooks';

interface ChangeAwaitingClientApproval {
    id: number
    changeType: string
    changeNumber: string
    title: string
    issuedToClient: string
}

export default function ClientApprovalLog() {
    const {projectAccess} = useAppSelector(state => state.user.user)
    const {data} = useGetAwaitingClientApprovalQuery<{
        data: {
            data: {
                awaitingApproval: ChangeAwaitingClientApproval[]
            }
        }
    }>(projectAccess, {skip: projectAccess.length === 0})
    const gridOptions: GridOptions = {
        onGridReady: (params) => {
            params.api.sizeColumnsToFit()
        },
        defaultColDef: {
            resizable: true,
            sortable: true,
            filter: true,
        },
        suppressMenuHide: true,
        domLayout: 'normal',
        columnDefs: [
            {
                field: 'changeType',
                headerName: 'Type',
                headerClass: 'header-grid',
            },
            {
                field: 'changeNumber',
                headerName: 'Number',
                headerClass: 'header-grid',
                cellRendererFramework: (params: ICellRendererParams) => {
                    if (params.value && params.data.id) {
                        let href = `/log/${params.data.id}/${params.data.changeType.toLowerCase()}/approveByClient`;
                        if (params.data.changeType.toLowerCase() === 'pci') {
                            href = `/log/pci/${params.data.id}/approveByClient`
                        }
                        return <Link className="pl-2" to={href}>{params.value}</Link>
                    }
                    return params.value
                }

            },
            {
                field: 'title',
                headerName: 'Title',
                headerClass: 'header-grid',
            },
            {
                field: 'issuedToClient',
                headerName: 'Issued For Approval',
                headerClass: 'header-grid',
            },

        ]
    }

    const rowData = useMemo(() => {
        return data?.data?.awaitingApproval ?? []
    }, [data])


    return (
        <div className={'client-approval-log-root'}>
            <h5>Changes Awaiting Approval</h5>
            <div className="client-approval-grid-container">
                <AgGridReact {...gridOptions} rowData={rowData}
                             className="ag-theme-bootstrap"
                />
            </div>
        </div>
    )
}