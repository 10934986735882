import React, {useState} from 'react'
import {useHistory} from 'react-router';
import ButtonSection from '../../../UI/ButtonSection';
import {Row, Button, Modal, ModalBody, ModalFooter} from 'reactstrap';
import ApprovalsButton from '../../../components/ApproveButton/ApprovalsButton';
import BusyActionButton from '../../../components/ActionButton/BusyActionButton';

const PCRActionButtons = ({
                              cancelRoute,
                              isDirty,
                              onSave,
                              onSubmit,
                              onEditDisciplineImpact,
                              actionButtonsState,
                              approvalRoute,
                              saveInProgress,
                              isSaveDisabled
                          }) => {

    const {
        isSubmitHidden,
        submitLabel,
        isEditDisciplineImpactHidden,
    } = actionButtonsState
    const [openModal, setOpenModal] = useState(false);
    const toggle = () => setOpenModal(!openModal);
    let history = useHistory();

    const onConfirmCancel = () => {
        toggle();
        history.push(cancelRoute);
    }

    return (
        <Row>
            <ButtonSection>
                <Button color="link" data-testid="CancelLink" onClick={toggle} className="m-1">Exit</Button>
                <ApprovalsButton route={approvalRoute}/>
                <BusyActionButton color="primary" data-testid="SaveChanges"
                                  disabled={isSaveDisabled}
                                  onClick={onSave} className="m-1" busy={saveInProgress}>
                    Save Changes & Submit Later
                </BusyActionButton>
                {/*<Button color="primary" data-testid="update-state"*/}
                {/*        disabled={true || (saveInProgress || isUpdateStateHidden)} onClick={onUpdateState}*/}
                {/*        className="m-1">Send to Estimating</Button>*/}
                <BusyActionButton color="primary" data-testid="SubmitPci" onClick={onSubmit} className="m-1"
                                  disabled={saveInProgress || isSubmitHidden} busy={saveInProgress}>
                    {submitLabel}
                </BusyActionButton>
                <BusyActionButton color="primary" data-testid="EditDisciplineImpact"
                                  disabled={isDirty || saveInProgress || isEditDisciplineImpactHidden}
                                  onClick={onEditDisciplineImpact} className="m-1" busy={saveInProgress}>
                    Edit Discipline Impact
                </BusyActionButton>
            </ButtonSection>
            <Modal isOpen={openModal} data-testid="NextCancel-ConfirmModal">
                <ModalBody>
                    {'Are you sure you want to exit? - Unsaved changes will be lost'}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onConfirmCancel}>Yes</Button>
                    <Button color="primary" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </Row>
    )
};

export default PCRActionButtons
