// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageHeader_header__3S6oK {\n    height: 75px;\n    width: 100%;\n    font-size: 14px;\n    background-color: #333333;\n    color: white;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    flex-flow: row nowrap;\n  }\n  \n  .PageHeader_right__gGkjC {\n    display: flex\n  }\n  ", "",{"version":3,"sources":["webpack://src/components/Layout/PageHeader/PageHeader.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,qBAAqB;EACvB;;EAEA;IACE;EACF","sourcesContent":[".header {\n    height: 75px;\n    width: 100%;\n    font-size: 14px;\n    background-color: #333333;\n    color: white;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    flex-flow: row nowrap;\n  }\n  \n  .right {\n    display: flex\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "PageHeader_header__3S6oK",
	"right": "PageHeader_right__gGkjC"
};
export default ___CSS_LOADER_EXPORT___;
