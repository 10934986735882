import { useDispatch, connect } from "react-redux";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import React, {useEffect, useRef, useCallback, useMemo} from 'react'
import { projectExpenseSummarySetData } from "../redux/detailsDataAndTotalSlice"
import NumericEditor from "../../../../utilities/ag-grid-utilities/cell-editors/NumericEditor"
import { getRowStyle, getRowSpanForDescriptionTotal, decimalParser, isFooter, defaultGridOptions, sortById, currencyFormatter } from "../../../../utilities/ag-grid-utilities/AgGridUtilities"
import DecimalEditor from "../../../../utilities/ag-grid-utilities/cell-editors/DecimalEditor";
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';
import {CAN_OVERRIDE_DETAIL_VALUES} from '../../logs-roles/Roles';


const PER_DIEM = "PER DIEM"
const PROJECT_INCENTIVES = "PROJECT INCENTIVES"
const TOTAL_DESCRIPTION = "Total Project Expense (DIV U)"
const TRAVEL_MEALS_COMMUNICATION = "TRAVEL / MEALS / COMMUNICATION"

const cellClassRules = {
    'override-status': params => params.data.edited,
    'editable-cell': params => {
        if ((!params.colDef.editable || !params.colDef.editable(params)) || isFooter(params)) {
            return false;
        }
        if (params.colDef.field === 'activityDescription') {
            return params.data.edited || params.data.activityDescription.toLowerCase() === 'undefined';
        }
        return true;
    }
}


const ProjectExpenseSummaryGrid = React.memo((
        {
            data,
            totals,
            contractType,
            directLaborSummaryTotals,
            engineeringSummaryTotals,
            indirectLaborSummaryTotals,
            constructionManagementSummaryTotals,
            readOnly
        }) => {

        const dispatch = useDispatch()
        const doUpdate = useRef(false)
        const dataRef = useRef(...data)
        const {isAuthorized} = useAuthorized()
        const canOverrideValues = useMemo(() => {
            return isAuthorized([...CAN_OVERRIDE_DETAIL_VALUES]);
        }, [isAuthorized])


        useEffect(() => {
            dataRef.current = [...data]
        }, [data])

        const applyFormulasToDataModel = useCallback((localData) => {

            const updatedDataModel = localData.map(row => {
                const {activityDescription, labor, budgetOverride} = row
                if (budgetOverride) {
                    return row;
                }

                if (activityDescription === PROJECT_INCENTIVES) {
                    const value = Math.ceil(directLaborSummaryTotals.directLaborHours * labor.matlUnitRate);
                    return {
                        ...row,
                        laborDolars: value
                    };
                } else if (activityDescription === TRAVEL_MEALS_COMMUNICATION) {
                    const value = Math.ceil(engineeringSummaryTotals.laborDollars * labor.directLaborPctRate);
                    return {
                        ...row,
                        laborDolars: value
                    };
                } else if (activityDescription === PER_DIEM) {
                    const sum = indirectLaborSummaryTotals.hours + constructionManagementSummaryTotals.hours + directLaborSummaryTotals.directLaborHours;

                    const value = Math.ceil(sum * labor.matlUnitRate);

                    return {
                        ...row,
                        laborDollars: value
                    };
                }

                return row;
            })

            if (updatedDataModel.length > 0) {
                dispatch(projectExpenseSummarySetData(updatedDataModel))
            }

        }, [dispatch, directLaborSummaryTotals, engineeringSummaryTotals, constructionManagementSummaryTotals, indirectLaborSummaryTotals])

        const recalculateFormulas = useCallback(() => {
            applyFormulasToDataModel(dataRef.current)
        }, [applyFormulasToDataModel])

        useEffect(() => {
            if (doUpdate.current) {
                recalculateFormulas()
            }
        }, [contractType, recalculateFormulas, directLaborSummaryTotals, engineeringSummaryTotals, constructionManagementSummaryTotals, indirectLaborSummaryTotals])

        const valueSetter = (params) => {
            if (params.colDef.field === 'laborDolars') {
                //Validate Labor Dollars precision and scale
                if (params.newValue > 999999999.99) {
                    return params.oldValue
                }
            }
            const isTextField = ['activityDescription'].includes(params.colDef.field)
            const isNewValue = isTextField ? params.oldValue !== params.newValue : Number(params.oldValue) !== Number(params.newValue)
            if (isNewValue) {
                const value = isTextField ? params.newValue : Number(params.newValue);
                const updatedRow = {...params.data, [params.column.colId]: value, edited: true, budgetOverride: true}
                const filteredData = dataRef.current.filter(f => f.id !== params.data.id)
                applyFormulasToDataModel([...filteredData, ...[updatedRow]].sort(sortById))
            }
            return isNewValue
        }

        return (
            <div className="ag-theme-bootstrap" style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <div style={{flex: '1 1 auto'}}>
                    <AgGridReact
                        getRowStyle={getRowStyle}
                        gridOptions={{...defaultGridOptions}}
                        rowData={data}
                        getRowNodeId={data => data.id}
                        immutableData={true}
                        domLayout="autoHeight"
                        pinnedBottomRowData={[totals]}
                        onFirstDataRendered={() => doUpdate.current = true}
                        enableCellChangeFlash
                        singleClickEdit={true}
                        frameworkComponents={{
                            numericCellEditor: NumericEditor,
                            decimalCellEditor: DecimalEditor
                        }}
                    >

                        <AgGridColumn headerName="Edited" field="edited" hide
                                      valueFormatter={params => params.value ?? false}/>
                        <AgGridColumn headerName="Pact" field="pactCode" colSpan={getRowSpanForDescriptionTotal}
                                      valueFormatter={params => isFooter(params) ? TOTAL_DESCRIPTION : params.value} filter
                                      headerClass="header-grid" maxWidth={70}/>
                        <AgGridColumn headerName="Activity" field="activityDescription" filter headerClass="header-grid"
                                      valueSetter={valueSetter}
                                      editable={params => canOverrideValues && !readOnly && (params.data.activityDescription.toLowerCase() === 'undefined' || params.data.edited)}
                                      cellClassRules={cellClassRules} width={330}/>
                        <AgGridColumn headerName="Project Expense $" field="laborDolars" sortable filter
                                      headerClass="header-grid right-sortable-header-grid" cellEditor="decimalCellEditor"
                                      type="numericColumn"
                                      cellClassRules={cellClassRules}
                                      valueParser={params => decimalParser(params, 9, 2)}
                                      editable={params => canOverrideValues && !readOnly && !isFooter(params)}
                                      valueSetter={valueSetter}
                                      valueFormatter={(params) => currencyFormatter(params.data.laborDolars ?? 0, '$', 0)}/>
                    </AgGridReact>
                </div>
            </div>
        )


    }, (prevProps, nextProps) => {
        // true: do not render
        // false: do render
        return (
            prevProps.contractType === nextProps.contractType &&
            prevProps.directLaborSummaryTotals === nextProps.directLaborSummaryTotals &&
            prevProps.engineeringSummaryTotals === nextProps.engineeringSummaryTotals &&
            prevProps.constructionManagementSummaryTotals === nextProps.constructionManagementSummaryTotals &&
            prevProps.indirectLaborSummaryTotals === nextProps.indirectLaborSummaryTotals &&
            JSON.stringify(prevProps.totals) === JSON.stringify(nextProps.totals) &&
            JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
        )
    }
);

const mapStateToProps = (state) => {
    const { projectExpenseSummary: { data, totals }, contractType,
        directLaborSummary: { totals: directLaborSummaryTotals },
        engineeringSummary: { totals: engineeringSummaryTotals },
        constructionManagementSummary: { totals: constructionManagementSummaryTotals },
        indirectLaborSummary: { totals: indirectLaborSummaryTotals },
        readOnly } = state.detailsDataAndTotal
    return {
        data,
        totals,
        contractType,
        directLaborSummaryTotals,
        engineeringSummaryTotals,
        indirectLaborSummaryTotals,
        constructionManagementSummaryTotals,
        readOnly
    }
}

export default connect(mapStateToProps)(ProjectExpenseSummaryGrid)