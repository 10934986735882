import { useForm } from 'react-hook-form';
import Page from '../../../../../UI/Page';
import { useHistory } from 'react-router';
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form"
import PCIService from "../../api/pciService";
import { LogRoute } from '../../../../../setup/routes'
import { useSelector, useDispatch } from 'react-redux';
import { ErrorMessage } from '@hookform/error-message';
import { HISTORY_TYPE } from "../../../../../setup/constants";
import Authorized from '../../../../auth/Authorized/Authorized';
import { Container, Row, Col, Form, Label, Input } from 'reactstrap';
import PCIBasicInfoForm from '../../pci-basic-info-form/PCIBasicInfoForm';
import FeatureTitle from '../../../../../components/FeatureTitle/FeatureTitle'
import ApproveButton from "../../../../../components/ApproveButton/ApproveButton"
import { openSuccess } from "../../../../success/redux/successSlice"
import PCIDetailInformationForm from '../../pci-detail-information-form/PCIDetailInformationForm';
import Spinner from '../../../../../components/Spinner/Spinner'
import useSelectedProject from '../../../../../hooks/useSelectedProject'
import {PROJECT_CONTROLS, PROJECT_ENGINEER, PROJECT_MANAGER} from '../../../logs-roles/Roles';

const AuthorizedRoles = [PROJECT_MANAGER, PROJECT_ENGINEER, PROJECT_CONTROLS]

const ApprovePCIByPM = ({ match }) => {
    const pciId = match.params.id
    const dispatch = useDispatch()
    const [pciNumber, setPCINumber] = useState(null)
    const [attachments, setAttachments] = useState([])
    const isApprovePage = true
    const [pciStatusAndAreas, setPciStatusAndAreas] = useState(null)
    const [pciDisciplineDropdowns, setDisciplineDropdowns] = useState(null)
    const [pciData, setPciData] = useState(null)
    const userId = useSelector(state => state.user.user.userId);
    const [loading, setLoading] = useState(true)
    let history = useHistory();
    const [setSelectedProjectDropdown] = useSelectedProject();
    const [selectedProjectId, setSelectedProjectId] = useState(null)
    const [requestInProgress, setRequestInProgress] = useState(false)

    const { formState: { errors }, control, handleSubmit, reset, getValues } = useForm({
        defaultValues: {
            pciStatus: "1",
            pciTitle: "",
            clientNumber: "",
            variance: false,
            originator: "",
            pciScheduleImpactByText: "",
            sbiEnable: false
        },
        mode: "onChange"
    });

    useEffect(() => {
        async function getApprovePCIInfo() {
            const response = await PCIService.getApprovePCIByPMInfo(pciId)

            if (response.data.data) {
                const { areas, statuses, templates, roms, causes, pciNumber, attachedFiles, projectId } = response.data.data
                setAttachments(attachedFiles ?? [])
                setPciStatusAndAreas({ areas, statuses })
                setDisciplineDropdowns({ templates, roms, causes })
                setPciData(response.data.data)
                setPCINumber(pciNumber)
                setSelectedProjectId(projectId)
            }
            setLoading(false)
        }
        getApprovePCIInfo()
    }, [pciId])

    useEffect(() => {
        setSelectedProjectDropdown(selectedProjectId)
    }, [selectedProjectId, setSelectedProjectDropdown])

    useEffect(() => {
        if (pciData !== null) {
            const { logStatus, logArea, logTitle, clientNumber, description,
                templateId, pcrDiscipReturnDays, pciRomId, pciValue, causeId,
                pciScheduleId, scheduleImpactedBy, notes, logOriginator, logIsVariance, sbiEnable } = pciData
            reset({
                "clientNumber": clientNumber ?? "", "pciStatus": logStatus, "pciArea": logArea, "pciTitle": logTitle, "variance": logIsVariance,
                "pciDescription": description, "pciBudgetTemplate": templateId ?? "DEFAULT", "pciDisciplineImpactReturnExpected": pcrDiscipReturnDays ?? 0,
                "pciAproxTicCostRom": pciRomId ?? "DEFAULT", "pciAnticipatedValue": pciValue ?? 0, "pciReasonForChange": causeId ?? "DEFAULT", "sbiEnable": sbiEnable,
                "pciSchedule": pciScheduleId ?? "1", "pciScheduleImpactByText": scheduleImpactedBy ?? "", "pciNotes": notes ?? "", "originator": logOriginator
            })
        }
    }, [pciData, reset])


    const onApproval = handleSubmit(async (data) => {
        try {
            setRequestInProgress(true)
            const response = await PCIService.approvePCIByPM(
                {
                    pciId,
                    approved: true,
                    userId,
                    comments: data.pciApprovalComments
                });
            if (history.action !== HISTORY_TYPE.NO_HISTORY) {
                history.goBack();
            } else {
                history.push(LogRoute);
            }
            if (response.status === 200) {
                const message = "The PCI was Approved, and an email has been sent to " +
                    response.data.data.notified.map(name => ` ${name}`) + "."
                setTimeout(() => {
                    dispatch(openSuccess(message))
                }, 4000)
            }
            setRequestInProgress(false)
        } catch (e) {
            console.log(e)
            setRequestInProgress(false)
        }
    })

    const onNotApproval = handleSubmit(async (data) => {
        try {
            setRequestInProgress(true)
            const response = await PCIService.approvePCIByPM(
                {
                    pciId,
                    approved: false,
                    userId,
                    comments: data.pciApprovalComments
                }
            );
            if (history.action !== HISTORY_TYPE.NO_HISTORY) {
                history.goBack();
            } else {
                history.push(LogRoute);
            }
            if (response.status === 200 && !response.data.data.approved) {
                const message = response.data.data.notified.map(name => ` ${name}`) + " were notified. "
                setTimeout(() => {
                    dispatch(openSuccess(message))
                }, 4000)
            }
            setRequestInProgress(false)
        } catch (e) {
            console.log(e)
            setRequestInProgress(false)
        }
    })

    if (loading) {
        return (<Spinner />)
    }
    
    return (
        <Page>
            {pciData && <Container className="pb-3">
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title={`Project Change: ${pciNumber}`} />
                            <hr />
                        </Container>
                    </Col>
                </Row>
                <Form className="pb-5" onSubmit={handleSubmit(onApproval)}>
                    <Row>
                        <Col>
                            {pciStatusAndAreas &&
                                <Authorized roles={AuthorizedRoles}>
                                    <PCIBasicInfoForm control={control} errors={errors}
                                        pciStatusAndAreas={pciStatusAndAreas} getValues={getValues}
                                        readOnly={isApprovePage} attachments={attachments} />
                                </Authorized>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {pciDisciplineDropdowns &&
                                <Authorized roles={AuthorizedRoles}>
                                    <PCIDetailInformationForm control={control}
                                        getValues={getValues} errors={errors} templates={pciDisciplineDropdowns.templates}
                                        roms={pciDisciplineDropdowns.roms} causes={pciDisciplineDropdowns.causes} readOnly={isApprovePage} />
                                </Authorized>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr />
                            <Row className="mt-md-3">
                                <Col sm="2" lg="2" className="text-right">
                                    <Label for="pciDescription">Approval Comments</Label>
                                </Col>
                                <Col sm="8" lg="9">
                                    <Controller
                                        placeholder="ApprovalComments"
                                        control={control}
                                        name="pciApprovalComments"
                                        render={({ field }) => <Input type="textarea" {...field} data-testid="pciApprovalComments" />}
                                        rules={{ maxLength: { message: "Approval Comments must be no longer than 4000 characters.", value: 4000 } }}
                                    />
                                    <ErrorMessage className="text-danger small" errors={errors} name={"pciApprovalComments"} as="p" key={"pciApprovalComments"} />
                                </Col>
                            </Row>
                            <hr />
                            <ApproveButton onNotApprove={onNotApproval} requestInProgress={requestInProgress}></ApproveButton>
                        </Col>
                    </Row>
                </Form>
            </Container>}
        </Page>
    )
}

export default ApprovePCIByPM