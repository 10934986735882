import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import BusyActionButton from '../../components/ActionButton/BusyActionButton';
import {ChangeEvent, useCallback, useMemo, useState, KeyboardEvent} from 'react';
import {IDistributionResponse, useGetFCODistributionListQuery} from '../../api/apiSlice';
import {useAppSelector} from '../../app/hooks';
import './DistributionControl.css';
import FcoService from '../logs/fco/api/fcoService';
import {useParams} from 'react-router-dom';

const MOCK_DISTRIBUTION_RESPONSE: IDistributionResponse[] = [
    {
        type: 'E',
        name: 'John Doe',
        email: 'john.doe@sbec.com',
        title: 'Civil Foreman',
    },
    {
        type: 'E',
        name: 'Jane Doe',
        email: 'jane.doe@sbec.com',
        title: 'Piping Foreman',
    },
    {
        type: 'E',
        name: 'Jim Doe',
        email: 'jim.doe',
        title: 'Piping Foreman',
    },
]

interface IDistributionControl {
    fcoStatusId: number
}

const validStatuses = [30, 33, 35, 37]
export default function DistributionControl({fcoStatusId}: IDistributionControl) {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false)
    const {jobNo} = useAppSelector(state => state.selectedProjectDetails);
    const {userId} = useAppSelector(state => state.user.user);

    const {data: distributionData} = useGetFCODistributionListQuery<{ data: IDistributionResponse[] }>(jobNo, {skip: !jobNo})
    const [selectedRecipients, setSelectedRecipients] = useState<string[]>([])
    const [customRecipient, setCustomRecipient] = useState<string>('');
    const {id} = useParams<{id: string}>()

    const distributionList = useMemo(() => {
        return distributionData ?? []
        // return MOCK_DISTRIBUTION_RESPONSE
    }, [distributionData])


    const allSelected = useMemo(() => {
        return distributionList.filter(i => i.email.includes('@')).map(o => o.email).every(p => selectedRecipients.includes(p))
    }, [distributionList, selectedRecipients])


    const toggleOpen = () => {
        setDialogOpen((prev) => !prev)
    }

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSelectedRecipients((prev) => {
            if (prev.includes(e.target.name)) {
                return prev.filter(p => p !== e.target.name)
            }
            return prev.concat(e.target.name)
        })
    }, [])

    const handleDistribution = useCallback(async () => {
        try {
            setRequestInProgress(true)
            const response = await FcoService.sendFCOChargeableNotification(
                parseInt(id, 10),
                userId,
                selectedRecipients,
            )
            setRequestInProgress(false)
            console.log(response);
            if (response.status === 200) {
                setDialogOpen(false)
            }
        } catch (e) {
            console.log(e);
            setRequestInProgress(false)
        }
    }, [selectedRecipients])

    const handleAddCustomRecipient = useCallback(() => {
        if (customRecipient.length > 0 && customRecipient.includes('@')) {
            setSelectedRecipients((prev) => {
                if (prev.includes(customRecipient)) {
                    return prev.filter(p => p !== customRecipient)
                }
                return prev.concat(customRecipient.replaceAll(' ', ''));
            });
            setCustomRecipient('')
        }
    }, [customRecipient])

    const toggleSelectAll = useCallback(() => {
        setSelectedRecipients((prev) => {
            const distributionEmails = distributionList.filter(i => i.email.includes('@')).map(o => o.email)
            let recipients = prev
            const customRecipients = recipients.filter(i => !distributionEmails.includes(i))
            if (allSelected) {
                recipients = customRecipients
            } else {
                recipients = customRecipients.concat(distributionEmails)
            }
            return recipients
        })
    }, [distributionList, allSelected])

    const handleRemoveRecipient = useCallback((removedRecipient: string) => {
        setSelectedRecipients((prev) => {
            return prev.filter(p => p !== removedRecipient)
        })
    }, [])

    const handleEnter = useCallback((e: KeyboardEvent<HTMLInputElement> ) => {
        if (e.key === 'Enter') {
            handleAddCustomRecipient()
        }
    }, [handleAddCustomRecipient])

    return (!validStatuses.includes(fcoStatusId) ? null :
        <>
            <Button onClick={toggleOpen} color={'primary'}>
                Send Distribution
            </Button>
            <Modal isOpen={dialogOpen} onClosed={() => setDialogOpen(false)}>
                <ModalHeader>Distribution Selection</ModalHeader>
                <ModalBody className={'distribution-dialog-modal-body'}>
                    <span className={'body-header'}>Available FCO Distribution List</span>
                    <table className={'distribution-table'}>
                        <thead>
                        <tr>
                            <th>
                                <input type={'checkbox'} checked={allSelected} onChange={toggleSelectAll}/>
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Title
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {distributionList.length > 0 ? distributionList.map(d => {
                            const isMissingEmail = !d.email.includes('@')
                            return (
                                <tr key={d.email}>
                                    <td>
                                        <input type={'checkbox'} disabled={isMissingEmail} onChange={handleChange}
                                               name={d.email} checked={selectedRecipients.includes(d.email)}/>
                                    </td>
                                    <td>
                                        {d.name}
                                    </td>
                                    <td>
                                        {d.title}
                                    </td>
                                    <td>
                                        {isMissingEmail ? 'Missing Email' : d.email}
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <tr><td colSpan={3} className={'centered'}>** No users on FCO distribution list **</td></tr>
                        }
                        </tbody>
                    </table>
                    <span className={'body-header'}>Distribution Recipients</span>
                    <hr/>
                    {selectedRecipients.length > 0 &&
                        <table className={'selected-distribution-table'}>
                            <tbody>
                            {
                                selectedRecipients.map(r => {
                                    return (
                                        <tr key={r}>
                                            <td>{r}</td>
                                            <td>
                                                <span onClick={() => handleRemoveRecipient(r)}>
                                                    X
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>

                    }
                    <span>Additional recipient email</span>
                    <Input onChange={(e) => setCustomRecipient(e.target.value)} onBlur={handleAddCustomRecipient} onKeyDown={handleEnter}
                           value={customRecipient}/>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggleOpen}>Cancel</Button>
                    <BusyActionButton color={'primary'} busy={requestInProgress}
                                      onClick={handleDistribution}>Send</BusyActionButton>
                </ModalFooter>
            </Modal>
        </>
    )
}