import { useEffect } from "react";
import Page from "../../../UI/Page";
import { useForm } from 'react-hook-form';
import templateService from '../api/templatesService';
import { Col, Container, Form, Row } from "reactstrap";
import { TemplatesRoute } from "../../../setup/routes";
import NextCancel from "../../../components/NextCancel/NextCancel";
import localStorageService from "../../../services/localStorageService";
import FeatureTitle from "../../../components/FeatureTitle/FeatureTitle";
import TemplateBasicInfoForm from "../create-template/template-basic-info-form/TemplateBasicInfoForm";
import {useSelector} from 'react-redux';


const UpdateTemplate = (props) => {
    const { register, handleSubmit, formState: { errors, isValid }, setValue, control } = useForm({
        defaultValues: {
            templateName: " ",
            templateDescription: " ",
            templateStatus: "PENDING"
        },
        mode: "onChange"
    });
    const userId = useSelector((state) => state.user.user.userId);

    useEffect(() => {
        async function getTemplate() {
            try {
                const { data: { data: template } } = await templateService.getTemplate(props.match.params.id)
                setValue('templateStatus', template.status)
                setValue('templateName', template.name)
                setValue('templateDescription', template.description)
            } catch (error) {
                console.log(error)
            }
        }
        getTemplate()
    }, [props.match.params.id, setValue]);

    const templateStatuses = [
        "ACTIVE", "PENDING", "CLOSED"
    ];

    const onSubmit = (formData) => {
        templateService.updateTemplate({
            templateId: props.match.params.id,
            userId,
            projectId: localStorageService.getProjectId(),
            name: formData.templateName,
            description: formData.templateDescription,
            status: formData.templateStatus
        }).then(response => {
            props.history.push(TemplatesRoute);
        }).catch(error => console.log("Error: ", error));
    }

    return (
        <Page>
            <Container>
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title="Update Template" />
                            <hr />
                        </Container>
                    </Col>
                </Row>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col>
                            <TemplateBasicInfoForm register={register} errors={errors} templateStatuses={templateStatuses} control={control} />
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <NextCancel cancelRoute={TemplatesRoute} disabled={!isValid} saveText="Save" />
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Page>
    )
}

export default UpdateTemplate