import { useState } from "react"
import { Link } from 'react-router-dom'
import {AgGridColumn, AgGridReact} from 'ag-grid-react'
import { Container, Row, Col } from 'reactstrap';
import routes, { getUpdateFTRoute } from '../../../../setup/routes';
import AgGridOptionButtons from "../../../../components/AgGridOptionButtons/AgGridOptionButtons";
import { getRowStyle, defaultGridOptions, currencyFormatter, getRowSpanForAggregationTotal, getTotalDescriptionForAggregation, isFooterAgg } from '../../../../utilities/ag-grid-utilities/AgGridUtilities'


const TOTAL_DESCRIPTION = "Subtotal Construction"

const getUpdateChangeLink = (params) => {
    if (params.data) {
        const { id, flashTrendNumber } = params.data;
        return [flashTrendNumber, getUpdateFTRoute(id)]
    }
    return params
}

const FlashTrendsGrid = ({ flashTrends }) => {
    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params) => {
        setGridApi(params.api);
    }
    return (
        <Container className="pb-5">
            <AgGridOptionButtons agGridApi={gridApi} quickLinkButtons={[{ label: "Logs", route: routes.LogRoute }]} />
            <Row style={{ marginTop: "20px", height: "41vh" }}>
                <Col fluid={"true"} >
                    <AgGridReact
                        getRowStyle={getRowStyle}
                        gridOptions={{ ...defaultGridOptions }}
                        rowData={flashTrends}
                        domLayout="normal"
                        className="ag-theme-bootstrap"
                        suppressMenuHide="true"
                        onGridReady={onGridReady}
                        groupIncludeTotalFooter={true}
                        defaultExcelExportParams={{
                            autoConvertFormulas: true,
                            processCellCallback: params => {
                                const field = params.column.getColDef().field;
                                return field === 'flashTrendNumber' ? params.value[0] : params.value;
                            }
                        }}>
                        <AgGridColumn headerName='Flash #' field='flashTrendNumber' headerClass="header-grid"
                            valueGetter={getUpdateChangeLink}
                            cellRendererFramework={(params) => isFooterAgg(params) ? TOTAL_DESCRIPTION : <Link className="pl-2" to={params.value[1]}>{params.value[0]}</Link>}
                            colSpan={getRowSpanForAggregationTotal}
                            valueFormatter={params => getTotalDescriptionForAggregation(params, TOTAL_DESCRIPTION)} />
                        <AgGridColumn headerName='Description' field='description' headerClass="header-grid" minWidth={300} />
                        <AgGridColumn headerName='UOM' field='uom' headerClass="header-grid" maxWidth={110} />
                        <AgGridColumn headerName='QTY' field='quantity' headerClass="header-grid" type='numericColumn' />
                        <AgGridColumn headerName='Low $(-40%)' field='lowDollars' headerClass="header-grid" type='numericColumn' aggFunc="sum"
                            valueFormatter={params => currencyFormatter(params.value ?? 0, '$')} />
                        <AgGridColumn headerName='ROM $' field='romDollars' headerClass="header-grid" valueFormatter={params => currencyFormatter(params.value ?? 0, '$')} type='numericColumn' aggFunc="sum" />
                        <AgGridColumn headerName='High $(+40%)' field='highDollars' headerClass="header-grid" valueFormatter={params => currencyFormatter(params.value ?? 0, '$')} type='numericColumn' aggFunc="sum" />
                        <AgGridColumn headerName='Covered By' field='coveredBy' headerClass="header-grid" />
                        <AgGridColumn headerName='Tracking' field='tracking' headerClass="header-grid" cellStyle={() => ({ "fontWeight": "800", textAlign: 'center' })} maxWidth={110} />
                        <AgGridColumn headerName='Change #' field='changeNumber' headerClass="header-grid" />
                        <AgGridColumn headerName='Remarks' field='remarks' headerClass="header-grid" />
                    </AgGridReact>
                </Col>
            </Row>
        </Container>
    )
}

export default FlashTrendsGrid
