import Page from '../../../UI/Page';
import FTService from '../api/ftService'
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { FTRoute } from '../../../setup/routes';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Form } from "reactstrap";
import { getUpdateFTRoute } from '../../../setup/routes';
import { removeComma } from '../../../utilities/Numbers';
import FTFinanceForm from '../ft-finance-form/FTFinanceForm';
import FTTrackingForm from '../ft-tracking-form/FTTrackingForm';
import NextCancel from '../../../components/NextCancel/NextCancel';
import FeatureTitle from '../../../components/FeatureTitle/FeatureTitle'
import FTStatusRemarksForm from '../ft-status-remark-form/FTRemarksForm';
import FTGeneralInfoForm from '../ft-general-info-form/FTGeneralInfoForm';
import { openSuccess } from '../../success/redux/successSlice';

const CreateFT = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const { projectId } = useSelector((state) => state)
    const userId = useSelector(state => state.user.user.userId);
    const { getValues, formState: { errors }, control, handleSubmit } = useForm({
        defaultValues: {
            tracking: "A",
            uom: "-1",
            quantity: "",
            changeNumber: "",
            coveredBy: ""
        },
        mode: "onChange"
    });

    const onSave = handleSubmit(async (data) => {
        if (data.uom === "-1") {
            delete data.uom
        }
        const flashTrend = {
            ...data,
            userId,
            projectId: projectId.value,
            quantity: removeComma(data.quantity ?? 0),
            lowDollars: removeComma(data.lowDollars ?? 0),
            romDollars: removeComma(data.romDollars ?? 0),
            highDollars: removeComma(data.highDollars ?? 0),
        }
        FTService.saveFT(flashTrend).then((response) => {
            if (response.data.data) {
                const redirectUrl = getUpdateFTRoute(response.data.data.id)
                history.push(redirectUrl);
                setTimeout(() => {
                    dispatch(openSuccess("The Flash Trend was Saved."))
                }, 4000)
            }
        }).catch((error) => {
            console.log(error)
        })
    })

    return (
        <Page>
            <Container className="pb-3">
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title="Flash Trend: New" />
                            <hr />
                        </Container>
                    </Col>
                </Row>
                <Form className="pb-5 mx-4" onSubmit={onSave} >
                    <Row>

                        <FTGeneralInfoForm control={control} getValues={getValues} errors={errors}></FTGeneralInfoForm>
                        <FTFinanceForm control={control} getValues={getValues} errors={errors}></FTFinanceForm>
                        <FTTrackingForm control={control} getValues={getValues} errors={errors}></FTTrackingForm>
                        <FTStatusRemarksForm control={control} getValues={getValues} errors={errors}></FTStatusRemarksForm>
                    </Row>
                    <Row>
                        <Col>
                            <NextCancel cancelRoute={FTRoute} disabled={false} saveText="Save" cancelConfirmText="Are you sure you want to close?" />
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Page>
    );
}

export default CreateFT;