import { Controller } from "react-hook-form"
import NumberFormat from "react-number-format";
import { ErrorMessage } from '@hookform/error-message';
import { Container, Label, InputGroup, InputGroupAddon, Row, Col } from "reactstrap";


const MAX_VAL = 999999999.99;
const withValueLimit = ({ floatValue }) => floatValue <= MAX_VAL;


const FTFinanceForm = ({ control, errors, readOnly = false }) => {

    return (
        <>
            <Container>
                <h5>Finance</h5>
                <Row>
                    <Col xs={1} className="text-right">
                        <Label for="lowDollars">Low $ (-40%)</Label>
                    </Col>
                    <Col xs={2} >
                        <Controller
                            control={control}
                            name="lowDollars"
                            render={({ field }) =>
                                <InputGroup className="input-group-sm">
                                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                    <NumberFormat isAllowed={withValueLimit} className="form-control-sm form-control" disabled={readOnly} thousandSeparator={true} decimalScale={0} fixedDecimalScale {...field} />
                                </InputGroup>}
                        />
                        <ErrorMessage className="text-danger small" errors={errors} name={"lowDollars"} as="p" key={"lowDollars"} />
                    </Col>

                    <Col xs={1} className="text-right">
                        <Label for="romDollars">ROM $</Label>
                    </Col>
                    <Col xs={2}>
                        <Controller
                            control={control}
                            name="romDollars"
                            render={({ field, onChange }) =>
                                <InputGroup className="input-group-sm">
                                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                    <NumberFormat isAllowed={withValueLimit} className="form-control-sm form-control" disabled={readOnly} thousandSeparator={true} decimalScale={0} fixedDecimalScale {...field} />
                                </InputGroup>}
                        />
                        <ErrorMessage className="text-danger small" errors={errors} name={"romDollars"} as="p" key={"romDollars"} />
                    </Col>

                    <Col xs={1} className="text-right">
                        <Label for="highDollars">High $ (+40%)</Label>
                    </Col>
                    <Col xs={2}>
                        <Controller
                            control={control}
                            name="highDollars"
                            render={({ field }) =>
                                <InputGroup className="input-group-sm">
                                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                    <NumberFormat isAllowed={withValueLimit} className="form-control-sm form-control" disabled={readOnly} thousandSeparator={true} decimalScale={0} fixedDecimalScale {...field} />
                                </InputGroup>}
                        />
                        <ErrorMessage className="text-danger small" errors={errors} name={"highDollars"} as="p" key={"highDollars"} />
                    </Col>

                </Row>
                <hr />
            </Container>
        </>
    )
}
export default FTFinanceForm