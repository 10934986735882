import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import FTService from '../api/ftService'
import { Container, Row, Col, } from 'reactstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import FlashTrendsGrid from './ft-grid/FlashTrendGrid';
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import FlashTrendsHeader from './ft-header/FlashTrendHeader';
import FlashTrendTotals from './ft-totals/FlashTrendTotals';

const initialTotals = { a: 0, b: 0, ac: 0, r: 0, d: 0 }

const getTotals = (data) => (
    data.reduce((x, y) => {
        switch (y.tracking) {
            case 'A': return { ...x, a: x.a + y.romDollars ?? 0 }
            case 'B': return { ...x, b: x.b + y.romDollars ?? 0 }
            case 'AC': return { ...x, ac: x.ac + y.romDollars ?? 0 }
            case 'R': return { ...x, r: x.r + y.romDollars ?? 0 }
            case 'D': return { ...x, d: x.d + y.romDollars ?? 0 }
            default: return x
        }
    }, initialTotals)
)

const FlashTrend = () => {
    const { projectId } = useSelector((state) => state)
    const [flashTrends, setFlashTrends] = useState([])
    const [totals, setTotals] = useState(initialTotals)

    useEffect(() => {
        FTService.getFlashTrendByProjectId(projectId.value)
            .then(response => {
                const { data } = response.data
                if (data) {
                    setFlashTrends(data)
                    setTotals(getTotals(data))
                }
            })
            .catch(error => console.log(error))
    }, [projectId])

    return (
        <Container>
            <Row>
                <Col>
                    <FlashTrendsHeader />
                </Col>
            </Row>
            <Row className="border pt-3">
                <Col>
                    <FlashTrendsGrid flashTrends={flashTrends} />
                </Col>
            </Row>
            <FlashTrendTotals totals={totals} />
        </Container>
    )
}

export default FlashTrend