import { createSlice } from '@reduxjs/toolkit'

export const successSlice = createSlice({
    name: 'success',
    initialState: {
        showSuccess: false,
        message: ""
    },

    reducers: {
        openSuccess: (state, action) => {
            state.showSuccess = true
            state.message = action.payload
        },
        closeSuccess: (state, _) => {
            state.showSuccess = false
            state.message = ""
        }
    }
})

export const { openSuccess, closeSuccess } = successSlice.actions
export default successSlice.reducer