import { createSlice } from '@reduxjs/toolkit'

const hoursAndLaborInitialTotals = { hours: 0, sbiHours: 0, laborDollars: 0, sbiLaborDollars: 0 }
const indirectMaterialSummaryInitialTotals = { materialDollars: 0, subcontractDollars: 0 }
const directLaborSummaryInitialTotals = { directLaborHours: 0, directLaborDollars: 0, quantity: 0, unitRate: 0, directMaterialDollars: 0, subDollars: 0 }
const indirectLaborSummaryInitialTotals = { hours: 0, dollars: 0 }
const taggedEquipmentSummaryTotals = { equipmentDollars: 0 }
const divisionAllowancesTotals = { dollars: 0 }
const projectExpenseSummaryTotals = { laborDolars: 0 }
const projectOHPSummaryTotals = { dollars: 0 }
const constructionSummaryTotals = { hours: 0, laborDollars: 0 }
const contingencyEscalationTotals = { dollars: 0 }


const sortByPact = ((a, b) => a.pact > b.pact ? 1 : -1)
const sortByPactCode = ((a, b) => a.pactCode > b.pactCode ? 1 : -1)

export const detailsDataAndTotalSlice = createSlice({
    name: 'data-and-totals',
    initialState: {
        contractType: null,
        productivityAdjustment: 0,
        premiumTimeOther: 0,
        title: "",
        changeNumber: "",
        premiumTime: {
            value: null, options: [], markup: 0
        },
        saleTaxes: [],
        engineeringSummary: {
            data: [],
            totals: hoursAndLaborInitialTotals
        },
        projectManagementSummary: {
            data: [],
            totals: hoursAndLaborInitialTotals
        },
        directLaborSummary: {
            data: [],
            totals: directLaborSummaryInitialTotals
        },
        indirectLaborSummary: {
            data: [],
            totals: indirectLaborSummaryInitialTotals
        },
        taggedEquipmentSummary: {
            data: [],
            totals: { equipmentDollars: 310 }
        },
        indirectMaterialSummary: {
            data: [],
            totals: indirectMaterialSummaryInitialTotals
        },
        taggedEquipment: {
            data: [],
            totals: indirectMaterialSummaryInitialTotals
        },
        divisionAllowances: {
            data: [],
            totals: divisionAllowancesTotals
        },
        projectExpenseSummary: {
            data: [],
            totals: projectExpenseSummaryTotals
        },
        constructionManagementSummary: {
            data: [],
            totals: constructionSummaryTotals
        },
        contingencyEscalationSummary: {
            data: [],
            totals: contingencyEscalationTotals
        },
        projectOHPSummary: {
            data: [],
            totals: { dollars: 0 }
        }
    },
    reducers: {
        detailsSetData: (state, action) => {
            let componentState = {}
            Object.keys(action.payload).map(propName => {
                switch (propName) {

                    case "engineeringSummary":
                        const engineeringSummaryData = [...action.payload.engineeringSummary].sort(sortByPact)
                        const engineeringSummaryTotals = getHoursAndLaborTotals(engineeringSummaryData)
                        componentState = { ...componentState, engineeringSummary: { data: engineeringSummaryData, totals: engineeringSummaryTotals } }
                        break;

                    case "projectManagementSummary":
                        const projectManagementSummaryData = [...action.payload.projectManagementSummary].sort(sortByPact)
                        const projectManagementSummaryTotals = getHoursAndLaborTotals(projectManagementSummaryData)
                        componentState = { ...componentState, projectManagementSummary: { data: projectManagementSummaryData, totals: projectManagementSummaryTotals } }
                        break;

                    case "directLaborSummary":
                        const directLaborSummaryData = [...action.payload.directLaborSummary].sort(sortByPact)
                        const directLaborSummaryTotals = getDirectLaborSummaryTotals(directLaborSummaryData)
                        componentState = { ...componentState, directLaborSummary: { data: directLaborSummaryData, totals: directLaborSummaryTotals } }
                        break;
                    case "indirectLaborSummary":
                        const indirectLaborSummaryData = [...action.payload.indirectLaborSummary].sort(sortByPact)
                        const indirectLaborSummaryTotals = getIndirectLaborSummaryTotals(indirectLaborSummaryData)
                        componentState = { ...componentState, indirectLaborSummary: { data: indirectLaborSummaryData, totals: indirectLaborSummaryTotals } }
                        break;

                    case "indirectMaterialSummary":
                        const indirectMaterialSummaryData = [...action.payload.indirectMaterialSummary].sort(sortByPact)
                        const indirectMaterialSummaryTotals = getIndirectMaterialSummaryTotals(indirectMaterialSummaryData)
                        componentState = { ...componentState, indirectMaterialSummary: { data: indirectMaterialSummaryData, totals: indirectMaterialSummaryTotals } }
                        break;

                    case "taggedEquipmentSummary":
                        const taggedEquipmentData = [...action.payload.taggedEquipmentSummary].sort(sortByPact)
                        const taggedEquipmentTotals = getTaggedEquipmentSummaryTotals(taggedEquipmentData)
                        componentState = { ...componentState, taggedEquipmentSummary: { data: taggedEquipmentData, totals: taggedEquipmentTotals } }
                        break;

                    case "divisionAllowances":
                        const divisionAllowancesData = [...action.payload.divisionAllowances].sort(sortByPact)
                        const divisionAllowancesTotals = getDivisionAllowancesTotals(divisionAllowancesData)
                        componentState = { ...componentState, divisionAllowances: { data: divisionAllowancesData, totals: divisionAllowancesTotals } }
                        break;

                    case "projectExpenseSummary":
                        const projectExpenseSummaryData = [...action.payload.projectExpenseSummary].sort(sortByPactCode)
                        const projectExpenseSummaryTotals = getProjectExpenseSummaryTotals(projectExpenseSummaryData)
                        componentState = { ...componentState, projectExpenseSummary: { data: projectExpenseSummaryData, totals: projectExpenseSummaryTotals } }
                        break;

                    case "constructionManagementSummary":
                        const constructionManagementSummaryData = [...action.payload.constructionManagementSummary].sort(sortByPact)
                        const constructionManagementSummaryTotals = getConstructionManagementSummaryTotals(constructionManagementSummaryData)
                        componentState = { ...componentState, constructionManagementSummary: { data: constructionManagementSummaryData, totals: constructionManagementSummaryTotals } }
                        break;

                    case "contingencyEscalationSummary":
                        const contingencyEscalationSummaryData = [...action.payload.contingencyEscalationSummary].sort(sortByPact)
                        const contingencyEscalationSummaryTotals = getContingencyEscalationSummaryTotals(contingencyEscalationSummaryData)
                        componentState = { ...componentState, contingencyEscalationSummary: { data: contingencyEscalationSummaryData, totals: contingencyEscalationSummaryTotals } }
                        break;

                    case "projectOHPSummary":
                        const projectOHPSummaryData = [...action.payload.projectOHPSummary].sort(sortByPact)
                        const projectOHPSummaryTotals = getProjectOHPSummaryTotals(projectOHPSummaryData)
                        componentState = { ...componentState, projectOHPSummary: { data: projectOHPSummaryData, totals: projectOHPSummaryTotals } }
                        break;
                    case "title": componentState = { ...componentState, title: action.payload.title }
                        break;
                    case "changeNumber": componentState = { ...componentState, changeNumber: action.payload.changeNumber }
                        break;
                    case "contractType": componentState = { ...componentState, contractType: action.payload.contractType }
                        break;

                    case "premiumTime": componentState = { ...componentState, premiumTime: { ...componentState.premiumTime, value: action.payload.premiumTime } }
                        break;

                    case "premiumTimeOther": componentState = { ...componentState, premiumTimeOther: action.payload.premiumTimeOther ?? 0 }
                        break;

                    case "dlPremiumTimeList": componentState = { ...componentState, premiumTime: { ...componentState.premiumTime, options: action.payload.dlPremiumTimeList } }
                        break;

                    case "productivityAdjustment": componentState = { ...componentState, productivityAdjustment: action.payload.productivityAdjustment }
                        break;

                    case "saleTaxes": componentState = { ...componentState, saleTaxes: action.payload.saleTaxes }
                        break;

                    default:
                        break;
                }
                return null
            })

            componentState = { ...componentState, readOnly: action.payload.readOnly, premiumTime: { ...componentState.premiumTime, markup: getPremiumTimeMarkup(componentState.contractType, componentState.premiumTime) } }
            return { ...state, ...componentState }

        },
        engineeringSummarySetData: (state, action) => {
            const data = [...action.payload].sort(sortByPact)
            const totals = getHoursAndLaborTotals(data)
            return {
                ...state,
                engineeringSummary: { data, totals }
            }
        },
        projectManagementSummarySetData: (state, action) => {
            const data = [...action.payload].sort(sortByPact)
            const totals = getHoursAndLaborTotals(data)
            return {
                ...state,
                projectManagementSummary: { data, totals }
            }
        },
        directLaborSummarySetData: (state, action) => {
            const data = [...action.payload].sort(sortByPact)
            const totals = getDirectLaborSummaryTotals(data)
            return {
                ...state,
                directLaborSummary: { data, totals }
            }
        },
        indirectLaborSummarySetData: (state, action) => {
            const data = [...action.payload].sort(sortByPact)
            const totals = getIndirectLaborSummaryTotals(data)
            return {
                ...state,
                indirectLaborSummary: { data, totals }
            }
        },
        indirectMaterialSummarySetData: (state, action) => {
            const data = [...action.payload].sort(sortByPact)
            const totals = getIndirectMaterialSummaryTotals(data)
            return {
                ...state,
                indirectMaterialSummary: { data, totals }
            }
        },
        taggedEquipmentSummarySetData: (state, action) => {
            const data = [...action.payload].sort(sortByPact)
            const totals = getTaggedEquipmentSummaryTotals(data)
            return {
                ...state,
                taggedEquipmentSummary: { data, totals }
            }
        },
        divisionAllowancesSetData: (state, action) => {
            const data = [...action.payload].sort(sortByPact)
            const totals = getDivisionAllowancesTotals(data)
            return {
                ...state,
                divisionAllowances: { data, totals }
            }
        },
        projectExpenseSummarySetData: (state, action) => {
            const data = [...action.payload].sort(sortByPactCode)
            const totals = getProjectExpenseSummaryTotals(data)

            return {
                ...state,
                projectExpenseSummary: { data, totals }
            }
        },
        constructionManagementSummarySetData: (state, action) => {
            const data = [...action.payload].sort(sortByPact)
            const totals = getConstructionManagementSummaryTotals(data)
            return {
                ...state,
                constructionManagementSummary: { data, totals }
            }
        },
        contingencyEscalationSummarySetData: (state, action) => {
            const data = [...action.payload].sort(sortByPact)
            const totals = getContingencyEscalationSummaryTotals(data)
            return {
                ...state,
                contingencyEscalationSummary: { data, totals }
            }
        },
        projectOHPSummarySetData: (state, action) => {
            const data = [...action.payload].sort(sortByPact)
            const totals = getProjectOHPSummaryTotals(data)
            return {
                ...state,
                projectOHPSummary: { data, totals }
            }
        },
        contractTypeSetData: (state, action) => {
            return {
                ...state,
                contractType: action.payload,
                premiumTime: { ...state.premiumTime, markup: getPremiumTimeMarkup(action.payload, state.premiumTime) }
            }
        },
        premiumTimeSetData: (state, action) => {
            return {
                ...state,
                premiumTimeOther: 0,
                premiumTime: { ...state.premiumTime, value: action.payload, markup: getPremiumTimeMarkup(state.contractType, { options: [...state.premiumTime.options], value: action.payload }) }
            }
        },
        premiumTimeOtherSetData: (state, action) => {
            return {
                ...state,
                premiumTimeOther: action.payload
            }
        },
        productivityAdjustmentSetData: (state, action) => {
            return {
                ...state,
                productivityAdjustment: action.payload
            }
        }
    }
})

const getHoursAndLaborTotals = (data) => {
    const reducedTotals = data.reduce((a, b) => {
        return (
            {
                hours: a.hours + b.hours,
                sbiHours: a.sbiHours + b.sbiHours,
                laborDollars: a.laborDollars + b.laborDollars,
                sbiLaborDollars: a.sbiLaborDollars + b.sbiLaborDollars
            })
    }, hoursAndLaborInitialTotals)
    return reducedTotals
}

const getDirectLaborSummaryTotals = (data) => {
    const reducedTotals = data.reduce((a, b) => {
        return (
            {
                directLaborHours: a.directLaborHours + b.directLaborHours,
                directLaborDollars: a.directLaborDollars + b.directLaborDollars,
                quantity: a.quantity + b.quantity,
                unitRate: a.unitRate + b.unitRate,
                directMaterialDollars: a.directMaterialDollars + b.directMaterialDollars,
                subDollars: a.subDollars + b.subDollars
            })
    }, directLaborSummaryInitialTotals)
    return reducedTotals
}

const getIndirectLaborSummaryTotals = (data) => {
    const reducedTotals = data.reduce((a, b) => {
        return (
            {
                hours: a.hours + b.hours,
                dollars: a.dollars + b.dollars
            }
        )
    }, indirectLaborSummaryInitialTotals);
    return reducedTotals;
}

const getIndirectMaterialSummaryTotals = (data) => {
    const reducedTotals = data.reduce((a, b) => {
        return (
            {
                materialDollars: a.materialDollars + b.materialDollars,
                subcontractDollars: a.subcontractDollars + b.subcontractDollars
            })
    }, indirectMaterialSummaryInitialTotals)
    return reducedTotals
}

const getTaggedEquipmentSummaryTotals = (data) => {
    const reducedTotals = data.reduce((a, b) => {
        return (
            {
                equipmentDollars: Number(a.equipmentDollars) + Number(b.equipmentDollars)
            }
        )
    }, taggedEquipmentSummaryTotals);
    return reducedTotals;
}

const getDivisionAllowancesTotals = (data) => {
    const reducedTotals = data.reduce((a, b) => {
        return (
            {
                dollars: Number(a.dollars) + Number(b.dollars)
            }
        )
    }, divisionAllowancesTotals);
    return reducedTotals;
}

const getProjectExpenseSummaryTotals = (data) => {
    const reducedTotals = data.reduce((a, b) => {
        return (
            {
                laborDolars: Number(a.laborDolars) + Number(b.laborDolars)
            }
        )
    }, projectExpenseSummaryTotals)

    return reducedTotals
}


const getConstructionManagementSummaryTotals = (data) => {
    const reducedTotals = data.reduce((a, b) => {
        return (
            {
                hours: Number(a.hours) + Number(b.hours),
                laborDollars: Number(a.laborDollars) + Number(b.laborDollars)
            }
        )
    }, constructionSummaryTotals);
    return reducedTotals;
}

const getContingencyEscalationSummaryTotals = (data) => {
    const reducedTotals = data.reduce((a, b) => {
        return (
            {
                dollars: a.dollars + b.dollars
            }
        )
    }, contingencyEscalationTotals);
    return reducedTotals;
}

const getProjectOHPSummaryTotals = (data) => {
    const reducedTotals = data.reduce((a, b) => {
        return (
            {
                dollars: a.dollars + b.dollars
            }
        )
    }, projectOHPSummaryTotals)

    return reducedTotals
}

const getPremiumTimeMarkup = (contractType, premiumTime) => {
    const premiumTimeSelected = premiumTime.options.find(pt => pt.id === parseInt(premiumTime.value))
    return contractType === 'L' ? premiumTimeSelected.lumpSummarkup : premiumTimeSelected.costReimbursibleMarkup
}

export const { detailsSetData, engineeringSummarySetData, projectManagementSummarySetData,
    directLaborSummarySetData, indirectLaborSummarySetData, indirectMaterialSummarySetData,
    contractTypeSetData, premiumTimeSetData, premiumTimeOtherSetData, productivityAdjustmentSetData,
    divisionAllowancesSetData, constructionManagementSummarySetData,
    taggedEquipmentSummarySetData, projectExpenseSummarySetData, contingencyEscalationSummarySetData, projectOHPSummarySetData } = detailsDataAndTotalSlice.actions
export default detailsDataAndTotalSlice.reducer