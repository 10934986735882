import axios from './../../../setup/axios';

class TemplateService {

    BASE_ROUTE = "/templates";

    getTemplatesByProjectId(projectId) {
        return this.get(`${projectId}/project`)
    }

    getTemplatesByJobNumber(jobNumber){
        return this.get(`${jobNumber}/job`)
    }

    getTemplate(templateId) {
        return this.get(`${templateId}`)
    }

    getTemplateAndRates(templateId) {
        return this.get(`${templateId}/rates`);
    }

    createTemplate(templateData) {
        return this.post('', templateData);
    }

    updateTemplate(templateData) {
        return this.put('', templateData);
    }

    updateRates(templateId, ratesData) {
        return this.put(`${templateId}/rates`, ratesData);
    }

    get(route) {
        return axios.get(`${this.BASE_ROUTE}/${route}`)
    }

    post(route, data) {
        return axios.post(`${this.BASE_ROUTE}/${route}`, data);
    }

    put(route, data) {
        return axios.put(`${this.BASE_ROUTE}/${route}`, data);
    }
}

export default new TemplateService()