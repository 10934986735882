import { connect, useDispatch } from 'react-redux';
import { CONTRACT_TYPE } from '../type-and-time/Options'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import React, {useEffect, useRef, useCallback, useMemo} from 'react'
import { contingencyEscalationSummarySetData } from "../redux/detailsDataAndTotalSlice"
import NumericEditor from "../../../../utilities/ag-grid-utilities/cell-editors/NumericEditor"
import { currencyFormatter, getRowStyle, getRowSpanForDescriptionTotal, isFooter, defaultGridOptions } from "../../../../utilities/ag-grid-utilities/AgGridUtilities";
import DecimalEditor from '../../../../utilities/ag-grid-utilities/cell-editors/DecimalEditor';
import {CAN_OVERRIDE_DETAIL_VALUES} from '../../logs-roles/Roles';
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';


const ESCALATION = "ESCALATION"
const CONTINGENCY = "CONTINGENCY"
const TOTAL_DESCRIPTION = "Total Contingency & Allowances"

const cellClassRules = {
    'override-status': params => params.data.edited,
    'editable-cell': params => {
        if ((!params.colDef.editable || !params.colDef.editable(params)) || isFooter(params)) {
            return false;
        }
        if (params.colDef.field === 'activityDescription') {
            return params.data.activityDescription.toLowerCase() === 'undefined';
        }
        return true;
    }
}

const ContingencyEscalationSummaryGrid = React.memo(({ data,
    totals,
    contractType,
    f18, k18,
    f39, k39,
    f61, j61, k61,
    f72,
    f87,
    j96,
    e110, j110,
    j123,
    readOnly
}) => {

    const dispatch = useDispatch()
    const doUpdate = useRef(false)
    const dataRef = useRef(...data)
    const { isAuthorized } = useAuthorized()
    const canOverrideValues = useMemo(() => {
        return isAuthorized([...CAN_OVERRIDE_DETAIL_VALUES]);
    }, [isAuthorized])

    useEffect(() => {
        dataRef.current = [...data]
    }, [data])

    const applyFormulasToDataModel = useCallback((editedData) => {
        const escalationType = contractType === CONTRACT_TYPE.LumpSum ? 'lumpSumEscalation' : 'costReimbursibleEscalation'
        const contingencyType = contractType === CONTRACT_TYPE.LumpSum ? 'lumpSumContingency' : 'costReimbursibleContingency'
        const dataWidoutContingency = editedData.filter(d => d.activityDescription.trim() !== CONTINGENCY.trim())
        let contingencyDataRow = editedData.find(d => d.activityDescription.trim() === CONTINGENCY.trim())
        let escalationDollarsValue = 0

        const updatedDataModelWidoutContingency = dataWidoutContingency.map(row => {
            const { labor, activityDescription, dollars, budgetOverride } = row
            let dollarsValue = dollars;
            if (activityDescription === ESCALATION) {
                if (!budgetOverride) {
                    dollarsValue = Math.ceil((j61 + j96) * labor[escalationType]);
                }
                escalationDollarsValue = dollarsValue
            }
            return {
                ...row,
                dollars: dollarsValue
            }
        })

        if (!contingencyDataRow.budgetOverride) {
            const calculatedContingencyValue = Math.ceil((f18 + k18 + f39 + k39 + f61 + j61 + k61 + f72 + f87 + j96 + e110 + j110 + j123 + escalationDollarsValue) * contingencyDataRow.labor[contingencyType]);
            contingencyDataRow = {...contingencyDataRow, dollars: calculatedContingencyValue}
        }

        const updatedDataModel = [...updatedDataModelWidoutContingency, ...[contingencyDataRow]]
        dispatch(contingencyEscalationSummarySetData(updatedDataModel))

    }, [contractType, dispatch, e110, f18, f39, f61, f72, f87, j110, j123, j61, j96, k18, k39, k61])

    const recalculateFormulas = useCallback(() => {
        applyFormulasToDataModel(data)
    }, [applyFormulasToDataModel, data])

    useEffect(() => {
        if (doUpdate.current) {
            recalculateFormulas()
        }
    }, [recalculateFormulas, contractType, e110, f18, f39, f61, f72, f87, j110, j123, j61, j96, k18, k39, k61])

    const valueSetter = (params) => {
        if (params.colDef.field === "dollars") {
            //Validate Dollars precision and scale
            if (params.newValue > 999999999.99) {
                return params.oldValue
            }
        }
        const isNewValue = Number(params.oldValue) !== Number(params.newValue)
        if (isNewValue) {
            const updatedRow = { ...params.data, [params.column.colId]: Number(params.newValue), edited: true, budgetOverride: true }
            const filteredData = dataRef.current.filter(f => f.id !== params.data.id)
            applyFormulasToDataModel([...filteredData, ...[updatedRow]])
        }
        return isNewValue
    }

    return (
        <div className="ag-theme-bootstrap" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ flex: '1 1 auto' }} >
                <AgGridReact
                    gridOptions={{ ...defaultGridOptions }}
                    getRowStyle={getRowStyle}
                    rowData={data}
                    getRowNodeId={data => data.id}
                    immutableData={true}
                    domLayout="autoHeight"
                    enableCellChangeFlash
                    singleClickEdit={true}
                    onFirstDataRendered={() => doUpdate.current = true}
                    pinnedBottomRowData={[totals]}
                    frameworkComponents={{
                        numericCellEditor: NumericEditor,
                        decimalCellEditor: DecimalEditor
                    }}>
                    <AgGridColumn headerName="Edited" field="edited" hide valueFormatter={params => params.value ?? false} />
                    <AgGridColumn headerName="Pact" field="pact" headerClass="header-grid" width={75} maxWidth={75} cellClassRules={cellClassRules}
                        colSpan={getRowSpanForDescriptionTotal}
                        valueFormatter={params => isFooter(params) ? TOTAL_DESCRIPTION : params.value} />
                    <AgGridColumn headerName="Activity" field="activityDescription" headerClass="header-grid" width={330} cellClassRules={cellClassRules}
                    />
                    <AgGridColumn headerName="Contingency and Allowances $" field="dollars" headerClass="header-grid right-sortable-header-grid"
                        type="numericColumn" editable={params => canOverrideValues && !readOnly && !isFooter(params)} cellClassRules={cellClassRules}
                        valueSetter={valueSetter} cellEditor="decimalCellEditor"
                        valueFormatter={(params) => currencyFormatter(params.data.dollars ?? 0, '$', 0)} />
                </AgGridReact>
            </div>
        </div>
    )


}, (prevProps, nextProps) => {
    return (
        prevProps.f18 === nextProps.f18 && prevProps.k18 === nextProps.k18 &&
        prevProps.f39 === nextProps.f39 && prevProps.k39 === nextProps.k39 &&
        prevProps.f61 === nextProps.f61 && prevProps.j61 === nextProps.j61 && prevProps.k61 === nextProps.k61 &&
        prevProps.f72 === nextProps.f72 &&
        prevProps.f87 === nextProps.f87 &&
        prevProps.j96 === nextProps.j96 &&
        prevProps.e110 === nextProps.e110 && prevProps.j110 === nextProps.j110 &&
        prevProps.j123 === nextProps.j123 &&
        prevProps.contractType === nextProps.contractType &&
        JSON.stringify(prevProps.totals) === JSON.stringify(nextProps.totals) &&
        JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
    )
});

const mapStateToProps = (state) => {
    const {
        contingencyEscalationSummary: { data, totals },
        engineeringSummary: { totals: { laborDollars: f18, sbiLaborDollars: k18 } },
        projectManagementSummary: { totals: { laborDollars: f39, sbiLaborDollars: k39 } },
        directLaborSummary: { totals: { directLaborDollars: f61, directMaterialDollars: j61, subDollars: k61 } },
        indirectLaborSummary: { totals: { dollars: f72 } },
        constructionManagementSummary: { totals: { laborDollars: f87 } },
        taggedEquipmentSummary: { totals: { equipmentDollars: j96 } },
        indirectMaterialSummary: { totals: { materialDollars: e110, subcontractDollars: j110 } },
        projectExpenseSummary: { totals: { laborDolars: j123 } },
        contractType, readOnly } = state.detailsDataAndTotal
    return {
        data,
        totals,
        contractType,
        f18, k18,
        f39, k39,
        f61, j61, k61,
        f72,
        f87,
        j96,
        e110, j110,
        j123,
        readOnly
    }
}

export default connect(mapStateToProps)(ContingencyEscalationSummaryGrid)