import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    Input
} from "reactstrap";

export default forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);
    const [options, setOptions] = useState([]);
    const refInput = useRef(null);

    useEffect(() => {
        setOptions(props.options.map((value, key) => (
            <option value={value} key={key}>{value}</option>
        )))
        if (props.options?.length === 1) {
            setValue(props.options[0]);
        }
    }, [props.options]);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                // this simple editor doubles any value entered into the input
                return value;
            },

            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            isCancelBeforeStart() {
                return false;
            },

            // Gets called once when editing is finished (eg if Enter is pressed).
            // If you return true, then the result of the edit will be ignored.
            isCancelAfterEnd() {
                // our editor will reject any value greater than 1000
                return false;
            }
        };
    });

    const onDropDownSelect = (event) => {
        props.data.activityCode = ""
        props.data.activityDescription = ""
        setValue(event.target.value)
        setTimeout(() => props.api.stopEditing(), 50)
    }

    return (
        <Input type="select"
            ref={refInput}
            value={value}
            onChange={onDropDownSelect}
            bsSize="sm"
            className="custom-blue-select"
            style={{ width: "100%" }}>
            {options}
        </Input>
    );
});