import { Controller } from "react-hook-form"
import { ErrorMessage } from '@hookform/error-message';
import { Container, Label, Input, Row, Col } from "reactstrap";

const coveredByOptions = ["None", "Contingency", "Escalation", "Other"];

const trackingOptions = [
    { value: "A", description: "A - Above Line In CR" },
    { value: "B", description: "B - Below Line In CR" },
    { value: "AC", description: "AC - Approved Change In Budget" },
    { value: "R", description: "R - On Radar" },
    { value: "D", description: "D - Deleted" }
]

const FTTrackingForm = ({ control, errors, readOnly = false }) => {

    return (
        <>
            <Container>
                <h5>Tracking</h5>
                <Row>
                    <Col xs={1} className="text-right">
                        <Label for="changeNumber">Change #</Label>
                    </Col>
                    <Col xs={2} >
                        <Controller
                            control={control}
                            name="changeNumber"
                            render={({ field }) => <Input disabled={readOnly} bsSize="sm" {...field} />}
                            rules={{ maxLength: { message: "Change number must be no longer than 10 characters.", value: 10 } }}

                        />
                        <ErrorMessage className="text-danger small" errors={errors} name={"changeNumber"} as="p" key={"changeNumber"} />
                    </Col>

                    <Col xs={1} className="text-right">
                        <Label for="tracking">Tracking</Label>
                    </Col>
                    <Col xs={2}>
                        <Controller
                            control={control}
                            name="tracking"
                            render={({ field }) => <Input
                                type="select"
                                bsSize="sm"
                                disabled={readOnly}
                                required={true}
                                {...field}>
                                {trackingOptions.map(option => <option key={option.value} value={option.value}>{option.description}</option>)}
                            </Input>} />
                    </Col>

                    <Col xs={1} className="text-right">
                        <Label for="coveredBy">Covered By</Label>
                    </Col>
                    <Col sm="3" lg="2">
                        <Controller
                            control={control}
                            name="coveredBy"
                            render={({ field }) => <Input
                                type="select"
                                bsSize="sm"
                                disabled={readOnly}
                                required={true}
                                {...field}>
                                {coveredByOptions.map(option => <option key={option} value={option}>{option}</option>)}
                            </Input>} />
                    </Col>
                </Row>
                <hr />
            </Container>
        </>
    )
}
export default FTTrackingForm