// import { lazy } from "react";
import { Switch } from "react-router";
import routes from '../../setup/routes';
import Page from "../../UI/Page";
import AuthorizedRoute from "../auth/Authorized/AuthorizedRoute";
import {CLIENT, PROJECT_CONTROLS} from '../logs/logs-roles/Roles';
import CreateFT from './create-ft/CreateFT';
import UpdateFT from './update-ft/UpdateFT';
import FlashTrend from './list-ft/FlashTrend';

// const CreateFT = lazy(() => import('./create-ft/CreateFT'));
// const UpdateFT = lazy(() => import('./update-ft/UpdateFT'));
// const FlashTrend = lazy(() => import('./list-ft/FlashTrend'));

const AllowedRoles = [PROJECT_CONTROLS]

const FTPage = () => {
    return (
        <Page>
            <Switch>
                <AuthorizedRoute path={routes.CreateFTRoute} component={CreateFT} roles={AllowedRoles} />
                <AuthorizedRoute path={routes.UpdateFTRoute} component={UpdateFT} roles={AllowedRoles} />
                <AuthorizedRoute path={routes.FTRoute} component={FlashTrend} exclude={[CLIENT]}></AuthorizedRoute>
            </Switch>
        </Page>
    );
}

export default FTPage;