import React from 'react'
import classes from './TemplateHeader.module.css'
import FeatureTitle from '../../../../components/FeatureTitle/FeatureTitle';
import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { CreateTemplateRoute } from '../../../../setup/routes';
import Authorized from '../../../auth/Authorized/Authorized';
import { TEMPLATE_USERS } from '../../../logs/logs-roles/Roles';

const AuthorizedRoles = [...TEMPLATE_USERS];

const TemplateHeader = () => {

    return (
        <Container>
            <Row>
                <Col>
                    <FeatureTitle title="Templates" />
                </Col>
                <Authorized roles={AuthorizedRoles}>
                    <Col className={classes.flexEnd}>
                        <Link to={CreateTemplateRoute}>
                            <Button color="primary" >Create Template</Button>
                        </Link>
                    </Col>
                </Authorized>
            </Row>

        </Container>


    )
}

export default TemplateHeader