import { useForm } from 'react-hook-form';
import { Container, Row, Col, Form } from 'reactstrap';
import NextCancel from '../../../components/NextCancel/NextCancel';
import TemplateBasicInfoForm from './template-basic-info-form/TemplateBasicInfoForm';
import { TemplatesRoute, getEditRatesRoute } from '../../../setup/routes';
import Page from '../../../UI/Page';
import templateService from '../api/templatesService';
import {useDispatch, useSelector} from 'react-redux';
import { selectedTemplate } from '../redux/templatesSlice';
import localStorageService from '../../../services/localStorageService';
import { useHistory } from 'react-router';
import FeatureTitle from '../../../components/FeatureTitle/FeatureTitle';
import RatesCopyForm from './rates-copy-form/RatesCopyForm';
import Authorized from '../../auth/Authorized/Authorized';
import {PROJECT_CONTROLS} from '../../logs/logs-roles/Roles';

const AuthorizedRoles = [PROJECT_CONTROLS]

const CreateTemplate = () => {
    let history = useHistory();
    const userId = useSelector(state => state.user.user.userId)
    const { register, handleSubmit, formState: { errors, isValid }, control, getValues, setValue, watch, trigger } = useForm({

        defaultValues: {
            templateStatus: "PENDING",
            rates: "manually",
            templateToCopy: " "
        },
        mode: "onChange"
    });

    const dispatch = useDispatch()

    const templateStatuses = [
        "ACTIVE", "CLOSED", "PENDING"
    ];

    const onSubmit = (formData) => {
        templateService.createTemplate({
            name: formData.templateName,
            description: formData.templateDescription,
            status: formData.templateStatus,
            projectId: localStorageService.getProjectId(),
            userId,
            templateToCopy: formData.templateToCopy !== " " && formData.rates === 'copy' ? formData.templateToCopy.value : null
        })
            .then(response => {
                dispatch(selectedTemplate(response.data.data));
                history.push(getEditRatesRoute(response.data.data));
            })
            .catch(err => console.log("error"));
    }

    return (
        <Page>
            <Container>
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title="Create Template" />
                            <hr />
                        </Container>
                    </Col>
                </Row>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col>
                            <TemplateBasicInfoForm register={register} errors={errors} templateStatuses={templateStatuses} control={control} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Authorized roles={AuthorizedRoles}>
                                <RatesCopyForm control={control} getValues={getValues} errors={errors} watch={watch} setValue={setValue} register={register} trigger={trigger} />
                            </Authorized>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <NextCancel cancelRoute={TemplatesRoute} disabled={!isValid} cancelConfirmText="Are you sure you want to close?"/>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Page>
    )
}

export default CreateTemplate;