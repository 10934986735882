

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import LogService from "../../../api/logService"
import {
    Input
} from "reactstrap";

export default forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);
    const [area] = useState(props.data.area);
    const [projectId] = useState(props.projectId)
    const [activityOptions, setActivityOptions] = useState([])
    const refInput = useRef(null);
    const [pactNumber] = useState(props.pactNumber);

    useEffect(() => {
        async function getActivitiesByArea() {
            if (area) {
                const response = await LogService.getActivitiesByArea(area, projectId)
                let activities = response.data.data?.activities ?? []
                activities = activities.filter(a => a.taskNumber.startsWith(pactNumber))

                var options = activities.map(({ taskNumber, description }) => (
                    <option value={taskNumber} id={description} key={taskNumber}>{taskNumber}</option>
                ));
                options.unshift(<option value={""} key={""}>{activities.length === 0 ? "No Activities" : "Select Activity"}</option>)
                setActivityOptions(options)
            }
        }
        getActivitiesByArea()
    }, [area, projectId, pactNumber]);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                // this simple editor doubles any value entered into the input
                return value;
            },

            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            isCancelBeforeStart() {
                return false;
            },

            // Gets called once when editing is finished (eg if Enter is pressed).
            // If you return true, then the result of the edit will be ignored.
            isCancelAfterEnd() {
                // our editor will reject any value greater than 1000
                return false;
            }
        };
    });

    const onDropDownSelect = (event) => {
        const activityDescription = event.target[event.target.selectedIndex].id
        props.data.activityDescription = activityDescription
        setValue(event.target.value)
        setTimeout(() => props.api.stopEditing(), 50)
    }

    return (
        <Input type="select"
            ref={refInput}
            value={value}
            onChange={onDropDownSelect}
            bsSize="sm"
            className="custom-blue-select"
            style={{ width: "100%" }}
        >
            {activityOptions}
        </Input>
    );
});