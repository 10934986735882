export const PROJECT_MANAGER = 'ProjectManager';
export const PROJECT_ENGINEER = 'ProjectEngineer';
export const PROJECT_CONTROLS = 'ProjectControls';
export const PROJECT_COORDINATOR = 'ProjectCoordinator';
export const CONSTRUCTION_MANAGER = 'ConstructionManager';
export const ELECTRICAL_LEAD = 'ElectricalLead';
export const CIVIL_STRUCTURAL_LEAD = 'CivilStructuralLead';
export const INSTRUMENT_LEAD = 'InstrumentLead';
export const MECHANICAL_LEAD = 'MechanicalLead';
export const PIPING_LEAD = 'PipingLead';
export const PROCESS_LEAD = 'ProcessLead';
export const BUDGET_COORDINATOR = 'BudgetCoordinator';
export const CLIENT = 'ClientCanAccessApp';

export const PROJECT_COORDINATORS = [PROJECT_MANAGER, PROJECT_ENGINEER, PROJECT_CONTROLS, PROJECT_COORDINATOR,CONSTRUCTION_MANAGER]
export const DISCIPLINE_LEADS = [ELECTRICAL_LEAD, CIVIL_STRUCTURAL_LEAD, INSTRUMENT_LEAD, MECHANICAL_LEAD, PIPING_LEAD, PROCESS_LEAD]
export const FCO_USERS = [PROJECT_COORDINATOR, BUDGET_COORDINATOR, PROJECT_CONTROLS,CONSTRUCTION_MANAGER]
export const TEMPLATE_USERS = [PROJECT_CONTROLS]
export const LOG_USERS = [CONSTRUCTION_MANAGER, PROJECT_CONTROLS, PROJECT_ENGINEER, PROJECT_MANAGER, PROJECT_COORDINATOR,
ELECTRICAL_LEAD, CIVIL_STRUCTURAL_LEAD, INSTRUMENT_LEAD, MECHANICAL_LEAD, PIPING_LEAD, PROCESS_LEAD, BUDGET_COORDINATOR]
export const CAN_OVERRIDE_DETAIL_VALUES = [PROJECT_CONTROLS, PROJECT_ENGINEER, PROJECT_COORDINATOR, PROJECT_MANAGER, CONSTRUCTION_MANAGER]
export const CAN_REVERT_STATUS = [PROJECT_MANAGER, PROJECT_ENGINEER, PROJECT_CONTROLS]
