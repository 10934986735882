import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faBars, faTrash, faPencilAlt, faCog,
    faChevronUp, faChevronDown, faSquare,
    faPlusCircle, faExclamationTriangle, 
    faInfoCircle, faFilter, faFilePdf,faFileExcel, faDownload, faTable
} from '@fortawesome/free-solid-svg-icons';

import { faFile as faFileRegular, faTrashAlt as faTrashAltRegular } from '@fortawesome/free-regular-svg-icons'

library.add(faBars,
    faPlusCircle,
    faTrash,
    faPencilAlt,
    faCog,
    faFilter,
    faFilePdf,
    faFileExcel,
    faChevronUp,
    faChevronDown,
    faSquare,
    faExclamationTriangle,
    faExclamationTriangle,
    faInfoCircle,
    faFileRegular,
    faTrashAltRegular,
    faDownload,
    faTable
);