import React from 'react'
import { Container, Alert } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { closeSuccess } from '../redux/successSlice'
import Page from '../../../UI/Page'

const SuccessArea = () => {
    const { showSuccess, message } = useSelector((state) => state.success)

    const dispatch = useDispatch()

    const onDismiss = () => {
        dispatch(closeSuccess())
    };

    return (
        <Page>
            <Container>
                <Alert color="success" isOpen={showSuccess} toggle={onDismiss} fade>
                    <h4 className="alert-heading">Success</h4>
                    <hr />
                    {message}
                </Alert>
            </Container>
        </Page>
    )

}

export default SuccessArea