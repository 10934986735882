import { useDispatch, connect } from "react-redux";
import { CONTRACT_TYPE } from '../type-and-time/Options'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import React, { useEffect, useRef, useCallback } from 'react'
import { projectOHPSummarySetData } from "../redux/detailsDataAndTotalSlice"
import { getRowStyle, defaultGridOptions, getRowSpanForDescriptionTotal, isFooter, currencyFormatter } from "../../../../utilities/ag-grid-utilities/AgGridUtilities";


const FEES = "FEES"
const TOTAL_DESCRIPTION = "Total Project OH&P (DIV 6)"

const cellClassRules = {
    'override-status': params => params.data.edited,
}


const ProjectOHPSummaryGrid = React.memo(({
    data,
    totals,
    contractType,
    engineeringSummaryTotals,
    directLaborSummaryTotals,
    indirectLaborSummaryTotals,
    projectExpenseSummaryTotals,
    taggedEquipmentSummaryTotals,
    indirectMaterialSummaryTotals,
    projectManagementSummaryTotals,
    constructionManagementSummaryTotals,
    contingencyEscalationSummary }) => {

    const dispatch = useDispatch()
    const doUpdate = useRef(false)

    const applyFormulasToDataModel = useCallback((localData) => {
        const updatedDataModel = localData.map(row => {
            const { activityDescription, dollars, labor } = row

            if (activityDescription === FEES) {
                let ohpValue;

                // const contingency = contingencyEscalationSummary.data.find(budget => budget.activityDescription === CONTINGENCY);

                const sum =
                    engineeringSummaryTotals.laborDollars + engineeringSummaryTotals.sbiLaborDollars +
                    projectManagementSummaryTotals.laborDollars + projectManagementSummaryTotals.laborDollars +
                    directLaborSummaryTotals.directLaborDollars + directLaborSummaryTotals.directMaterialDollars + directLaborSummaryTotals.subDollars +
                    indirectLaborSummaryTotals.dollars +
                    constructionManagementSummaryTotals.laborDollars +
                    taggedEquipmentSummaryTotals.equipmentDollars +
                    indirectMaterialSummaryTotals.materialDollars + indirectMaterialSummaryTotals.subcontractDollars +
                    projectExpenseSummaryTotals.laborDolars +
                    contingencyEscalationSummary.totals.dollars

                if (contractType === CONTRACT_TYPE.LumpSum) {
                    ohpValue = Math.ceil(Number(sum) * Number(labor.lumpSumMarkup))
                } else {
                    ohpValue = Math.ceil(Number(sum) * Number(labor.costReimbursibleMarkup))
                }
                return {
                    ...row,
                    dollars: ohpValue
                };
            } else {
                return {
                    ...row,
                    dollars
                };
            }

        })

        if (updatedDataModel.length > 0) {
            dispatch(projectOHPSummarySetData(updatedDataModel))
        }

    }, [contractType,
        dispatch,
        engineeringSummaryTotals,
        projectManagementSummaryTotals,
        directLaborSummaryTotals,
        constructionManagementSummaryTotals,
        taggedEquipmentSummaryTotals,
        indirectMaterialSummaryTotals,
        projectExpenseSummaryTotals,
        indirectLaborSummaryTotals,
        contingencyEscalationSummary.totals])

    const recalculateFormulas = useCallback(() => {
        applyFormulasToDataModel(data)
    }, [applyFormulasToDataModel, data])

    useEffect(() => {
        if (doUpdate.current) {
            recalculateFormulas()
        }
    }, [contractType,
        recalculateFormulas,
        engineeringSummaryTotals,
        projectManagementSummaryTotals,
        directLaborSummaryTotals,
        constructionManagementSummaryTotals,
        taggedEquipmentSummaryTotals,
        indirectMaterialSummaryTotals,
        projectExpenseSummaryTotals,
        indirectLaborSummaryTotals,
        contingencyEscalationSummary])

    return (
        <div className="ag-theme-bootstrap" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ flex: '1 1 auto' }} >
                <AgGridReact
                    getRowStyle={getRowStyle}
                    gridOptions={{ ...defaultGridOptions }}
                    rowData={data}
                    getRowNodeId={data => data.id}
                    immutableData={true}
                    domLayout="autoHeight"
                    singleClickEdit={true}
                    pinnedBottomRowData={[totals]}
                    onFirstDataRendered={() => doUpdate.current = true}
                    enableCellChangeFlash>
                    <AgGridColumn headerName="Pact" field="pact"
                        colSpan={getRowSpanForDescriptionTotal} valueFormatter={params => isFooter(params) ? TOTAL_DESCRIPTION : params.value} filter headerClass="header-grid" maxWidth={70} />
                    <AgGridColumn headerName="Activity" field="activityDescription" headerClass="header-grid" minWidth={400} 
                    />
                    <AgGridColumn headerName="Project OH and P $"
                        field="dollars"
                        headerClass="header-grid right-sortable-header-grid"
                        type="numericColumn" cellClassRules={cellClassRules}
                        valueFormatter={(params) => currencyFormatter(params.data.dollars ?? 0, '$')} />
                </AgGridReact>
            </div>
        </div>

    )

}, (prevProps, nextProps) => {
    // true: do not render
    // false: do render
    return (
        prevProps.contractType === nextProps.contractType &&
        prevProps.engineeringSummaryTotals === nextProps.engineeringSummaryTotals &&
        prevProps.projectManagementSummaryTotals === nextProps.projectManagementSummaryTotals &&
        prevProps.directLaborSummaryTotals === nextProps.directLaborSummaryTotals &&
        prevProps.constructionManagementSummaryTotals === nextProps.constructionManagementSummaryTotals &&
        prevProps.taggedEquipmentSummaryTotals === nextProps.taggedEquipmentSummaryTotals &&
        prevProps.indirectMaterialSummaryTotals === nextProps.indirectMaterialSummaryTotals &&
        prevProps.projectExpenseSummaryTotals === nextProps.projectExpenseSummaryTotals &&
        prevProps.indirectLaborSummaryTotals === nextProps.indirectLaborSummaryTotals &&
        prevProps.contingencyEscalationSummary === nextProps.contingencyEscalationSummary &&
        JSON.stringify(prevProps.totals) === JSON.stringify(nextProps.totals) &&
        JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
    )
}
)

const mapStateToProps = (state) => {
    const { projectOHPSummary: { data, totals }, contractType,
        engineeringSummary: { totals: engineeringSummaryTotals },
        projectManagementSummary: { totals: projectManagementSummaryTotals },
        directLaborSummary: { totals: directLaborSummaryTotals },
        constructionManagementSummary: { totals: constructionManagementSummaryTotals },
        taggedEquipmentSummary: { totals: taggedEquipmentSummaryTotals },
        indirectMaterialSummary: { totals: indirectMaterialSummaryTotals },
        projectExpenseSummary: { totals: projectExpenseSummaryTotals },
        indirectLaborSummary: { totals: indirectLaborSummaryTotals },
        contingencyEscalationSummary } = state.detailsDataAndTotal

    return {
        data,
        totals,
        contractType,
        engineeringSummaryTotals,
        directLaborSummaryTotals,
        indirectLaborSummaryTotals,
        projectExpenseSummaryTotals,
        taggedEquipmentSummaryTotals,
        indirectMaterialSummaryTotals,
        projectManagementSummaryTotals,
        constructionManagementSummaryTotals,
        contingencyEscalationSummary
    }
}

export default connect(mapStateToProps)(ProjectOHPSummaryGrid)