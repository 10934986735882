export const disciplineImpactInitialState = []

export const disciplineImpactReducer = (state = disciplineImpactInitialState, action) => {
    switch (action.type) {
        case 'ADD': return [...state, action.payload]
        case 'ADD_BATCH': return [...state, ...action.payload]
        case 'UPDATE': return state.map(discipline => {
            if (discipline.id === action.payload.id) {
                return {
                    ...discipline,
                    ...action.payload
                }
            }
            return discipline
        })
        case 'DELETE': return state.filter(discipline => !action.payload.some(selectedDiscipline => selectedDiscipline === discipline.id.toString()))
        case 'DELETE_ALL': return disciplineImpactInitialState
        case 'SET_DISCIPLINE_IMPACT': return action.payload
        default: return state
    }
}