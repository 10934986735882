import { Controller } from "react-hook-form"
import NumberFormat from "react-number-format";
import React, { useState, useEffect } from 'react'
import { ErrorMessage } from '@hookform/error-message';
import { StyledColumn } from "../../../../components/StyledColumn"
import { Container, Label, Input, InputGroup, InputGroupAddon, Row, Col } from "reactstrap";
import { StarIndicator } from "../../../../components/StarIndicator";

const mapDropdownOptions = (data, concatId = false) => (
    (data ? data.map(({ id, description, name }) => (
        <option value={id} key={id}>{concatId && `${id} - `}{description ?? name}</option>
    )) : [])
)

const PCIDetailInformationForm = ({ control, getValues, errors, templates, roms, causes, readOnly = false }) => {

    const [selectedId, setSelectedId] = useState(null)
    const scheduleValue = getValues("pciSchedule") ?? "1"

    useEffect(() => {
        setSelectedId(scheduleValue.toString())
    }, [scheduleValue])

    return (
        <Container className="pt-2">
            <hr />
            <h5>Detail Information</h5>
            <Row className="mt-md-3">
                <StyledColumn>
                    <Label for="pciBudgetTemplate">Budget Template<StarIndicator /></Label>
                </StyledColumn>
                <Col sm="5" lg="4">
                    <Controller
                        control={control}
                        name="pciBudgetTemplate"
                        rules={{ required: "Budget Templates is required." }}
                        render={({ field }) => <Input
                            disabled={readOnly}
                            type="select"
                            bsSize="sm"
                            data-testid="pciBudgetTemplate"
                            defaultValue="DEFAULT"
                            {...field}
                        >
                            {[...mapDropdownOptions(templates, true), <option value="DEFAULT" key="DEFAULT" disabled hidden>Choose here</option>]}
                        </Input>}
                    />
                    <ErrorMessage className="text-danger small" errors={errors} name={"pciBudgetTemplate"} as="p" key={"pciBudgetTemplate"} />
                </Col>

                <StyledColumn w={15}>
                    <Label for="pciDisciplineImpactReturnExpected">Discipline Impact Return Expected<StarIndicator /></Label>
                </StyledColumn>
                <Col sm="3" lg="2" className="ml-3">
                    <Controller
                        placeholder="Discipline Impact Return Expected"
                        control={control}
                        name="pciDisciplineImpactReturnExpected"
                        render={({ field }) =>
                            <InputGroup className="input-group-sm">
                                <Input {...field} data-testid="pciDisciplineImpactReturnExpected" value={field.value || ''} type="number" min={0} max={99} disabled={readOnly} />
                                <InputGroupAddon addonType="prepend">Day(s)</InputGroupAddon>
                            </InputGroup>}
                        rules={{
                            required: "Required",
                            min: { message: "Min Value for Discipline Impact Return Expected is 0", value: 0 },
                            max: { message: "Max Value for Discipline Impact Return Expected is 99", value: 99 },
                            maxLength: { message: "Discipline Impact Return Expected must be no longer than 2 digits.", value: 2 }
                        }}
                    />
                    <ErrorMessage className="text-danger small" errors={errors} name={"pciDisciplineImpactReturnExpected"} as="p" key={"pciDisciplineImpactReturnExpected"} />
                </Col>
            </Row>

            <Row className="mt-md-3">
                <StyledColumn>
                    <Label for="pciAproxTicCostRom">Approximate TIC Cost/ROM<StarIndicator /></Label>
                </StyledColumn>
                <Col sm="5" lg="4">
                    <Controller
                        control={control}
                        name="pciAproxTicCostRom"
                        rules={{ required: "Approximate TIC Cost/ROM is required." }}
                        render={({ field }) => <Input
                            disabled={readOnly}
                            type="select"
                            bsSize="sm"
                            data-testid="pciAproxTicCostRom"
                            defaultValue="DEFAULT"
                            {...field}
                        >
                            {[...mapDropdownOptions(roms.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1)), <option value="DEFAULT" key="DEFAULT" disabled hidden>Choose here</option>]}
                        </Input>}
                    />
                    <ErrorMessage className="text-danger small" errors={errors} name={"pciAproxTicCostRom"} as="p" key={"pciAproxTicCostRom"} />
                </Col>

                <StyledColumn w={15}>
                    <Label for="pciAnticipatedValue">Anticipated Value</Label>
                </StyledColumn>
                <Col sm="3" lg="2" className="ml-3">
                    <Controller
                        placeholder="Anticipated Value"
                        control={control}
                        name="pciAnticipatedValue"
                        render={({ field }) =>
                            <InputGroup className="input-group-sm">
                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                <NumberFormat className="form-control-sm form-control" disabled={readOnly} thousandSeparator={true} decimalScale={2} fixedDecimalScale {...field} />
                            </InputGroup>}
                        rules={{
                            required: "Required",
                            min: { message: "Min Value for Anticipated Value is 0.00", value: 0.00 },
                            max: { message: "Max Value for Anticipated Value is 999999999.99", value: 999999999.99 },
                            maxLength: { message: "Anticipated Value must be no longer than 9 digits.", value: 12 }
                        }}
                    />
                    <ErrorMessage className="text-danger small" errors={errors} name={"pciAnticipatedValue"} as="p" key={"pciAnticipatedValue"} />
                </Col>
            </Row>

            <Row className="mt-md-3">
                <StyledColumn>
                    <Label for="pciSchedule">Schedule</Label>
                </StyledColumn>
                <Col sm="5" lg="4" className="ml-3">
                    <Controller
                        control={control}
                        name="pciSchedule"
                        render={({ field }) =>
                            <div {...field} className="d-inline" >
                                <div>
                                    <Input
                                        disabled={readOnly}
                                        type="radio"
                                        id="pciScheduleNoImpact"
                                        value={1}
                                        checked={selectedId === "1"}
                                        onChange={() => setSelectedId(1)}
                                    />
                                    <Label for="pciScheduleNoImpact">No schedule impact</Label>
                                </div>
                                <div>
                                    <Input
                                        disabled={readOnly}
                                        id="pciScheduleImpactNotDeterminated"
                                        type="radio"
                                        value={2}
                                        checked={selectedId === "2"}
                                        onChange={() => setSelectedId(2)}
                                    />
                                    <Label for="pciScheduleImpactNotDeterminated">Schedule impact not determined</Label>
                                </div>
                                <div className="d-inline">
                                    <Input
                                        disabled={readOnly}
                                        id="pciScheduleImpactByRadio"
                                        type="radio"
                                        value={3}
                                        checked={selectedId === "3"}
                                        onChange={() => setSelectedId(3)}
                                    />
                                    <Label for="pciScheduleImpactByRadio">Schedule impact by</Label>
                                </div>
                            </div>}
                    />

                    <Controller
                        control={control}
                        name="pciScheduleImpactByText"
                        render={({ field }) =>
                            <Input
                                type="text"
                                bsSize="sm"
                                className="ml-2 col-md-7 d-inline"
                                data-testid="pciScheduleImpactByText"
                                disabled={readOnly || selectedId !== "3"}
                                {...field}
                            />}
                        rules={{ maxLength: { message: "Schedule impact by must be no longer than 50 characters.", value: 50 } }}
                    />
                    <ErrorMessage className="text-danger small" errors={errors} name={"pciScheduleImpactByText"} as="p" key={"pciScheduleImpactByText"} />
                </Col>

                <StyledColumn w={14}>
                    <Label for="pciReasonForChange">Reason for Change<StarIndicator /></Label>
                </StyledColumn>
                <Col sm="5" lg="3" className="pl-4">
                    <Controller
                        control={control}
                        name="pciReasonForChange"
                        rules={{ required: "Required" }}
                        render={({ field }) => <Input
                            disabled={readOnly}
                            type="select"
                            bsSize="sm"
                            data-testid="pciReasonForChange"
                            defaultValue="DEFAULT"
                            {...field}
                        >
                            {[...mapDropdownOptions(causes), <option value="DEFAULT" key="DEFAULT" disabled hidden>Choose here</option>]}
                        </Input>}
                    />
                </Col>
            </Row>
            <Row className="mt-md-3">
                <StyledColumn>
                    <Label for="pciNotes">Notes</Label>
                </StyledColumn>
                <Col sm="12" lg="10">
                    <Controller
                        placeholder="notes"
                        control={control}
                        name="pciNotes"
                        render={({ field }) => <Input type="textarea" {...field} data-testid="pciNotes" disabled={readOnly} />}
                        rules={{ maxLength: { message: "Notes must be no longer than 4000 characters.", value: 4000 } }}
                    />
                    <ErrorMessage className="text-danger small" errors={errors} name={"pciNotes"} as="p" key={"pciNotes"} />
                </Col>
            </Row>
        </Container>
    )
}

export default PCIDetailInformationForm
