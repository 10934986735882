import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Row,
    Button,
    Modal, ModalBody, ModalFooter
} from "reactstrap"
import styled from "styled-components";

const ButtonSection = styled.div`
    margin-left: auto;
`

const DetailsActionButtons = ({ cancelRoute, onSave, saveDisabled, editDisciplinesRoute }) => {

    const [openModal, setOpenModal] = useState(false);
    const toggle = () => setOpenModal(!openModal);
    let history = useHistory();

    const onConfirmCancel = () => {
        toggle();
        history.push(cancelRoute);
    }

    const onEditDisciplineImpact = () => {
        history.push(editDisciplinesRoute)
    }

    return (
        <Row>
            <ButtonSection>
                <Button color="link" hidden={!cancelRoute} data-testid="CancelLink" onClick={toggle} className="m-1">Close</Button>
                <Button color="primary" size="sm" data-testid="SaveChanges" disabled={saveDisabled} onClick={onSave} className="m-1 px-4 py-2">SAVE</Button>
                { editDisciplinesRoute && <Button color="primary" data-testid="EditDisciplineImpact" onClick={onEditDisciplineImpact} className="m-1">Edit Discipline Impact</Button> }
            </ButtonSection>
            <Modal isOpen={openModal}>
                <ModalBody>
                    {"Are you sure you want to close?"}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onConfirmCancel}>Yes</Button>
                    <Button color="primary" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </Row>
    )
}

export default DetailsActionButtons;