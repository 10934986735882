// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TemplateHeader_flexEnd__1JfZs {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n\n}", "",{"version":3,"sources":["webpack://src/features/templates/list-templates/template-header/TemplateHeader.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;;AAE7B","sourcesContent":[".flexEnd {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexEnd": "TemplateHeader_flexEnd__1JfZs"
};
export default ___CSS_LOADER_EXPORT___;
