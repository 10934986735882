import {configureStore} from '@reduxjs/toolkit'
import templateReducer from '../features/templates/redux/templatesSlice'
import successReducer from '../features/success/redux/successSlice'
import errorReducer from '../features/errors/redux/errorSlice'
import projectDetailReducer from '../components/ProjectDropdown/redux/projectDetailSlice'
import projectIdReducer from '../components/ProjectDropdown/redux/projectIdSlice'
import userReducer from '../features/auth/redux/userSlice';
import detailsDataAndTotalReducer from '../features/logs/details/redux/detailsDataAndTotalSlice'
import pcrReducer from '../features/logs/pcr/redux/pcrSlice'
import {apiSlice, distributionSlice, permissionsApiSlice} from '../api/apiSlice';

const store = configureStore({
    reducer: {
        template: templateReducer,
        success: successReducer,
        error: errorReducer,
        selectedProjectDetails: projectDetailReducer,
        projectId: projectIdReducer,
        user: userReducer,
        detailsDataAndTotal: detailsDataAndTotalReducer,
        pcrDetails: pcrReducer,
        [permissionsApiSlice.reducerPath]: permissionsApiSlice.reducer,
        [distributionSlice.reducerPath]: distributionSlice.reducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(permissionsApiSlice.middleware).concat(distributionSlice.middleware).concat(apiSlice.middleware),

})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch