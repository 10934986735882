import React from 'react'
import styled from 'styled-components';
import {
    Col,
} from 'reactstrap';

const HistoryRow = styled.div`
    display: flex;
    flex-wrap: wrap;   
    border-left: 3px solid #1490D7;
`

function HistoryGrid({ historyData }) {
    return (
        <ul className="list-unstyled">
            {historyData.map((history, index) => {
                const { statusDescription, comments, creationDate, createdBy } = history
                return (
                    <li key={index}>
                        <HistoryRow>
                            <Col sm="9">
                                <div className="pt-1">
                                    Status: {statusDescription}
                                </div>
                                <div className="d-flex pt-1" style={{ fontSize: "0.8em" }}>
                                    <div className="d-inline font-weight-bold pr-1">Comments/Notes:</div> {comments}
                                </div>
                            </Col>

                            <Col sm="3">
                                <div className="d-flex pt-1 float-right" style={{ fontSize: "0.8em" }}>
                                    <div className="d-inline font-weight-bold pr-1">By:</div>{createdBy}
                                    &nbsp; 
                                    <div className="d-inline font-weight-bold pr-1"> Date:</div>{creationDate}
                                </div>
                            </Col>
                        </HistoryRow>
                        {(index + 1) < historyData.length && <hr />}
                    </li>
                )
            })}

        </ul>
    )
}

export default HistoryGrid
