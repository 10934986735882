// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RatesCopyForm_radioButtonWidth__3kyK3 {\n    width: 200px;\n} \n.RatesCopyForm_rowPadding__2asmh {\n    padding-left: 35px;\n}\n\n.RatesCopyForm_dropdown__10nsq { \n    top: 4px;   \n    font-weight: normal; \n    font-size: 12px;\n    margin-right:10px;\n    height: 40px;\n}\n\n.RatesCopyForm_text__355Jq {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: 10px;\n    font-weight: normal;\n}", "",{"version":3,"sources":["webpack://src/features/templates/create-template/rates-copy-form/RatesCopyForm.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,QAAQ;IACR,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,2DAA2D;IAC3D,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".radioButtonWidth {\n    width: 200px;\n} \n.rowPadding {\n    padding-left: 35px;\n}\n\n.dropdown { \n    top: 4px;   \n    font-weight: normal; \n    font-size: 12px;\n    margin-right:10px;\n    height: 40px;\n}\n\n.text {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: 10px;\n    font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioButtonWidth": "RatesCopyForm_radioButtonWidth__3kyK3",
	"rowPadding": "RatesCopyForm_rowPadding__2asmh",
	"dropdown": "RatesCopyForm_dropdown__10nsq",
	"text": "RatesCopyForm_text__355Jq"
};
export default ___CSS_LOADER_EXPORT___;
