import Page from "../../../UI/Page";
import styled from "styled-components";
import LogService from "../api/logService"
import { useForm } from 'react-hook-form';
import FlexHeader from "../../../UI/FlexHeader";
import TypeAndTime from "./type-and-time/TypeAndTime";
import { Col, Container, Form, Row } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import DetailsActionButtons from "./DetailsActionButtons";
import StickySummary from "./sticky-summary/StickySummary";
import { useEffect, useState, useMemo } from "react";
import { detailsSetData } from "./redux/detailsDataAndTotalSlice";
import FeatureTitle from "../../../components/FeatureTitle/FeatureTitle";
import { openSuccess } from "../../success/redux/successSlice"
import { getUpdateFCORoute, getUpdatePCRRoute, getDisciplinesPCRRoute } from "../../../setup/routes";
import ProjectOHPSummaryGrid from "./summary-project-ohp/ProjectOHPSummaryGrid";
import TaggedEquipmentGrid from "./summary-tagged-equipment/TaggedEquipmentGrid";
import DirectLaborSummaryGrid from "./summary-direct-labor/DirectLaborSummaryGrid"
import EngineeringSummaryGrid from "./summary-engineering/EngineeringSummaryGrid";
import IndirectMaterialGrid from "./summary-indirect-material/IndirectMaterialGrid";
import IndirectLaborSummaryGrid from "./summary-indirect-labor/IndirectLaborSummaryGrid"
import { currencyFormatter } from "../../../utilities/ag-grid-utilities/AgGridUtilities";
import DivisionAllowancesGrid from "./summary-division-allowances/DivisionAllowancesGrid";
import ProjectExpenseSummaryGrid from "./summary-project-expense/ProjectExpenseSummaryGrid";
import { CollapsibleSection } from "../../../components/CollapsibleSection/CollapsibleSection";
import CollapsibleContainer from "../../../components/CollapsibleContainer/CollapsibleContainer";
import ProjectManagementSummaryGrid from "./summary-project-management/ProjectManagementSummaryGrid"
import ContingencyEscalationSummaryGrid from "./summary-contingency-escalation/ContingencyEscalationSummaryGrid";
import ConstructionManagementSummaryGrid from "./summary-construction-management/ConstructionManagementSummaryGrid";


const Width100 = styled.div`
   width: 100%; 
`
const DetailsPage = ({ match }) => {
    const logId = match.params.id;
    const dispatch = useDispatch()
    const [formSaved, setFormSaved] = useState(0)
    const [anyOpen, setAnyOpen] = useState(true);
    const [cancelRoute, setCancelRoute] = useState();
    const [isOHPSummaryOpen, setIsOHPSummaryOpen] = useState(true);
    const [isInMatSummaryOpen, setIsInMatSummaryOpen] = useState(true);
    const [isContEscSummaryOpen, setIsContEscSummaryOpen] = useState(true);
    const [isDivAllowSummaryOpen, setIsDivAllowSummaryOpen] = useState(true);
    const [isEngineeringSummaryOpen, setIsEngineeringSummaryOpen] = useState(true);
    const [isDirectLaborSummaryOpen, setIsDirectLaborSummaryOpen] = useState(true);
    const [isSBConManageSummaryOpen, setIsSBConManageSummaryOpen] = useState(true);
    const [isTaggedEquipSummaryOpen, setIsTaggedEquipSummaryOpen] = useState(true);
    const [isProjExpenseSummaryOpen, setIsProjExpenseSummaryOpen] = useState(true);
    const [isSBInDirectLaborSummaryOpen, setIsSBInDirectLaborSummaryOpen] = useState(true);
    const [isProjectManagementSummaryOpen, setIsProjectManagmentSummaryOpen] = useState(true);
    const [editDisciplinesRoute, setEditDisciplinesRoute] = useState(null);


    const { engineeringSummary, projectManagementSummary, directLaborSummary,
        indirectLaborSummary, indirectMaterialSummary, taggedEquipmentSummary,
        divisionAllowances, constructionManagementSummary, projectExpenseSummary,
        contingencyEscalationSummary, projectOHPSummary, title, changeNumber,
        contractType, premiumTime, productivityAdjustment, premiumTimeOther, readOnly } = useSelector(state => state.detailsDataAndTotal)

    const detailTotals = useMemo(() => {
        const totalHours = engineeringSummary.totals.hours + projectManagementSummary.totals.hours +
            directLaborSummary.totals.directLaborHours + indirectLaborSummary.totals.hours +
            constructionManagementSummary.totals.hours + engineeringSummary.totals.sbiHours +
            projectManagementSummary.totals.sbiHours
        const totalDollars = engineeringSummary.totals.laborDollars + projectManagementSummary.totals.laborDollars +
            directLaborSummary.totals.directLaborDollars + directLaborSummary.totals.directMaterialDollars + directLaborSummary.totals.subDollars +
            indirectLaborSummary.totals.dollars + indirectMaterialSummary.totals.materialDollars + indirectMaterialSummary.totals.subcontractDollars +
            projectExpenseSummary.totals.laborDolars +
            contingencyEscalationSummary.totals.dollars +
            projectOHPSummary.totals.dollars +
            divisionAllowances.totals.dollars +
            constructionManagementSummary.totals.laborDollars +
            taggedEquipmentSummary.totals.equipmentDollars +
            engineeringSummary.totals.sbiLaborDollars +
            projectManagementSummary.totals.sbiLaborDollars

        return {
            totalHours,
            totalDollars,
        };

    }, [
        constructionManagementSummary,
        contingencyEscalationSummary,
        directLaborSummary,
        divisionAllowances,
        engineeringSummary,
        indirectLaborSummary,
        indirectMaterialSummary,
        projectExpenseSummary,
        projectManagementSummary,
        projectOHPSummary,
        taggedEquipmentSummary]);

    const { formState: { errors }, control } = useForm({
        defaultValues: {
            contractType: "L",
            productivityAdjustment: "",
            premiumTimeOther: 0

        },
        mode: "onChange"
    });

    const readOnlyIsSet = useMemo(() => {
        return readOnly !== undefined;
    }, [readOnly])

    const sections = useMemo(() => [
        {
            title: 'Engineering Summary',
            totalLabel: "Total Engineering (DIV R)",
            totalColumns: [
                { label: "Hours", value: currencyFormatter(engineeringSummary.totals.hours, '') },
                { label: "Labor", value: currencyFormatter(engineeringSummary.totals.laborDollars, '$') },
                { label: "SBI Hours", value: currencyFormatter(engineeringSummary.totals.sbiHours, '') },
                { label: "SBI Labor", value: currencyFormatter(engineeringSummary.totals.sbiLaborDollars, '$') }],
            state: isEngineeringSummaryOpen,
            toggle: setIsEngineeringSummaryOpen,
            component: (contractType && readOnlyIsSet) ? <EngineeringSummaryGrid /> : <></>
        },
        {
            title: 'Project Management Summary',
            totalLabel: "Total Management (DIV P)",
            totalColumns: [
                { label: "Hours", value: currencyFormatter(projectManagementSummary.totals.hours, '') },
                { label: "Labor", value: currencyFormatter(projectManagementSummary.totals.laborDollars, '$') },
                { label: "SBI Hours", value: currencyFormatter(projectManagementSummary.totals.sbiHours, '') },
                { label: "SBI Labor", value: currencyFormatter(projectManagementSummary.totals.sbiLaborDollars, '$') }],
            state: isProjectManagementSummaryOpen,
            toggle: setIsProjectManagmentSummaryOpen,
            component: (contractType && readOnlyIsSet) ? <ProjectManagementSummaryGrid /> : <></>
        },
        {
            title: 'Direct Labor Summary',
            state: isDirectLaborSummaryOpen,
            totalLabel: "Total Direct Cost (DIV L, D & I)",
            totalColumns: [
                { label: "D. Labor Hours", value: currencyFormatter(directLaborSummary.totals.directLaborHours, '') },
                { label: "D. Labor", value: currencyFormatter(directLaborSummary.totals.directLaborDollars, '$') },
                { label: "Direct Materials", value: currencyFormatter(directLaborSummary.totals.directMaterialDollars, '$') },
                { label: "Subcontracts", value: currencyFormatter(directLaborSummary.totals.subDollars, '$') }],
            toggle: setIsDirectLaborSummaryOpen,
            component: (contractType && premiumTime && readOnlyIsSet) ? <DirectLaborSummaryGrid /> : <></>
        },
        {
            title: 'Indirect Labor Summary',
            state: isSBInDirectLaborSummaryOpen,
            totalLabel: "Total Indirect Labor (DIV M)",
            totalColumns: [
                { label: "Indirect Labor Hours", value: currencyFormatter(indirectLaborSummary.totals.hours, '') },
                { label: "Indirect Labor", value: currencyFormatter(indirectLaborSummary.totals.dollars, '$') }],
            toggle: setIsSBInDirectLaborSummaryOpen,
            component: (contractType && readOnlyIsSet) ? <IndirectLaborSummaryGrid /> : <></>
        },
        {
            title: 'Construction Management Summary',
            state: isSBConManageSummaryOpen,
            totalLabel: "Total Construction Management (DIV N)",
            totalColumns: [
                { label: "Constr Mgmt Hours", value: currencyFormatter(constructionManagementSummary.totals.hours, '') },
                { label: "Constr Mgmt Labor", value: currencyFormatter(constructionManagementSummary.totals.laborDollars, '$') }],
            toggle: setIsSBConManageSummaryOpen,
            component:  readOnlyIsSet ? <ConstructionManagementSummaryGrid /> : <></>
        },
        {
            title: 'Tagged Equipment Summary',
            state: isTaggedEquipSummaryOpen,
            totalLabel: "Total Engineered Equipment (DIV A)",
            totalColumns: [{ label: "Equipment", value: currencyFormatter(taggedEquipmentSummary.totals.equipmentDollars, '$') }],
            toggle: setIsTaggedEquipSummaryOpen,
            component: readOnlyIsSet ? <TaggedEquipmentGrid /> : <></>
        },
        {
            title: 'Indirect Material Summary',
            state: isInMatSummaryOpen,
            totalLabel: "Total Indirect Material/Subs (DIV G, 03, 04, I)",
            totalColumns: [
                { label: "I. Labor", value: currencyFormatter(indirectMaterialSummary.totals.materialDollars, '$') },
                { label: "Subcontracts", value: currencyFormatter(indirectMaterialSummary.totals.subcontractDollars, '$') }],
            toggle: setIsInMatSummaryOpen,
            component:  readOnlyIsSet ? <IndirectMaterialGrid /> : <></>
        },
        {
            title: 'Project Expense Summary',
            state: isProjExpenseSummaryOpen,
            totalLabel: "Total Project Expense (DIV U)",
            totalColumns: [{ label: "P. Expenses", value: currencyFormatter(projectExpenseSummary.totals.laborDolars, '$') }],
            toggle: setIsProjExpenseSummaryOpen,
            component:  readOnlyIsSet ? <ProjectExpenseSummaryGrid /> : <></>
        },
        {
            title: 'Contingency and Escalation',
            state: isContEscSummaryOpen,
            totalLabel: "Total Contingency & Allowances",
            totalColumns: [{ label: "C&A", value: currencyFormatter(contingencyEscalationSummary.totals.dollars, '$') }],
            toggle: setIsContEscSummaryOpen,
            component:  readOnlyIsSet ? <ContingencyEscalationSummaryGrid /> : <></>
        },
        {
            title: 'Project OH & P',
            state: isOHPSummaryOpen,
            totalLabel: "Total Project OH&P (DIV 6)",
            totalColumns: [{ label: "Project OH&P", value: currencyFormatter(projectOHPSummary.totals.dollars, '$') }],
            toggle: setIsOHPSummaryOpen,
            component: (contractType && readOnlyIsSet) ? <ProjectOHPSummaryGrid /> : <></>
        },
        {
            title: 'Division Allowances',
            state: isDivAllowSummaryOpen,
            totalLabel: "Total Division Allowances (DIV 7)",
            totalColumns: [{ label: "Allowances", value: currencyFormatter(divisionAllowances.totals.dollars, '$') }],
            toggle: setIsDivAllowSummaryOpen,
            component:  readOnlyIsSet ? <DivisionAllowancesGrid /> : <></>
        }
    ], [readOnlyIsSet, constructionManagementSummary.totals, contingencyEscalationSummary.totals.dollars, contractType, directLaborSummary.totals, divisionAllowances.totals.dollars, engineeringSummary.totals, indirectLaborSummary.totals, indirectMaterialSummary.totals, isContEscSummaryOpen, isDirectLaborSummaryOpen, isDivAllowSummaryOpen, isEngineeringSummaryOpen, isInMatSummaryOpen, isOHPSummaryOpen, isProjExpenseSummaryOpen, isProjectManagementSummaryOpen, isSBConManageSummaryOpen, isSBInDirectLaborSummaryOpen, isTaggedEquipSummaryOpen, premiumTime, projectExpenseSummary.totals.laborDolars, projectManagementSummary.totals, projectOHPSummary, taggedEquipmentSummary.totals.equipmentDollars]);


    function areAnyOpen() {
        return sections.some(isOpen);
    }

    function isOpen(section) {
        return section.state;
    }

    function toggle() {
        sections.forEach(s => anyOpen ? closeSection(s) : openSection(s));
        setAnyOpen(!anyOpen);
    }

    function toggleSection(section) {
        section.state = !section.state;
        section.toggle(section.state);
        setAnyOpen(areAnyOpen());
    }

    function closeSection(section) {
        section.state = false;
        section.toggle(section.state);
    }

    function openSection(section) {
        section.state = true;
        section.toggle(section.state);
    }

    const sectionDisplays = sections.map((section, index) => {
        return (
            <div key={index}>
                <Row className="pt-1">
                    <CollapsibleSection title={section.title} isOpen={section.state} toggle={() => toggleSection(section)} totalLabel={section.totalLabel} totalColumns={section.totalColumns} index={index}>
                        {section.component}
                    </CollapsibleSection>
                </Row>
                <hr />
            </div>
        );
    });

    useEffect(() => {
        async function getDetailsByLogId() {
            const response = await LogService.getDetailsByLogId(logId);
            if (response.data.data) {
                const data = response.data.data;
                if (data.changeNumber.indexOf('PCR') >= 0) {
                    setCancelRoute(getUpdatePCRRoute(logId));
                } else {
                    setCancelRoute(getUpdateFCORoute(logId));
                }
                dispatch(detailsSetData(data))
            }
        }
        getDetailsByLogId();
    }, [logId, formSaved, dispatch]);

    useEffect(() => {
        async function getPCRByLogId() {
            const response = await LogService.getPCRByLogId(logId)
            if (response.data.data) {
                const { id } = response.data.data
                setEditDisciplinesRoute(getDisciplinesPCRRoute(id))
            }
        }
        getPCRByLogId()
    }, [logId])

    const onSave = async () => {
        const request = {
            logId, contractType, premiumTime: premiumTime.value, productivityAdjustment, premiumTimeOther,
            engineeringSummary: engineeringSummary.data, projectManagementSummary: projectManagementSummary.data,
            divisionAllowances: divisionAllowances.data, constructionManagementSummary: constructionManagementSummary.data,
            directLaborSummary: directLaborSummary.data, indirectLaborSummary: indirectLaborSummary.data,
            taggedEquipmentSummary: taggedEquipmentSummary.data, indirectMaterialSummary: indirectMaterialSummary.data,
            contingencyEscalationSummary: contingencyEscalationSummary.data, projectExpenseSummary: projectExpenseSummary.data,
            projectOHPSummary: projectOHPSummary.data
        }

        const response = await LogService.putDetailsByLogId(request)
        if (response.status === 200) {
            if (!response.data.errors || response.data.errors.length === 0) {
                setTimeout(() => {
                    dispatch(openSuccess("The details was saved."))
                }, 2000)
                window.scrollTo(0, 0)
                setFormSaved(formSaved + 1)
            }
        }
    }

    return (
        <Page>
            <Container>

                <Row>
                    <Col>
                        <Container>
                            <FlexHeader>
                                <FeatureTitle title="Edit Details" />
                                <div>{changeNumber}</div>
                            </FlexHeader>
                            <hr />
                        </Container>
                    </Col>
                </Row>
                <Form>
                    <Row>
                        <Col fluid={"true"} style={{ height: 150, marginRight: "25px" }}>
                            <Container>
                                <Row>
                                    <Col sm={10}>
                                        <TypeAndTime control={control} errors={errors} readOnly={readOnly} />
                                    </Col>
                                    <Col sm={2} className="align-self-center mt-md-3">
                                        <DetailsActionButtons onSave={onSave} saveDisabled={readOnly}/>
                                    </Col>
                                </Row>
                            </Container>

                        </Col>
                    </Row>
                    <StickySummary changeNumber={changeNumber} title={title} totalHours={detailTotals.totalHours} totalDollar={detailTotals.totalDollars} />
                    <Row>
                        <Col>
                            <Container>
                                <CollapsibleContainer isOpen={anyOpen} toggle={toggle}>
                                    {sectionDisplays}
                                </CollapsibleContainer>
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col fluid={"true"} style={{ height: 150 }}>
                            <Width100>
                                <DetailsActionButtons cancelRoute={cancelRoute} onSave={onSave} saveDisabled={readOnly} editDisciplinesRoute={editDisciplinesRoute}/>
                            </Width100>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Page>
    )
}

export default (DetailsPage);