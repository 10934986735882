import React from 'react';

import classes from './PageFooter.module.css';

const footer = () => (
    <footer className={classes.Footer}>
        <p>&copy; {new Date().getFullYear()} S&amp;B Engineers and Constructors, Ltd.</p>
    </footer>
);

export default footer;