import React, { useState } from 'react';
import {
    Container
} from 'reactstrap';
import { CollapsibleSection } from '../../../components/CollapsibleSection/CollapsibleSection';
import HistoryGrid from './history-grid/HistoryGrid';

export const History = ({ historyData }) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <Container>
            <CollapsibleSection title="History" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} titleClassName="h5" >
                {historyData && <HistoryGrid historyData={historyData} />}
            </CollapsibleSection>
        </Container>
    )
}
