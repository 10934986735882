import Portal from './../Portal/Portal'
import css from './SlideoutModal.module.css'

const SlideoutModal = ({
  isShowing, toggle, hideHeader, children
}) => (
  <Portal id={"menu"}>
    <div className={`${css["modal-overlay"]}`} style={{ display: isShowing ? 'block' : 'none' }} onClick={toggle} />
    <div className={`${css["modal-wrapper"]} ${isShowing ? css["slide-in"] : css["slide-out"]}`} aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className={`${css["modal"]} ${css["menu"]} ${isShowing ? css["slide-in"] : css["slide-out"]}`}>
        {hideHeader ? (<></>)
          : (
            <div className={css["modal-header"]}>
              <button
                type="button"
                className={css["modal-close-button"]}
                data-dismiss="modal"
                aria-label="Close"
                onClick={toggle}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
        <div className={css["modal-body"]}>
          {children}
        </div>
      </div>
    </div>
  </Portal>
)

export default SlideoutModal
