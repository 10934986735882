import { useDispatch, connect } from "react-redux";
import { CONTRACT_TYPE } from '../type-and-time/Options'
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import React, {useEffect, useRef, useCallback, useMemo} from 'react';
import { indirectLaborSummarySetData } from "../redux/detailsDataAndTotalSlice"
import NumericEditor from "../../../../utilities/ag-grid-utilities/cell-editors/NumericEditor"
import { getRowStyle, getRowSpanForDescriptionTotal, getTotalDescription, currencyFormatter, isFooter, defaultGridOptions } from "../../../../utilities/ag-grid-utilities/AgGridUtilities";
import Icon from '../../../../components/Icon/Icon';
import DecimalEditor from "../../../../utilities/ag-grid-utilities/cell-editors/DecimalEditor";
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';
import {CAN_OVERRIDE_DETAIL_VALUES} from '../../logs-roles/Roles';

const TOTAL_DESCRIPTION = "Total Indirect Labor (DIV M)";
const EDITABLE_PACTS = ["16", "29"]
const getHoursValue = (originalEstimatePct, totalDirectLaborHours) => Math.ceil(Number(originalEstimatePct) * Number(totalDirectLaborHours));
const getLaborDollars = (rate, markup, overlay, hours) => Math.ceil(((Number(rate) * Number(markup)) + Number(overlay)) * hours)
const isEditablePact = (pact) => EDITABLE_PACTS.includes(pact)

const cellClassRules = {
    'override-status': params => params.data.edited,
    'editable-cell': params => {
        if ((!params.colDef.editable || !params.colDef.editable(params)) || isFooter(params)) {
            return false;
        }
        if (params.colDef.field === 'activityDescription' || params.colDef.field === 'dollars') {
            return isEditablePact(params.data.pact) || params.data.activityDescription.toLowerCase() === 'undefined';
        }
        return true;
    }
};

const cellRendererWarning = (params) => {
    return <div className="d-flex override-status"><Icon icon="exclamation-triangle" color="#E76143" size="sm" className="px-1" title="Please verify rates are set" />{params.value}</div>
}

const IndirectLaborSummaryGrid = React.memo(({ data, totals, totalDirectLaborHours, contractType, readOnly }) => {
    const dispatch = useDispatch()
    const doUpdate = useRef(false)
    const dataRef = useRef(...data)
    const { isAuthorized } = useAuthorized()
    const canOverrideValues = useMemo(() => {
        return isAuthorized([...CAN_OVERRIDE_DETAIL_VALUES]);
    }, [isAuthorized])

    useEffect(() => {
        dataRef.current = [...data]
    }, [data])

    const applyFormulasToDataModel = useCallback((localData) => {
        const markupType = contractType === CONTRACT_TYPE.LumpSum ? 'lumpSumMarkup' : 'costReimbursibleMarkup'
        const overlayType = contractType === CONTRACT_TYPE.LumpSum ? 'lumpSumOverlay' : 'costReimbursibleOverlay'

        const updatedDataModel = localData.map(row => {
            const { labor, pact, dollars, edited } = row
            const budgetOverride = !!edited || row.budgetOverride;
            const hours = budgetOverride ? row.hours : getHoursValue(labor.originalEstimatePct, totalDirectLaborHours)
            return {
                ...row,
                budgetOverride,
                hours,
                dollars: isEditablePact(pact) ? dollars : getLaborDollars(labor.rate, labor[markupType], labor[overlayType], hours)
            }
        })
        if (updatedDataModel.length > 0) {
            dispatch(indirectLaborSummarySetData(updatedDataModel))
        }
    }, [contractType, dispatch, totalDirectLaborHours])

    const recalculateFormulas = useCallback(() => {
        applyFormulasToDataModel(data)
    }, [applyFormulasToDataModel, data])

    useEffect(() => {
        if (doUpdate.current) {
            recalculateFormulas()
        }
    }, [contractType, totalDirectLaborHours, recalculateFormulas])

    const valueSetter = (params) => {
        const isTextField = ["activityDescription"].includes(params.colDef.field)
        const isNewValue = isTextField ? (params.oldValue !== params.newValue) : (Number(params.oldValue) !== Number(params.newValue))
        if (isNewValue) {
            const value = isTextField ? params.newValue : Number(params.newValue);
            const updatedRow = { ...params.data, [params.column.colId]: value, edited: true }
            const filteredData = dataRef.current.filter(f => f.id !== params.data.id)
            applyFormulasToDataModel([...filteredData, ...[updatedRow]])
        }
        return isNewValue
    }

    return (
        <div className="ag-theme-bootstrap" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div id="indirect-labor-summary-table" style={{ flex: '1 1 auto' }} >
                <AgGridReact
                    getRowStyle={getRowStyle}
                    gridOptions={{ ...defaultGridOptions }}
                    rowData={data}
                    getRowNodeId={data => data.id}
                    immutableData={true}
                    domLayout="autoHeight"
                    enableCellChangeFlash
                    singleClickEdit={true}
                    pinnedBottomRowData={[totals]}
                    onFirstDataRendered={() => doUpdate.current = true}
                    frameworkComponents={{
                        numericCellEditor: NumericEditor,
                        decimalCellEditor: DecimalEditor
                    }}>
                    <AgGridColumn headerName="Pact" field="pact" headerClass="header-grid" maxWidth={70} cellClassRules={cellClassRules}
                        colSpan={getRowSpanForDescriptionTotal}
                        valueFormatter={params => getTotalDescription(params, TOTAL_DESCRIPTION)} />
                    <AgGridColumn headerName="Activity" field="activityDescription" headerClass="header-grid" minWidth={400} cellClassRules={cellClassRules}
                        editable={(params) => canOverrideValues && !readOnly && !isFooter(params) && isEditablePact(params.data.pact)}
                        valueSetter={valueSetter}
                        cellRendererFramework={params => Number(params.data.hours) > 0 &&  Number(params.data.dollars) === 0 ? cellRendererWarning(params) : params.value}
                        />
                    <AgGridColumn headerName="Hours" field="hours" headerClass="header-grid right-sortable-header-grid" type="numericColumn" minWidth={200} cellClassRules={cellClassRules}
                        editable={params =>  canOverrideValues && !readOnly && !isFooter(params)}
                        valueSetter={valueSetter} cellEditor="numericCellEditor"
                        valueFormatter={(params) => currencyFormatter(params.data.hours, '')} />
                    <AgGridColumn headerName="Labor $" field="dollars"
                        headerClass="header-grid right-sortable-header-grid" type="numericColumn" cellClassRules={cellClassRules} width={150} maxWidth={150}
                        editable={params => canOverrideValues && !readOnly && !isFooter(params)  && isEditablePact(params.data.pact)} cellEditor="decimalCellEditor"
                        valueFormatter={(params) => currencyFormatter(params.data.dollars ?? 0, '$', 0)}
                        valueSetter={valueSetter} />
                </AgGridReact>
            </div>
        </div>
    );
}, (prevProps, nextProps) => {
    return (
        prevProps.totalDirectLaborHours === nextProps.totalDirectLaborHours &&
        prevProps.contractType === nextProps.contractType &&
        prevProps.readOnly === nextProps.readOnly &&
        JSON.stringify(prevProps.totals) === JSON.stringify(nextProps.totals) &&
        JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
    )
});

const mapStateToProps = (state) => {
    const { directLaborSummary: { totals: { directLaborHours: totalDirectLaborHours } }, indirectLaborSummary: { data, totals }, contractType, readOnly } = state.detailsDataAndTotal
    return {
        data,
        totals,
        contractType,
        totalDirectLaborHours,
        readOnly
    }
}

export default connect(mapStateToProps)(IndirectLaborSummaryGrid)
