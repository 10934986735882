// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TemplateRowAction_button__2r2BL {\n    font-size: .69rem;\n}", "",{"version":3,"sources":["webpack://src/features/templates/list-templates/template-grid/TemplateRowAction.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB","sourcesContent":[".button {\n    font-size: .69rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "TemplateRowAction_button__2r2BL"
};
export default ___CSS_LOADER_EXPORT___;
