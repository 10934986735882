import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import routes from '../../../../setup/routes';
import 'ag-grid-community/dist/styles/ag-grid.css';
import Icon from '../../../../components/Icon/Icon'
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import FeatureTitle from '../../../../components/FeatureTitle/FeatureTitle'
import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';

const FlashTrendsHeader = () => {

    const { selectedProjectDetails } = useSelector((state) => state);
    const user = useSelector(state => state.user.user);
    const history = useHistory();
    return (
        <Container>
            <Row>
                <Col>
                    <FeatureTitle title={`Welcome, ${user.givenName} ${user.familyName}`} />
                    <div className="d-flex justify-content-end pb-2">
                        <Button
                            color="primary"
                            className="d-flex align-items-center px-4"
                            onClick={() => history.push(routes.CreateFTRoute)}
                            size="sm"
                            title="Clear All Filters">
                            {"Create"}
                            <div className="pl-1 fa-xs"><Icon icon="plus-circle" /></div>
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default FlashTrendsHeader
