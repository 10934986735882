import "./Log.css"
import LogGrid from './log-grid/LogGrid'
import { useSelector } from 'react-redux';
import PCLogService from '../api/logService'
import LogHeader from './log-header/LogHeader'
import LogTotals from './log-totals/LogTotals';
import { Container, Row, Col } from 'reactstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { useEffect, useState, useReducer } from 'react';
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import { initialTotals, LogTotalsReducer } from "./log-totals/LogTotalsReducerProps";
import Spinner from '../../../components/Spinner/Spinner'

const Log = () => {
    const [logs, setLogs] = useState([])
    const { projectId } = useSelector((state) => state)
    const [filteredLogs, setFilteredLogs] = useState([])
    const [changeType, setChangeType] = useState([])
    const [logTotalState, logTotalDispatch] = useReducer(LogTotalsReducer, initialTotals)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        PCLogService.getLogsByProjectId(projectId.value)
            .then(response => {
                const { logs, totals } = response.data.data
                setLogs(logs)
                setFilteredLogs(logs)
                setLoading(false)
                logTotalDispatch({ payload: totals ?? initialTotals })
            })
            .catch(error => {
                setLogs([])
                setFilteredLogs([])
                setLoading(false)
                console.log(error)
            })
    }, [projectId])

    if (loading) {
        return (<Spinner />)
    }

    return (
        <Container>
            <Row>
                <Col>
                    <LogHeader logs={logs} setFilteredLogs={setFilteredLogs} logTotalDispatch={logTotalDispatch} setChangeType={setChangeType} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <LogGrid logs={filteredLogs} changeType={changeType} />
                </Col>
            </Row>
            <LogTotals totals={logTotalState} />

        </Container>
    )
}

export default Log