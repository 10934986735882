import React, { useState, useCallback } from 'react'
import { useDispatch } from "react-redux";
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { openError } from '../../features/errors/redux/errorSlice';


const getColor = ({ isDragAccept, isDragReject, isDragActive }) => {
    if (isDragAccept) {
        return '#00e676';
    }
    if (isDragActive) {
        return '#2196f3';
    }
    if (isDragReject) {
        return '#ff1744';
    }
    return '#B1C1CE';
}

const Container = styled.div`
    flex: 1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 50px;
    padding-left: 50px;
    border-width: 1px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: #F5FCFF;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    font-size: 0.85rem;
  `;

const acceptedExtension = ['.xls', '.xlsx', '.doc', '.docx', '.pdf', '.txt', '.dwg', '.msg', '.jpeg', '.jpg', '.png', '.tiff', '.raw', '.bmp']
const maxSize = 2000000
const maxTotalSize = 25000000

const useDragAndDrop = () => {
    const dispatch = useDispatch()
    const [dragAndDropFiles, setDragAndDropFiles] = useState([]);

    const ValidateMaxTotalSize = (files) => {
        let size = 0
        return !files.some(file => {
            size += file.size
            if (size > maxTotalSize) {
                dispatch(openError(["The size of the files exceeds the allowed"]))
                return true;
            }
            else {
                return false
            }
        })
    }

    const DragAndDrop = ({ disabled }) => {
        const onDrop = useCallback(acceptedFiles => {
            const notDuplicates = dragAndDropFiles.reduce((arrayAccumulator, currenValue) => {
                const duplicatedValue = acceptedFiles.find((file) => file.name === currenValue.name);
                if (duplicatedValue) {
                    return [...arrayAccumulator]
                }
                else {
                    return [...arrayAccumulator, currenValue]
                }
            }, [])

            if (ValidateMaxTotalSize([...notDuplicates, ...acceptedFiles])) {
                const acceptedFilesMetaData = acceptedFiles.map(af => ({ fileName: af.name, status: "NEW" }))
                setDragAndDropFiles([...notDuplicates, ...acceptedFiles, ...acceptedFilesMetaData]);
            }
        }, []);

        const {
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject } = useDropzone({ onDrop, accept: [...acceptedExtension, ...acceptedExtension.map(e => e.toUpperCase())], maxSize, disabled });

        return (
            <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject, className: "dropzone" })}>
                <input {...getInputProps()} />
                <div>
                    <p className="mt-3 font-italic">Drag and Drop a File Here or <span className="text-primary">Browse</span></p>
                </div>
            </Container>
        )
    }
    return { dragAndDropFiles, setDragAndDropFiles, DragAndDrop }
}
export { useDragAndDrop }
