import { useForm } from 'react-hook-form';
import Page from '../../../../../UI/Page';
import { useHistory } from 'react-router';
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import PCIService from "../../api/pciService";
import { LogRoute } from '../../../../../setup/routes'
import { useSelector, useDispatch } from 'react-redux';
import { ErrorMessage } from '@hookform/error-message';
import { HISTORY_TYPE } from "../../../../../setup/constants";
import Authorized from '../../../../auth/Authorized/Authorized';
import { PROJECT_COORDINATORS } from "../../../logs-roles/Roles";
import { Container, Row, Col, Form, Label, Input } from 'reactstrap';
import PCIBasicInfoForm from '../../pci-basic-info-form/PCIBasicInfoForm';
import FeatureTitle from '../../../../../components/FeatureTitle/FeatureTitle'
import ApproveButton from "../../../../../components/ApproveButton/ApproveButton"
import { openSuccess } from "../../../../success/redux/successSlice"
import Spinner from '../../../../../components/Spinner/Spinner'
import useSelectedProject from '../../../../../hooks/useSelectedProject'

const AuthorizedRoles = [...PROJECT_COORDINATORS];

const ApprovePCIByPE = ({ match }) => {

    const isApprovePage = true
    let history = useHistory();
    const pciId = match.params.id
    const dispatch = useDispatch()
    const [pciData, setPciData] = useState(null)
    const [pciNumber, setPCINumber] = useState(null)
    const [attachments, setAttachments] = useState([])
    const [pciStatusAndAreas, setPciStatusAndAreas] = useState(null)
    const userId = useSelector(state => state.user.user.userId);
    const [loading, setLoading] = useState(true)
    const [setSelectedProjectDropdown] = useSelectedProject();
    const [selectedProjectId, setSelectedProjectId] = useState(null)
    const [requestInProgress, setRequestInProgress] = useState(false)

    const { getValues, formState: { errors }, control, handleSubmit, setValue } = useForm({
        defaultValues: {
            pciStatus: "1",
            pciTitle: "",
            clientNumber: "",
            variance: false,
            originator: "",
            sbiEnable: false
        },
        mode: "onChange"
    });

    useEffect(() => {
        async function getApprovePCIInfo() {
            const response = await PCIService.getApprovePCIByPEInfo(pciId)
            if (response.data.data) {
                const { areas, statuses, pciNumber, attachedFiles, projectId } = response.data.data
                setAttachments(attachedFiles ?? [])
                setPciStatusAndAreas({ areas, statuses })
                setPciData(response.data.data)
                setPCINumber(pciNumber)
                setSelectedProjectId(projectId);
            }
            setLoading(false)
        }
        getApprovePCIInfo()
    }, [pciId])

    useEffect(() => {
        setSelectedProjectDropdown(selectedProjectId)
    }, [selectedProjectId, setSelectedProjectDropdown])

    useEffect(() => {
        if (pciData !== null) {
            const { logStatus, logArea, clientNumber, logTitle, description, logOriginator, logIsVariance,
                sbiEnable } = pciData
            setValue("pciStatus", logStatus)
            setValue("clientNumber", clientNumber ?? "")
            setValue("pciArea", logArea)
            setValue("pciTitle", logTitle)
            setValue("pciDescription", description)
            setValue("originator", logOriginator)
            setValue("variance", logIsVariance)
            setValue("sbiEnable", sbiEnable)
        }
    }, [pciData, setValue])


    const onApproval = handleSubmit(async (data) => {
        try {
            setRequestInProgress(true)
            const response = await PCIService.approvePCIByPE(
                {
                    pciId,
                    approved: true,
                    userId,
                    comments: data.pciApprovalComments
                });

            if (history.action !== HISTORY_TYPE.NO_HISTORY) {
                history.goBack();
            } else {
                history.push(LogRoute);
            }
            if (response.status === 200) {
                setTimeout(() => {
                    dispatch(openSuccess("The PCI was Approved, and an email has been sent to the PC."))
                }, 4000)
            }
            setRequestInProgress(false)
        } catch (e) {
            console.log(e)
            setRequestInProgress(false)
        }
    })

    const onNotApproval = handleSubmit(async (data) => {
        try {
            setRequestInProgress(true)
            const response = await PCIService.approvePCIByPE(
                {
                    pciId,
                    approved: false,
                    userId,
                    comments: data.pciApprovalComments
                }
            );
            if (history.action !== HISTORY_TYPE.NO_HISTORY) {
                history.goBack();
            } else {
                history.push(LogRoute);
            }
            if (response.status === 200 && !response.data.data) {
                setTimeout(() => {
                    dispatch(openSuccess("The PCI was Deleted"))
                }, 4000)
            }
            setRequestInProgress(false)
        } catch (e) {
            console.log(e)
            setRequestInProgress(false)
        }
    })

    if (loading) {
        return (<Spinner />)
    }

    return (
        <Page>
            {pciData && <Container>
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title={`Project Change: ${pciNumber}`} />
                            <hr />
                        </Container>
                    </Col>
                </Row>
                <Form onSubmit={handleSubmit(onApproval)}>
                    <Row>
                        <Col>
                            {pciStatusAndAreas &&
                                <Authorized roles={AuthorizedRoles}>
                                    <PCIBasicInfoForm control={control} errors={errors}
                                        pciStatusAndAreas={pciStatusAndAreas} getValues={getValues}
                                        readOnly={isApprovePage} attachments={attachments} />
                                </Authorized>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr />
                            <Row className="mt-md-3">
                                <Col sm="2" lg="1" className="text-right">
                                    <Label for="pciDescription">Approval Comments</Label>
                                </Col>
                                <Col sm="8" lg="10" className="ml-2">
                                    <Controller
                                        placeholder="ApprovalComments"
                                        control={control}
                                        name="pciApprovalComments"
                                        render={({ field }) => <Input type="textarea" {...field} data-testid="pciApprovalComments" />}
                                        rules={{ maxLength: { message: "Approval Comments must be no longer than 4000 characters.", value: 4000 } }}
                                    />
                                    <ErrorMessage className="text-danger small" errors={errors} name={"pciApprovalComments"} as="p" key={"pciApprovalComments"} />
                                </Col>
                            </Row>
                            <hr />
                            <ApproveButton onNotApprove={onNotApproval} requestInProgress={requestInProgress}></ApproveButton>
                        </Col>
                    </Row>
                </Form>
            </Container>}
        </Page>
    )
}

export default ApprovePCIByPE