import React, {useState, useEffect, useMemo} from 'react'
import {
    Container
} from 'reactstrap';
import {AgGridReact, AgGridColumn} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import {currencyFormatter, getRowStyle} from '../../../../utilities/ag-grid-utilities/AgGridUtilities'
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';
import {CLIENT} from '../../logs-roles/Roles';

const initialBudgetTotals = {
    laborHours: 0,
    laborDollars: 0,
    materialDollars: 0,
    subcontractDollars: 0,
    equipmentDollars: 0,
    rowTotal: 0
}

const initialValueTotals = {
    total: 0
}

const reduceBudgetTotals = (data) => {
    return data.reduce((a, b) => (
        {
            laborHours: a.laborHours + b.laborHours,
            laborDollars: a.laborDollars + b.laborDollars,
            materialDollars: a.materialDollars + b.materialDollars,
            subcontractDollars: a.subcontractDollars + b.subcontractDollars,
            equipmentDollars: a.equipmentDollars + b.equipmentDollars,
            rowTotal: a.rowTotal + (b.laborDollars + b.materialDollars + b.subcontractDollars + b.equipmentDollars)
        }
    ), initialBudgetTotals)
}

const reduceValueTotals = (data) => {
    return data.reduce((a, b) => (
        {
            total: a.total + b.total
        }
    ), initialValueTotals)
}

const getBudgetSummaryTable = (rowData, totals, restrictedView) => {
    if (rowData && totals) {
        return (
            <Container className="ag-theme-bootstrap pt-2"
                       style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                {!restrictedView && <div style={{flex: '1 1 auto'}}>
                    <AgGridReact
                        domLayout="autoHeight"
                        rowSelection={'multiple'}
                        headerHeight={28}
                        rowData={rowData}
                        getRowStyle={getRowStyle}>
                        <AgGridColumn field="id" hide/>
                        <AgGridColumn field="description" headerClass="header-grid" headerName="Description"
                                      width={250}/>
                        {!restrictedView && <AgGridColumn field="laborHours" headerClass="header-grid right-header-grid"
                                                          headerName="Labor Hours" type="numericColumn" width={120}/>}
                        <AgGridColumn field="laborDollars" headerClass="header-grid right-header-grid"
                                      headerName="Labor $" type="numericColumn" width={120}
                                      valueFormatter={params => currencyFormatter(params.data.laborDollars, '$')}/>
                        <AgGridColumn field="materialDollars" headerClass="header-grid right-header-grid"
                                      headerName="Material $" type="numericColumn" width={120}
                                      valueFormatter={params => currencyFormatter(params.data.materialDollars, '$')}/>
                        <AgGridColumn field="subcontractDollars" headerClass="header-grid right-header-grid"
                                      headerName="Subcontract $" type="numericColumn" width={140}
                                      valueFormatter={params => currencyFormatter(params.data.subcontractDollars, '$')}/>
                        <AgGridColumn field="equipmentDollars" headerClass="header-grid right-header-grid"
                                      headerName="Equipment $" type="numericColumn" width={130}
                                      valueFormatter={params => currencyFormatter(params.data.equipmentDollars, '$')}/>
                        <AgGridColumn headerName="Total" field="rowTotal" headerClass="header-grid right-header-grid"
                                      type="numericColumn" width={150}
                                      cellRenderer={(params) => {
                                          const {
                                              laborDollars,
                                              materialDollars,
                                              subcontractDollars,
                                              equipmentDollars,
                                              valueRowTotal
                                          } = params.data
                                          return currencyFormatter(laborDollars + materialDollars + subcontractDollars + equipmentDollars + (valueRowTotal ?? 0), '$')
                                      }}
                        />
                    </AgGridReact>
                </div>}
                <div style={{flex: 'none', height: '60px'}}>
                    <AgGridReact
                        rowData={[totals]}
                        headerHeight={restrictedView ? 28 : 0}
                        rowStyle={{fontWeight: 'bold', background: '#EDF2F5'}}>
                        <AgGridColumn field="description" width={250} valueGetter={() => 'Total Change Value'}
                                      headerName={'Description'}/>
                        {!restrictedView && <AgGridColumn field="laborHours" type="numericColumn" width={120}
                                                          headerName={'Labor Hours'}/>}
                        <AgGridColumn field="laborDollars" type="numericColumn" width={120}
                                      valueFormatter={params => currencyFormatter(params.data.laborDollars, '$')}
                                      headerName={'Labor $'}/>
                        <AgGridColumn field="materialDollars" type="numericColumn" width={120}
                                      valueFormatter={params => currencyFormatter(params.data.materialDollars, '$')}
                                      headerName={'Material $'}/>
                        <AgGridColumn field="subcontractDollars" type="numericColumn" width={140}
                                      valueFormatter={params => currencyFormatter(params.data.subcontractDollars, '$')}
                                      headerName={'Subcontract $'}/>
                        <AgGridColumn field="equipmentDollars" type="numericColumn" width={130}
                                      valueFormatter={params => currencyFormatter(params.data.equipmentDollars, '$')}
                                      headerName={'Equipment $'}/>
                        <AgGridColumn field="rowTotal" type="numericColumn" width={150}
                                      valueFormatter={params => currencyFormatter(params.data.rowTotal, '$')}
                                      headerName={'Total'}/>
                    </AgGridReact>
                </div>
            </Container>
        )
    } else {
        return null
    }
}

const PCRSummary = ({pcrSummaryData, isLumpSum}) => {
    const [budgetSummary, setBudgetSummary] = useState([])
    const [budgetSummaryTotals, setBudgetSummaryTotals] = useState({
        equipmentDollars: 0,
        laborDollars: 0,
        laborHours: 0,
        materialDollars: 0,
        rowTotal: 0,
        subcontractDollars: 0,
    });
    const [valueSummary, setValueSummary] = useState([])
    const [valueSummaryTotal, setValueSummaryTotal] = useState({total: 0})
    const {isNotAuthorized} = useAuthorized()
    const restrictedView = isLumpSum && isNotAuthorized([CLIENT])
    useEffect(() => {
        (async function getFcoSummary() {
            if (pcrSummaryData) {
                const {budgetSummary, valueSummary} = pcrSummaryData
                if (budgetSummary) {
                    setBudgetSummary(budgetSummary)
                    setBudgetSummaryTotals(reduceBudgetTotals(budgetSummary))
                }
                if (valueSummary) {
                    setValueSummary(valueSummary)
                    setValueSummaryTotal(reduceValueTotals(valueSummary));
                }
            }
        })()
    }, [pcrSummaryData])
    const budgetSummaryTable = useMemo(() => getBudgetSummaryTable([...budgetSummary, ...(valueSummary.map(o => ({
        ...o,
        valueRowTotal: o.total
    })))], {
        ...budgetSummaryTotals,
        rowTotal: budgetSummaryTotals.rowTotal + valueSummaryTotal.total
    }, restrictedView), [valueSummary, budgetSummary, budgetSummaryTotals, valueSummaryTotal])

    return (
        <>
            {(budgetSummary && budgetSummaryTotals) && budgetSummaryTable}
        </>
    )
}

export default PCRSummary