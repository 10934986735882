import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Page from '../../../UI/Page'
import TemplateGrid from './template-grid/TemplateGrid'
import TemplateHeader from './template-header/TemplateHeader'

const Templates = () => {

    return (
        <Page>
            <Container>
                <Row>
                    <Col>
                        <TemplateHeader />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TemplateGrid />
                    </Col>
                </Row>
            </Container>
        </Page>
    )
}
export default Templates