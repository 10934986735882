import { useDispatch, connect } from "react-redux";
import { AgGridReact, AgGridColumn } from "ag-grid-react/lib/agGridReact";
import React, { useEffect, useRef, useCallback } from "react";
import { taggedEquipmentSummarySetData } from "../redux/detailsDataAndTotalSlice"
import { getRowStyle, getRowSpanForDescriptionTotal, getTotalDescription, currencyFormatter, defaultGridOptions, isFooter, sortById } from "../../../../utilities/ag-grid-utilities/AgGridUtilities";
import DecimalEditor from "../../../../utilities/ag-grid-utilities/cell-editors/DecimalEditor";


const TOTAL_DESCRIPTION = 'Total Engineered Equipment (DIV A)';

const cellClassRules = {
    'override-status': params => params.data.edited,
    'editable-cell': params => {
        if (!params.colDef.editable || isFooter(params)) {
            return false;
        }
        if (params.colDef.field === 'activityDescription') {
            return params.data.activityDescription.toLowerCase() === 'undefined';
        }
        return true;
    }
};



const TaggedEquipmentGrid = React.memo(({ data, totals, readOnly }) => {
    const dispatch = useDispatch()
    const doUpdate = useRef(false)
    const dataRef = useRef(...data)

    useEffect(() => {
        dataRef.current = [...data]
    }, [data]);

    const applyFormulasToDataModel = useCallback((localData) => {
        const updatedDataModel = localData.map(row => {
            const { equipmentDollars, activityDescription } = row

            return {
                ...row,
                equipmentDollars: equipmentDollars,
                activityDescription: activityDescription
            }
        })

        if (updatedDataModel.length > 0) {
            dispatch(taggedEquipmentSummarySetData(updatedDataModel))
        }
    }, [dispatch])


    const valueSetter = (params) => {
        if (params.colDef.field === "equipmentDollars") {
            //Validate Equipment Dollars precision and scale
            if (params.newValue > 999999999.99) {
                return params.oldValue
            }
        }

        const isNewValue = Number(params.oldValue) !== Number(params.newValue)
        if (isNewValue) {
            const value = params.colDef.field === 'equipmentDollars' ? Number(params.newValue) : params.newValue;
            const updatedRow = { ...params.data, [params.column.colId]: value, edited: true }
            const filteredData = dataRef.current.filter(f => f.id !== params.data.id)
            applyFormulasToDataModel([...filteredData, ...[updatedRow]].sort(sortById))
        }
        return isNewValue
    }

    return (
        <div className="ag-theme-bootstrap" style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <div style={{flex: '1 1 auto', overflow: "hidden"}}>
                <AgGridReact
                    gridOptions={{...defaultGridOptions}}
                    getRowStyle={getRowStyle}
                    rowData={data}
                    getRowNodeId={data => data.id}
                    immutableData={true}
                    domLayout="autoHeight"
                    singleClickEdit={true}
                    enableCellChangeFlash={true}
                    pinnedBottomRowData={[totals]}
                    onFirstDataRendered={() => doUpdate.current = true}
                    frameworkComponents={{
                        decimalCellEditor: DecimalEditor
                    }}>
                    <AgGridColumn headerName="Edited" field="edited" hide
                                  valueFormatter={params => params.value ?? false} suppressSizeToFit={false}/>
                    <AgGridColumn headerName="Pact" field="pact" headerClass="header-grid"
                                  cellClassRules={cellClassRules} width={75} maxWidth={75}
                                  colSpan={getRowSpanForDescriptionTotal}
                                  valueFormatter={params => getTotalDescription(params, TOTAL_DESCRIPTION)}/>
                    <AgGridColumn headerName="Activity"
                                  editable={params => !readOnly && params.node.data.activityDescription === 'UNDEFINED'}
                                  field="activityDescription" headerClass="header-grid" cellClassRules={cellClassRules}
                                  width={330}
                                  valueSetter={valueSetter}/>
                    <AgGridColumn headerName="Equipment $"
                                  editable={params => !readOnly && !isFooter(params)}
                                  field="equipmentDollars"
                                  headerClass="header-grid right-sortable-header-grid" type="numericColumn"
                                  cellClassRules={cellClassRules} width={150} maxWidth={150}
                                  valueSetter={valueSetter} cellEditor="decimalCellEditor"
                                  valueFormatter={(params) => currencyFormatter(params.data.equipmentDollars, '$', 0)}/>
                </AgGridReact>
            </div>
        </div>
    );
}, (prevProps, nextProps) => {
    return (
        JSON.stringify(prevProps.totals) === JSON.stringify(nextProps.totals) &&
        JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
    );
});

function mapStateToProps(state) {
    const { taggedEquipmentSummary: { data, totals }, readOnly} = state.detailsDataAndTotal
    return {
        data,
        totals,
        readOnly
    }
}

export default connect(mapStateToProps)(TaggedEquipmentGrid);