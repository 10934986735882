import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { openSuccess } from "../../../../success/redux/successSlice"
import { useForm } from 'react-hook-form';
import Page from '../../../../../UI/Page';
import FeatureTitle from '../../../../../components/FeatureTitle/FeatureTitle'
import PCIBasicInfoForm from '../../pci-basic-info-form/PCIBasicInfoForm';
import { getUpdatePCRRoute, HomeRoute, LogRoute } from '../../../../../setup/routes'
import PCIDetailInformationForm from '../../pci-detail-information-form/PCIDetailInformationForm';
import PCIApproveActionButtons from '../PCIApproveActionButtons';
import PCIService from "../../api/pciService";
import Authorized from '../../../../auth/Authorized/Authorized';
import {
    Container,
    Row,
    Col,
    Form,
    Label,
    Input
} from 'reactstrap';
import { Controller } from "react-hook-form"
import { ErrorMessage } from '@hookform/error-message';
import { HISTORY_TYPE } from "../../../../../setup/constants";
import Spinner from '../../../../../components/Spinner/Spinner';
import useSelectedProject from '../../../../../hooks/useSelectedProject'
import {CLIENT, PROJECT_CONTROLS, PROJECT_ENGINEER, PROJECT_MANAGER} from '../../../logs-roles/Roles';
import {useAuthorized} from '../../../../auth/Authorized/useAuthorized';
const AuthorizedRoles = [PROJECT_MANAGER, PROJECT_ENGINEER, PROJECT_CONTROLS, CLIENT]

const ApprovePCIByClient = ({ match }) => {
    const pciId = match.params.id
    const userId = useSelector(state => state.user.user.userId);
    const [attachments, setAttachments] = useState([])
    const [pciData, setPciData] = useState(null)
    const [pciStatusAndAreas, setPciStatusAndAreas] = useState(null)
    const [pciDisciplineDropdowns, setDisciplineDropdowns] = useState(null)
    const [changeNumber, setChangeNumber] = useState(null)
    const [loading, setLoading] = useState(true)
    let history = useHistory();
    const {isAuthorized} = useAuthorized();
    const isClient = isAuthorized([CLIENT]);
    const [setSelectedProjectDropdown] = useSelectedProject();
    const [selectedProjectId, setSelectedProjectId] = useState(null)
    const [requestInProgress, setRequestInProgress] = useState(false)

    const dispatch = useDispatch()
    const { setValue, getValues, formState: { errors, isValid }, control, handleSubmit, reset } = useForm({
        defaultValues: {
            pciStatus: "1",
            pciTitle: "",
            clientNumber: "",
            pciScheduleImpactByText: "",
            pciAnticipatedValue: "",
            pciDisciplineImpactReturnExpected: "",
            variance: false,
            sbiEnable: false
        },
        mode: "onChange"
    });

    useEffect(() => {
        async function getPCIByNumber() {
            const response = await PCIService.getApprovePCIByClientInfo(pciId)
            if (response.data.data) {
                const { areas, statuses, templates, roms, causes, pciNumber, attachedFiles, projectId } = response.data.data
                setAttachments(attachedFiles ?? [])
                setChangeNumber(pciNumber)
                setPciStatusAndAreas({ areas, statuses })
                setDisciplineDropdowns({ templates, roms, causes })
                setPciData(response.data.data)
                setSelectedProjectId(projectId);
            }
            setLoading(false)
        }
        getPCIByNumber()
    }, [pciId])

    useEffect(() => {
        setSelectedProjectDropdown(selectedProjectId)
    }, [selectedProjectId, setSelectedProjectDropdown])

    useEffect(() => {
        if (pciData !== null) {
            const { logStatus, logArea, logTitle, clientNumber, description,
                templateId, pcrDiscipReturnDays, pciRomId, pciValue, causeId,
                pciScheduleId, scheduleImpactedBy, notes, originator, logIsVariance, sbiEnable } = pciData

            reset({
                "clientNumber": clientNumber ?? "", "pciStatus": logStatus, "pciArea": logArea.toString(), "pciTitle": logTitle, "variance": logIsVariance,
                "pciDescription": description, "pciBudgetTemplate": templateId ?? "DEFAULT", "pciDisciplineImpactReturnExpected": pcrDiscipReturnDays ?? 0,
                "pciAproxTicCostRom": pciRomId ?? "DEFAULT", "pciAnticipatedValue": pciValue ?? 0, "pciReasonForChange": causeId ?? "DEFAULT", "sbiEnable": sbiEnable,
                "pciSchedule": pciScheduleId ?? "1", "pciScheduleImpactByText": scheduleImpactedBy ?? "", "pciNotes": notes ?? "", "originator": originator
            })
        }
    }, [pciData, setValue, reset])


    const onApprove = handleSubmit(async (data, estimateAndChange) => {
        const request = { "pciId": pciData.id, userId, approved: true, comments: data.pciApprovalComments, estimateAndChange }
        try {
            setRequestInProgress(true)
            const response = await PCIService.approvePCIByClient(request)

            if (response.status === 200) {
                if (isClient) {
                    history.push(HomeRoute);
                } else if (history.action !== HISTORY_TYPE.NO_HISTORY) {
                    history.push(getUpdatePCRRoute(response.data.data.pcrLogId));
                } else {
                    history.push(LogRoute);
                }
                setTimeout(() => {
                    dispatch(openSuccess("The PCI was Approved."))
                }, 4000)
            }
            setRequestInProgress(false)
        } catch (e) {
            console.log(e)
            setRequestInProgress(false)
        }
    })

    const onReject = handleSubmit(async (data) => {
        const request = { "pciId": pciData.id, userId, approved: false, comments: data.pciApprovalComments }
        try {
            setRequestInProgress(true)
            const response = await PCIService.approvePCIByClient(request)
            if (response.status === 200) {
                if (isClient) {
                    history.push(HomeRoute);
                } else if (history.action !== HISTORY_TYPE.NO_HISTORY) {
                    history.goBack();
                } else {
                    history.push(LogRoute);
                }
                setTimeout(() => {
                    dispatch(openSuccess("The PCI was Rejected."))
                }, 4000)
            }
            setRequestInProgress(false)
        } catch (e) {
            console.log(e)
            setRequestInProgress(false)
        }
    })

    if (loading) {
        return (<Spinner />)
    }

    return (
        <Page>
            {pciData && <Container className="pb-3">
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title={'Project Change: ' + changeNumber}/>
                            <hr/>
                        </Container>
                    </Col>
                </Row>
                <Form className="pb-5">
                    <Row>
                        <Col>
                            {pciStatusAndAreas && <PCIBasicInfoForm
                                control={control} errors={errors}
                                pciStatusAndAreas={pciStatusAndAreas} getValues={getValues}
                                readOnly={true} attachments={attachments}/>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {pciDisciplineDropdowns &&
                                <Authorized roles={AuthorizedRoles}>
                                    <PCIDetailInformationForm control={control}
                                                              getValues={getValues} errors={errors}
                                                              templates={pciDisciplineDropdowns.templates}
                                                              roms={pciDisciplineDropdowns.roms}
                                                              causes={pciDisciplineDropdowns.causes} readOnly={true}/>
                                </Authorized>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {pciData?.approver && pciData.approver.length > 0 &&
                                <div className="h6 font-italic pt-4 mb-0 d-flex justify-content-end">
                                    {<div className="pr-1">When submitted an approval will be sent to </div>}
                                    {<div>{pciData.approver.map((name, id) => (
                                        <div className="text-capitalize" key={id}>{name}</div>))}</div>}
                                </div>
                            }
                            <hr/>
                            <Row className="mt-md-3">
                                <Col sm="3" lg="2" className="text-right">
                                    <Label for="pciDescription">Approval Comments</Label>
                                </Col>
                                <Col sm="12" lg="10">
                                    <Controller
                                        placeholder="ApprovalComments"
                                        control={control}
                                        name="pciApprovalComments"
                                        render={({field}) => <Input type="textarea" {...field}
                                                                    data-testid="pciApprovalComments"/>}
                                        rules={{
                                            maxLength: {
                                                message: 'Approval Comments must be no longer than 4000 characters.',
                                                value: 4000
                                            }
                                        }}
                                    />
                                    <ErrorMessage className="text-danger small" errors={errors}
                                                  name={'pciApprovalComments'} as="p" key={'pciApprovalComments'}/>
                                </Col>
                            </Row>
                            <hr/>
                            <PCIApproveActionButtons cancelRoute={HomeRoute} onApprove={onApprove} onReject={onReject}
                                                     isValid={isValid} requestInProgress={requestInProgress}/>
                        </Col>
                    </Row>
                </Form>
            </Container>}
        </Page>
    );
}

export default ApprovePCIByClient