import axios from '../../../setup/axios';

class LogService {

    BASE_ROUTE = "/log";

    getPCIStatusesAndAreas(projectId) {
        return this.get(`pci/${projectId}/statusesareas`)
    }

    getPCRStatusesAndAreas(projectId) {
        return this.get(`pcr/${projectId}/statusesareas`)
    }

    getActivitiesByArea(area, pcrId) {
        return this.get(`pcr/project/${pcrId}/areas/${area}/activities`)
    }

    getLogsByProjectId(projectId) {
        return this.get(`${projectId}/project`)
    }

    getPCIByLogId(logId) {
        return this.get(`${logId}/pci`)
    }

    getFCOByLogId(logId) {
        return this.get(`${logId}/fco`)
    }

    getFCOForCMApproval(logId) {
        return this.get(`${logId}/fcoForCMApproval`)
    }

    getFCOForClientApproval(logId) {
        return this.get(`${logId}/fcoForClientApproval`)
    }

    getFTByLogId(logId) {
        return this.get(`${logId}/ft`)
    }

    getPCRByLogId(logId) {
        return this.get(`${logId}/pcr`)
    }

    getPCRForPEApproval(logId) {
        return this.get(`${logId}/pcrForPEApproval`)
    }

    getPCRForPMApproval(logId) {
        return this.get(`${logId}/pcrForPMApproval`)
    }

    getPCRForClientApproval(logId) {
        return this.get(`${logId}/pcrForClientApproval`)
    }

    getDisciplinesByPCR(pcrId, userId, projectId, discipline) {
        const params = { params: { userId, projectId, discipline } }
        return this.get(`pcr/${pcrId}/disciplines`, params)
    }

    saveDisciplinesImpact(data) {
        return this.post('pcr/disciplines', data);
    }

    updatePCR(pciData) {
        return this.putMultipart('pcr', pciData);
    }

    updatePCI(pciData) {
        return this.putMultipart('pci', pciData);
    }

    submitToEstimating(data) {
        return this.post(`pcr/estimating`, data);
    }

    savePCI(pciData) {
        return this.postMultipart('pci', pciData);
    }

    savePCR(pcrData) {
        return this.postMultipart('pcr', pcrData);
    }

    submitPCI(pciData) {
        return this.post(`/pci/${pciData.id}/submit`, pciData);
    }

    submitPCR(pcrData) {
        return this.post(`/pcr/submit`, pcrData);
    }

    submitPCRToDisciplineLeads(pcrData) {
        return this.post(`/pcr/submitPCRToDiscipline`, pcrData);
    }

    submitPCRDisciplines(pcrData) {
        return this.post(`/pcr/disciplines/submit`, pcrData);
    }

    getDetailsByLogId(logId) {
        return this.get(`${logId}/details`);
    }

    putDetailsByLogId(data) {
        return this.put(`details`, data);
    }

    downloadAttachment(filePath) {
        return axios.get(`/attachments/local?filePath=${filePath}`)
    }

    get(route, params) {
        return axios.get(`${this.BASE_ROUTE}/${route}`, params)
    }

    post(route, data) {
        return axios.post(`${this.BASE_ROUTE}/${route}`, data);
    }

    postMultipart(route, data) {
        const config = { headers: { 'Content-Type': `multipart/form-data; boundary=${data._boundary}` } };
        return axios.post(`${this.BASE_ROUTE}/${route}`, data, config);
    }

    put(route, data) {
        return axios.put(`${this.BASE_ROUTE}/${route}`, data);
    }

    putMultipart(route, data) {
        const config = { headers: { 'Content-Type': `multipart/form-data; boundary=${data._boundary}` } };
        return axios.post(`${this.BASE_ROUTE}/${route}/update`, data, config);
    }
}

export default new LogService()