import Page from '../../../../UI/Page';
import {useForm} from 'react-hook-form';
import FCOService from '../api/fcoService'
import {useEffect, useState} from 'react';
import LogService from '../../api/logService'
import {Summary} from '../../summary/Summary'
import {History} from '../../history/History';
import {getApproveFCOByClientRoute, getApproveFCOByCMRoute, LogRoute} from '../../../../setup/routes'
import FCOActionButtons from '../FCOActionButtons';
import {useSelector, useDispatch} from 'react-redux';
import {Container, Row, Col, Form} from 'reactstrap';
import FCOBasicInfoForm from '../fco-basic-info-form/FCOBasicInfoForm';
import FCODetailInfoForm from '../fco-detail-info-form/FCODetailInfoForm';
import FeatureTitle from '../../../../components/FeatureTitle/FeatureTitle'
import RFIAttachementGrid from '../rfi-attachments-grid/RFIAttachementGrid'
import {openSuccess} from '../../../success/redux/successSlice';
import {statuses} from '../../statuses';
import Spinner from '../../../../components/Spinner/Spinner'
import useSelectedProject from '../../../../hooks/useSelectedProject'
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';
import {PROJECT_CONTROLS, PROJECT_COORDINATOR} from '../../logs-roles/Roles';
import {useHistory} from 'react-router';

//FCO Created, Approved by Construction Manager
const allowedStatus = [28, 30]

const readOnlyStatuses = [32, 33, 34, 31]

const UpdateFCO = ({match}) => {
    const logId = match.params.id
    const {projectId} = useSelector((state) => state)
    const userId = useSelector(state => state.user.user.userId);
    const [dropdownsData, setDropdownsData] = useState(null)
    const [fcoData, setFcoData] = useState(null)
    const [changeNumber, setChangeNumber] = useState(null)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
    const [summaryData, setSummaryData] = useState(null)
    const [approvalRoute, setApprovalRoute] = useState()
    const [formSaved, setFormSaved] = useState(0)
    const [readOnly, setReadOnly] = useState()
    const [saveInProgress, setSaveInProgress] = useState(false)
    const [loading, setLoading] = useState(true)
    const [pcLogStatusHistories, setPcLogStatusHistories] = useState(null)
    const [setSelectedProjectDropdown] = useSelectedProject();
    const [selectedProjectId, setSelectedProjectId] = useState(null)
    const {isAuthorized} = useAuthorized()
    const history = useHistory()

    const {getValues, formState: {errors}, control, handleSubmit, setValue, reset} = useForm({
        defaultValues: {
            requestedBy: userId,
            type: 'R',
            tracking: false,
            projectType: 'L',
            scheduleImpact: false,
            shortDescription: '',
            identifier: '',
            variance: false
        },
        mode: 'onChange'
    });
    const [rfiData, setrfiData] = useState([])
    const [selectedRfiList, setSelectedRfiList] = useState([])
    const [attachments, setAttachments] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {

        async function getFCOByLogId() {
            const response = await LogService.getFCOByLogId(logId)
            const responseData = response.data.data

            if (responseData) {

                responseData.statuses.sort((a, b) => {
                    return b.id - a.id;
                })
                setLoading(false)
                setDropdownsData(responseData)
                setFcoData(responseData)
                setAttachments(responseData.attachedFiles ?? [])
                setChangeNumber(responseData.log.changeNumber)
                setrfiData(responseData.allRFIs)
                setSelectedRfiList(responseData.selectedRFIs)
                setSummaryData(responseData.summary ?? null)
                determineApprovalRoute(responseData)
                setPcLogStatusHistories(responseData.pcLogStatusHistories)

                if (allowedStatus.some(s => s === responseData.log.status)) {
                    setIsSubmitDisabled(false);
                }
                setSelectedProjectId(responseData.projectId)
                setReadOnly(readOnlyStatuses.indexOf(responseData.log.status) >= 0)
            }
        }

        getFCOByLogId()
    }, [logId, formSaved])

    useEffect(() => {
        setSelectedProjectDropdown(selectedProjectId)
    }, [selectedProjectId, setSelectedProjectDropdown])

    const determineApprovalRoute = (data) => {

        let route = undefined;
        switch (data.log.status) {
            case statuses.PENDING_CM_APPROVAL:
                route = getApproveFCOByCMRoute(data.log.id)
                break;
            case statuses.PENDING_CLIENT_APPROVAL_FCO:
                route = getApproveFCOByClientRoute(data.log.id)
                break;

            default:
                break
        }

        setApprovalRoute(route);
    }

    useEffect(() => {
        if (fcoData !== null) {
            const {
                log: {area, title, originator, status, variance}, cause, type,
                tracking, projectType, scheduleImpact, scheduleHours, resolution, description, templateId,
                identifier
            } = fcoData
            reset({
                'status': status,
                'area': area.toString(),
                'shortDescription': title,
                'originator': originator,
                'type': type,
                'cause': cause.id,
                'requestedBy': originator,
                'projectType': projectType,
                'tracking': tracking,
                'scheduleImpact': scheduleImpact,
                'scheduleHours': scheduleHours,
                'fullDescription': description,
                'resolution': resolution ?? '',
                'identifier': identifier ?? '',
                'template': templateId,
                'variance': variance
            })

        }
    }, [fcoData, setValue, reset])

    const onSave = handleSubmit(async (data) => {
        const formData = new FormData()
        const command = {
            ...data, ...{
                projectId: projectId.value,
                id: fcoData.id,
                userId,
                causeId: data.cause,
                templateId: data.template,
                attachments: [],
                selectedRFIs: selectedRfiList
            }
        }

        attachments.forEach((file) => {
            if (file.name) {
                formData.append('files', file, file.name)
            } else {
                command.attachments.push({
                    'fileName': file.fileName,
                    'status': file.status,
                    'fileDownloadUri': file.fileDownloadUri
                })
            }
        })

        formData.append('command', new Blob([JSON.stringify(command)], {type: 'application/json'}))
        try {
            setSaveInProgress(true)
            const response = await FCOService.updateFCO(formData)
            if (response.data.data) {
                setFormSaved(formSaved + 1)
                dispatch(openSuccess('The FCO was Updated.'))
                determineApprovalRoute(fcoData);
            }
            setSaveInProgress(false)
        } catch (error) {
            setSaveInProgress(false)
            console.log(error)
        }
    })

    const onSubmit = handleSubmit(async (data) => {

        const formData = new FormData()
        const command = {
            ...data, ...{
                projectId: projectId.value,
                id: fcoData.id,
                userId,
                causeId: data.cause,
                templateId: data.template,
                attachments: [],
                selectedRFIs: selectedRfiList
            }
        }

        attachments.forEach((file) => {
            if (file.name) {
                formData.append('files', file, file.name)
            } else {
                command.attachments.push({
                    'fileName': file.fileName,
                    'status': file.status,
                    'fileDownloadUri': file.fileDownloadUri
                })
            }
        })

        formData.append('command', new Blob([JSON.stringify(command)], {type: 'application/json'}))
        try {
            setSaveInProgress(true)
            const response = await FCOService.submitFCO(formData)
            if (response.status === 200) {
                if (!response.data.errors || response.data.errors.length === 0) {
                    setFormSaved(formSaved + 1)
                    determineApprovalRoute(fcoData);
                    setIsSubmitDisabled(true);
                    dispatch(openSuccess('The FCO was submited.'))
                }
            }
            setSaveInProgress(false)
        } catch (e) {
            setSaveInProgress(false)
            console.log(e)
        }
    })

    if (loading) {
        return (<Spinner/>)
    }

    const onCloseFCO = async (closeStatus) => {
        if (logId) {
            try {
                setSaveInProgress(true)
                const response = await FCOService.closeFCO(logId, userId, projectId, closeStatus);
                setSaveInProgress(false)
                if (response.status === 200) {
                    history.push(LogRoute)
                } else {
                    console.error(response)
                }
            } catch (e) {
                setSaveInProgress(false)
                console.error(e)
            }
        }
    }

    const isSaveDisabled = () => {
        if (isAuthorized([PROJECT_COORDINATOR, PROJECT_CONTROLS])) {
            return false
        }
        return readOnly || !dropdownsData
    }

    return (
        <Page>
            <Container className="pb-3">
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title={'Project Change: ' + changeNumber}/>
                            <hr/>
                        </Container>
                    </Col>
                </Row>
                <Form className="pb-5" onSubmit={onSave}>
                    <Row>
                        {dropdownsData &&
                            <>
                                <FCOBasicInfoForm
                                    control={control} errors={errors} dropdownsData={dropdownsData}
                                    getValues={getValues} setAttachments={setAttachments} attachments={attachments}
                                    readOnly={readOnly}/>
                                <FCODetailInfoForm
                                    control={control} errors={errors} dropdownsData={dropdownsData}
                                    getValues={getValues} setAttachments={setAttachments} attachments={attachments}
                                    readOnly={readOnly}/>
                            </>
                        }
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <Summary id={logId} fcoSummaryData={summaryData}/>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            {pcLogStatusHistories && <History historyData={pcLogStatusHistories}/>}
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            {rfiData && <RFIAttachementGrid rfiList={rfiData} selectedRfiList={selectedRfiList}
                                                            setSelectedRfiList={setSelectedRfiList}
                                                            readOnly={readOnly}/>}
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <FCOActionButtons onCloseFCO={onCloseFCO} saveInProgress={saveInProgress} onSave={onSave}
                                              onSubmit={onSubmit} approvalRoute={approvalRoute} cancelRoute={LogRoute}
                                              isCloseFCODisabled={!isAuthorized([PROJECT_COORDINATOR, PROJECT_CONTROLS])}
                                              isSubmitDisabled={isSubmitDisabled || readOnly}
                                              isSaveDisabled={isSaveDisabled()}
                                              fcoStatusId={(fcoData?.statuses ?? [])[0]?.id ?? -1}
                            />
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Page>
    );
}

export default UpdateFCO;