import React from 'react'
import { Col, Row } from "reactstrap";
import { currencyFormatter } from "../../../../utilities/ag-grid-utilities/AgGridUtilities"

const StickySummary = ({ changeNumber, title, totalHours = 0, totalDollar = 0 }) => {
    return (
        <Row style={{ background: "#333333" }} className="mx-4 mb-2 text-light rounded sticky-top fixed-top">
            <Col className="p-3"><h6>{"CHANGE MANAGEMENT NO."}</h6>{changeNumber}</Col>
            <Col xs={5} className="p-3"><h6>{"TITLE"}</h6>{title}</Col>
            <Col className="p-3"><h6>{"TOTAL HOURS"}</h6>{currencyFormatter(totalHours, '', 0)}</Col>
            <Col className="p-3"><h6>{"TOTAL DOLLARS"}</h6>{currencyFormatter(totalDollar, '$', 0)}</Col>
        </Row>
    )
}

export default StickySummary
