import React from 'react'
import { Container, Alert } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { closeError } from '../redux/errorSlice'
import Page from '../../../UI/Page'

const ErrorArea = () => {
    const { showError, errors } = useSelector((state) => state.error)

    const dispatch = useDispatch()

    const onDismiss = () => {
        dispatch(closeError())
    };

    let listItems = "";
    if (errors && errors.length > 0) {
        listItems = errors.map((error, index) =>
            <li key={index}>{error}</li>
        );
    }    

    return (
        <Page>
            <Container>
                <Alert color="danger" isOpen={showError} toggle={onDismiss}>
                    <h4 className="alert-heading">Something went wrong!</h4>
                    <hr />
                    <ul>
                        {listItems}
                    </ul>
                </Alert>
            </Container>
        </Page>
    )

}

export default ErrorArea