// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectDropdown_dropdown__2yun5 { \n    top: 4px;   \n    font-weight: normal; \n    font-size: 12px;\n    margin-right:10px;\n    height: 40px;\n}\n\n.ProjectDropdown_text__rTbs5 {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: 10px;\n    font-weight: normal;\n}", "",{"version":3,"sources":["webpack://src/components/ProjectDropdown/ProjectDropdown.module.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,2DAA2D;IAC3D,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".dropdown { \n    top: 4px;   \n    font-weight: normal; \n    font-size: 12px;\n    margin-right:10px;\n    height: 40px;\n}\n\n.text {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: 10px;\n    font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "ProjectDropdown_dropdown__2yun5",
	"text": "ProjectDropdown_text__rTbs5"
};
export default ___CSS_LOADER_EXPORT___;
