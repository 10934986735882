import {useEffect, useState} from 'react'

const Loading = () => {
  const loadingText = ["Loading", "Loading.", "Loading..", "Loading..."]

  const [loadingCount, setLoadingCount] = useState(0);
  const [display, setDisplay] = useState("Loading");

  const timeoutDisplay = (text, time) => {
    return window.setTimeout(() => {
      setDisplay(text);
      setLoadingCount((loadingCount + 1) % 3);
    }, time)
  }

  useEffect(() => {
    let timer = timeoutDisplay(loadingText[loadingCount + 1], 750)
    return () => {
      clearTimeout(timer);
    }
  })

  return <div>{display}</div>
}

export default Loading