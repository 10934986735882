import React from 'react';

import { Input } from 'reactstrap';

const CheckBox = (props) => {
    return (
        <Input type="checkbox" style={{marginLeft: '0.25rem'}}
            name = {props.name} 
            id = {props.name}
            checked = {props.isChecked}
            defaultChecked = {props.isDefaultChecked}
            disabled = {props.isDefaultChecked}
            onChange = {props.onChange}
        />
    )
}
export default CheckBox;