import styled from 'styled-components';
import {Link} from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3:first-of-type {
      font-size: 7rem;
    }
  }
`
const FourOFour = () => {
    return (
        <Container>
            <div>
                <h3>404</h3>
                <h3>Page Not Found</h3>
                <Link to={'/home'}>Back to Home</Link>
            </div>
        </Container>
    )
}

export default FourOFour
