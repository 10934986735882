import {Fragment, useEffect} from 'react'
import PageHeader from './PageHeader/PageHeader'
import PageFooter from './PageFooter/PageFooter'
import styles from './Layout.module.css'
import GlobalStyles from '../../styles/GlobalStyles';
import {useOktaAuth} from '@okta/okta-react';
import {useDispatch, useSelector} from 'react-redux';
import {setUser} from '../../features/auth/redux/userSlice';
import SuccessArea from '../../features/success/success-area/SuccessArea';
import ErrorArea from '../../features/errors/error-area/ErrorArea';
// import {useLazyGetUserGroupsQuery} from '../../api/apiSlice';

const Layout = (props) => {
    const {oktaAuth, authState} = useOktaAuth()
    const dispatch = useDispatch();
    const jobNo = useSelector(state => state.selectedProjectDetails.jobNo)
    // const [getUserOktaGroups] = useLazyGetUserGroupsQuery()

    useEffect(() => {
        //console.log('---> Executing Layout...');
        if (authState && (!authState.isAuthenticated ||
            authState.accessToken?.authorizeUrl !== 'https://sbec-external.okta.com/oauth2/v1/authorize')
        ) {
            // console.log('Authenticating...');
            oktaAuth.signInWithRedirect({originalUri: '/'})
        } else if (authState?.isAuthenticated) {
            oktaAuth.getUser().then((profile) => {
                const {preferred_username, groups, given_name, family_name} = profile;
                const projectPermissions = [];
                const projectAccess = new Map();
                let userGroups = [...(groups ?? [])]
                /* Parse roles */
                userGroups.forEach(g => {
                    if (g.startsWith('CMS%ALL') || g.startsWith('CMS%' + jobNo)) {
                        let role = g.split('%');
                        if (role.length > 2) {
                            projectAccess.set(role[1], true);
                            projectPermissions.push(role[2]);
                        }
                    }
                })
                dispatch(
                    setUser({
                        userId: preferred_username.substring(0, preferred_username.indexOf('@')),
                        givenName: given_name,
                        familyName: family_name,
                        roles: projectPermissions,
                        projectAccess: Array.from([...projectAccess.keys()]),
                    })
                )
                console.log(projectPermissions, Array.from([...projectAccess.keys()]))
            })
        }
    }, [window.location.pathname, authState, jobNo])

    return (
        <Fragment>
            <GlobalStyles/>
            <PageHeader/>
            <main className={styles.content}>
                <SuccessArea/>
                <ErrorArea/>
                {props.children}
            </main>
            <PageFooter/>
        </Fragment>
    );
}

export default Layout