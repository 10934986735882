import {useDispatch, connect} from 'react-redux';
import {AgGridReact, AgGridColumn} from 'ag-grid-react';
import React, {useEffect, useRef, useCallback, useMemo} from 'react';
import {indirectMaterialSummarySetData} from '../redux/detailsDataAndTotalSlice'
import NumericEditor from '../../../../utilities/ag-grid-utilities/cell-editors/NumericEditor'
import {
    getRowStyle,
    getRowSpanForDescriptionTotal,
    getTotalDescription,
    currencyFormatter,
    defaultGridOptions,
    isFooter,
    sortById
} from '../../../../utilities/ag-grid-utilities/AgGridUtilities';
import DecimalEditor from '../../../../utilities/ag-grid-utilities/cell-editors/DecimalEditor';
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';
import {CAN_OVERRIDE_DETAIL_VALUES} from '../../logs-roles/Roles';


const EDITABLE_PACTS = ['30', '16']
const K58_ACTIVITY_DESCRIPTION = 'PRORATABLES'
const FORMULA_SUM1_ACTIVITY_DESCRIPTION = 'SITEWORK'
const SALES_TAXES_ACTIVITY_DESCRIPTION = 'SALES TAXES'
const J59_ACTIVITY_DESCRIPTION = 'COMMISSIONING / STARTUP'
const INDIRECT_SUBCONTRACT_DOLLAR_CALCULATED_PACT = ['20', '21', '24', '26']
const TOTAL_DESCRIPTION = 'Total Indirect Material/Subs (DIV G, 03, 04, I)'
const INDIRECT_MATERIAL_DOLLAR_CALCULATED_WITH_TAGGED_EQUIPMENT_DOLLARS = ['28']
const INDIRECT_MATERIAL_DOLLAR_CALCULATED_WITH_DIRECT_LABOR_DOLLARS = ['20', '21', '24', '26']
const HEADER_ACTIVITY_DESCRIPTION_SUMMARY = ['CONSTRUCTION EQUIPMENT', 'TEMPORARY FACILITIES', 'CONSTRUCTION SUPPLIES', 'SAFETY SUPPLIES']
const FORMULA_SUM2_ACTIVITY_DESCRIPTION = ['BUILDINGS', 'MECHANICAL', 'PIPING', 'ELECTRICAL', 'INSULATION', 'PAINT', 'FIRE PROTECTION', 'SITE IMPROVEMENTS / ROADS']


const cellClassRules = {
    'override-status': params => params.data.edited,
    'editable-cell': params => {
        if ((!params.colDef.editable || !params.colDef.editable(params)) || isFooter(params)) {
            return false;
        }
        if (params.colDef.field === 'activityDescription') {
            return EDITABLE_PACTS.includes(params.data.pact) || params.data.activityDescription.toLowerCase() === 'undefined';
        }
        return true;
    }
}

const getIndirectMaterialDollarValue = (pact, materialDollars, directLaborPctRate, directLaborDollars, equipmentDollars) => {
    if (INDIRECT_MATERIAL_DOLLAR_CALCULATED_WITH_DIRECT_LABOR_DOLLARS.includes(pact)) {
        return Math.ceil(directLaborPctRate * directLaborDollars)
    }
    if (INDIRECT_MATERIAL_DOLLAR_CALCULATED_WITH_TAGGED_EQUIPMENT_DOLLARS.includes(pact)) {
        return Math.ceil(directLaborPctRate * equipmentDollars)
    }
    return materialDollars
}

const getIndirectSubcontractDollarValue = (pact, directLaborPctRate, subcontractDollars, subDollars) => {
    if (INDIRECT_SUBCONTRACT_DOLLAR_CALCULATED_PACT.includes(pact)) {
        return Math.ceil(subDollars * directLaborPctRate)
    }
    return subcontractDollars
}

const getValueByDescription = (data, searchField, value, returnedField) => {
    return data.find(d => d[searchField].toLowerCase().trim() === value.toLowerCase().trim())[returnedField] ?? 0
}


const IndirectMaterialGrid = React.memo(({
                                             data,
                                             j59,
                                             k58,
                                             totals,
                                             subDollars,
                                             directLaborSiteWorkMultBySaleTaxes,
                                             indirectMaterialSummaryAndMultBySaleTaxes,
                                             equipmentDollars,
                                             directLaborDollars,
                                             readOnly
                                         }) => {
    const dispatch = useDispatch()
    const doUpdate = useRef(false)
    const dataRef = useRef(...data)
    const directLaborDollarsRef = useRef(directLaborDollars)
    const equipmentDollarsRef = useRef(equipmentDollars)
    const subDollarsRef = useRef(subDollars)

    const {isAuthorized} = useAuthorized()
    const canOverrideValues = useMemo(() => {
        return isAuthorized([...CAN_OVERRIDE_DETAIL_VALUES]);
    }, [isAuthorized])


    useEffect(() => {
        subDollarsRef.current = subDollars
    }, [subDollars])

    useEffect(() => {
        equipmentDollarsRef.current = equipmentDollars
    }, [equipmentDollars])

    useEffect(() => {
        directLaborDollarsRef.current = directLaborDollars
    }, [directLaborDollars])

    useEffect(() => {
        dataRef.current = [...data]
    }, [data])


    const applyFormulasToDataModel = useCallback((localData) => {

        const updatedDataModel = localData.map(row => {
            const {pact, subcontractDollars, materialDollars, labor: {directLaborPctRate}, budgetOverride} = row
            return {
                ...row,
                materialDollars: budgetOverride ? materialDollars : getIndirectMaterialDollarValue(pact, materialDollars, directLaborPctRate, directLaborDollarsRef.current, equipmentDollarsRef.current),
                subcontractDollars: budgetOverride ? subcontractDollars : getIndirectSubcontractDollarValue(pact, directLaborPctRate, subcontractDollars, subDollarsRef.current)
            }
        })

        let newUpdatedDataModel = [...updatedDataModel]
        if (!canOverrideValues) {
            const salesTaxesRow = updatedDataModel.find(u => u.activityDescription.toLowerCase().trim() === SALES_TAXES_ACTIVITY_DESCRIPTION.toLowerCase().trim());
            const salesTaxesRate = salesTaxesRow?.labor?.directLaborPctRate ?? 0
            const updatedDataModelWithoutSalesTaxes = updatedDataModel.filter(u => u.activityDescription.toLowerCase().trim() !== SALES_TAXES_ACTIVITY_DESCRIPTION.toLowerCase().trim())
            const headerSummaryRows = updatedDataModelWithoutSalesTaxes.filter(u => HEADER_ACTIVITY_DESCRIPTION_SUMMARY.includes(u.activityDescription))
            const totalSalesTaxes = Math.round((headerSummaryRows.reduce((a, b) => a + b.materialDollars, 0) + j59 + k58 + directLaborSiteWorkMultBySaleTaxes + indirectMaterialSummaryAndMultBySaleTaxes) * salesTaxesRate)
            const newSalesTaxesRow = {...salesTaxesRow, materialDollars: totalSalesTaxes}
            newUpdatedDataModel = [...updatedDataModelWithoutSalesTaxes, ...[newSalesTaxesRow]]
        }

        if (updatedDataModel.length > 0) {
            dispatch(indirectMaterialSummarySetData(newUpdatedDataModel))
        }
    }, [dispatch, directLaborSiteWorkMultBySaleTaxes, indirectMaterialSummaryAndMultBySaleTaxes, j59, k58])


    const valueSetter = (params) => {
        if (params.colDef.field === 'subcontractDollars') {
            //Validate Subcontract Dollars precision and scale
            if (params.newValue > 999999999.99) {
                return params.oldValue
            }
        }
        const isTextField = ['activityDescription'].includes(params.colDef.field)
        const isNewValue = isTextField ? params.oldValue !== params.newValue : Number(params.oldValue) !== Number(params.newValue)
        if (isNewValue) {
            const value = isTextField ? params.newValue : Number(params.newValue);
            const updatedRow = {...params.data, [params.column.colId]: value, edited: true, budgetOverride: true}
            const filteredData = dataRef.current.filter(f => f.id !== params.data.id)
            applyFormulasToDataModel([...filteredData, ...[updatedRow]].sort(sortById));
        }
        return isNewValue
    }

    const recalculateFormulas = useCallback(() => {
        applyFormulasToDataModel(dataRef.current)
    }, [applyFormulasToDataModel])

    useEffect(() => {
        if (doUpdate.current) {
            recalculateFormulas()
        }
    }, [directLaborDollars, subDollars, equipmentDollars, totals, recalculateFormulas])

    return (
        <div className="ag-theme-bootstrap" style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <div style={{flex: '1 1 auto', overflow: 'hidden'}}>
                <AgGridReact
                    gridOptions={{...defaultGridOptions}}
                    getRowStyle={getRowStyle}
                    rowData={data}
                    getRowNodeId={data => data.id}
                    immutableData={true}
                    domLayout="autoHeight"
                    enableCellChangeFlash={true}
                    singleClickEdit={true}
                    pinnedBottomRowData={[totals]}
                    onFirstDataRendered={() => doUpdate.current = true}
                    frameworkComponents={{
                        numericCellEditor: NumericEditor,
                        decimalCellEditor: DecimalEditor
                    }}>
                    <AgGridColumn headerName="Edited" field="edited" hide
                                  valueFormatter={params => params.value ?? false}/>
                    <AgGridColumn headerName="Pact" field="pact" headerClass="header-grid"
                                  cellClassRules={cellClassRules} width={75} maxWidth={75}
                                  colSpan={getRowSpanForDescriptionTotal}
                                  valueFormatter={params => getTotalDescription(params, TOTAL_DESCRIPTION)}/>
                    <AgGridColumn headerName="Activity"
                                  editable={(params) => canOverrideValues && !readOnly && (params.node.data.activityDescription === 'UNDEFINED' || EDITABLE_PACTS.includes(params.data.pact))}
                                  field="activityDescription"
                                  headerClass="header-grid" cellClassRules={cellClassRules} width={330}
                                  valueSetter={valueSetter}
                    />
                    <AgGridColumn headerName="Material $" field="materialDollars"
                                  headerClass="header-grid right-sortable-header-grid" type="numericColumn"
                                  cellClassRules={cellClassRules} width={150} maxWidth={150}
                                  editable={params => canOverrideValues && !readOnly && !isFooter(params)}
                                  cellRenderer={params => currencyFormatter(params.data.materialDollars, '$')}
                                  cellEditor="numericCellEditor"
                                  valueSetter={valueSetter}
                    />
                    <AgGridColumn headerName="Subcontract $" field="subcontractDollars"
                                  headerClass="header-grid right-sortable-header-grid" type="numericColumn"
                                  cellClassRules={cellClassRules} width={150} maxWidth={150}
                                  editable={params => canOverrideValues && !readOnly && !isFooter(params)}
                                  valueSetter={valueSetter} cellEditor="decimalCellEditor"
                                  valueFormatter={(params) => currencyFormatter(params.data.subcontractDollars, '$', 0)}/>
                </AgGridReact>
            </div>
        </div>
    );
}, (prevProps, nextProps) => {
    return (
        prevProps.directLaborSiteWorkMultBySaleTaxes === nextProps.directLaborSiteWorkMultBySaleTaxes &&
        prevProps.indirectMaterialSummaryAndMultBySaleTaxes === nextProps.indirectMaterialSummaryAndMultBySaleTaxes &&
        prevProps.j59 === nextProps.j59 &&
        prevProps.k58 === nextProps.k58 &&
        prevProps.directLaborDollars === nextProps.directLaborDollars &&
        prevProps.subDollars === nextProps.subDollars &&
        prevProps.equipmentDollars === nextProps.equipmentDollars &&
        JSON.stringify(prevProps.totals) === JSON.stringify(nextProps.totals) &&
        JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
    )
})

function mapStateToProps(state) {

    const
        {
            indirectMaterialSummary: {data, totals},
            directLaborSummary: {data: directLaborSummaryData, totals: {directLaborDollars, subDollars}},
            taggedEquipmentSummary: {totals: {equipmentDollars}},
            saleTaxes,
            readOnly
        }
            = state.detailsDataAndTotal

    let directLaborSiteWorkMultBySaleTaxes = 0
    let indirectMaterialSummaryAndMultBySaleTaxes = 0
    let j59 = 0
    let k58 = 0
    2
    if (directLaborSummaryData.length > 0 && saleTaxes.length > 0) {

        directLaborSiteWorkMultBySaleTaxes = (getValueByDescription(directLaborSummaryData, 'activityDescription', FORMULA_SUM1_ACTIVITY_DESCRIPTION, 'subDollars') *
            getValueByDescription(saleTaxes, 'description', FORMULA_SUM1_ACTIVITY_DESCRIPTION, 'pctSubMaterialTaxed'))

        j59 = getValueByDescription(directLaborSummaryData, 'activityDescription', J59_ACTIVITY_DESCRIPTION, 'directMaterialDollars')
        k58 = getValueByDescription(directLaborSummaryData, 'activityDescription', K58_ACTIVITY_DESCRIPTION, 'directMaterialDollars')

        FORMULA_SUM2_ACTIVITY_DESCRIPTION.forEach(activity => {
            const taxRate = getValueByDescription(saleTaxes, 'description', activity, 'pctSubMaterialTaxed')
            indirectMaterialSummaryAndMultBySaleTaxes += (getValueByDescription(directLaborSummaryData, 'activityDescription', activity, 'subDollars') * (taxRate / 100))
        })
    }

    return {
        k58,
        j59,
        data,
        totals,
        subDollars,
        equipmentDollars,
        directLaborDollars,
        directLaborSiteWorkMultBySaleTaxes,
        indirectMaterialSummaryAndMultBySaleTaxes,
        readOnly
    }
}

export default connect(mapStateToProps)(IndirectMaterialGrid);