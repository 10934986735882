import React, { useState } from 'react'
import { useHistory } from 'react-router';
import styled from 'styled-components';
import {
    Row,
    Button,
    Modal, ModalBody, ModalFooter
} from 'reactstrap';



const ButtonSection = styled.div`
margin-left: auto;

`

const PCRDisciplineActionButtons = ({ cancelRoute, isSubmitHidden, onSave, onSubmit, isDisabledSaveAndSubmit, readOnly }) => {

    const [openModal, setOpenModal] = useState(false);
    const toggle = () => setOpenModal(!openModal);
    let history = useHistory();

    const onConfirmCancel = () => {
        toggle();
        history.goBack();
    }

    return (
        <Row>
            <ButtonSection>
                <Button color="link" data-testid="CancelLink" onClick={toggle} className="m-1">Close</Button>
                <Button color="primary" data-testid="SaveChanges" onClick={onSave} disabled={isDisabledSaveAndSubmit || readOnly} className="m-1">Save</Button>
                <Button hidden={isSubmitHidden} color="primary" data-testid="SubmitDisciplinesImpact" onClick={onSubmit} disabled={isDisabledSaveAndSubmit || readOnly} className="m-1">Submit</Button>
            </ButtonSection>
            <Modal isOpen={openModal} data-testid="NextCancel-ConfirmModal">
                <ModalBody>
                    {"Are you sure you want to close?"}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onConfirmCancel}>Yes</Button>
                    <Button color="primary" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </Row>
    )
}

export default PCRDisciplineActionButtons
