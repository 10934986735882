import { useState, useEffect, useMemo } from "react";
import {
    Container,
    Label,
    Input,
    Row,
    Col
} from "reactstrap";
import { Controller } from "react-hook-form"
import { ErrorMessage } from '@hookform/error-message';
import { StarIndicator } from "../../../../components/StarIndicator"
import { Attachments } from "../../attachments";
import { StyledColumn } from "../../../../components/StyledColumn";
import styled from "styled-components";
import {CAN_REVERT_STATUS} from '../../logs-roles/Roles';
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';

const RadioContainer = styled.div`
    border-style: dashed;
    border-color: #B1C1CE;
    border-width: 1px;
    border-radius: 2px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    display: flex;
`

const stringToBoolean = (string) => string === 'false' ? false : !!string

const PCRBasicInfoForm = ({ control, getValues, errors, statusAndAreas, readOnly = false, attachments, setAttachments }) => {
    const [statusOptions, setStatusOptions] = useState([])
    const [areaOptions, setAreaOptions] = useState([])
    const [templateOptions, setTemplateOptions] = useState([])

    const [contractType, setContractType] = useState("L")
    const [scheduleImpact, setScheduleImpact] = useState(false)
    const [sbiEnable, setSbiEnable] = useState(false)
    const [incorporatedInSchedule, setIncorporatedInSchedule] = useState(false)
    const [variance, setVariance] = useState(false)

    const contractTypeValue = getValues("contractType") ?? "L"
    const scheduleImpactValue = stringToBoolean(getValues("scheduleImpact")) ?? false
    const incorporatedInScheduleValue = stringToBoolean(getValues("incorporatedInSchedule")) ?? false
    const varianceValue = stringToBoolean(getValues("variance")) ?? false
    const sbiEnableValue = stringToBoolean(getValues("sbiEnable")) ?? false
    const {isAuthorized} = useAuthorized();

    useEffect(() => {
        setContractType(contractTypeValue)
    }, [contractTypeValue])

    useEffect(() => {
        setScheduleImpact(scheduleImpactValue)
    }, [scheduleImpactValue])

    useEffect(() => {
        setSbiEnable(sbiEnableValue)
    }, [sbiEnableValue])

    useEffect(() => {
        setIncorporatedInSchedule(incorporatedInScheduleValue)
    }, [incorporatedInScheduleValue])

    useEffect(() => {
        setVariance(varianceValue)
    }, [varianceValue])
    const sortByDescription = ((a, b) => {
        return a.description > b.description ? 1 : -1
    })
    const sortByName = ((a, b) => {
        return a.name > b.name ? 1 : -1
    })
    useEffect(() => {
        if (statusAndAreas) {
            setStatusOptions(statusAndAreas.statuses ? statusAndAreas.statuses.sort(sortByDescription).map(({ id, description }) => (
                <option value={id} key={id}>{description}</option>
            )) : [])
            setAreaOptions(statusAndAreas.areas ? statusAndAreas.areas.map(({ taskNumber }) => (
                <option value={taskNumber.toString()} key={taskNumber}>{taskNumber}</option>
            )) : [])
            setTemplateOptions(statusAndAreas.templates ? statusAndAreas.templates.sort(sortByName).map(({ id, name }) => (
                <option value={id} key={id}>{name}</option>
            )) : [])
        }
    }, [statusAndAreas])

    const isStatusDisabled = useMemo(() => {
        if (isAuthorized([...CAN_REVERT_STATUS])) {
            return false
        }
        return readOnly
    }, [readOnly])


    return (
        <>
            <Container >
                <h5>Status</h5>
                <Row className="mt-md-3">
                    <StyledColumn w={7}>
                        <Label for="status">Status</Label>
                    </StyledColumn>
                    <Col sm="4" lg="3" className="ml-3">
                        <Controller
                            control={control}
                            name="status"
                            render={({ field }) => <Input
                                type="select"
                                data-testid="status"
                                bsSize="sm"
                                disabled={isStatusDisabled}
                                required={true}
                                {...field}
                            >
                                {statusOptions}
                            </Input>} />
                    </Col>

                    <StyledColumn w={7}>
                        <Label for="originator">Originator</Label>
                    </StyledColumn>
                    <Col sm="3" lg="2">
                        <Controller
                            control={control}
                            name="originator"
                            render={({ field }) => <Input disabled={true} bsSize="sm" {...field} data-testid="originator" />}
                        />
                    </Col>
                    <StyledColumn w={7}>
                        <Label for="variance">Variance</Label>
                    </StyledColumn>
                    <Col lg="1" className="d-flex">
                        <Controller
                            control={control}
                            name="variance"
                            render={({ field }) =>
                                <RadioContainer  {...field}>
                                    <div className="ml-3">
                                        <Input
                                            disabled={readOnly}
                                            id="varianceYes"
                                            type="radio"
                                            value={true}
                                            checked={variance}
                                            onChange={() => setVariance(true)}
                                        />
                                        <Label for="varianceYes">Yes</Label>
                                    </div>

                                    <div className="ml-5">
                                        <Input
                                            disabled={readOnly}
                                            id="varianceNot"
                                            type="radio"
                                            value={false}
                                            checked={!variance}
                                            onChange={() => setVariance(false)}
                                        />
                                        <Label for="varianceNot">No</Label>
                                    </div>
                                </RadioContainer>
                            } />
                    </Col>
                </Row>
                <hr />
            </Container>

            <Container>
                <h5>General Information</h5>
                <Row className="d-flex">
                    <Col lg="7">
                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="template" >Budget Template<StarIndicator /> </Label>
                            </StyledColumn>
                            <Col sm="3" lg="4">
                                <Controller
                                    control={control}
                                    name="template"
                                    rules={{ required: "Budget Template is required." }}
                                    render={({ field }) => <Input
                                        style={{ width: "300px" }}
                                        type="select"
                                        bsSize="sm"
                                        disabled={readOnly}
                                        defaultValue="DEFAULT"
                                        data-testid="template"
                                        {...field}
                                    >
                                        {[...templateOptions, <option value="DEFAULT" key="DEFAULT" disabled hidden>Choose here</option>]}
                                    </Input>}
                                />
                                <ErrorMessage className="text-danger small" errors={errors} name={"template"} as="p" key={"template"} />
                            </Col>

                        </Row>

                    </Col>
                </Row>

                <Row className="d-flex">
                    <Col lg="7">
                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="area" >Area<StarIndicator /> </Label>
                            </StyledColumn>
                            <Col sm="1" lg="2">
                                <Controller
                                    control={control}
                                    name="area"
                                    rules={{ required: "Area is required." }}
                                    render={({ field }) => <Input
                                        style={{ width: "90px" }}
                                        type="select"
                                        bsSize="sm"
                                        disabled={readOnly}
                                        defaultValue="DEFAULT"
                                        data-testid="area"
                                        {...field}
                                    >
                                        {[...areaOptions, <option value="DEFAULT" key="DEFAULT" disabled hidden>Choose here</option>]}
                                    </Input>}
                                />
                                <ErrorMessage className="text-danger small" errors={errors} name={"area"} as="p" key={"area"} />
                            </Col>

                            <Col sm="2" lg="1" className="text-right">
                                <Label for="title">Title<StarIndicator /></Label>
                            </Col>
                            <Col sm="3" lg="7">
                                <Controller
                                    placeholder="Title"
                                    control={control}
                                    name="title"
                                    rules={{ required: "Title is required.", maxLength: { message: "Title must be no longer than 50 characters.", value: 50 } }}
                                    render={({ field }) => <Input disabled={readOnly} bsSize="sm" {...field} data-testid="title" />}
                                />
                                <ErrorMessage className="text-danger small" errors={errors} name={"title"} as="p" key={"title"} />
                            </Col>
                        </Row>
                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="description">Description<StarIndicator /></Label>
                            </StyledColumn>
                            <Col sm="9" lg="10">
                                <Controller
                                    placeholder="Description"
                                    control={control}
                                    name="description"
                                    render={({ field }) => <Input disabled={readOnly} type="textarea" {...field} data-testid="description" />}
                                    rules={{ required: "Description is required.", maxLength: { message: "Description must be no longer than 4000 characters.", value: 4000 } }}
                                />
                                <ErrorMessage className="text-danger small" errors={errors} name={"description"} as="p" key={"description"} />
                            </Col>
                        </Row>

                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="contractType" >Budget Type<StarIndicator /></Label>
                            </StyledColumn>
                            <Col lg="2" className="d-flex">
                                <Controller
                                    control={control}
                                    name="contractType"
                                    render={({ field }) =>
                                        <RadioContainer  {...field}>
                                            <div className="ml-3">
                                                <Input
                                                    disabled={readOnly}
                                                    id="contractTypeLumpSum"
                                                    type="radio"
                                                    value="L"
                                                    checked={contractType === "L"}
                                                    onChange={() => setContractType("L")}
                                                />
                                                <Label for="contractTypeLumpSum">Lump Sum</Label>
                                            </div>

                                            <div className="ml-4">
                                                <Input
                                                    disabled={readOnly}
                                                    id="contractTypeCostPlus"
                                                    type="radio"
                                                    value="C"
                                                    checked={contractType === "C"}
                                                    onChange={() => setContractType("C")}
                                                />
                                                <Label for="contractTypeCostPlus">Cost Plus</Label>
                                            </div>
                                        </RadioContainer>
                                    } />
                            </Col>
                        </Row>

                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="sbiEnable" >S&B India<StarIndicator /></Label>
                            </StyledColumn>
                            <Col lg="3" className="d-flex">
                                <Controller
                                    control={control}
                                    name="sbiEnable"
                                    render={({ field }) =>
                                        <RadioContainer  {...field}>
                                            <div className="ml-3">
                                                <Input
                                                    disabled={readOnly}
                                                    id="sbiEnableTrue"
                                                    type="radio"
                                                    value={true}
                                                    checked={sbiEnable}
                                                    onChange={() => setSbiEnable(true)}
                                                />
                                                <Label for="sbiEnableTrue">Yes</Label>
                                            </div>

                                            <div className="ml-5">
                                                <Input
                                                    disabled={readOnly}
                                                    id="sbiEnableFalse"
                                                    type="radio"
                                                    value={false}
                                                    checked={!sbiEnable}
                                                    onChange={() => setSbiEnable(false)}
                                                />
                                                <Label for="sbiEnableFalse">No</Label>
                                            </div>
                                        </RadioContainer>
                                    } />
                            </Col>
                        </Row>

                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="scheduleImpact" >Schedule Impact<StarIndicator /></Label>
                            </StyledColumn>
                            <Col lg="3" className="d-flex">
                                <Controller
                                    control={control}
                                    name="scheduleImpact"
                                    render={({ field }) =>
                                        <RadioContainer  {...field}>
                                            <div className="ml-3 ">
                                                <Input
                                                    disabled={readOnly}
                                                    id="scheduleImpactTrue"
                                                    type="radio"
                                                    value={true}
                                                    checked={scheduleImpact}
                                                    onChange={() => setScheduleImpact(true)}
                                                />
                                                <Label for="scheduleImpactTrue">Yes</Label>
                                            </div>

                                            <div className="ml-5">
                                                <Input
                                                    disabled={readOnly}
                                                    id="scheduleImpactFalse"
                                                    type="radio"
                                                    value={false}
                                                    checked={!scheduleImpact}
                                                    onChange={() => setScheduleImpact(false)}
                                                />
                                                <Label for="scheduleImpactFalse">No</Label>
                                            </div>
                                        </RadioContainer>
                                    } />
                            </Col>
                           

                            <StyledColumn w={9}>
                                <Label for="scheduleHours">Schedule Days</Label>
                            </StyledColumn>
                            <Col sm="3" lg="3">
                                <Controller
                                    control={control}
                                    name="scheduleHours"
                                    value={""}
                                    render={({ field }) => <Input disabled={!scheduleImpact || readOnly} bsSize="sm" {...field} data-testid="scheduleHours" />}
                                />
                            </Col>

                        </Row>

                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="incorporatedInSchedule" >Incorporated in Schedule<StarIndicator /></Label>
                            </StyledColumn>
                            <Col lg="3" className="d-flex">
                                <Controller
                                    control={control}
                                    name="incorporatedInSchedule"
                                    render={({ field }) =>
                                        <RadioContainer {...field} >
                                            <div className="ml-3">
                                                <Input
                                                    disabled={readOnly}
                                                    id="incorporatedInScheduleTrue"
                                                    type="radio"
                                                    value={true}
                                                    checked={incorporatedInSchedule}
                                                    onChange={() => setIncorporatedInSchedule(true)}
                                                />
                                                <Label for="incorporatedInScheduleTrue">Yes</Label>
                                            </div>
                                            <div className="ml-5 ">
                                                <Input
                                                    disabled={readOnly}
                                                    id="incorporatedInScheduleFalse"
                                                    type="radio"
                                                    value={false}
                                                    checked={!incorporatedInSchedule}
                                                    onChange={() => setIncorporatedInSchedule(false)}
                                                />
                                                <Label for="incorporatedInScheduleFalse">No</Label>
                                            </div>
                                        </RadioContainer>
                                    } />

                            </Col>

                            <StyledColumn w={9}>
                                <Label for="scheduleImpactDescription">Describe</Label>
                            </StyledColumn>
                            <Col lg="6">
                                <Controller
                                    control={control}
                                    name="scheduleImpactDescription"
                                    render={({ field }) => <Input className="text-placeholder" placeholder="Describe The Schedule Impact..." disabled={readOnly} type="textarea" {...field} data-testid="scheduleImpactDescription" />}
                                    rules={{ maxLength: { message: "Describe must be no longer than 4000 characters.", value: 4000 } }}
                                />
                                <ErrorMessage className="text-danger small" errors={errors} name={"scheduleImpactDescription"} as="p" key={"scheduleImpactDescription"} />
                            </Col>
                        </Row>

                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="comments">Comments</Label>
                            </StyledColumn>
                            <Col sm="9" lg="10">
                                <Controller
                                    placeholder="Comments"
                                    control={control}
                                    name="comments"
                                    render={({ field }) => <Input disabled={readOnly} type="textarea" {...field} data-testid="comments" />}
                                    rules={{ maxLength: { message: "Comments must be no longer than 4000 characters.", value: 4000 } }}
                                />
                                <ErrorMessage className="text-danger small" errors={errors} name={"comments"} as="p" key={"comments"} />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="5">
                        <Row className="mt-md-3">
                            <Attachments attachments={attachments} setAttachments={setAttachments} readOnly={readOnly} />
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PCRBasicInfoForm