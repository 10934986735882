import classes from './ProjectDetails.module.css';
import { useSelector } from 'react-redux';

const ProjectDetails = () => {    

    const { selectedProjectDetails } = useSelector((state) => state);
    const userId = useSelector(state => state.user.user.userId);

    return (
        <div className={classes.ProjectDetails}>
            <dt>
                <b>Client:</b> {selectedProjectDetails.client}
            </dt>
            <dt>
                <b>Project:</b> {selectedProjectDetails.projectTitle}
            </dt>
            <dt>
                <b>User:</b> {userId}
            </dt>            
        </div>
    )    
}

export default ProjectDetails;