import axios from '../../../setup/axios'

class FTService {
    BASE_ROUTE = "/flashtrend";

    saveFT(ftData){
        return this.post("", ftData);
    }

    updateFlashTrend(ftData){
        return this.post("/update", ftData);
    }

    getFlashTredById(id){
        return this.get(`/${id}`)
    }
    getFlashTrendByProjectId(projectId) {
        return this.get(`${projectId}/project`)
    }

    get(route) {
        return axios.get(`${this.BASE_ROUTE}/${route}`)
    }

    post(route, data) {
        return axios.post(`${this.BASE_ROUTE}/${route}`, data);
    }

    put(route, data) {
        return axios.put(`${this.BASE_ROUTE}/${route}`, data);
    }
}

export default new FTService()