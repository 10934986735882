import { Button, Row, Col } from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DropDownEditor from './cell-editors/DropDownEditor';
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AsyncDropDownEditor from './cell-editors/AsyncDropDownEditor';
import NumericEditor from "../../../../utilities/ag-grid-utilities/cell-editors/NumericEditor"
import { defaultGridOptions, getRowStyle, isFooterAgg } from "../../../../utilities/ag-grid-utilities/AgGridUtilities"
import CheckBox from '../../../../UI/CheckBox';

const cellRendererDropdown = (params) => {
    return <div><span className="float-left">{params.value ?? ""}</span> <FontAwesomeIcon icon={['fas', 'chevron-down']} className="float-right font-weight-bold mt-1 text-primary" /></div>
}

const getNewRowData = (rowData) => {
    return {
        ...rowData,
        "laborHrs": 0,
        "quantity": 0,
        state: "NEW",
        id: Math.floor(Math.random() * 10000)
    }
}

const DisciplineImpactGrid = ({ pactNumber, areas, disciplineImpactState, disciplineImpactDispatch, projectId, readOnly, disciplineImpactValue }) => {
    const pactNumberRef = useRef(pactNumber)
    const gridRef = useRef(null)
    const [ enableDeleteOption, setEnableDeleteOption ] = useState(false)
    const [ selectedActivities, setSelectedActivities ] = useState([])

    useEffect(() => {
        pactNumberRef.current = pactNumber
    }, [pactNumber])

    useEffect(() => {
        setEnableDeleteOption(selectedActivities.length > 0)
    }, [setEnableDeleteOption, selectedActivities])

    const cellRendererCheckbox = (params) => {
        const isChecked = selectedActivities.includes(params.data.id.toString())
        return <CheckBox name={params.data.id} onChange={onRowSelected} isChecked={isChecked}/>
    }

    const addItem = () => {
        const newItem = [{
            "activityCode": "",
            "activityDescription": "",
            "area": ""
        }];
        disciplineImpactDispatch({ type: "ADD", payload: getNewRowData(...newItem) })
    };


    const valueSetter = (params) => {
        const isTextField = ["area", "activityCode", "activityDescription"].includes(params.colDef.field)
        const isNewValue = isTextField ? params.oldValue !== params.newValue : Number(params.oldValue) !== Number(params.newValue)
        if (isNewValue) {
            const value = isTextField ? params.newValue : Number(params.newValue);
            const updatedRow = { ...params.data, [params.column.colId]: value, edited: true }
            disciplineImpactDispatch({ type: 'UPDATE', payload: updatedRow })
        }
        return isNewValue
    }

    const deleteItem = () => {
        setEnableDeleteOption(false)
        disciplineImpactDispatch({ type: "DELETE", payload: selectedActivities })
    };

    const onRowSelected = (event) => {
        const checked = event.target.checked;
        if (checked) {
            setSelectedActivities([...selectedActivities, event.target.name]);
          } else {
            setSelectedActivities(selectedActivities.filter((id) => id !== event.target.name));
          }
    };

    return (
        <div className="pb-5">
            <Row style={{ marginTop: "20px", height: "25vh", maxWidth: "1260px" }}>
                <Col fluid={"true"}>
                    <div className="d-flex flex-row-reverse">
                        <Button color="primary" hidden={readOnly} size="sm"  disabled={!enableDeleteOption || disciplineImpactValue !== 'Y'} className="m-1" onClick={deleteItem}>Delete Impact</Button>
                        <Button color="primary" hidden={readOnly} size="sm" disabled={disciplineImpactValue !== 'Y'} className="m-1" onClick={addItem}>Add Impact Area</Button>
                    </div>
                    <AgGridReact
                        ref={gridRef}
                        getRowStyle={getRowStyle}
                        gridOptions={{ ...defaultGridOptions }}
                        rowData={disciplineImpactState}
                        getRowNodeId={disciplineImpactState => disciplineImpactState.id}
                        immutableData={true}
                        domLayout="normal"
                        className="ag-theme-bootstrap"
                        singleClickEdit={true}
                        groupIncludeTotalFooter={true}
                        rowSelection="multiple"
                        frameworkComponents={{
                            numericCellEditor: NumericEditor,
                            asyncDropdownCellEditor: AsyncDropDownEditor,
                            dropdownCellEditor: DropDownEditor
                        }}>
                        <AgGridColumn headerName="Delete" field="delete" headerClass="header-grid" maxWidth={100} cellRendererFramework={(params) => isFooterAgg(params) ? null : cellRendererCheckbox(params)}/>
                        <AgGridColumn headerName="Pact" field="pact" headerClass="header-grid" maxWidth={70} valueGetter={pactNumber}
                            cellRenderer={(params) => isFooterAgg(params) ? "Total" : params.value} />
                        <AgGridColumn headerName="Area" field="area" headerClass="header-grid" editable={!readOnly} maxWidth={200}
                            valueGetter={params => (params.data && params.data.area) ? params.data.area : "Select Area Code"}
                            cellEditor="dropdownCellEditor" cellEditorParams={{ options: areas.map(a => a.taskArea) }}
                            cellRendererFramework={params => isFooterAgg(params) ? null : cellRendererDropdown(params)}
                            valueSetter={valueSetter} />
                        <AgGridColumn headerName="Task Code" field="activityCode" headerClass="header-grid" editable={!readOnly} maxWidth={200}
                            valueGetter={params => (params.data && params.data.activityCode) ? params.data.activityCode : "Select Activity"}
                            cellEditor="asyncDropdownCellEditor" cellEditorParams={{ projectId, pactNumber }}
                            cellRendererFramework={params => isFooterAgg(params) ? null : cellRendererDropdown(params)}
                            valueSetter={valueSetter} />
                        <AgGridColumn headerName="Activity" field="activityDescription" headerClass="header-grid" maxWidth={600} />
                        <AgGridColumn headerName="Quantity" field="quantity" headerClass="header-grid" editable={!readOnly} type="numericColumn" cellEditor="numericCellEditor" maxWidth={100}
                            valueSetter={valueSetter} />
                        <AgGridColumn headerName="MHRS." field="laborHrs" headerClass="header-grid" editable={!readOnly} type="numericColumn" cellEditor="numericCellEditor" maxWidth={90} aggFunc="sum"
                            valueSetter={valueSetter} />
                    </AgGridReact>
                </Col>
            </Row>
        </div>
    )
}

export default DisciplineImpactGrid
