// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FAButton_fabutton__1aXh9 {\n    width: 225px;\n}", "",{"version":3,"sources":["webpack://src/components/FAButton/FAButton.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB","sourcesContent":[".fabutton {\n    width: 225px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fabutton": "FAButton_fabutton__1aXh9"
};
export default ___CSS_LOADER_EXPORT___;
