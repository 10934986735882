import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container
} from 'reactstrap';
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import { CollapsibleSection } from '../../../components/CollapsibleSection/CollapsibleSection';
import { getEditDetailsRoute } from '../../../setup/routes';
import SmallLinkButton from '../../../UI/SmallLinkButton';
import PCRSummary from '../pcr/summary/PCRSummary';
import {useAuthorized} from '../../auth/Authorized/useAuthorized';
import {CLIENT} from '../logs-roles/Roles';

const EditDetailsLink = ({ id, showEditDetailsLink }) => {
    let history = useHistory();
    const onClick = (e) => {
        e.preventDefault();
        history.push(getEditDetailsRoute(id));
    }
    if (showEditDetailsLink) {
        return <SmallLinkButton color="link" onClick={onClick}>Edit Details</SmallLinkButton>
    } else {
        return null
    }
};

export const Summary = ({ id, pcrSummaryData, fcoSummaryData, isLumpSum = false, showEditDetailsLink = true }) => {
    const [isOpen, setIsOpen] = useState(true)
    const {isNotAuthorized} = useAuthorized()
    const hideEditDetails = isNotAuthorized([CLIENT])
    return (
        <Container>
            <CollapsibleSection title="Summary" action={!hideEditDetails ? <EditDetailsLink id={id} showEditDetailsLink={showEditDetailsLink} /> : null} isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} titleClassName="h5" >
                {fcoSummaryData && <PCRSummary pcrSummaryData={fcoSummaryData} isLumpSum={isLumpSum}/>}
                {pcrSummaryData && <PCRSummary pcrSummaryData={pcrSummaryData} isLumpSum={isLumpSum}/>}
            </CollapsibleSection>
        </Container>
    )
}
