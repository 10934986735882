import { Spinner as ReactstrapSpinner } from 'reactstrap';

const Spinner = () => {
    return (
        <div className="mt-2 text-center justify-content-around">
            <ReactstrapSpinner color="primary" size="" />
        </div>
    );
};

export default Spinner;
