import React from 'react';
import Icon from '../../components/Icon/Icon';
import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';

import './CollapsibleContainer.css';

const CollapsibleContainer = ({ children, isOpen, toggle }) => {

    return (

        <Container>
            <Row className="pb-3">
                <Col sm="12" lg="12" className="border-details">
                    <h5 className="mr-auto">Details</h5>
                    <span className="mr-3">Legends:</span>
                    <div className="rateSquare discipline mr-1"></div>
                    <span className="mr-3">Discipline Impact</span>
                    <div className="rateSquare override mr-1"></div>
                    <span className="mr-3">Override</span>
                    <Button className="d-flex" color="link" onClick={toggle} >
                        <span className="mr-1">{isOpen ? "Collapse All" : "Expand All"}</span>
                        <Icon icon={isOpen ? "chevron-up" : "chevron-down"} color="#008CBA" />
                    </Button>
                </Col>
            </Row>
            {children}
        </Container>
    );

}

export default CollapsibleContainer