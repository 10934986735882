class LocalStorageService {

    // getLoggedInUserId() {
    //     var req = new XMLHttpRequest();
    //     //LOCALHOST:
    //     const hostname = window && window.location && window.location.hostname;
    //     if (hostname.substr('localhost')) {
    //         return localStorage.getItem('loginId') || 'avalose';
    //     }
    //
    //     req.open("GET", document.location, false);
    //     req.send(null);
    //     return req.getResponseHeader("remote_user");
    // }

    getProjectId() {
        return localStorage.getItem('projectId') || "999979";
    }

    getJobNo() {
        return localStorage.getItem('jobNo') || 'AAA';
    }

    getProjects() {
        //TODO - Remove the mock least once we have the projects available
        let projectList = [];
        const hostname = window && window.location && window.location.hostname;

        if (hostname.indexOf('cms.ipims2.sbec.com') >= 0) {
            projectList = [
                {
                    "jobNo": "C1753",
                    "projectTitle": "PRATTVILLE PHASE 3 PROJECT",
                    "client": "JAMES HARDIE BUILDING PRODUCTS",
                    "projectId": 138332
                },
                {
                    "jobNo": "E6127",
                    "projectTitle": "OM2",
                    "client": "ORIGIN MATERIALS",
                    "projectId": 144367
                },
                {
                    "jobNo": "S0113",
                    "projectTitle": "PEACHTREE PROJECT",
                    "client": "PLUG POWER INC",
                    "projectId": 123123
                },
                {
                    "jobNo": "Z1478",
                    "projectTitle": "EXPEDITE TESTING - FRAC 7 8",
                    "client": "S&B Project",
                    "projectId": 999979
                },
                {
                "jobNo": "E6365",
                "projectTitle": "PHOENIX HYDROGEN HUB PHASE 1",
                "client": "NIKOLA CORPORATION"
            },
            ];
            
        } else {
            projectList = [
                /*{
                    "jobNo": "C1753",
                    "projectTitle": "PRATTVILLE PHASE 3 PROJECT",
                    "client": "JAMES HARDIE BUILDING PRODUCTS",
                    "projectId": 138332
                },*/
                {
                    "jobNo": "Z1478",
                    "projectTitle": "EXPEDITE TESTING - FRAC 7 8",
                    "client": "S&B Project",
                    "projectId": 999979
                },
                {
                    "jobNo": "C1635",
                    "projectTitle": "LYB  POTBA",
                    "client": "LYONDELLBASELL",
                    "projectId": 95050
                },
                {
                    "jobNo": "E2896",
                    "projectTitle": "FEASIBILITY EVAL TELGE CAMPUS",
                    "client": "SIEMENS ENERGY INC",
                    "projectId": 117888
                },
                {
                    "jobNo": "A6682",
                    "projectTitle": "CAL E&I TECHNICIAN SUPPORT",
                    "client": "CALUMET SPECIALTY PRODUCTS",
                    "projectId": 107620
                },
                {
                    "jobNo": "A9999",
                    "projectTitle": "FBD MLU PROC ENG OH",
                    "client": "FORD, BACON AND DAVIS",
                    "projectId": 1786
                },
                {
                    "jobNo": "M3111",
                    "projectTitle": "CHOCOLATE BAYOU PDH PIPELINES",
                    "client": "PETROLOGISTICS LLC",
                    "projectId": 100289
                },            
                {
                    "jobNo": "U7007",
                    "projectTitle": "CLEANINSPECTREPAIR TANKS 48-54",
                    "client": "CAPE-BURNS& MCDONNELL JV - C00224",
                    "projectId": 114916
                }, 
                {
                    "jobNo": "C1691",
                    "projectTitle": "ENTERPRISE PDH II",
                    "client": "ENTERPRISE",
                    "projectId": 100461
                },
                {
                "jobNo": "E6365",
                "projectTitle": "PHOENIX HYDROGEN HUB PHASE 1",
                "client": "NIKOLA CORPORATION"
            },
            ];
        }
        
        return JSON.parse(localStorage.getItem('projects') || JSON.stringify(projectList));
    }
    
}

export default new LocalStorageService()
