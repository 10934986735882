import {Fragment} from 'react'
import './App.css';
import routes from './setup/routes';
import Layout from './components/Layout/Layout'
import Loading from './components/Loading/Loading'
import {setSelectedProject} from './components/ProjectDropdown/redux/projectDetailSlice'
import {setProjectId} from './components/ProjectDropdown/redux/projectIdSlice'
import {useDispatch} from 'react-redux'
import HomePage from './features/home/HomePage';
import {
    TEMPLATE_USERS,
    LOG_USERS,
    CLIENT,
    FCO_USERS,
    PROJECT_CONTROLS,
    PROJECT_MANAGER, PROJECT_ENGINEER, CONSTRUCTION_MANAGER, PROJECT_COORDINATOR
} from './features/logs/logs-roles/Roles';
import OktaWrapper from './features/auth/OktaWrapper';
import {BrowserRouter, Redirect, Route} from 'react-router-dom';
import {LoginCallback} from '@okta/okta-react';
import LogsPage from './features/logs/LogsPage';
import TemplatesPage from './features/templates/TemplatesPage';
import FTPage from './features/ft/FTPage';
import ReportsPage from './features/reports/ReportsPage';
import FourOFour from './components/Common/FourOFour';
import {Switch} from 'react-router';
import AuthorizedRoute from './features/auth/Authorized/AuthorizedRoute';
import UpdatePCI from './features/logs/pci/update-pci/UpdatePCI';
import CreatePCI from './features/logs/pci/create-pci/CreatePCI';
import UpdatePCR from './features/logs/pcr/update-pcr/UpdatePCR';
import CreatePCR from './features/logs/pcr/create-pcr/CreatePCR';
import UpdateFCO from './features/logs/fco/update-fco/UpdateFCO';
import CreateFCO from './features/logs/fco/create-fco/CreateFCO';
import DisciplineImpact from './features/logs/pcr/disciplines/DisciplineImpact';
import DetailsPage from './features/logs/details/DetailsPage';
import ApprovePCIByPE from './features/logs/pci/approve-pci/by-pe/ApprovePCIByPE';
import ApprovePCIByClient from './features/logs/pci/approve-pci/by-client/ApprovePCIByClient';
import ApproveFCOByClient from './features/logs/fco/approve-fco/by-client/ApproveFCOByClient';
import ApprovePCRByClient from './features/logs/pcr/approve-pcr/by-client/ApprovePCRByClient';
import ApproveFCOByCM from './features/logs/fco/approve-fco/by-cm/ApproveFCOByCM';
import ApprovePCIByPM from './features/logs/pci/approve-pci/by-pm/ApprovePCIByPM';
import ApprovePCRByPE from './features/logs/pcr/approve-pcr/by-pe/ApprovePCRByPE';
import ApprovePCRByPM from './features/logs/pcr/approve-pcr/by-pm/ApprovePCRByPM';
import Log from './features/logs/list-logs/Log';

export const ROUTES = [
    {title: 'Home', path: routes.HomeRoute, renderer: HomePage},
    {
        title: 'Templates',
        path: routes.TemplatesRoute,
        renderer: () => <TemplatesPage/>,
        auth_roles: [...TEMPLATE_USERS]
    },
    {title: 'Log', path: routes.LogRoute, renderer: () => <LogsPage/>, auth_roles: [...LOG_USERS]},
    {title: 'Flash Trends', path: routes.FTRoute, renderer: () => <FTPage/>, exclude: [CLIENT]},
    {title: 'Reports', path: routes.ReportsRoute, renderer: () => <ReportsPage/>, exclude: [CLIENT]}
]

function App() {
    const dispatch = useDispatch()

    dispatch(setSelectedProject(JSON.parse(localStorage.getItem('selectedProject'))))
    dispatch(setProjectId(localStorage.getItem('projectId')))

    return (
        <Fragment>
            <BrowserRouter>
                <OktaWrapper>
                    <Layout>
                        <Switch>
                            <Route path="/callback"
                                   render={(props) => <LoginCallback {...props} loadingElement={<Loading/>}/>}/>
                            <AuthorizedRoute path={routes.ApprovePCIByClientRoute} component={ApprovePCIByClient} roles={[PROJECT_MANAGER, PROJECT_ENGINEER, PROJECT_CONTROLS, CLIENT]}></AuthorizedRoute>
                            <AuthorizedRoute path={routes.ApproveFCOByClientRoute} component={ApproveFCOByClient} roles={[CONSTRUCTION_MANAGER, PROJECT_COORDINATOR, PROJECT_MANAGER, PROJECT_ENGINEER, PROJECT_CONTROLS, CLIENT]}></AuthorizedRoute>
                            <AuthorizedRoute path={routes.ApprovePCRByClientRoute} component={ApprovePCRByClient} roles={[PROJECT_MANAGER, PROJECT_CONTROLS, PROJECT_ENGINEER, CLIENT]}></AuthorizedRoute>
                            <AuthorizedRoute path={routes.TemplatesRoute} component={TemplatesPage} roles={[...TEMPLATE_USERS]}/>
                            <AuthorizedRoute exclude={[CLIENT]} path={routes.UpdatePCIRoute} component={UpdatePCI}></AuthorizedRoute>
                            <AuthorizedRoute exclude={[CLIENT]} path={routes.CreatePCIRoute} component={CreatePCI}></AuthorizedRoute>
                            <AuthorizedRoute exclude={[CLIENT]} path={routes.UpdatePCRRoute} component={UpdatePCR}></AuthorizedRoute>
                            <AuthorizedRoute exclude={[CLIENT]} path={routes.CreatePCRRoute} component={CreatePCR}></AuthorizedRoute>
                            <AuthorizedRoute roles={[...FCO_USERS]} path={routes.UpdateFCORoute} component={UpdateFCO}></AuthorizedRoute>
                            <AuthorizedRoute roles={[...FCO_USERS]} path={routes.CreateFCORoute} component={CreateFCO}></AuthorizedRoute>
                            <AuthorizedRoute exclude={[CLIENT]} path={routes.DisciplinesPCRRoute} component={DisciplineImpact}></AuthorizedRoute>
                            <AuthorizedRoute exclude={[CLIENT]} path={routes.EditDetailsRoute} component={DetailsPage}></AuthorizedRoute>
                            <AuthorizedRoute path={routes.ApprovePCIByPERoute} component={ApprovePCIByPE} roles={[PROJECT_CONTROLS, PROJECT_MANAGER, PROJECT_ENGINEER]}></AuthorizedRoute>
                            <AuthorizedRoute path={routes.ApproveFCOByCMRoute} component={ApproveFCOByCM} roles={[PROJECT_COORDINATOR, PROJECT_MANAGER, PROJECT_ENGINEER, PROJECT_CONTROLS, CONSTRUCTION_MANAGER]}></AuthorizedRoute>
                            <AuthorizedRoute path={routes.ApprovePCIByPMRoute} component={ApprovePCIByPM} roles={[PROJECT_MANAGER, PROJECT_CONTROLS, PROJECT_ENGINEER]}></AuthorizedRoute>
                            <AuthorizedRoute path={routes.ApprovePCRByPERoute} component={ApprovePCRByPE} roles={[PROJECT_MANAGER, PROJECT_CONTROLS, PROJECT_ENGINEER]}></AuthorizedRoute>
                            <AuthorizedRoute path={routes.ApprovePCRByPMRoute} component={ApprovePCRByPM} roles={[PROJECT_MANAGER, PROJECT_CONTROLS, PROJECT_ENGINEER]}></AuthorizedRoute>
                            <AuthorizedRoute exact path={routes.LogRoute} component={Log} exclude={[CLIENT]}></AuthorizedRoute>
                            <AuthorizedRoute path={routes.FTRoute} component={FTPage} exclude={[CLIENT]}/>
                            <AuthorizedRoute path={routes.ReportsRoute} component={ReportsPage} exclude={[CLIENT]}/>
                            <Redirect exact path={'/'} to={'/home'}/>
                            <AuthorizedRoute exact path={routes.HomeRoute} component={HomePage}/>
                            <Route path={'*'} render={FourOFour}/>
                        </Switch>
                    </Layout>
                </OktaWrapper>
            </BrowserRouter>
        </Fragment>
    );
}

export default App;
