import { Container } from "reactstrap"
import { useSelector } from 'react-redux';
import ReportService from "./api/reportService"
import { useReducer, useEffect, useState } from "react"
import DownloadList from "./download-list/DownloadList"
import CheckboxSection from "./checkbox-section/CheckboxSection"
import DropdownSection from "./dropdown-section/DropdownSection"
// import RadioButtonSection from "./radiobutton-section/RadioButtonSection"
import { checkboxReducer, checkBoxInitialState } from "./checkbox-section/CheckboxReducerProps"
import { dropdownReducer, dropdownInitialState } from "./dropdown-section/DropdownReducerProps"
import { downloadListReducer, downloadListInitialState } from "./download-list/DownloadListReducerProps"
import  {DISCIPLINES_BY_PROJECT, SBI_DISCIPLINES } from '../logs/pcr/disciplines/constants'
import LogService from "../logs/api/logService"
import Spinner from '../../components/Spinner/Spinner'


const ReportsPage = () => {
    const { projectId } = useSelector((state) => state)
    const [contractType, setContractType] = useState('C')
    const [checkboxState, checkboxDispatch] = useReducer(checkboxReducer, checkBoxInitialState)
    const [dropdownState, dropdownDispatch] = useReducer(dropdownReducer, dropdownInitialState)
    const [downloadListState, downloadListDispatch] = useReducer(downloadListReducer, downloadListInitialState)
    const { selectedProjectDetails } = useSelector((state) => state);
    const [disciplinesByProject, setDisciplinesByProject] = useState([])
    const [selectedLogId, setSelectedLogId] = useState(null)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        ReportService.getReportDataByProjectId(projectId.value)
            .then(response => {
                if (response?.data?.data) {
                    const jobNo = selectedProjectDetails.jobNo;
                    const projectInitial = jobNo.substring(0, 1);
                    const disciplinesByProjectInitial = DISCIPLINES_BY_PROJECT.filter(d => d.initial === projectInitial)?.map(di => di.disciplines)?.[0] || []
                    const filteredDisciplines = response.data.data.disciplines.filter(d => disciplinesByProjectInitial.includes(d))
                    setDisciplinesByProject(filteredDisciplines)
                    dropdownDispatch({ payload: { ...response.data.data, disciplines: filteredDisciplines }})
                }
            })
            .catch(error => console.log(error))
    }, [projectId.value, selectedProjectDetails.jobNo])

    useEffect(() => {
        if (selectedLogId) {
            async function getPCRByLogId() {
                const response = await LogService.getPCRByLogId(selectedLogId)
                if (response.data.data) {
                    const { sbiEnable } = response.data.data
                    const  disciplinesByPCR = sbiEnable ? [ ...disciplinesByProject, ...SBI_DISCIPLINES] : disciplinesByProject
                    dropdownDispatch({ type: "disciplinesDD", payload: disciplinesByPCR })

                }
            }
            getPCRByLogId()
        } else {
            dropdownDispatch({ type: "disciplinesDD", payload: disciplinesByProject })
        }
    }, [selectedLogId, disciplinesByProject])

    const getSelectedChangeType = (selectedChangeNumber) => {
        if (selectedChangeNumber !== "-1") {
            return dropdownState.changeNumbers.options.find(c => c.changeNumber === selectedChangeNumber).changeType
        }
        else {
            return selectedChangeNumber
        }
    }

    const GetParamsRequest = (reportFormat) => {
        const selectedChangeNumber = dropdownState.changeNumbers.selected
        const params = {
            contractType,
            reportFormat,
            ftScope: checkboxState.ft,
            projectId: projectId.value,
            pciScope: checkboxState.pci,
            pcrScope: checkboxState.pcr,
            fcoScope: checkboxState.fco,
            varScope: checkboxState.var,
            area: dropdownState.areas.selected,
            changeNumber: selectedChangeNumber,
            showDetails: checkboxState.showDetails,
            template: dropdownState.templates.selected,
            jobGroup: dropdownState.jobGroups.selected,
            areaGroup: dropdownState.areaGroups.selected,
            discipline: dropdownState.disciplines.selected,
            printAttachments: checkboxState.printAttachments,
            changeType: getSelectedChangeType(selectedChangeNumber)
        }

        return Object.keys(params)
            .filter((k) => params[k] !== "-1" && params[k])
            .reduce((a, k) => ({ ...a, [k]: params[k] }), {});
    }

    const onDownload = (reportId, reportType) => {
        const params = GetParamsRequest(reportType)
        setLoading(true)
        ReportService.downloadReport(reportId, params)
            .then((response) => {
                setLoading(false)
                let a = document.createElement("a");
                a.href = `data:application/octet-stream;base64,${response.data.data}`;
                a.setAttribute('download', `${reportId}.${reportType === 'excel' ? 'xlsx' : reportType}`);
                a.click();
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            })
    }

    const onDropdownChange = (type, selectedValue) => {
        if (type === 'changeNumbers') {
            const logId = dropdownState.changeNumbers.options.filter((c) => c.changeNumber === selectedValue && c.changeType === "PCR")?.[0]?.logId || null 
            setSelectedLogId(logId);
        }
        dropdownDispatch({ type, payload: selectedValue })
    }

    return (
        <Container>
            {loading ? (<Spinner />) : ""}
            <CheckboxSection checkboxDispatch={checkboxDispatch} dropdownDispatch={dropdownDispatch} checkboxState={checkboxState} />
            <DropdownSection onDropdownChange={onDropdownChange} dropdownState={dropdownState} downloadListDispatch={downloadListDispatch} />
            {/*<RadioButtonSection setContractType={setContractType} contractType={contractType} />*/}
            <DownloadList downloadListState={downloadListState} onDownload={onDownload} />
        </Container>
    )
}

export default ReportsPage