import axios from 'axios';

const token = "";

const instance = axios.create({
    baseURL: window.location.origin === 'https://cms.ipims2.dev.sbec.com' ? 'https://cms.ipims2.dev.sbec.com/cost/api/' : `${window.location.origin}/cost/api/`
});

instance.defaults.headers.common['Authorization'] = token;

export default instance;
