import React, { useEffect } from 'react'
import { Row, Input, Label } from "reactstrap"

const DropdownSection = ({ dropdownState, onDropdownChange, downloadListDispatch }) => {

    const inputCommonProps = {
        type: "select",
        className: "custom-blue-select col-2 ml-2"
    }

    useEffect(() => {
        downloadListDispatch(
            {
                type: "update-list",
                payload: { templates: dropdownState.templates.selected, disciplines: dropdownState.disciplines.selected }
            }
        )
    }, [dropdownState.templates.selected, dropdownState.disciplines.selected, downloadListDispatch])

    return (
        <>
            <Row className="mt-4 ml-1">
                <Label for="templates" className="pt-2 col-1">Template</Label>
                <Input
                    id="templates"
                    {...inputCommonProps}
                    value={dropdownState.templates.selected}
                    onChange={(e) => onDropdownChange(e.target.id, e.target.value)}>
                    {dropdownState.templates.htmlOptions}
                </Input>
            </Row>

            <Row className="mt-4 ml-1">
                <Label for="areas" className="pt-2 col-1">Area</Label>
                <Input
                    id="areas"
                    {...inputCommonProps}
                    value={dropdownState.areas.selected}
                    required={true}
                    onChange={(e) => onDropdownChange(e.target.id, e.target.value)}>
                    {dropdownState.areas.htmlOptions}
                </Input>

                <Label for="areaGroups" className="pt-2 ml-1 col-1">Area Group</Label>
                <Input
                    id="areaGroups"
                    {...inputCommonProps}
                    value={dropdownState.areaGroups.selected}
                    required={true}
                    onChange={(e) => onDropdownChange(e.target.id, e.target.value)}>
                    {dropdownState.areaGroups.htmlOptions}
                </Input>
            </Row>

            <Row className="mt-4 ml-1">
                <Label for="changeNumbers" className="pt-2 col-1">Change #</Label>
                <Input
                    id="changeNumbers"
                    {...inputCommonProps}
                    value={dropdownState.changeNumbers.selected}
                    required={true}
                    onChange={(e) => onDropdownChange(e.target.id, e.target.value)}>
                    {dropdownState.changeNumbers.htmlOptions}
                </Input>
            </Row>

            <Row className="mt-4 ml-1">
                <Label for="disciplines" className="pt-2 col-1">Discipline</Label>
                <Input
                    id="disciplines"
                    {...inputCommonProps}
                    value={dropdownState.disciplines.selected}
                    required={true}
                    onChange={(e) => onDropdownChange(e.target.id, e.target.value)}>
                    {dropdownState.disciplines.htmlOptions}
                </Input>
            </Row>

            <Row className="mt-4 ml-1">
                <Label for="jobGroups" className="pt-2 col-1">Job Groups</Label>
                <Input
                    id="jobGroups"
                    {...inputCommonProps}
                    value={dropdownState.jobGroups.selected}
                    required={true}
                    onChange={(e) => onDropdownChange(e.target.id, e.target.value)}>
                    {dropdownState.jobGroups.htmlOptions}
                </Input>
            </Row>
        </>
    )
}

export default DropdownSection
