import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  :root {
    --black: #2E2E2E;
    --white: #fff;
    --grey: #efefef;
  }

  body {
    font-size: 1rem;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: var(--black) var(--white);
  }

  body::-webkit-scrollbar-track {
    background: var(--white);
  }

  body::-webkit-scrollbar-thumb {
    background-color: var(--black);
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  img {
    max-width: 100%;
  }

  .ag-theme-balham .ag-cell-inline-editing {
    height: auto !important;
  }

  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 2px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid #D7DBE0;
    background-color: #e7e6e7;
    border-radius: 50%;
  }

  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: #3ED48C;
    border: 2px solid #3ED48C;
  }

  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 19.07px;
  }

  h5 {
    size: 20px;
    line-height: 27.24px;
    font-weight: 500;
  }

  .text-placeholder::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-style: italic;
    font-size: small;
    color: #C2BDC3;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .ag-cell {
    overflow: visible !important;
  }

  .fco-attachments-grid .ag-row-selected {
    background-color: #C3E1FB !important;
  }

  .fco-attachments-grid .ag-labeled.ag-label-align-right.ag-checkbox.ag-input-field {
    padding-right: 10px;

  }

  .override-status {
    background-color: #E8D1D1 !important;
  }

  .editable-cell {
    border-color: #B1C1CE !important;
    border-radius: 6px !important;
    background-color: #ffffff !important;
  }

  .discipline-impact-status {
    background-color: #d9e3f6;
  }

  .right-sortable-header-grid .ag-cell-label-container .ag-header-cell-label {
    justify-content: end;
  }

  .right-header-grid .ag-cell-label-container .ag-header-cell-label {
    flex: inherit !important;
  }

  .dollar-input-group-addon .input-group-text {
    color: #0069D9 !important;
    background: #ffffff;
    font-weight: 600;
  }

  input[type=number].dollar-input-group-addon {
    border-left: #ffffff;
  }

  input[type=text].dollar-input-group-addon {
    border-left: #ffffff;
  }

  .custom-blue-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 8 7'%3e%3cpath fill='%230069D9' d='M1.5 1l-1.5 1.5 4 4 4-4-1.5-1.5-2.5 2.5-2.5-2.5z'/%3e%3c/svg%3e") right 0.85rem center/10px 12px no-repeat;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    appearance: none;
  }

  /* .ag-center-cols-clipper,
  .ag-center-cols-container {
    min-height: 300px !important;
  } */


`;



export default GlobalStyles;