import {
    Container,
    Label,
    Input,
    Row,
    Col
} from "reactstrap";
import { Controller } from "react-hook-form"
import {StyledColumn} from '../../../../components/StyledColumn';
import { ErrorMessage } from '@hookform/error-message';
import { StarIndicator } from "../../../../components/StarIndicator"

const FCODetailInfoForm = ({ control, readOnly = false, errors }) => {
    return (
        <>
            <Container>
                <h5>Detail Information</h5>
                <Row className="d-flex">
                    <Col lg="7">
                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="shortDescription">Short Description<StarIndicator /></Label>
                            </StyledColumn>
                            <Col sm="9" lg="10">
                                <Controller
                                    placeholder="shortDescription"
                                    control={control}
                                    name="shortDescription"
                                    render={({ field }) => <Input disabled={readOnly} bsSize="sm" {...field} data-testid="shortDescription" />}
                                    rules={{ required: "Short Description is required.", maxLength: { message: "Short Description must be no longer than 50 characters.", value: 50 } }}
                                />
                                <ErrorMessage className="text-danger small" errors={errors} name={"shortDescription"} as="p" key={"shortDescription"} />
                            </Col>
                        </Row>

                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="fullDescription">Description<StarIndicator /></Label>
                            </StyledColumn>
                            <Col sm="9" lg="10">
                                <Controller
                                    placeholder="Description"
                                    control={control}
                                    name="fullDescription"
                                    render={({ field }) => <Input disabled={readOnly} type="textarea" {...field} data-testid="fullDescription" />}
                                    rules={{ required: "Description is required.", maxLength: { message: "Description must be no longer than 4000 characters.", value: 4000 } }}
                                />
                                <ErrorMessage className="text-danger small" errors={errors} name={"fullDescription"} as="p" key={"fullDescription"} />
                            </Col>
                        </Row>
                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="resolution">Resolution if identified</Label>
                            </StyledColumn>
                            <Col sm="9" lg="10">
                                <Controller
                                    placeholder="resolution"
                                    control={control}
                                    name="resolution"
                                    render={({ field }) => <Input disabled={readOnly} type="textarea" {...field} data-testid="resolution" />}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="identifier">Identifier</Label>
                            </StyledColumn>
                            <Col sm="9" lg="10">
                                <Controller
                                    placeholder="Identifier"
                                    control={control}
                                    name="identifier"
                                    render={({ field }) => <Input disabled={readOnly} bsSize="sm" {...field} data-testid="identifier" />}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default FCODetailInfoForm