import { createSlice } from '@reduxjs/toolkit'

export const errorSlice = createSlice({
    name: 'error',
    initialState: {
        showError: false,
        errors: []
    },

    reducers: {
        openError: (state, action) => {
            state.showError = true
            state.errors = action.payload
        },
        closeError: (state, action) => {
            state.showError = false
            state.errors = []
        }

    }

})

export const { openError, closeError } = errorSlice.actions
export default errorSlice.reducer