import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import FeatureTitle from '../../../../components/FeatureTitle/FeatureTitle'
import {openSuccess} from '../../../success/redux/successSlice';
import {
    Container,
    Row,
    Col,
    Form
} from 'reactstrap';
import Page from '../../../../UI/Page';
import {LogRoute, getUpdatePCRRoute} from '../../../../setup/routes'
import LogService from '../../api/logService'
import NextCancel from '../../../../components/NextCancel/NextCancel';
import PCRBasicInfoForm from '../pcr-basic-info-form/PCRBasicInfoForm';
import {statuses} from '../../statuses';

const CreatePCR = (params) => {

    const queryParams = new URLSearchParams(params.location.search);
    const isVar = queryParams.get('isVar') || false;
    const {projectId} = useSelector((state) => state)
    const userId = useSelector(state => state.user.user.userId);
    const [statusAndAreas, setStatusAndAreas] = useState(null)
    const [attachments, setAttachments] = useState([])
    const history = useHistory();
    const dispatch = useDispatch()
    const [saveInProgress, setSaveInProgress] = useState(false)

    const {getValues, formState: {errors}, control, handleSubmit} = useForm({
        defaultValues: {
            status: statuses.PCR_CREATED,
            title: '',
            originator: userId,
            incorporatedInSchedule: false,
            contractType: 'C',
            scheduleImpact: false,
            comments: '',
            variance: isVar
        },
        mode: 'onChange'
    });

    useEffect(() => {
        async function getPcrStatusAndAreas() {
            const response = await LogService.getPCRStatusesAndAreas(projectId.value)
            setStatusAndAreas(response.data.data)
        }

        getPcrStatusAndAreas()
    }, [projectId])

    const onSave = handleSubmit(async (data) => {
        const formData = new FormData()
        const command = formatPCRRequestObject(data)

        attachments.forEach((file) => {
            if (file.name) {
                formData.append('files', file, file.name)
            } else {
                command.attachments.push({
                    'fileName': file.fileName,
                    'status': file.status,
                    'fileDownloadUri': file.fileDownloadUri
                })
            }
        })

        formData.append('command', new Blob([JSON.stringify(command)], {type: 'application/json'}))
        try {
            setSaveInProgress(true)
            const response = await LogService.savePCR(formData)
            if (response.data.data) {
                const redirectUrl = getUpdatePCRRoute(response.data.data.logId)
                dispatch(openSuccess('The PCR was Saved.'))
                history.push(redirectUrl);
            }
            setSaveInProgress(false)
        } catch (e) {
            setSaveInProgress(false)
            console.log(e)
        }
    })

    const formatPCRRequestObject = ({
                                        status,
                                        contractType,
                                        scheduleImpact,
                                        area,
                                        title,
                                        description,
                                        scheduleImpactDescription,
                                        incorporatedInSchedule,
                                        comments,
                                        template,
                                        variance,
                                        sbiEnable
                                    }) => (
        {
            userId,
            projectId: projectId.value,
            status,
            area,
            title,
            description,
            contractType,
            scheduleImpact: scheduleImpact,
            scheduleImpactDescription,
            incorporatedInSchedule: incorporatedInSchedule,
            templateId: template,
            attachments: [],
            comments,
            variance,
            sbiEnable
        }
    )

    return (
        <Page>
            <Container className="pb-3">
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title="Project Change: New"/>
                            <hr/>
                        </Container>
                    </Col>
                </Row>
                <Form className="pb-5" onSubmit={onSave}>
                    <Row>
                        {statusAndAreas && <PCRBasicInfoForm
                            control={control} errors={errors} statusAndAreas={statusAndAreas}
                            getValues={getValues} setAttachments={setAttachments} attachments={attachments}/>}
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <NextCancel cancelRoute={LogRoute} disabled={!statusAndAreas || saveInProgress} saveText="Save"
                                        cancelConfirmText="Are you sure you want to close?"/>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Page>
    )
}

export default CreatePCR