import { PROJECT_CONTROLS } from '../../logs/logs-roles/Roles';

const REPORT_TYPE = {
    TEMPLATE: "template",
    DISCIPLINE: "disciplines",
    OTHER: "other"
}

export const downloadListInitialState = {
    individualChange: { description: "Data Report for individual change (FCO, PCI, PCR, VAR)", pdf: true, excel: false, type: REPORT_TYPE.OTHER },
    disciplineImpactChecklist: { description: "Discipline Impact Checklist", pdf: true, excel: false, type: REPORT_TYPE.DISCIPLINE },
    missingDisciplineImpact: { description: "Missing Discipline Impact", pdf: true, excel: false, type: REPORT_TYPE.DISCIPLINE },
    changeLogAll: { description: "Change Log Report (includes all data tables)", pdf: true, excel: true, type: REPORT_TYPE.OTHER },
    changeLogByPACTOrACTCDE: { description: "Change Log Report by Activity Code or PACT", pdf: false, excel: true, type: REPORT_TYPE.DISCIPLINE },
    flashTrend: { description: "Flash Trend Report", pdf: true, excel: true, type: REPORT_TYPE.OTHER },
    fcoMgmt: { description: "FCO Cause Summary Report for Mgmt.", pdf: true, excel: true, type: REPORT_TYPE.OTHER },
    fcoMaster: { description: "Master FCO Cause List", pdf: true, excel: false, type: REPORT_TYPE.OTHER },
    masterTemplatesDetail: { description: "Master Templates Detail Report", pdf: true, excel: false, type: REPORT_TYPE.TEMPLATE, roles: [PROJECT_CONTROLS]}
}

export const downloadListReducer = (state, action) => {
    switch (action.type) {
        case "update-list":
            const { templates, disciplines } = action.payload
            if (templates !== "-1") {
                let keysTemplate = Object.keys(state)
                let templateReports = keysTemplate.filter(k => downloadListInitialState[k].type === REPORT_TYPE.TEMPLATE).map(report => ({ [report]: { ...downloadListInitialState[report] } })).reduce((a, b) => ({ ...a, ...b }), {})
                let otherReportsTemplate = keysTemplate.filter(k => state[k].type !== REPORT_TYPE.TEMPLATE)
                let otherReportsTemplateUpdated = otherReportsTemplate.map(report => ({ [report]: { ...state[report], pdf: false, excel: false } })).reduce((a, b) => ({ ...a, ...b }), {})
                return { ...otherReportsTemplateUpdated, ...templateReports }
            }

            if (disciplines !== "-1") {
                let keysDisciplines = Object.keys(state)
                let disciplinesReports = keysDisciplines.filter(k => downloadListInitialState[k].type === REPORT_TYPE.DISCIPLINE).map(report => ({ [report]: { ...downloadListInitialState[report] } })).reduce((a, b) => ({ ...a, ...b }), {})
                let otherReportsDisciplines = keysDisciplines.filter(k => state[k].type !== REPORT_TYPE.DISCIPLINE)
                let otherReportsDisciplinesUpdated = otherReportsDisciplines.map(report => ({ [report]: { ...state[report], pdf: false, excel: false } })).reduce((a, b) => ({ ...a, ...b }), {})
                return { ...otherReportsDisciplinesUpdated, ...disciplinesReports }
            }

            return { ...downloadListInitialState }
        default: return state

    }

}