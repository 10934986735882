export const checkBoxInitialState = {
    ft: false,
    all: false,
    printAttachments: false,
    showDetails: false,
    pci: false,
    pcr: false,
    fco: false,
    var: false
}

const isAll = (state) => {
    return state.pci && state.pcr && state.fco && state.ft;
}

const isShowDetails = (state) => {
    return state.showDetails && (state.pci || state.pcr || state.fco || state.ft);
}

export const checkboxReducer = (state, action) => {
    let changedState = {
        ...state
    };

    switch (action.type) {
        case "flash-trends":
            changedState = { ...changedState, ft: !changedState.ft }
            break;
        case "print-attachments":
            changedState = { ...changedState, printAttachments: !changedState.printAttachments }
            break;
        case "show-details":
            changedState = { ...changedState, showDetails: !changedState.showDetails }
            break;
        case "pci":
            changedState = { ...changedState, pci: !changedState.pci }
            break;
        case "pcr":
            changedState = { ...changedState, pcr: !changedState.pcr }
            break;
        case "fco":
            changedState = { ...changedState, fco: !changedState.fco }
            break;
        case "var":
            changedState = { ...changedState, var: !changedState.var }
            break;
        case "all":
            const newState = !changedState.all
            changedState = {
                ...changedState,
                all: newState,
                ft: newState,
                pci: newState,
                pcr: newState,
                fco: newState,
                var: newState
            }
            break;
        default: break;
    }
    return {
        ...changedState,
        showDetails: isShowDetails(changedState),
        all: isAll(changedState)
    }
}