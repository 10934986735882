import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react'
import { Container, Row, Col } from 'reactstrap';
import React, { useState, useEffect } from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import TemplateRowAction from './TemplateRowAction';
import TemplateService from '../../api/templatesService'
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import { getRowStyle } from '../../../../utilities/ag-grid-utilities/AgGridUtilities';

const TemplateGrid = () => {

    const [templates, setTemplates] = useState([])
    const { projectId } = useSelector((state) => state)

    useEffect(() => {
        retrieveTemplates(projectId.value);
    }, [projectId])

    const retrieveTemplates = (projectId) => {
        TemplateService.getTemplatesByProjectId(projectId)
            .then((response) => {
                (response.data.data ?? []).sort((a, b) => {
                    return a.name > b.name ? 1 : -1;
                })
                setTemplates(response.data.data)
            })
    }

    const agGrid = {
        columnDefs: [
            { headerName: 'Name', field: 'name', sortable: true, flex: 1, filter: true, headerClass: "header-grid" },
            { headerName: 'Description', field: 'description', flex: 2, sortable: true, filter: true, headerClass: "header-grid" },
            { headerName: 'Status', field: 'status', width: 120, sortable: true, filter: true, headerClass: "header-grid" },
            { headerName: 'Action', field: 'id', width: 100, headerClass: "header-grid", cellRendererFramework: (params) => <TemplateRowAction selectedTemplate={params.value} /> }
        ]
    }

    return (
        <Container data-testid="templateGridId" >
            <Row style={{ marginTop: "20px", marginBottom: "60px", minHeight: "600px" }}>
                <Col fluid={"true"}>
                    <AgGridReact
                        getRowStyle={getRowStyle}
                        columnDefs={agGrid.columnDefs}
                        rowData={templates}
                        domLayout="normal"
                        className="ag-theme-bootstrap"
                        suppressMenuHide="true"
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default TemplateGrid