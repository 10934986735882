import React from 'react'
import { Row } from 'reactstrap';
import styled from 'styled-components'
import { currencyFormatter } from '../../../../utilities/ag-grid-utilities/AgGridUtilities'


const ValueSection = styled.div`
    font-weight: 600;
    font-size: larger;
    text-align: center;
`
const TotalCol = styled.div`
    padding: 1.5rem;
`
const LogTotals = ({ totals }) => {
    const { approvedBudgetHours, approvedBudgetDollars, pendingBudgetHours, pendingBudgetDollars } = totals
    return (
        <Row style={{ background: "#EDF2F5" }} className="border mt-2 text-right justify-content-around" data-testid="log-total-footer">
            <TotalCol><h6>{"Approved Budget Hours"}</h6><ValueSection>{currencyFormatter(approvedBudgetHours, '')}</ValueSection></TotalCol>
            <TotalCol><h6>{"Total Approved Budget Dollars"}</h6><ValueSection>{currencyFormatter(approvedBudgetDollars, '$', 0)}</ValueSection></TotalCol>
            <TotalCol><h6>{"Pending Budget Hours"}</h6><ValueSection>{currencyFormatter(pendingBudgetHours, '')}</ValueSection></TotalCol>
            <TotalCol><h6>{"Total Pending Budget Dollars"}</h6><ValueSection>{currencyFormatter(pendingBudgetDollars, '$', 0)}</ValueSection></TotalCol>
        </Row>
    )
}

export default LogTotals
