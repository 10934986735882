import React, { useEffect, useRef } from 'react'
import {
    Label,
    Col
} from "reactstrap";
import { useDragAndDrop } from '../../../components/DragAndDrop/useDragAndDrop';
import { FileList } from '../../../components/FileList';

const updateAttachments = (dragAndDropFiles, attachedFiles) => {
    const dragAndDropFileNames = dragAndDropFiles.map(({ name }) => name).filter(Boolean)
    const filteredAttachedFiles = attachedFiles.filter(af => !dragAndDropFileNames.includes(af.fileName ?? af.name))
    return [...dragAndDropFiles, ...filteredAttachedFiles]
}

export const Attachments = ({ attachments, setAttachments, readOnly = false }) => {

    const { dragAndDropFiles, setDragAndDropFiles, DragAndDrop } = useDragAndDrop()
    const disabled = readOnly || !setAttachments
    const attachmentsRef = useRef([])

    useEffect(() => {
        if (setAttachments && dragAndDropFiles.length > 0) {
            const udpdatedAttachments = updateAttachments(dragAndDropFiles, attachmentsRef.current)
            setAttachments(udpdatedAttachments)
            setDragAndDropFiles([])
        }
    }, [dragAndDropFiles, setAttachments, setDragAndDropFiles])

    useEffect(() => {
        attachmentsRef.current = attachments;
    }, [attachments])

    return (
        <div className="d-flex">
            <Label for="pciDescription">Attachments</Label>
            <Col lg="11">
                <DragAndDrop disabled={disabled} />
                {(dragAndDropFiles.length + attachments.length) > 0 && <FileList
                    disabled={disabled}
                    setDragAndDropFiles={setDragAndDropFiles}
                    attachments={attachments}
                    setAttachments={setAttachments} />}
            </Col>
        </div>
    )
}
