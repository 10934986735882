import Page from '../../../../UI/Page';
import {useHistory} from 'react-router';
import {useForm} from 'react-hook-form';
import LogService from '../../api/logService'
import {History} from '../../history/History';
import {Summary} from '../../summary/Summary';
import PCRActionButtons from '../PCRActionButtons';
import {statuses, PCR_STATES} from '../../statuses';
import {useDispatch, useSelector} from 'react-redux';
import {Container, Row, Col, Form} from 'reactstrap';
import PCRBasicInfoForm from '../pcr-basic-info-form/PCRBasicInfoForm';
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';
import {useEffect, useRef, useState, useMemo, useCallback} from 'react';
import FeatureTitle from '../../../../components/FeatureTitle/FeatureTitle'
import {CAN_REVERT_STATUS, DISCIPLINE_LEADS, PROJECT_COORDINATORS} from '../../logs-roles/Roles'
import {openSuccess} from '../../../success/redux/successSlice';
import {
    LogRoute,
    getDisciplinesPCRRoute,
    getApprovePCRByPMRoute,
    getApprovePCRByPERoute,
    getApprovePCRByClientRoute
} from '../../../../setup/routes'
import Spinner from '../../../../components/Spinner/Spinner'
import {setSbiEnable} from '../redux/pcrSlice';
import useSelectedProject from '../../../../hooks/useSelectedProject'

const readOnlyFalseForProjectCoordinators = [0, 8, 9, 13, 21, 22, 23, 14, 24]
const revertibleStatuses = [26, 17, 47, 48]
const pendingDisciplineImpactState = 13


const formStateInitialState = {
    'isSaveHidden': true,
    'isSubmitHidden': true,
    'isUpdateStateHidden': true,
    'isEditDisciplineImpactHidden': true,
    'submitLabel': 'SUBMIT PCR',
    'readOnly': true,
    'canRevertStatus': false
}

const UpdatePCR = ({match}) => {

    const logId = match.params.id
    const pcrId = useRef()
    const history = useHistory();
    const dispatch = useDispatch()
    const {isAuthorized} = useAuthorized()
    const [pcrData, setPcrData] = useState(null)
    const [formSaved, setFormSaved] = useState(0)
    const [attachments, setAttachments] = useState([])
    const [summaryData, setSummaryData] = useState(null)
    const [changeNumber, setChangeNumber] = useState(null)
    const [statusAndAreas, setStatusAndAreas] = useState(null)
    const [formState, setFormState] = useState(formStateInitialState)
    const [pcLogStatusHistories, setPcLogStatusHistories] = useState(null)
    const {projectId} = useSelector((state) => state)
    const userId = useSelector(state => state.user.user.userId);
    const isProjectCoodinator = useMemo(() => isAuthorized([...PROJECT_COORDINATORS]), [isAuthorized])
    const isDisciplineLead = useMemo(() => isAuthorized([...DISCIPLINE_LEADS]), [isAuthorized])
    const canRevertStatus = useMemo(() => isAuthorized([...CAN_REVERT_STATUS]), [isAuthorized])
    const [approvalRoute, setApprovalRoute] = useState()
    const [saveInProgress, setSaveInProgress] = useState(false)
    const [loading, setLoading] = useState(true)
    const [setSelectedProjectDropdown] = useSelectedProject();
    const [selectedProjectId, setSelectedProjectId] = useState(null)

    const {setValue, getValues, formState: {errors, isValid, isDirty}, control, handleSubmit, reset} = useForm({
        defaultValues: {
            status: '0',
            title: '',
            originator: '',
            scheduleImpact: false,
            incorporatedInSchedule: false,
            describe: '',
            comments: '',
            variance: false,
            scheduleHours: '',
            sbiEnable: false
        },
        mode: 'onChange'
    });

    const updateFormState = useCallback((readOnly, isEditDisciplineImpactHidden, isSubmitHidden, isSaveHidden, isUpdateStateHidden, canRevertStatus, submitLabel) => {
        let newFormState = formState
        newFormState.readOnly = readOnly;
        newFormState.isEditDisciplineImpactHidden = isEditDisciplineImpactHidden;
        newFormState.isSubmitHidden = isSubmitHidden;
        newFormState.isSaveHidden = isSaveHidden;
        newFormState.isUpdateStateHidden = isUpdateStateHidden;
        newFormState.submitLabel = submitLabel ?? formState.submitLabel;
        newFormState.canRevertStatus = canRevertStatus;
        setFormState(newFormState)
    }, [formState])

    const handleFormState = useCallback((pcrStatus) => {

        const isUpdateStateHidden = pcrStatus === PCR_STATES.PENDING_ESTIMATING_REVIEW || pcrStatus >= PCR_STATES.PENDING_PROJECT_MANAGER_REVIEW
        if (isProjectCoodinator) {
            if (readOnlyFalseForProjectCoordinators.some(s => s === pcrStatus)) {
                updateFormState(false, false, false, false, isUpdateStateHidden, false)
            } else {
                const userCanRevertStatus = canRevertStatus && revertibleStatuses.some(s => s === pcrStatus)
                updateFormState(true, true, true, true, isUpdateStateHidden, userCanRevertStatus)
            }
        } else if (isDisciplineLead) {
            if (pendingDisciplineImpactState === pcrStatus) {
                updateFormState(true, false, true, true, isUpdateStateHidden, 'SUBMIT TO DISCIPLINE LEADS')
            }
        }

    }, [isDisciplineLead, isProjectCoodinator, updateFormState, canRevertStatus])

    useEffect(() => {
        async function getPCRByLogId() {
            const response = await LogService.getPCRByLogId(logId)
            if (response.data.data) {
                const {
                    areas,
                    statuses,
                    templates,
                    log: {changeNumber, status},
                    pcLogStatusHistories,
                    attachedFiles,
                    id,
                    summary,
                    projectId
                } = response.data.data

                statuses.sort((a, b) => {
                    return b.id - a.id;
                })
                setLoading(false)
                setChangeNumber(changeNumber)
                setPcLogStatusHistories(pcLogStatusHistories)
                setAttachments(attachedFiles ?? [])
                setStatusAndAreas({areas: areas ?? [], statuses, templates})
                setPcrData(response.data.data)
                handleFormState(status)
                setSummaryData(summary)
                determineApprovalRoute(response.data.data)
                setSelectedProjectId(projectId)
                pcrId.current = id
            }
        }

        getPCRByLogId()
    }, [logId, formSaved, setAttachments, handleFormState])

    useEffect(() => {
        setSelectedProjectDropdown(selectedProjectId)
    }, [selectedProjectId, setSelectedProjectDropdown])

    const determineApprovalRoute = (data) => {
        let route = undefined;
        switch (data.log.status) {
            case statuses.PENDING_PE_REVIEW_PCR:
                route = getApprovePCRByPERoute(data.log.id);
                break;
            case PCR_STATES.PENDING_PROJECT_MANAGER_REVIEW:
                route = getApprovePCRByPMRoute(data.log.id);
                break;
            case PCR_STATES.PENDING_CLIENT_REVIEW:
                route = getApprovePCRByClientRoute(data.log.id);
                break;
            default:
                break;
        }

        setApprovalRoute(route);
    }

    useEffect(() => {
        if (pcrData !== null) {
            const {
                log: {status, area, title, originator, variance},
                contractType,
                description,
                comments,
                scheduleImpact,
                scheduleHours,
                incorporatedInSchedule,
                scheduleImpactDescription,
                templateId,
                sbiEnable
            } = pcrData
            reset({
                'status': status,
                'area': area.toString(),
                'title': title,
                'originator': originator,
                'variance': variance,
                'description': description,
                'scheduleImpactDescription': scheduleImpactDescription ?? '',
                'contractType': contractType,
                'comments': comments ?? '',
                'scheduleImpact': scheduleImpact,
                'scheduleHours': scheduleHours ?? '',
                'incorporatedInSchedule': incorporatedInSchedule,
                'template': templateId,
                'sbiEnable': sbiEnable
            })
            dispatch(setSbiEnable(sbiEnable))
        }
    }, [pcrData, setValue, reset, dispatch])

    const onSave = handleSubmit(async (data) => {
        const formData = new FormData()
        const command = {
            ...data, ...{
                projectId: projectId.value,
                pcrId: pcrId.current,
                attachments: [],
                templateId: data.template,
                statusId: data.status,
                userId
            }
        }

        attachments.forEach((file) => {
            if (file.name) {
                formData.append('files', file, file.name)
            } else {
                command.attachments.push({
                    'fileName': file.fileName,
                    'status': file.status,
                    'fileDownloadUri': file.fileDownloadUri
                })
            }
        })

        formData.append('command', new Blob([JSON.stringify(command)], {type: 'application/json'}))
        try {
            setSaveInProgress(true)
            const response = await LogService.updatePCR(formData)
            if (response.data.data) {
                dispatch(openSuccess('The PCR was Updated.'))
                setFormSaved(formSaved + 1)
                dispatch(setSbiEnable(response.data.data.sbiEnable))
            }
            setSaveInProgress(false)
        } catch (e) {
            setSaveInProgress(false)
            console.log(e)
        }
    })

    const onSubmit = handleSubmit(async (data) => {
        const formData = new FormData()
        const command = {...data, ...{id: pcrId.current, attachments: [], templateId: data.template, userId}}

        attachments.forEach((file) => {
            if (file.name) {
                formData.append('files', file, file.name)
            } else {
                command.attachments.push({
                    'fileName': file.fileName,
                    'status': file.status,
                    'fileDownloadUri': file.fileDownloadUri
                })
            }
        })

        formData.append('command', new Blob([JSON.stringify(command)], {type: 'application/json'}))


        try {
            setSaveInProgress(true)
            let response;
            if (data.status === statuses.PCR_CREATED ||
                data.status >= PCR_STATES.PENDING_ESTIMATING_REVIEW) {
                response = await LogService.submitPCR(formData);
            } else {
                response = await LogService.submitPCRToDisciplineLeads(formData)
            }
            if (response.status === 200) {
                if (!response.data.errors || response.data.errors.length === 0) {
                    determineApprovalRoute(pcrData);
                    dispatch(openSuccess('The PCR was Submited.'))
                    setFormSaved(formSaved + 1)
                }
            }
            setSaveInProgress(false)
        } catch (e) {
            setSaveInProgress(false)
            console.log(e)
        }
    })

    const onEditDisciplineImpact = handleSubmit(() => {
        const disciplineImpactUrl = getDisciplinesPCRRoute(pcrData.id)
        history.push(disciplineImpactUrl)
    })

    const onUpdateState = (async () => {
        const response = await LogService.submitToEstimating({pcrId: pcrData.id, userId})
        if (response.status === 200) {
            if (!response.data.errors || response.data.errors.length === 0) {
                dispatch(openSuccess('The PCR was Updated.'))
                setFormSaved(formSaved + 1)
            }
        }

    })

    if (loading) {
        return (<Spinner/>)
    }

    const isSaveDisabled = () => {
        if (isAuthorized([...CAN_REVERT_STATUS])) {
            return false
        }
        return !isValid || saveInProgress || formState.readOnly;
    }

    return (
        <Page>
            <Container className="pb-3">
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title={'Project Change: ' + changeNumber}/>
                            <hr/>
                        </Container>
                    </Col>
                </Row>
                <Form className="pb-5" onSubmit={onSave}>
                    <Row>
                        {statusAndAreas && <PCRBasicInfoForm
                            control={control} errors={errors} statusAndAreas={statusAndAreas}
                            readOnly={formState.readOnly}
                            getValues={getValues} setAttachments={setAttachments} attachments={attachments}/>}
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <Summary id={logId} pcrSummaryData={summaryData}/>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            {pcLogStatusHistories && <History historyData={pcLogStatusHistories}/>}
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            {pcrData?.approvers && pcrData.approvers.length > 0 &&
                                <>
                                    <hr/>
                                    <div className="h6 font-italic pt-4 mb-0 d-flex justify-content-end">
                                        {<div className="pr-1">When submitted an approval will be sent to </div>}
                                        {<div>{pcrData.approvers.map((name, id) => (
                                            <div className="text-capitalize" key={id}>{name}</div>))}</div>}
                                    </div>
                                </>
                            }
                            <hr/>
                            <PCRActionButtons onSave={onSave} onSubmit={onSubmit}
                                              onEditDisciplineImpact={onEditDisciplineImpact}
                                              onUpdateState={onUpdateState}
                                              cancelRoute={LogRoute} isDirty={isDirty} isValid={isValid}
                                              actionButtonsState={formState}
                                              approvalRoute={approvalRoute} saveInProgress={saveInProgress}
                                              isSaveDisabled={isSaveDisabled()}
                            />
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Page>
    );
}

export default UpdatePCR