import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import { useSelector } from 'react-redux';
import {
    Container,
    Row,
    Col,
    Label,
    Input
} from 'reactstrap';
import FeatureTitle from '../../../../components/FeatureTitle/FeatureTitle'
import routes from '../../../../setup/routes';
import ActionButton from '../../../../components/ActionButton/ActionButton'
import Icon from '../../../../components/Icon/Icon'
import "../Log.css"

const LogActionLinks = [
    { label: "PCI - Project Change Identification", path: routes.CreatePCIRoute },
    { label: "PCR - Project Change Request", path: routes.CreatePCRRoute },
    { label: "FCO - Field Change Request", path: routes.CreateFCORoute },
    { label: "Create VAR from PCI", path: routes.CreatePCIRouteVAR },
    { label: "Create VAR from PCR", path: routes.CreatePCRRouteVAR },
    //{ label: "Create VAR from FCO", path: routes.CreateFCORouteVAR },
];

const changeTypes = [
    "All Changes", "FCO", "PCI", "PCR", "VAR"
];

const LogHeader = ({ logs, setFilteredLogs, logTotalDispatch, setChangeType }) => {
    const user = useSelector(state => state.user.user);

    const handleChangeSelect = (event) => {
        if (event.target.value === "All Changes") {
            setFilteredLogs(logs)
            logTotalDispatch({ type: 'ALL' })
        } else {
            const selectedOption = event.target.value;
            setFilteredLogs(filterChangeType(selectedOption));
            logTotalDispatch({ type: selectedOption })
            setChangeType(selectedOption);
        }
    }

    const filterChangeType = (changeType) => {
        let results;
        if (changeType === 'VAR') {
            results = logs.filter(log => log.variance === 'Y');
        } else {
            results = logs.filter(log => log.changeType === changeType);
        }
        return results;
    }

    return (
        <Container>
            <Row>
                <Col>
                    <FeatureTitle title={`Welcome, ${user.givenName} ${user.familyName}`} />
                    <div className="d-flex justify-content-end pb-2">
                        <ActionButton
                            label="Create"
                            icon={<Icon icon="plus-circle" />}
                            links={LogActionLinks}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="12" lg="12" className="header">
                    <h5 className="mr-auto">All Changes</h5>
                    <Label className="mr-3" for="changeType">Changes Type</Label>
                    <Input className="d-flex selectWidth" bsSize="sm" type="select" onChange={handleChangeSelect}>
                        {changeTypes.map(changeType => <option value={changeType} key={changeType}>{changeType}</option>)}
                    </Input>
                </Col>
            </Row>
        </Container>
    )
}

export default LogHeader
