import { useDispatch, connect } from "react-redux";
import { CONTRACT_TYPE } from '../type-and-time/Options'
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import React, {useEffect, useRef, useCallback, useMemo} from 'react';
import { constructionManagementSummarySetData } from "../redux/detailsDataAndTotalSlice"
import { getRowStyle, getRowSpanForDescriptionTotal, getTotalDescription, currencyFormatter, defaultGridOptions, sortByPact, isFooter } from "../../../../utilities/ag-grid-utilities/AgGridUtilities";
import Icon from '../../../../components/Icon/Icon';
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';
import {CAN_OVERRIDE_DETAIL_VALUES} from '../../logs-roles/Roles';

const TOTAL_DESCRIPTION = "Total Construction Management (DIV N)";
const getHoursValue = (originalEstimatePct, totalDirectLaborHours) => Math.ceil(Number(originalEstimatePct) * Number(totalDirectLaborHours));
const getLaborDollars = (rate, markup, overlay, hours) => Math.ceil(((Number(rate) * Number(markup)) + Number(overlay)) * hours)

const cellClassRules = {
    'override-status': params => params.data.edited,
    'editable-cell': params => {
        return !((!params.colDef.editable || !params.colDef.editable(params)) || isFooter(params));
    }
};

const cellRendererWarning = (params) => {
    return <div className="d-flex override-status"><Icon icon="exclamation-triangle" color="#E76143" size="sm" className="px-1" title="Please verify rates are set" />{params.value}</div>
}

const ConstructionManagementSummaryGrid = React.memo(({ data, totals, totalDirectLaborHours, contractType, readOnly }) => {
    const dispatch = useDispatch()
    const doUpdate = useRef(false)
    const dataRef = useRef(...data)
    const { isAuthorized } = useAuthorized()
    const canOverrideValues = useMemo(() => {
        return isAuthorized([...CAN_OVERRIDE_DETAIL_VALUES]);
    }, [isAuthorized])


    useEffect(() => {
        dataRef.current = [...data]
    }, [data])

    const applyFormulasToDataModel = useCallback((localData) => {
        const markupType = contractType === CONTRACT_TYPE.LumpSum ? 'lumpSumMarkup' : 'costReimbursibleMarkup'
        const overlayType = contractType === CONTRACT_TYPE.LumpSum ? 'lumpSumOverlay' : 'costReimbursibleOverlay'
        const updatedDataModel = localData.map(row => {
            const { labor } = row
            let hours; 
            let laborDollars;
            
            if(row.hasBeenOverwritten){
              hours = row.hours
              laborDollars =  getLaborDollars(labor.rate, labor[markupType], labor[overlayType], hours)
            }else{
              hours = getHoursValue(labor.originalEstimatePct, totalDirectLaborHours)
              laborDollars =  getLaborDollars(labor.rate, labor[markupType], labor[overlayType], hours)
            }
            return {
                ...row,
                hours,
                laborDollars,
                hasBeenOverwritten: row.hasBeenOverwritten
            }
        })
        if (updatedDataModel.length > 0) {
            dispatch(constructionManagementSummarySetData(updatedDataModel))
        }
    }, [contractType, dispatch, totalDirectLaborHours])

    const recalculateFormulas = useCallback(() => {
        applyFormulasToDataModel(data)
    }, [applyFormulasToDataModel, data])

    useEffect(() => {
        if (doUpdate.current) {
            recalculateFormulas()
        }
    }, [contractType, totalDirectLaborHours, recalculateFormulas])

    const valueSetter = (params) => {
        
        if (params.colDef.field === "hours") {
            //Validate hour precision and scale
            if (params.newValue > 999999) {
                return params.oldValue
            }
        } 

        const isNewValue = Number(params.oldValue) !== Number(params.newValue)

        if (isNewValue) {
            const value =  Number(params.newValue);
            const updatedRow = { ...params.data, [params.column.colId]: value, edited: true, "hasBeenOverwritten": true }
            const filteredData = dataRef.current.filter(f => f.id !== params.data.id)
            applyFormulasToDataModel([...filteredData, ...[updatedRow]].sort(sortByPact))
        }
        return isNewValue
    }

    return (
        <div className="ag-theme-bootstrap" style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <div id="project-management-summary-table" style={{flex: '1 1 auto'}}>
                <AgGridReact
                    getRowStyle={getRowStyle}
                    gridOptions={{...defaultGridOptions}}
                    rowData={data}
                    getRowNodeId={data => data.id}
                    immutableData={true}
                    domLayout="autoHeight"
                    enableCellChangeFlash
                    pinnedBottomRowData={[totals]}
                    singleClickEdit={true}
                    onFirstDataRendered={() => doUpdate.current = true}>
                    <AgGridColumn headerName="Pact" field="pact" headerClass="header-grid" maxWidth={70}
                                  colSpan={getRowSpanForDescriptionTotal}
                                  valueFormatter={params => getTotalDescription(params, TOTAL_DESCRIPTION)}/>
                    <AgGridColumn headerName="Activity" field="activityDescription" headerClass="header-grid"
                                  minWidth={400}
                                  cellRendererFramework={params => Number(params.data.hours) > 0 && Number(params.data.laborDollars) === 0 ? cellRendererWarning(params) : params.value}
                    />
                    <AgGridColumn headerName="Hours" field="hours" headerClass="header-grid right-sortable-header-grid"
                                  type="numericColumn" minWidth={200}
                                  valueFormatter={(params) => currencyFormatter(params.data.hours, '')}
                                  editable={(params) => canOverrideValues && !readOnly && !isFooter(params)}
                                  valueSetter={valueSetter}
                                  cellClassRules={cellClassRules}
                    />
                    <AgGridColumn headerName="Labor $" field="laborDollars"
                                  headerClass="header-grid right-sortable-header-grid" type="numericColumn"
                                  cellClassRules={cellClassRules} width={150} maxWidth={150}
                                  valueFormatter={(params) => currencyFormatter(params.data.laborDollars, '$')}/>
                </AgGridReact>
            </div>
        </div>
    );
}, (prevProps, nextProps) => {
    return (
        prevProps.totalDirectLaborHours === nextProps.totalDirectLaborHours &&
        prevProps.contractType === nextProps.contractType &&
        JSON.stringify(prevProps.totals) === JSON.stringify(nextProps.totals) &&
        JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
    )
});

const mapStateToProps = (state) => {
    const { directLaborSummary: { totals: { directLaborHours: totalDirectLaborHours } }, constructionManagementSummary: { data, totals }, contractType, readOnly } = state.detailsDataAndTotal
    return {
        data,
        totals,
        contractType,
        totalDirectLaborHours,
        readOnly
    }
}

export default connect(mapStateToProps)(ConstructionManagementSummaryGrid)
