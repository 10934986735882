import React from 'react'
import { Button } from "reactstrap";
import Icon from "../../../components/Icon/Icon"

const DownloadButton = ({iconName, onDownload}) => {   

    return (
        <Button
            color="primary"
            className="d-flex align-items-center px-4 mx-2"
            onClick={onDownload}            
            size="sm"
            title="Download">           
            <div className="pl-1 fa-xs"><Icon icon={iconName} /></div>
        </Button>
    )
}

export default DownloadButton
