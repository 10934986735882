import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import DecimalEditor from '../../../../utilities/ag-grid-utilities/cell-editors/DecimalEditor'

const RatesMasterMarkupGrid = ({
    masterMarkups,
    valueFormatter,
    rowStyle,
    updateWorkHourRates,
    decimalParser
}) => {

    const sbIndiaWageRateSetter = params => {
        if (params.data.masterMarkupDefnPactDescription === 'SB INDIA WAGE RATE') {
            updateWorkHourRates(params.newValue);
        }

        params.data.costReimbursibleMarkup = params.newValue;
        return true;
    };

    const agGrid = {
        columnDefs: [
            { headerName: 'Prime Account Description', field: 'masterMarkupDefnPactDescription', sortable: true, flex: 1, filter: true, headerClass: "header-grid" },
            {
                headerName: 'Contract Markup (Cost Reimbursable)', field: 'costReimbursibleMarkup', flex: 1, sortable: true, filter: true, editable: true,
                valueSetter: params => sbIndiaWageRateSetter(params), 
                valueParser: params => params.data.masterMarkupDefnPactDescription.includes("DIRECT LABOR PREMIUM") ? decimalParser(params, 9, 4) : decimalParser(params, 9, 2),
                valueFormatter: params => {
                    var decimals = params.data.masterMarkupDefnPactDescription.includes("DIRECT LABOR PREMIUM") ? 4 : 2;
                    return valueFormatter(params.data.costReimbursibleMarkup, params.data.masterMarkupDefnCostReimbursibleType, true, decimals)
                }, headerClass: "header-grid"
            },
            {
                headerName: 'Contract Markup (Lump Sum)', field: 'lumpSumMarkup', flex: 1, sortable: true, filter: true, editable: true,
                valueParser: params => params.data.masterMarkupDefnPactDescription.includes("DIRECT LABOR PREMIUM") ? decimalParser(params, 9, 4) : decimalParser(params, 9, 2),
                valueFormatter: params => {
                    var decimals = params.data.masterMarkupDefnPactDescription.includes("DIRECT LABOR PREMIUM") ? 4 : 2;
                    return valueFormatter(params.data.lumpSumMarkup, params.data.masterMarkupDefnLumpSumType, true, decimals)
                }, headerClass: "header-grid",
                
            }
        ]
    }

    function cellEditorSelector(params) {
        if(params.data.masterMarkupDefnPactDescription.includes("DIRECT LABOR PREMIUM")) {
            return { component: <DecimalEditor decimalScale={4} /> }
        }

        return { component: <DecimalEditor decimalScale={2} /> }
    }

    return (

        <AgGridReact
            getRowStyle={rowStyle}
            columnDefs={agGrid.columnDefs}
            rowData={masterMarkups}
            suppressMenuHide="true"
            domLayout="autoHeight"
            className="ag-theme-bootstrap"            
            singleClickEdit={true}
            frameworkComponents={{
                decimalCellEditor: DecimalEditor
            }}>
                <AgGridColumn
                    field="value"
                    editable={true}
                    cellEditorSelector={cellEditorSelector}
                />
            </AgGridReact>

    )
}

export default RatesMasterMarkupGrid;