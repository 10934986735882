import React, { useCallback, useEffect } from 'react'
import { Row, Col, Input, FormGroup, Label } from "reactstrap"
import { StarIndicator } from "../../../components/StarIndicator"

const CheckboxSection = ({ checkboxState, checkboxDispatch, dropdownDispatch }) => {

    const getSelectedOptions = useCallback(() => {
        return Object.keys(checkboxState)
            .filter(f => !["all", "printAttachments"].includes(f))
            .filter(key => checkboxState[key] === true || checkboxState[key].value)
    }, [checkboxState])


    useEffect(() => {
        dropdownDispatch({ type: "changeNumbers-filter", payload: getSelectedOptions() })
    }, [checkboxState.ft, checkboxState.pci, checkboxState.pcr, checkboxState.fco, checkboxState.var, dropdownDispatch, getSelectedOptions])


    const inputCommonProps = {
        type: "checkbox",
        onChange: (e) => checkboxDispatch({ type: e.target.id })
    }

    return (
        <>
            <Row>
                <Col xs={1}><Label className="col-5 ml-1">{"Change Type:"}<StarIndicator /> </Label></Col>
                <Col xs={3}>
                    <FormGroup check>
                        <Label check className="col-5">
                            <Input id="pci" className="mt-1" checked={checkboxState.pci} {...inputCommonProps} /> PCI
                        </Label>
                        <Label check className="col-5">
                            <Input id="show-details" className="mt-1" checked={checkboxState.showDetails}  {...inputCommonProps} /> Show Details
                        </Label>
                    </FormGroup>

                    <FormGroup check >
                        <Label check className="col-5">
                            <Input id="pcr" className="mt-1" checked={checkboxState.pcr} {...inputCommonProps} /> PCR
                        </Label>
                    </FormGroup>

                    <FormGroup check>
                        <Label check className="col-5">
                            <Input id="fco" className="mt-1" checked={checkboxState.fco} {...inputCommonProps} /> FCO
                        </Label>
                    </FormGroup>

                    <FormGroup check>
                        <Label check className="col-5">
                            <Input id="var" className="mt-1" checked={checkboxState.var} {...inputCommonProps} /> VAR
                        </Label>
                    </FormGroup>

                    <FormGroup check>
                        <Label check className="col-5">
                            <Input id="flash-trends" className="mt-1" checked={checkboxState.ft} {...inputCommonProps} /> Flash Trends
                        </Label>
                    </FormGroup>
                </Col>
                <Col xs={4} >
                    <FormGroup check>
                        <Label check className="col-5">
                            <Input id="print-attachments" className="mt-1" checked={checkboxState.printAttachments} {...inputCommonProps} /> Print Attachments
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}

export default CheckboxSection
