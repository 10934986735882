import { useHistory } from "react-router";
import { Button } from "reactstrap";
import Authorized from "../../features/auth/Authorized/Authorized";
import { PROJECT_COORDINATORS } from "../../features/logs/logs-roles/Roles";

const ApprovalsButton = ({ route }) => {

    const history = useHistory();

    const handleClick = (e) => {
        e.stopPropagation();
        history.push(route);
    }

    return (
        <>
            {route &&
                <Authorized roles={[...PROJECT_COORDINATORS]}>
                    <Button color="link" onClick={handleClick} className="m-1">Approvals Page</Button>
                </Authorized>
            }
        </>
    )
}

export default ApprovalsButton;