import {
    Container,
    Label,
    Input,
    Row,
    Col
} from "reactstrap";
import FormFieldError from '../../../../UI/FormFieldError';
import { Controller } from "react-hook-form";
import { StarIndicator } from "../../../../components/StarIndicator"

const TemplateBasicInfoForm = ({
    register,
    errors,
    templateStatuses,
    control
}) => {

    const templateStatusOptions = templateStatuses ? templateStatuses.map(status => (
        <option value={status} key={status}>{status}</option>
    )) : [];

    return (
        <Container>
            <h5>Basic Information</h5>
            <Row className="mt-md-3">
                <Col sm="2" lg="1">
                    <Label for="templateName">Name<StarIndicator /> </Label>
                </Col>
                <Col sm="4" lg="3">
                    <Controller
                        placeholder="Name"
                        control={control}
                        name="templateName"
                        render={({ field }) => <Input {...field} data-testid="templateName" />}
                        rules={{ required: true }}
                    />
                    {errors.templateName && <FormFieldError className="text-danger">Template Name is required</FormFieldError>}
                </Col>
                <Col sm="2" lg="1">
                    <Label for="templateStatus">Status<StarIndicator /></Label>
                </Col>
                <Col sm="4" lg="3">
                    <select className="form-control" {...register("templateStatus")} type="select" data-testid="templateStatus">
                        {templateStatusOptions}
                    </select>
                </Col>
            </Row>
            <Row className="mt-md-3">
                <Col sm="2" lg="1">
                    <Label for="templateDescription">Description<StarIndicator /></Label>
                </Col>
                <Col sm="10" lg="7">
                    <Controller
                        placeholder="Name"
                        control={control}
                        name="templateDescription"
                        render={({ field }) => <Input type="textarea" {...field} data-testid="templateDescription" />}
                        rules={{ required: true }}
                    />
                    {errors.templateDescription && <FormFieldError className="text-danger">Template Description is required</FormFieldError>}
                </Col>
            </Row>
        </Container>
    )
}

export default TemplateBasicInfoForm;