import { setProjectId } from './../components/ProjectDropdown/redux/projectIdSlice'
import localStorageService from './../services/localStorageService'
import { setSelectedProject } from './../components/ProjectDropdown/redux/projectDetailSlice'
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react'

const useSelectedProject = () => {
    const dispatch = useDispatch()
    const { projectId } = useSelector((state) => state)

    const setSelectedProjectDropdown = useCallback((newProjectId) => {
        const projects = localStorageService.getProjects();
        const selectedProject = projects.filter(p => p.projectId === newProjectId);  
        if (projectId?.value !== newProjectId && newProjectId && selectedProject) {
            dispatch(setProjectId(newProjectId))
            dispatch(setSelectedProject(selectedProject[0])); 
        }
    },
    [projectId, dispatch])
  
    return [setSelectedProjectDropdown]
}

export default useSelectedProject
