import {useState, useEffect, useMemo} from 'react';
import {
    Container,
    Label,
    Input,
    Row,
    Col
} from 'reactstrap';
import {Controller} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message';
import {StarIndicator} from '../../../../components/StarIndicator'
import {Attachments} from '../../attachments';
import {StyledColumn} from '../../../../components/StyledColumn';
import styled from 'styled-components';
import {CAN_REVERT_STATUS} from '../../logs-roles/Roles';
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';

const RadioContainer = styled.div`
  border-style: dashed;
  border-color: #B1C1CE;
  border-width: 1px;
  border-radius: 2px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  display: flex;
`

const stringToBoolean = (string) => string === 'false' ? false : !!string

const PCIBasicInfoForm = ({
                              control,
                              errors,
                              pciStatusAndAreas,
                              readOnly = false,
                              attachments,
                              setAttachments,
                              getValues
                          }) => {
    const [pciStatusOptions, setPciStatusOptions] = useState([])
    const [pciAreaOptions, setpciAreaOptions] = useState([])
    const {isAuthorized} = useAuthorized();
    const [variance, setVariance] = useState(false)
    const [sbiEnable, setSbiEnable] = useState(false)
    const sbiEnableValue = stringToBoolean(getValues('sbiEnable')) ?? false
    const varianceValue = stringToBoolean(getValues('variance')) ?? false
    const sortByDescription = ((a, b) => {
        return a.description > b.description ? 1 : -1
    })
    useEffect(() => {
        if (pciStatusAndAreas) {
            setPciStatusOptions(pciStatusAndAreas.statuses ? pciStatusAndAreas.statuses.sort(sortByDescription).map(({
                                                                                                                         id,
                                                                                                                         description
                                                                                                                     }) => (
                <option value={id} key={id}>{description}</option>
            )) : [])
            setpciAreaOptions(pciStatusAndAreas.areas ? pciStatusAndAreas.areas.map(({taskNumber}) => (
                <option value={taskNumber.toString()} key={taskNumber}>{taskNumber}</option>
            )) : [])
        }
    }, [pciStatusAndAreas])

    useEffect(() => {
        setVariance(varianceValue)
    }, [varianceValue])

    useEffect(() => {
        setSbiEnable(sbiEnableValue)
    }, [sbiEnableValue])

    const isStatusDisabled = useMemo(() => {
        if (isAuthorized([...CAN_REVERT_STATUS])) {
            return false
        }
        return readOnly
    }, [readOnly])

    return (
        <>
            <Container>
                <h5>Status</h5>
                <Row className="mt-md-3">
                    <StyledColumn w={7}>
                        <Label for="pciStatus">Status</Label>
                    </StyledColumn>
                    <Col sm="4" lg="3" className="ml-3">
                        <Controller
                            control={control}
                            name="pciStatus"
                            render={({field}) => <Input
                                type="select"
                                data-testid="pciStatus"
                                bsSize="sm"
                                disabled={isStatusDisabled}
                                required={true}
                                {...field}
                            >
                                {pciStatusOptions}
                            </Input>}/>
                    </Col>

                    <StyledColumn w={7}>
                        <Label for="originator">Originator</Label>
                    </StyledColumn>
                    <Col sm="3" lg="2">
                        <Controller
                            control={control}
                            name="originator"
                            render={({field}) => <Input disabled={true} bsSize="sm" {...field}
                                                        data-testid="originator"/>}
                        />
                    </Col>

                    <StyledColumn w={7}>
                        <Label for="clientNumber">Client Number</Label>
                    </StyledColumn>
                    <Col sm="3" lg="2">
                        <Controller
                            placeholder="Client Number"
                            control={control}
                            name="clientNumber"
                            render={({field}) => <Input disabled={readOnly} bsSize="sm" {...field}
                                                        data-testid="clientNumber"/>}
                            rules={{
                                maxLength: {
                                    message: 'Client number must be no longer than 10 characters.',
                                    value: 10
                                }
                            }}
                        />
                        <ErrorMessage className="text-danger small" errors={errors} name={'clientNumber'} as="p"
                                      key={'clientNumber'}/>
                    </Col>
                </Row>

                <Row className="mt-md-3">
                    <StyledColumn w={7}>
                        <Label for="variance">Variance</Label>
                    </StyledColumn>
                    <Col sm="4" lg="2" className="ml-3">
                        <Controller
                            control={control}
                            name="variance"
                            render={({field}) =>
                                <RadioContainer  {...field}>
                                    <div className="ml-3">
                                        <Input
                                            disabled={readOnly}
                                            id="varianceYes"
                                            type="radio"
                                            value={true}
                                            checked={variance}
                                            onChange={() => setVariance(true)}
                                        />
                                        <Label for="varianceYes">Yes</Label>
                                    </div>

                                    <div className="ml-5">
                                        <Input
                                            disabled={readOnly}
                                            id="varianceNot"
                                            type="radio"
                                            value={false}
                                            checked={!variance}
                                            onChange={() => setVariance(false)}
                                        />
                                        <Label for="varianceNot">No</Label>
                                    </div>
                                </RadioContainer>
                            }/>
                    </Col>
                </Row>
                <hr/>
            </Container>

            <Container>
                <h5>General Information</h5>
                <Row className="d-flex">
                    <Col lg="7">
                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="pciArea">Area<StarIndicator/> </Label>
                            </StyledColumn>
                            <Col sm="1" lg="2">
                                <Controller
                                    control={control}
                                    name="pciArea"
                                    rules={{required: 'Area is required.'}}
                                    render={({field}) => <Input
                                        style={{width: '90px'}}
                                        type="select"
                                        bsSize="sm"
                                        disabled={readOnly}
                                        defaultValue="DEFAULT"
                                        data-testid="pciArea"
                                        {...field}
                                    >
                                        {[...pciAreaOptions,
                                            <option value="DEFAULT" key="DEFAULT" disabled hidden>Choose here</option>]}
                                    </Input>}
                                />
                                <ErrorMessage className="text-danger small" errors={errors} name={'pciArea'} as="p"
                                              key={'pciArea'}/>
                            </Col>

                            <Col sm="2" lg="1" className="text-right">
                                <Label for="pciTitle">Title<StarIndicator/></Label>
                            </Col>
                            <Col sm="3" lg="7">
                                <Controller
                                    placeholder="Title"
                                    control={control}
                                    name="pciTitle"
                                    rules={{
                                        required: 'Title is required.',
                                        maxLength: {message: 'Title must be no longer than 50 characters.', value: 50}
                                    }}
                                    render={({field}) => <Input disabled={readOnly} bsSize="sm" {...field}
                                                                data-testid="pciTitle"/>}
                                />
                                <ErrorMessage className="text-danger small" errors={errors} name={'pciTitle'} as="p"
                                              key={'pciTitle'}/>
                            </Col>

                        </Row>
                        <Row className="mt-md-3">
                            <StyledColumn w={14}>
                                <Label for="pciDescription">Description<StarIndicator/></Label>
                            </StyledColumn>
                            <Col sm="9" lg="10">
                                <Controller
                                    placeholder="Description"
                                    control={control}
                                    name="pciDescription"
                                    render={({field}) => <Input disabled={readOnly} type="textarea" {...field}
                                                                data-testid="pciDescription"/>}
                                    rules={{
                                        required: 'Description is required.',
                                        maxLength: {
                                            message: 'Description must be no longer than 4000 characters.',
                                            value: 4000
                                        }
                                    }}
                                />
                                <ErrorMessage className="text-danger small" errors={errors} name={'pciDescription'}
                                              as="p" key={'pciDescription'}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="5">
                        <Row className="mt-md-3">
                            <Attachments attachments={attachments} setAttachments={setAttachments} readOnly={readOnly}/>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-md-3">
                    <StyledColumn w={7}>
                        <Label for="sbiEnable">S&B India</Label>
                    </StyledColumn>
                    <Col sm="4" lg="2" className="ml-3">
                        <Controller
                            control={control}
                            name="sbiEnable"
                            render={({field}) =>
                                <RadioContainer  {...field}>
                                    <div className="ml-3">
                                        <Input
                                            disabled={readOnly}
                                            id="sbiEnableTrue"
                                            type="radio"
                                            value={true}
                                            checked={sbiEnable}
                                            onChange={() => setSbiEnable(true)}
                                        />
                                        <Label for="sbiEnableTrue">Yes</Label>
                                    </div>

                                    <div className="ml-5">
                                        <Input
                                            disabled={readOnly}
                                            id="sbiEnableFalse"
                                            type="radio"
                                            value={false}
                                            checked={!sbiEnable}
                                            onChange={() => setSbiEnable(false)}
                                        />
                                        <Label for="sbiEnableFalse">No</Label>
                                    </div>
                                </RadioContainer>
                            }/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PCIBasicInfoForm