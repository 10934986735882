import axios from '../../../../setup/axios'

class PCIService {
    BASE_ROUTE = "/log/pci";

    getApprovePCIByPEInfo(pciId){
        return this.get(`${pciId}/approvedByPE`)
    }

    getApprovePCIByPMInfo(pciId){
        return this.get(`${pciId}/approvedByPM`)
    }

    getApprovePCIByClientInfo(pciId){
        return this.get(`${pciId}/approvedByClient`)
    }

    approvePCIByPE(approveData){
        return this.post( `approveByPE`,approveData)
    }

    approvePCIByPM(approveData){
        return this.post( `approveByPM`,approveData)
    }

    approvePCIByClient(approveData){
        return this.post(`approveByClient`, approveData);
    }

    get(route) {
        return axios.get(`${this.BASE_ROUTE}/${route}`)
    }

    post(route, data) {
        return axios.post(`${this.BASE_ROUTE}/${route}`, data);
    }

    put(route, data) {
        return axios.put(`${this.BASE_ROUTE}/${route}`, data);
    }
}
export default new PCIService()