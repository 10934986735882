
const root = ""

export const FTRoute = `${root}/ft`
export const LogRoute = `${root}/log`
export const HomeRoute = `${root}/home`;
export const ReportsRoute = `${root}/reports`
export const CreateFTRoute = `${root}/ft/create`
export const TemplatesRoute = `${root}/templates`;
export const UpdateFTRoute = `${root}/ft/:id/update`
export const CreatePCIRoute = `${root}/log/pci/create`
export const CreatePCRRoute = `${root}/log/pcr/create`
export const CreateFCORoute = `${root}/log/fco/create`
export const RatesRoute = `${root}/templates/:id/rates`;
export const EditDetailsRoute = `${root}/log/:id/details`
export const UpdatePCIRoute = `${root}/log/:id/pci/update`
export const UpdatePCRRoute = `${root}/log/:id/pcr/update`
export const UpdateFCORoute = `${root}/log/:id/fco/update`
export const CreateTemplateRoute = `${root}/templates/create`;
export const UpdateTemplateRoute = `${root}/templates/:id/update`;
export const ApprovePCIByPERoute = `${root}/log/pci/:id/approveByPE`
export const ApprovePCIByPMRoute = `${root}/log/pci/:id/approveByPM`
export const ApprovePCRByPMRoute = `${root}/log/:id/pcr/approveByPM`
export const ApprovePCRByPERoute = `${root}/log/:id/pcr/approveByPE`
export const ApprovePCRByClientRoute = `${root}/log/:id/pcr/approveByClient`
export const DisciplinesPCRRoute = `${root}/log/:id/pcr/disciplines`
export const ApproveFCOByCMRoute = `${root}/log/:id/fco/approveByCM`
export const CreatePCIRouteVAR = `${root}/log/pci/create?isVar=true`
export const CreatePCRRouteVAR = `${root}/log/pcr/create?isVar=true`
export const CreateFCORouteVAR = `${root}/log/fco/create?isVar=true`
export const ApprovePCIByClientRoute = `${root}/log/pci/:id/approveByClient`
export const ApproveFCOByClientRoute = `${root}/log/:id/fco/approveByClient`


export const getEditRatesRoute = (id) => RatesRoute.replace(':id', id);
export const getUpdatePCIRoute = (id) => UpdatePCIRoute.replace(':id', id);
export const getUpdateFTRoute = (id) => UpdateFTRoute.replace(':id', id);
export const getUpdatePCRRoute = (id) => UpdatePCRRoute.replace(':id', id);
export const getUpdateFCORoute = (id) => UpdateFCORoute.replace(':id', id);
export const getUpdateTemplateRoute = (id) => `${root}/templates/${id}/update`;
export const getEditDetailsRoute = (id) => EditDetailsRoute.replace(':id', id);
export const getDisciplinesPCRRoute = (id) => DisciplinesPCRRoute.replace(':id', id);
export const getApprovePCIByPERoute = (id) => ApprovePCIByPERoute.replace(':id', id);
export const getApprovePCIByPMRoute = (id) => ApprovePCIByPMRoute.replace(':id', id);
export const getApprovePCRByPERoute = (id) => ApprovePCRByPERoute.replace(':id', id);
export const getApprovePCRByPMRoute = (id) => ApprovePCRByPMRoute.replace(':id', id);
export const getApproveFCOByCMRoute = (id) => ApproveFCOByCMRoute.replace(':id', id);
export const getApprovePCIByClientRoute = (id) => ApprovePCIByClientRoute.replace(':id', id);
export const getApproveFCOByClientRoute = (id) => ApproveFCOByClientRoute.replace(':id', id);
export const getApprovePCRByClientRoute = (id) => ApprovePCRByClientRoute.replace(':id', id);


const routes = {
    FTRoute,
    LogRoute,
    HomeRoute,
    RatesRoute,
    ReportsRoute,
    CreateFTRoute,
    UpdateFTRoute,
    TemplatesRoute,
    CreatePCIRoute,
    CreatePCRRoute,
    UpdatePCIRoute,
    UpdatePCRRoute,
    CreateFCORoute,
    UpdateFCORoute,
    EditDetailsRoute,
    CreatePCIRouteVAR,
    CreatePCRRouteVAR,
    CreateFCORouteVAR,
    CreateTemplateRoute,
    UpdateTemplateRoute,
    ApprovePCIByPERoute,
    ApprovePCIByPMRoute,
    DisciplinesPCRRoute,
    ApproveFCOByCMRoute,
    ApprovePCRByPERoute,
    ApprovePCRByPMRoute,
    ApprovePCIByClientRoute,
    ApproveFCOByClientRoute,
    ApprovePCRByClientRoute
};

export default routes;
