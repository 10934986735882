import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import {
    Container,
    Label,
    Input,
    Row,
    Col
} from "reactstrap";
import { Controller } from "react-hook-form"
import { StarIndicator } from "../../../../components/StarIndicator"
import styled from "styled-components";
import DisciplineImpactDeleteConfirmationModal from "./DisciplineImpactDeleteConfirmationModal"
import {DISCIPLINES_BY_PROJECT, SBI_DISCIPLINES, STANDARD_DISCIPLINES} from './constants'

const RadioContainer = styled.div`
    border-style: dashed;
    border-color: #B1C1CE;
    border-width: 1px;
    border-radius: 2px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    display: flex;
    margin-top: 2px;
`

const stringToBoolean = (string) => string === 'false' ? false : !!string

const DisciplineImpactBasicInfoForm = ({ control, getValues, roleDisciplines, readOnly = false, readOnlyDiscipline,
     setDisciplineDropDownSelected, disciplineImpactState, deleteAllDisciplineImpactAreas, setDisciplineImpactConfirmValue }) => {
    const [disciplineOptions, setDisciplineOptions] = useState([])
    const disciplineImpactValue = getValues("disciplineImpact");
    const [disciplineImpact, setDisciplineImpact] = useState(disciplineImpactValue)
    const scheduleImpactValue = stringToBoolean(getValues("scheduleImpact")) ?? false
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [disciplineImpactToConfirm, setDisciplineImpactToConfirm] = useState(disciplineImpactValue)
    const [scheduleImpact, setScheduleImpact] = useState(scheduleImpactValue)
    const { selectedProjectDetails } = useSelector((state) => state);
    const { pcrDetails } = useSelector((state) => state);

    useEffect(() => {
        setScheduleImpact(scheduleImpactValue)
    }, [scheduleImpactValue])

    useEffect(() => {
        setDisciplineImpact(disciplineImpactValue)
    }, [disciplineImpactValue])

    useEffect(() => {
        const jobNo = selectedProjectDetails.jobNo;

        if (roleDisciplines && jobNo) {
            const projectInitial = jobNo.substring(0, 1);
            const disciplinesByProjectInitial =  DISCIPLINES_BY_PROJECT.find(d => d.initial === projectInitial)?.disciplines || STANDARD_DISCIPLINES
            const disciplinesByProject = pcrDetails.sbiEnable ? [...disciplinesByProjectInitial, ...SBI_DISCIPLINES] : [...disciplinesByProjectInitial]  //TODO: sbiEnable is incorrectly set on a refresh because redux store it not updated
            const filteredRoleDisciplines = roleDisciplines.filter(rd => disciplinesByProject.includes(rd.discipline))
            setDisciplineOptions(filteredRoleDisciplines.length > 0 ? filteredRoleDisciplines.map(({ discipline }, key) => (
                <option key={key} value={discipline}>{discipline}</option>
            )) : [])
        }
    }, [roleDisciplines, selectedProjectDetails.jobNo, pcrDetails.sbiEnable])


    const onChangeDisciplineImpact = (event) => {
        const dImpactValue = event.target.value;
        if (disciplineImpactState.length > 0 && (dImpactValue === 'N' || dImpactValue === 'A')) {
            event.preventDefault()
            setIsDeleteModalOpen(true)
            setDisciplineImpactToConfirm(dImpactValue)
        } else {
            setDisciplineImpact(dImpactValue)
            setDisciplineImpactConfirmValue(dImpactValue)
        }
    }

    const onConfirmDisciplineChange = () => {
        deleteAllDisciplineImpactAreas(disciplineImpactToConfirm)
        setIsDeleteModalOpen(false)
        setDisciplineImpact(disciplineImpactToConfirm)
    }

    const onConfirmDisciplineCancel = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen)
        setDisciplineImpact(disciplineImpact)
        setDisciplineImpactConfirmValue(disciplineImpact)
    }
    
    return (
        <>
            <Container >
                <h5>Discipline</h5>
                <Row className="mt-md-3">
                    <Col lg={1} className="text-right">
                        <Label for="discipline">Discipline</Label>
                    </Col>
                    <Col sm="2" lg="3" className="ml-3">
                        <Controller
                            control={control}
                            name="discipline"
                            render={({ field }) => <Input
                                {...field}
                                type="select"
                                data-testid="discipline"
                                bsSize="sm"
                                disabled={readOnlyDiscipline}
                                required={true}
                                onChange={(event) => setDisciplineDropDownSelected(event.target.value)}
                            >
                                {disciplineOptions}
                            </Input>} />
                    </Col>
                </Row>
                <hr />
            </Container>

            <Container>
                <h5>General Information</h5>
                <Row className="d-flex">
                    <Col lg="7">
                        <Row className="mt-md-3">
                            <Col lg={2} className="text-right">
                                <Label for="disciplineImpact" >Impacts Discipline<StarIndicator /></Label>
                            </Col>
                            <Col lg="3" className="d-flex">
                                <Controller
                                    control={control}
                                    name="disciplineImpact"
                                    render={({ field }) =>
                                        <RadioContainer {...field} >
                                            <div className="ml-3">
                                                <Input
                                                    disabled={readOnly}
                                                    id="disciplineImpactTrue"
                                                    type="radio"
                                                    value={'Y'}
                                                    checked={disciplineImpact === 'Y'}
                                                    onChange={onChangeDisciplineImpact}
                                                />
                                                <Label for="disciplineImpactTrue">Yes</Label>
                                            </div>
                                            <div className="ml-5">
                                                <Input
                                                    disabled={readOnly}
                                                    id="disciplineImpactFalse"
                                                    type="radio"
                                                    value={'N'}
                                                    checked={disciplineImpact === 'N'}
                                                    onChange={onChangeDisciplineImpact}
                                                />
                                                <Label for="disciplineImpactFalse">No</Label>
                                            </div>
                                            <div className="ml-5">
                                                <Input
                                                    disabled={readOnly}
                                                    id="disciplineImpactNA"
                                                    type="radio"
                                                    value={'A'}
                                                    checked={disciplineImpact === 'A'}
                                                    onChange={onChangeDisciplineImpact}
                                                />
                                                <Label for="disciplineImpactNA">N/A</Label>
                                            </div>
                                        </RadioContainer>
                                    } />
                            </Col>

                            <Col lg={2} className="text-right">
                                <Label for="scheduleImpact" >Impacts Schedule<StarIndicator /></Label>
                            </Col>
                            <Col lg="3" className="d-flex">
                                <Controller
                                    control={control}
                                    name="scheduleImpact"
                                    render={({ field }) =>
                                        <RadioContainer  {...field}>
                                            <div className="ml-3">
                                                <Input
                                                    disabled={readOnly}
                                                    id="scheduleImpactTrue"
                                                    type="radio"
                                                    value={true}
                                                    checked={scheduleImpact}
                                                    onChange={() => setScheduleImpact(true)}
                                                />
                                                <Label for="scheduleImpactTrue">Yes</Label>
                                            </div>

                                            <div className="ml-5">
                                                <Input
                                                    disabled={readOnly}
                                                    id="scheduleImpactFalse"
                                                    type="radio"
                                                    value={false}
                                                    checked={!scheduleImpact}
                                                    onChange={() => setScheduleImpact(false)}
                                                />
                                                <Label for="scheduleImpactFalse">No</Label>
                                            </div>
                                        </RadioContainer>
                                    } />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DisciplineImpactDeleteConfirmationModal isOpen={isDeleteModalOpen} confirmCancel={onConfirmDisciplineCancel} confirmDelete={onConfirmDisciplineChange}/>
                            </Col>
                        </Row>
                        <Row className="mt-md-3">
                            <Col lg={2} className="text-right">
                                <Label for="description">Description</Label>
                            </Col>
                            <Col sm="6" lg="8">
                                <Controller
                                    placeholder="description"
                                    control={control}
                                    name="description"
                                    render={({ field }) => <Input disabled={readOnly} type="textarea" {...field} data-testid="description" />}
                                    rules={{ maxLength: { message: "Description must be no longer than 4000 characters.", value: 4000 } }} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default DisciplineImpactBasicInfoForm