import {createSlice} from '@reduxjs/toolkit' ;

export const userSlice = createSlice({
    name: 'userSlice',
    initialState: {
        user: {
            userId: '',
            givenName: 'John',
            familyName: 'Doe',
            roles: [],
            projectAccess: [],
        },
    },
    reducers: {
        setUser: (state, action) => {
            if (action.payload) {
                state.user = action.payload
            }
        },
    }
})

export const {setUser} = userSlice.actions

export default userSlice.reducer