import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { openSuccess } from "../../../../success/redux/successSlice"
import { openError } from '../../../../errors/redux/errorSlice';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import LogService from "../../../api/logService"
import FCOService from '../../api/fcoService'
import FeatureTitle from '../../../../../components/FeatureTitle/FeatureTitle'
import FCOBasicInfoForm from '../../fco-basic-info-form/FCOBasicInfoForm';
import { LogRoute } from '../../../../../setup/routes'
import FCODetailInfoForm from '../../fco-detail-info-form/FCODetailInfoForm';
import { HISTORY_TYPE } from "../../../../../setup/constants";
import Spinner from '../../../../../components/Spinner/Spinner'

import {
    Container,
    Row,
    Col,
    Form,
    Label,
    Input
} from "reactstrap";

import { Summary } from "../../../summary/Summary"
import RFIAttachementGrid from "../../rfi-attachments-grid/RFIAttachementGrid"
import Page from '../../../../../UI/Page';
import FCOApproveActionButtons from '../FCOApproveActionButtons';
import { Controller } from "react-hook-form"
import { ErrorMessage } from '@hookform/error-message';
import useSelectedProject from '../../../../../hooks/useSelectedProject'

const ApproveFCOByCM = ({ match }) => {
    const FCO_AWAITING_CONSTRUCTION_MANAGER_REVIEW = 29
    const logId = match.params.id
    const userId = useSelector(state => state.user.user.userId);
    const [dropdownsData, setDropdownsData] = useState(null)
    const [fcoData, setFcoData] = useState(null)
    const [changeNumber, setChangeNumber] = useState(null)
    const [rfiData, setrfiData] = useState([])
    const [summaryData, setSummaryData] = useState(null)
    const [selectedRfiList, setSelectedRfiList] = useState([])
    const [isValidStatus, setIsValidStatus] = useState(false)
    const { getValues, formState: { errors, isValid }, control, handleSubmit, setValue, reset } = useForm({
        defaultValues: {
            requestedBy: userId,
            type: 'R',
            tracking: false,
            projectType: 'L',
            scheduleImpact: false,
            variance: false
        },
        mode: "onChange"
    });

    const [attachments, setAttachments] = useState([])
    const history = useHistory();
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [setSelectedProjectDropdown] = useSelectedProject();
    const [selectedProjectId, setSelectedProjectId] = useState(null)
    const [requestInProgress, setRequestInProgress] = useState(false)

    useEffect(() => {

        async function getFCOByLogId() {
            const response = await LogService.getFCOForCMApproval(logId)
            const responseData = response.data.data
            if (responseData) {
                setDropdownsData(responseData)
                setFcoData(responseData)
                setAttachments(responseData.attachedFiles ?? [])
                setChangeNumber(responseData.log.changeNumber)
                setrfiData(responseData.allRFIs)
                setSelectedRfiList(responseData.selectedRFIs)
                setSummaryData(responseData.summary ?? null)
                setSelectedProjectId(responseData.projectId)
                if (responseData.log.status === FCO_AWAITING_CONSTRUCTION_MANAGER_REVIEW) {
                    setIsValidStatus(true);
                } else {
                    dispatch(openError(["The FCO has not been sent for CM approval."]))
                }
            }
            setLoading(false)
        }
        getFCOByLogId()
    }, [dispatch, logId])

    useEffect(() => {
        setSelectedProjectDropdown(selectedProjectId)
    }, [selectedProjectId, setSelectedProjectDropdown])

    useEffect(() => {
        if (fcoData !== null) {
            const { log: { area, title, originator, status, variance }, cause, type,
                tracking, projectType, scheduleImpact, scheduleHours, resolution, description, templateId,
                identifier } = fcoData

            reset({
                "status": status, "area": area.toString(), "shortDescription": title, "originator": originator,
                "type": type, "cause": cause.id, "requestedBy": originator, "projectType": projectType,
                "tracking": tracking, "scheduleImpact": scheduleImpact, 'scheduleHours': scheduleHours, "fullDescription": description, "resolution": resolution,
                "identifier": identifier, "template": templateId, "variance": variance
            })

        }
    }, [fcoData, setValue, reset])

    const onApprove = handleSubmit(async (data) => {
        const request = { "fcoId": fcoData.id, userId, approved: true, comments: data.fcoApprovalComments }
        try {
            setRequestInProgress(true)
            const response = await FCOService.approveFCOByCM(request)
            if (response.status === 200) {
                if (history.action !== HISTORY_TYPE.NO_HISTORY) {
                    history.goBack();
                } else {
                    history.push(LogRoute);
                }
                setTimeout(() => {
                    dispatch(openSuccess("The FCO was approved."))
                }, 4000)
            }
            setRequestInProgress(false)
        } catch (e) {
            setRequestInProgress(false)
            console.log(e)
        }
    })

    const onReject = handleSubmit(async (data) => {
        const request = { "fcoId": fcoData.id, userId, approved: false, comments: data.fcoApprovalComments }
        try {
            setRequestInProgress(true)
            const response = await FCOService.approveFCOByCM(request)
            if (response.status === 200) {
                if (history.action !== HISTORY_TYPE.NO_HISTORY) {
                    history.goBack();
                } else {
                    history.push(LogRoute);
                }
                setTimeout(() => {
                    dispatch(openSuccess("The FCO was rejected."))
                }, 4000)
            }
            setRequestInProgress(false)
        } catch (e) {
            console.log(e)
            setRequestInProgress(false)
        }
    })

    if (loading) {
        return (<Spinner />)
    }

    return (
        <Page>
            {fcoData && <Container className="pb-3">
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title={'Project Change: ' + changeNumber}/>
                            <hr/>
                        </Container>
                    </Col>
                </Row>
                <Form className="pb-5">
                    <Row>
                        {dropdownsData && <> <FCOBasicInfoForm readOnly={true}
                                                               control={control} errors={errors}
                                                               dropdownsData={dropdownsData}
                                                               getValues={getValues} setAttachments={setAttachments}
                                                               attachments={attachments}/>
                            <FCODetailInfoForm
                                control={control} errors={errors} dropdownsData={dropdownsData} readOnly={true}
                                getValues={getValues} setAttachments={setAttachments} attachments={attachments}/>
                        </>
                        }
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <Summary id={logId} fcoSummaryData={summaryData}/>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            {rfiData && <RFIAttachementGrid rfiList={rfiData} selectedRfiList={selectedRfiList}
                                                            setSelectedRfiList={setSelectedRfiList}/>}
                        </Col>

                    </Row>
                    <hr/>
                    <Row className="mt-md-3">
                        <Col sm="3" lg="2" className="text-right">
                            <Label for="fcoApprovalComments">Approval Comments</Label>
                        </Col>
                        <Col sm="12" lg="10">
                            <Controller
                                placeholder="ApprovalComments"
                                control={control}
                                name="fcoApprovalComments"
                                render={({field}) => <Input type="textarea" {...field}
                                                            data-testid="fcoApprovalComments"/>}
                                rules={{
                                    maxLength: {
                                        message: 'Approval Comments must be no longer than 4000 characters.',
                                        value: 4000
                                    }
                                }}
                            />
                            <ErrorMessage className="text-danger small" errors={errors} name={'fcoApprovalComments'}
                                          as="p" key={'fcoApprovalComments'}/>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <FCOApproveActionButtons cancelRoute={LogRoute} onApprove={onApprove} onReject={onReject}
                                                     isValid={isValid && isValidStatus} rejectLabel="Reject"
                                                     requestInProgress={requestInProgress}/>
                        </Col>
                    </Row>
                </Form>
            </Container>}
        </Page>
    );
}

export default ApproveFCOByCM;