import styled from 'styled-components';

const MenuStyles = styled.div`

    .menu-heading {
        span {
            padding: 5px;
            color: #012169;
            font-size: 30px;
            font-weight: bold;
        }
    }

    .menu-listing {
        a {
            font-size: 20px;
            padding-bottom: 5px;
            color: black;
            &:hover {
                background: #eaeded;
                color: black;
                text-decoration: none;
            }
        }
    }

`;

export default MenuStyles;