import { Controller } from "react-hook-form"
import NumberFormat from "react-number-format";
import { ErrorMessage } from '@hookform/error-message';
import { Container, Label, Input, Row, Col } from "reactstrap";


const uomOptions = ["EMU", "LS", "LF", "CY", "EA", "CF", "TN"]

const FTGeneralInfoForm = ({ control, errors, readOnly = false }) => {

    return (
        <Container>
            <h5>General Information</h5>
            <Row>
                <Col xs={1} className="text-right">
                    <Label for="description">Description</Label>
                </Col>
                <Col xs={5} >
                    <Controller
                        placeholder="Description"
                        control={control}
                        name="description"
                        render={({ field }) => <Input disabled={readOnly} type="textarea" {...field} data-testid="description" />}
                        rules={{ maxLength: { message: "Description must be no longer than 100 characters.", value: 100 } }}
                    />
                    <ErrorMessage className="text-danger small" errors={errors} name={"description"} as="p" key={"description"} />
                </Col>


                <Col xs={1} className="text-right">
                    <Label for="uom">UOM</Label>
                </Col>
                <Col xs={2}>
                    <Controller
                        control={control}
                        name="uom"
                        render={({ field }) => <Input
                            type="select"
                            bsSize="sm"
                            disabled={readOnly}
                            required={true}
                            {...field}>
                            {[<option value="-1" key="-1" disabled hidden>Select here</option>, ...uomOptions.map(option => <option key={option} value={option}>{option}</option>)]}
                        </Input>} />
                </Col>

                <Col xs={1} className="text-right">
                    <Label for="quantity">Quantity</Label>
                </Col>
                <Col xs={1}>
                    <Controller
                        control={control}
                        name="quantity"
                        render={({ field }) => <NumberFormat className="form-control-sm form-control" disabled={readOnly} thousandSeparator={true} decimalSeparator={false} {...field} />} />
                </Col>
            </Row>
            <hr />
        </Container>
    )
}
export default FTGeneralInfoForm