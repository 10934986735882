import {
    Container,
    Label,
    Input,
    Row,
    Col
} from "reactstrap";

import { Controller } from "react-hook-form"
import { ErrorMessage } from '@hookform/error-message';



const FTStatusRemarksForm = ({ control, getValues, errors, readOnly = false }) => {

    return (
        <>
            <Container>
                <h5>{"Status & Remarks"}</h5>
                <Row>
                    <Col xs={1} className="text-right">
                        <Label for="remarks">Remarks</Label>
                    </Col>
                    <Col xs={5}>
                        <Controller
                            placeholder="remarks"
                            control={control}
                            name="remarks"
                            render={({ field }) => <Input disabled={readOnly} type="textarea" {...field} />}
                            rules={{ maxLength: { message: "Remarks must be no longer than 100 characters.", value: 100 } }}
                        />
                        <ErrorMessage className="text-danger small" errors={errors} name={"remarks"} as="p" key={"remarks"} />
                    </Col>

                </Row>
                <hr />
            </Container>
        </>
    )
}
export default FTStatusRemarksForm