import React, {useState} from 'react'
import {useHistory} from 'react-router';
import {
    Row,
    Col,
    Button,
    Modal, ModalBody, ModalFooter
} from 'reactstrap';
import BusyActionButton from '../../../../components/ActionButton/BusyActionButton';

const FCOApproveActionButtons = ({cancelRoute, onApprove, onReject, isValid, rejectLabel, requestInProgress}) => {

    const [openModal, setOpenModal] = useState(false);
    const toggle = () => setOpenModal(!openModal);
    let history = useHistory();

    const onConfirmCancel = () => {
        toggle();
        history.push(cancelRoute);
    }

    return (
        <Row>

            <Col className="text-center">
                <BusyActionButton color="primary" className="m-2" data-testid="Approve-Button" busy={requestInProgress}
                                  disabled={!isValid || requestInProgress} onClick={onApprove}>Approve</BusyActionButton>
                <BusyActionButton color="primary" className="m-2" data-testid="Reject-Button" busy={requestInProgress}
                                  disabled={!isValid || requestInProgress} onClick={onReject}>{rejectLabel}</BusyActionButton>
                <Button color="primary" className="m-2" data-testid="Come-Back-Later-Button"
                                  busy={requestInProgress} onClick={toggle}>Come Back Later</Button>
            </Col>
            <Modal isOpen={openModal} data-testid="Approve-Cancel-ConfirmModal">
                <ModalBody>
                    {'Are you sure you want to close?'}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onConfirmCancel}>Yes</Button>
                    <Button color="primary" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </Row>
    )
}

export default FCOApproveActionButtons
