const mapHtmlOptions = (data, id, description, controlName) => 
    [<option value="-1" key="DEFAULT">{`Select ${controlName}`}</option>, ...(data && data.length > 0 ? data.sort(sortByDescription(description)).map((props) => (
        <option value={props[id] ?? props} key={props[id] ?? props}>{props[description] ?? props}</option>
    )) : [])]

const sortByDescription = (description) => ((a, b) => {
    if (description) {
        return a[description] > b[description] ? 1 : -1;
    }
        return a > b ? 1 : -1;
})

export const dropdownInitialState = {
    templates: { selected: "-1", options: [], htmlOptions: [] },
    areas: { selected: "-1", options: [], htmlOptions: [] },
    areaGroups: { selected: "-1", options: [], htmlOptions: [] },
    changeNumbers: { selected: "-1", options: [], htmlOptions: [] },
    disciplines: { selected: "-1", options: [], htmlOptions: [] },
    jobGroups: { selected: "-1", options: [], htmlOptions: [] },
}

const getFilteredChangeNumbers = (state, changeTypes) =>
    mapHtmlOptions(state.changeNumbers.options.filter(cn => changeTypes.includes(cn.changeType.toLowerCase())), "changeNumber", "changeNumber", "Change #")

export const dropdownReducer = (state, action) => {
    switch (action.type) {
        case "templates": return { ...state, disciplines: { ...state.disciplines, selected: action.payload !== "-1" ? "-1" : state.disciplines.selected }, templates: { ...state.templates, selected: action.payload } }
        case "areas": return { ...state, areas: { ...state.areas, selected: action.payload }, areaGroups: { ...state.areaGroups, selected: "-1" } }
        case "areaGroups": return { ...state, areaGroups: { ...state.areaGroups, selected: action.payload }, areas: { ...state.areas, selected: "-1" } }
        case "changeNumbers": return { ...state, changeNumbers: { ...state.changeNumbers, selected: action.payload } }
        case "changeNumbers-filter": return {
            ...state, changeNumbers: {
                ...state.changeNumbers,
                selected: "-1",
                htmlOptions: getFilteredChangeNumbers(state, action.payload)
            }
        }
        case "disciplines": return { ...state, templates: { ...state.templates, selected: action.payload !== "-1" ? "-1" : state.templates.selected }, disciplines: { ...state.disciplines, selected: action.payload } }
        case "disciplinesDD": return { ...state, disciplines: { ...state.disciplines, options: action.payload, htmlOptions: mapHtmlOptions(action.payload, null, null, "Discipline") } }
        case "jobGroups": return { ...state, jobGroups: { ...state.jobGroups, selected: action.payload } }
        default: return {
            templates: { ...state.templates, options: action.payload.templates, htmlOptions: mapHtmlOptions(action.payload.templates, "id", "name", "Template") },
            areas: { ...state.areas, options: action.payload.areas, htmlOptions: mapHtmlOptions(action.payload.areas, "taskNumber", "taskNumber", "Area") },
            areaGroups: { ...state.areaGroups, options: action.payload.areaGroups, htmlOptions: mapHtmlOptions(action.payload.areaGroups, "id", "name", "Area Group") },
            changeNumbers: { ...state.changeNumbers, options: action.payload.changeNumbers, htmlOptions: mapHtmlOptions(action.payload.changeNumbers, "changeNumber", "changeNumber", "Change #") },
            disciplines: { ...state.disciplines, options: action.payload.disciplines, htmlOptions: mapHtmlOptions(action.payload.disciplines, null, null, "Discipline") },
            jobGroups: { ...state.jobGroups, options: action.payload.jobGroups, htmlOptions: mapHtmlOptions(action.payload.jobGroups, "id", "name", "Job Group") }
        }
    }
}