import {useSelector} from 'react-redux';
import {Col, Container, Row} from 'reactstrap';
import FeatureTitle from '../../components/FeatureTitle/FeatureTitle';
import Page from '../../UI/Page';
import ClientApprovalLog from '../approvalLog/ClientApprovalLog';
import {useAuthorized} from '../auth/Authorized/useAuthorized';
import {CLIENT} from '../logs/logs-roles/Roles';

const HomePage = () => {
    const user = useSelector(state => state.user.user)
    const {isAuthorized} = useAuthorized()

    return (
        <Page>
            <Container>
                <Row>
                    <Col>
                        <FeatureTitle title={`Welcome, ${user.givenName} ${user.familyName}`}/>
                    </Col>
                </Row>
                {isAuthorized([CLIENT]) && <ClientApprovalLog/>}
            </Container>
        </Page>
    )
}

export default HomePage;