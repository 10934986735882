import React from 'react';

import classes from './Icons.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const icon = ({ icon, color, size = "lg", className, title }) => (
    <div className={className ?? classes.Icons} title={title}>
        <FontAwesomeIcon
            icon={icon}
            size={size}
            style={{ color: color || "#fff" }} />
    </div>
);

export default icon;