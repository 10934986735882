export const initialTotals = {
    approvedBudgetHours: 0,
    approvedBudgetDollars: 0,
    pendingBudgetHours: 0,
    pendingBudgetDollars: 0,
    data: {}
}

const getPropByChangeType = (data, changeType) => {
    return {
        approvedBudgetHours: data[`${changeType}${changeType === '' ? "approvedBudgetHours" : "ApprovedBudgetHours"}`] ?? 0,
        approvedBudgetDollars: data[`${changeType}${changeType === '' ? "approvedBudgetDollars" : "ApprovedBudgetDollars"}`] ?? 0,
        pendingBudgetHours: data[`${changeType}${changeType === '' ? "pendingBudgetHours" : "PendingBudgetHours"}`] ?? 0,
        pendingBudgetDollars: data[`${changeType}${changeType === '' ? "pendingBudgetDollars" : "PendingBudgetDollars"}`] ?? 0,
    }
}

export const LogTotalsReducer = (state = initialTotals, action) => {
    switch (action.type) {
        case 'ALL':
            return { ...state, ...getPropByChangeType(state.data, '') }
        case 'PCI':
            return { ...state, ...getPropByChangeType(state.data, 'pci') }
        case 'PCR':
            return { ...state, ...getPropByChangeType(state.data, 'pcr') }
        case 'FCO':
            return { ...state, ...getPropByChangeType(state.data, 'fco') }
        case 'VAR':
            return { ...state, ...getPropByChangeType(state.data, 'var') }
        default:
            return { data: action.payload, ...getPropByChangeType(action.payload, '') }
    }
}