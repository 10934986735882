import axios from '../../../../setup/axios'


class FCOService {
    BASE_ROUTE = "/log/fco";

    getFCODropdownsData(projectId){
        return this.get(`${projectId}/dropdownsdata`)
    }

    updateFCO(fcoData){
        return this.post('/update', fcoData)
    }

    submitFCO(fcoData){
        return this.post('/submit', fcoData)
    }

    approveFCOByClient(approveData){
        return this.post('/approveByClient', approveData);
    }

    approveFCOByCM(approveData){
        return this.post('/approveByCM', approveData);
    }

    closeFCO(fcoId, userId, projectId, closeStatus) {
        return this.post('close', {id: fcoId, userId, projectId: projectId.value, closeStatus});
    }

    sendFCOChargeableNotification(fcoId, userId, emailRecipients) {
        return this.post('/sendChargeableNotification', {id: fcoId, userId, emailRecipients});
    }

    saveFCO(fcoData){
        return this.post('', fcoData)
    }

    get(route) {
        return axios.get(`${this.BASE_ROUTE}/${route}`)
    }

    post(route, data) {
        return axios.post(`${this.BASE_ROUTE}/${route}`, data);
    }

    put(route, data) {
        return axios.put(`${this.BASE_ROUTE}/${route}`, data);
    }
    postMultipart(route, data) {
        const config = { headers: { 'Content-Type': `multipart/form-data; boundary=${data._boundary}` } };
        return axios.post(`${this.BASE_ROUTE}/${route}`, data, config);
    }

}

export default new FCOService();