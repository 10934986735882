import { useEffect, useState } from "react"
import RFIAttachementGrid from "../rfi-attachments-grid/RFIAttachementGrid"
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Form
} from "reactstrap";
import Page from '../../../../UI/Page';
import FCOService from '../api/fcoService'
import FeatureTitle from '../../../../components/FeatureTitle/FeatureTitle'
import FCOBasicInfoForm from '../fco-basic-info-form/FCOBasicInfoForm';
import { getUpdateFCORoute, LogRoute } from '../../../../setup/routes'
import { openSuccess } from '../../../success/redux/successSlice';
import FCODetailInfoForm from '../fco-detail-info-form/FCODetailInfoForm';
import FCOActionButtons from "../FCOActionButtons";


const CreateFCO = (params) => {
    const queryParams = new URLSearchParams(params.location.search);
    const isVar = queryParams.get('isVar') || false;
    const userId = useSelector(state => state.user.user.userId);
    const projectId = useSelector(state => state.projectId);
    const [dropdownsData, setDropdownsData] = useState(null)
    const { getValues, formState: { errors }, control, handleSubmit } = useForm({
        defaultValues: {
            requestedBy: userId,
            tracking: false,
            projectType: 'L',
            scheduleImpact: false,
            shortDescription: "",
            fullDescription: "",
            resolution: "",
            identifier: "",
            variance: isVar
        },
        mode: "onChange"
    });
    const [attachments, setAttachments] = useState([])
    const history = useHistory();
    const dispatch = useDispatch()
    const [saveInProgress, setSaveInProgress] = useState(false)

    const [rfiData, setrfiData] = useState([])
    const [selectedRfiList, setSelectedRfiList] = useState([])
   
    useEffect(() => {
        async function getDropdownsData() {
            const response = await FCOService.getFCODropdownsData(projectId.value)
            setDropdownsData(response.data.data)
            const { allRFIs } = response.data.data            
			setrfiData(allRFIs ? allRFIs.sort((a, b) => a.rfiNumber < b.rfiNumber ? 1 : -1) : [])
        }
        getDropdownsData()
    }, [projectId])

    const onSave = handleSubmit(async (data) => {
        const formData = new FormData()
        const command = { ...data, ...{ projectId: projectId.value, userId, causeId: data.cause, templateId: data.template, attachments: [], selectedRFIs:selectedRfiList } }

        attachments.forEach((file) => {
            if (file.name) {
                formData.append('files', file, file.name)
            }
            else {
                command.attachments.push({
                    "fileName": file.fileName,
                    "status": file.status,
                    "fileDownloadUri": file.fileDownloadUri
                })
            }
        })

        formData.append('command', new Blob([JSON.stringify(command)], { type: "application/json" }))
        try {
            setSaveInProgress(true)
            const response = await FCOService.saveFCO(formData)
            setSaveInProgress(false)
            if (response.data.data) {
                const redirectUrl = getUpdateFCORoute(response.data.data.logId)
                history.push(redirectUrl);
                setTimeout(() => {
                    dispatch(openSuccess("The FCO was saved."))
                }, 4000)
            }
        } catch (e) {
            console.log(e)
            setSaveInProgress(false)
        }
    })

    const onSubmit = handleSubmit(async (data) => {
        const formData = new FormData()
        const command = { ...data, ...{ projectId: projectId.value, userId, causeId: data.cause, templateId: data.template, attachments: [], selectedRFIs:selectedRfiList, submit: true } }

        attachments.forEach((file) => {
            if (file.name) {
                formData.append('files', file, file.name)
            }
            else {
                command.attachments.push({
                    "fileName": file.fileName,
                    "status": file.status,
                    "fileDownloadUri": file.fileDownloadUri
                })
            }
        })

        formData.append('command', new Blob([JSON.stringify(command)], { type: "application/json" }))

        try {
            setSaveInProgress(true)
            const response = await FCOService.saveFCO(formData)
            setSaveInProgress(false)
            if (response.data.data) {
                const redirectUrl = getUpdateFCORoute(response.data.data.logId)
                history.push(redirectUrl);
                setTimeout(() => {
                    dispatch(openSuccess("The FCO was saved and submitted."))
                }, 4000)
            }
        } catch (e) {
            console.log(e)
            setSaveInProgress(false)
        }
    })

    return (
        <Page>
            <Container className="pb-3">
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title="Project Change: New"/>
                            <hr/>
                        </Container>
                    </Col>
                </Row>
                <Form className="pb-5">
                    <Row>
                        {dropdownsData && <> <FCOBasicInfoForm
                            control={control} errors={errors} dropdownsData={dropdownsData}
                            getValues={getValues} setAttachments={setAttachments} attachments={attachments}/>
                            <FCODetailInfoForm
                                control={control} errors={errors} dropdownsData={dropdownsData}
                                getValues={getValues} setAttachments={setAttachments} attachments={attachments}/>
                        </>
                        }
                    </Row>
                    <Row>
                        <Col>
                            {rfiData.length > 0 &&
                                <RFIAttachementGrid rfiList={rfiData} selectedRfiList={selectedRfiList}
                                                    setSelectedRfiList={setSelectedRfiList}/>}
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <FCOActionButtons onSave={onSave} onSubmit={onSubmit} cancelRoute={LogRoute}
                                              isSubmitDisabled={!dropdownsData} isSaveDisabled={!dropdownsData}
                                              saveInProgress={saveInProgress}/>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Page>
    );
}

export default CreateFCO;