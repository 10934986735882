import React from 'react'
import DownloadButton from './DownloadButton'
import { Row, Col, Label, Container } from "reactstrap"
import { useAuthorized } from '../../auth/Authorized/useAuthorized'

const DownloadList = ({ downloadListState, onDownload }) => {
    const { isAuthorized } = useAuthorized()

    return (
        <Container style={{ paddingBottom: "70px" }} className="mt-3 pr-5">
            <Label className="pb-2 font-weight-bold">Select from the above filters to be applied to the reports below:</Label>
            <div className="w-100 d-flex flex-wrap">
                {Object.keys(downloadListState).map(report => {
                    const { description, pdf, excel, roles } = downloadListState[report]
                    return (
                        ((pdf || excel) && (!roles || isAuthorized(roles))) ?
                            <Row className="w-50" key={report} >
                                <Col xs={6} >
                                    <Label>{description}</Label>
                                </Col>
                                <Col xs={2} className="d-flex align-items-center" >
                                    {pdf && <DownloadButton iconName="file-pdf" onDownload={() => onDownload(report, "pdf")} />}
                                    {excel && <DownloadButton iconName="file-excel" onDownload={() => onDownload(report, "excel")} />}
                                </Col>
                            </Row> : null
                    )
                })}
            </div>
        </Container>
    )
}

export default DownloadList
