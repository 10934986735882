import React, { useRef } from 'react'
import { Link } from 'react-router-dom';
import Icon from '../../../../components/Icon/Icon';
import classes from './TemplateRowAction.module.css';
import Authorized from '../../../auth/Authorized/Authorized';
import { Button, PopoverBody, Row, Col, UncontrolledPopover } from 'reactstrap';
import { getUpdateTemplateRoute, getEditRatesRoute } from '../../../../setup/routes';
import { TEMPLATE_USERS } from '../../../logs/logs-roles/Roles';

const AllowedRoles = [...TEMPLATE_USERS]


const TemplateRowAction = ({ selectedTemplate }) => {
    const buttonRef = useRef(null)

    return (
        <div>
            <div ref={buttonRef}>
                <Button size="sm" color="link" className={classes.button}>
                    <Icon icon="pencil-alt" color="#008CBA" />
                </Button>
            </div>
            <UncontrolledPopover placement="left" target={buttonRef} >
                <PopoverBody>
                    <Row>
                        <Col><Link to={getUpdateTemplateRoute(selectedTemplate)}>
                            Edit Basic Information
                        </Link> </Col>
                    </Row>
                    <Authorized roles={AllowedRoles}>
                        <Row>
                            <Col>
                                <Link to={getEditRatesRoute(selectedTemplate)}>
                                    Edit Rates
                                </Link>
                            </Col>
                        </Row>
                    </Authorized>
                </PopoverBody>
            </UncontrolledPopover>
        </div>
    )
}

export default React.memo(TemplateRowAction)
