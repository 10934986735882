import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Page from '../../../UI/Page';
import FeatureTitle from '../../../components/FeatureTitle/FeatureTitle';
import { openSuccess } from '../../success/redux/successSlice'
import templateService from '../api/templatesService';
import {
    Container,
    Row,
    Col,
    Form
} from 'reactstrap';


import RatesContainerCollapse from './collapse-rates/RatesContainerCollapse';
import { CollapsibleSection } from '../../../components/CollapsibleSection/CollapsibleSection';
import NextCancel from '../../../components/NextCancel/NextCancel';
import { TemplatesRoute } from '../../../setup/routes';
import { useHistory } from 'react-router';
import RatesMasterMarkupGrid from './rates-update-grid/RatesMasterMarkupGrid';
import RatesMasterSalesGrid from './rates-update-grid/RatesMasterSalesGrid';
import RatesBareLaborGrid from './rates-update-grid/RatesBareLaborGrid';

const Rates = () => {

    let history = useHistory();
    const dispatch = useDispatch()

    const userId = useSelector(state => state.user.user.userId);

    let { id } = useParams();
    const [templateName, setTemplateName] = useState();
    const [masterMarkups, setMasterMarkups] = useState();
    const [salesTaxes, setSalesTaxes] = useState();
    const [workHourRates, setWorkHourRates] = useState();

    const [isOpenMarkupCollapse, setIsOpenMarkupCollapse] = useState(false);
    const [isOpenSalesCollapse, setIsOpenSalesCollapse] = useState(false);
    const [isOpenLaborCollapse, setIsOpenLaborCollapse] = useState(false);

    const sbiWorkHourRates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    const updateWorkHourRates = newValue => {
        setWorkHourRates([]);
        const updatedRates = workHourRates.map(function (rate) {
            if (sbiWorkHourRates.includes(rate.workHourRateDefnId)) {
                rate.bareLaborPctRate = newValue;
            }
            return rate;
        });

        setWorkHourRates(updatedRates);
    };

    const toggle = () => {
        setIsOpenMarkupCollapse(!(isOpenMarkupCollapse || isOpenSalesCollapse || isOpenLaborCollapse));
        setIsOpenSalesCollapse(!(isOpenMarkupCollapse || isOpenSalesCollapse || isOpenLaborCollapse));
        setIsOpenLaborCollapse(!(isOpenMarkupCollapse || isOpenSalesCollapse || isOpenLaborCollapse));
    };

    const [cancelConfirmText, setCancelConfirmText] = useState();

    const handleData = useCallback((response) => {
        let data = response.data.data;
        setTemplateName(data ? data.name : "");
        setMasterMarkups(data ? data.masterMarkups.sort((a, b) => a.masterMarkupDefnSortOrder > b.masterMarkupDefnSortOrder ? 1 : -1) : []);
        setSalesTaxes(data ? data.saleTaxes.sort((a, b) => a.saleTaxDefnSortOrder > b.saleTaxDefnSortOrder ? 1 : -1) : []);
        setWorkHourRates(data ? data.workHourRates.sort((a, b) => a.workHourRateDefnSortOrder > b.workHourRateDefnSortOrder ? 1 : -1) : []);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            await templateService.getTemplateAndRates(id)
                .then(handleData)
                .catch(err => console.log(err));
        }

        fetchData();
        setCancelConfirmText("Are you sure you want to close?");
    }, [handleData, id]);

    const onSubmit = (event) => {
        event.preventDefault();
        templateService.updateRates(id, {
            masterMarkupRates: masterMarkups,
            salesTaxPortionRates: salesTaxes,
            bareLaborRates: workHourRates,
            updatedBy: userId
        }).then(() => {
            history.push(TemplatesRoute)
            setTimeout(() => {
                dispatch(openSuccess("The Rates was Saved."))
            }, 4000)
        }).catch(err => console.log(err));
    }

    const valueFormatter = (value, type, defnFlag, decimal = 2) => {
        if (defnFlag) {
            var sansDec = Number(value).toFixed(decimal);
            var formatted = sansDec.replace(/\B(?=(\d{4})+(?!\d))/g, ',');
            if (type === 'PCT') {
                return `${value ? formatted : 0}%`;
            } else if (type === 'DLR') {
                return `$${value ? formatted : 0}`;
            } else {
                return `${value ? formatted : 0}`;
            }
        } else {
            return "N/A"
        }
    }

    const decimalParser = (params, length, decimals) => {
        let pattern = '\\d{1,' + length + '}(?:\\.\\d{1,' + decimals + '})?$';
        let validValueRegex = new RegExp(pattern, 'y');
        let value = Number(params.newValue)
        if (!validValueRegex.test(value)) {
            value = Number(params.oldValue)
        }
        return value;
    }

    const rowStyle = params => {
        if (params.node.rowIndex % 2 === 0) {
            return { background: '#f4faff' };
        } else {
            return { background: '#ffffff' };
        }
    };

    return (
        <Page>
            <Container>
                <Row>
                    <Col>
                        <Container>
                            <FeatureTitle title={`Rates: ${templateName}`} />
                            <hr />
                        </Container>
                    </Col>
                </Row>

                <Form onSubmit={onSubmit}>
                    <Row>
                        <Col>
                            <Container>
                                <RatesContainerCollapse isOpen={isOpenMarkupCollapse || isOpenSalesCollapse || isOpenLaborCollapse} toggle={toggle}>
                                    <Row className="pt-4">
                                        <CollapsibleSection title={"Master Markup Values"} isOpen={isOpenMarkupCollapse} toggle={() => setIsOpenMarkupCollapse(!isOpenMarkupCollapse)}>
                                            <RatesMasterMarkupGrid masterMarkups={masterMarkups} valueFormatter={valueFormatter} rowStyle={rowStyle}
                                                updateWorkHourRates={updateWorkHourRates} decimalParser={decimalParser} />
                                            <hr />
                                        </CollapsibleSection>
                                    </Row>
                                    <Row>
                                        <CollapsibleSection title={"Master Sales Tax"} isOpen={isOpenSalesCollapse} toggle={() => setIsOpenSalesCollapse(!isOpenSalesCollapse)}>
                                            <RatesMasterSalesGrid salesTaxes={salesTaxes} valueFormatter={valueFormatter} rowStyle={rowStyle} decimalParser={decimalParser} />
                                            <hr />
                                        </CollapsibleSection>
                                    </Row>
                                    <Row>
                                        <CollapsibleSection title={"Bare Labor Rates"} isOpen={isOpenLaborCollapse} toggle={() => setIsOpenLaborCollapse(!isOpenLaborCollapse)}>
                                            <RatesBareLaborGrid workHourRates={workHourRates} valueFormatter={valueFormatter} rowStyle={rowStyle} decimalParser={decimalParser} />
                                        </CollapsibleSection>
                                    </Row>
                                </RatesContainerCollapse>
                            </Container>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col fluid={"true"} style={{ height: 150 }}>
                            <NextCancel cancelRoute={TemplatesRoute} disabled={false} cancelConfirmText={cancelConfirmText} saveText={"Save"} />
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Page>
    )
}

export default Rates;