import { useDispatch, connect } from "react-redux";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import React, {useEffect, useRef, useCallback, useMemo} from 'react';
import { divisionAllowancesSetData } from "../redux/detailsDataAndTotalSlice"
import { getRowStyle, getRowSpanForDescriptionTotal, getTotalDescription, currencyFormatter, defaultGridOptions, isFooter, sortById } from "../../../../utilities/ag-grid-utilities/AgGridUtilities";
import DecimalEditor from "../../../../utilities/ag-grid-utilities/cell-editors/DecimalEditor";
import {useAuthorized} from '../../../auth/Authorized/useAuthorized';
import {CAN_OVERRIDE_DETAIL_VALUES} from '../../logs-roles/Roles';


const TOTAL_DESCRIPTION = 'Total Division Allowances (DIV 7)';

const cellClassRules = {
    'override-status': params => params.data.edited,
    'editable-cell': params => {
        if ((!params.colDef.editable || !params.colDef.editable(params)) || isFooter(params)) {
            return false;
        }
        if (params.colDef.field === 'activityDescription') {
            return params.data.edited || params.data.activityDescription.toLowerCase() === 'undefined';
        }
        return true;
    }
};



const DivisionAllowancesGrid = React.memo(({ data, totals, readOnly }) => {
    const dispatch = useDispatch()
    const doUpdate = useRef(false)
    const dataRef = useRef(...data)
    const { isAuthorized } = useAuthorized()
    const canOverrideValues = useMemo(() => {
        return isAuthorized([...CAN_OVERRIDE_DETAIL_VALUES]);
    }, [isAuthorized])

    useEffect(() => {
        dataRef.current = [...data]
    }, [data]);

    const applyFormulasToDataModel = useCallback((localData) => {
        const updatedDataModel = localData.map(row => {
            const { dollars, activityDescription } = row
            return {
                ...row,
                dollars: dollars,
                activityDescription: activityDescription
            }
        })
        if (updatedDataModel.length > 0) {
            dispatch(divisionAllowancesSetData(updatedDataModel))
        }
    }, [dispatch])


    const valueSetter = (params) => {
        if (params.colDef.field === "dollars") {
            //Validate Direct Material precision and scale
            if (params.newValue > 999999999.99) {
                return params.oldValue
            }
        }

        const isTextField = ["activityDescription"].includes(params.colDef.field)
        const isNewValue = isTextField ? params.oldValue !== params.newValue : Number(params.oldValue) !== Number(params.newValue)
        if (isNewValue) {
            const value = isTextField ? params.newValue : Number(params.newValue);
            const updatedRow = { ...params.data, [params.column.colId]: value, edited: true }
            const filteredData = dataRef.current.filter(f => f.id !== params.data.id)
            applyFormulasToDataModel([...filteredData, ...[updatedRow]].sort(sortById))
        }
        return isNewValue
    }

    return (
        <div className="ag-theme-bootstrap" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ flex: '1 1 auto', overflow: "hidden" }} >
                <AgGridReact
                    gridOptions={{ ...defaultGridOptions }}
                    getRowStyle={getRowStyle}
                    rowData={data}
                    getRowNodeId={data => data.id}
                    immutableData={true}
                    domLayout="autoHeight"
                    enableCellChangeFlash={true}
                    singleClickEdit={true}
                    pinnedBottomRowData={[totals]}
                    onFirstDataRendered={() => doUpdate.current = true}
                    frameworkComponents={{
                        decimalCellEditor: DecimalEditor
                    }}>
                    <AgGridColumn headerName="Edited" field="edited" hide valueFormatter={params => params.value ?? false} suppressSizeToFit={false} />
                    <AgGridColumn headerName="Pact" field="pact" headerClass="header-grid" cellClassRules={cellClassRules} width={75} maxWidth={75}
                        colSpan={getRowSpanForDescriptionTotal}
                        valueFormatter={params => getTotalDescription(params, TOTAL_DESCRIPTION)} />
                    <AgGridColumn headerName="Activity" editable={(params) => canOverrideValues && !readOnly && !isFooter(params) && (params.data.edited || params.data.activityDescription.toLowerCase() === 'undefined')} field="activityDescription"
                        headerClass="header-grid" cellClassRules={cellClassRules} width={330}
                        valueSetter={valueSetter} 
                        />
                    <AgGridColumn headerName="Allowances $" 
                        editable={params => canOverrideValues && !readOnly && !isFooter(params)}
                        field="dollars"
                        headerClass="header-grid right-sortable-header-grid" type="numericColumn" cellClassRules={cellClassRules} width={150} maxWidth={150}
                        valueSetter={valueSetter} cellEditor="decimalCellEditor"
                        valueFormatter={(params) => currencyFormatter(params.data.dollars, '$', 0)} />
                </AgGridReact>
            </div>
        </div>
    );
}, (prevProps, nextProps) => {
    return (
        JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) && 
        JSON.stringify(prevProps.readOnly) === JSON.stringify(nextProps.readOnly)
    );
});

function mapStateToProps(state) {
    const { divisionAllowances: { data, totals }, readOnly } = state.detailsDataAndTotal
    return {
        data,
        totals,
        readOnly
    }
}

export default connect(mapStateToProps)(DivisionAllowancesGrid);