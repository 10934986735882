import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css'
import 'ag-grid-community/dist/styles/ag-grid.css'

const RatesMasterSalesGrid = ({
    salesTaxes,
    valueFormatter,
    rowStyle,
    decimalParser
}) => {
    
    const agGrid = {
        columnDefs: [
            { headerName: 'Prime Account Description', field: 'saleTaxDefnPrimeAccountDesc', sortable: true, flex: 1, filter: true, headerClass: "header-grid" },
            { headerName: '% Of Material Taxed', field: 'pctMaterialTaxed', flex: 1, sortable: true, filter: true, editable: true, headerClass: "header-grid",
                valueParser: params => decimalParser(params, 3, 2),
                valueFormatter: params => valueFormatter(params.data.pctMaterialTaxed, params.data.saleTaxDefnMtlTaxedType, true) },
            { headerName: '% Of Submaterial Taxed', field: 'pctSubMaterialTaxed', flex: 1, sortable: true, filter: true, editable: true, headerClass: "header-grid",
                valueParser: params => decimalParser(params, 3, 2),
                valueFormatter: params => valueFormatter(params.data.pctSubMaterialTaxed, params.data.saleTaxDefnSubMtlTaxedType, true) }
        ]
    }   
   
    return (        
        
        <AgGridReact getRowStyle={rowStyle} columnDefs={agGrid.columnDefs} rowData={salesTaxes} suppressMenuHide="true" 
            domLayout="autoHeight" className="ag-theme-bootstrap" singleClickEdit={true} />               
        
    )
}

export default RatesMasterSalesGrid;