import SlideoutModal from './../SlideoutModal/SlideoutModal'
import MenuStyles from './SlideoutMenuStyles'
import {useAuthorized} from '../../features/auth/Authorized/useAuthorized';
import {Link} from 'react-router-dom';
import {ROUTES} from '../../App';
import FAButton from '../FAButton/FAButton';
import {useCallback, useState} from 'react';

const MenuOptions = ({toggleMenu}) => {
    const {isAuthorized, isNotAuthorized} = useAuthorized()
    return (
        ROUTES.map((route, index) => {
            return (
                !isNotAuthorized(route.exclude) && isAuthorized(route.auth_roles)
                    ? <Link
                        key={`MenuLink${index}`}
                        to={route.path}
                        onClick={toggleMenu}
                        style={{
                            paddingLeft: 35, display: 'block',
                        }}
                    >
                        {route.title}
                    </Link> : null
            );
        })
    )
}

export default function SlideoutMenu() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = useCallback(() => {
        setMenuOpen((open) => !open)
    }, [])

    return (
        <>
            <FAButton
                buttonStyle={{
                    width: '60px',
                    height: '60px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: 'white',
                }}
                onClick={toggleMenu}
                iconStyle={{
                    width: '100%',
                    height: '100%',
                }}
                iconName="bars"
            />
            <SlideoutModal isShowing={menuOpen} toggle={toggleMenu}>
                <MenuStyles>
                    <div className="menu-heading">
                        <span>COST</span>
                    </div>
                    <div className="menu-listing">
                        <MenuOptions toggleMenu={toggleMenu}/>
                    </div>
                </MenuStyles>
            </SlideoutModal>
        </>
    )
}
