import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import localStorageService from '../../services/localStorageService'
import classes from './ProjectDropdown.module.css';
import ProjectService from '../../services/projectsService'
import { setSelectedProject } from './redux/projectDetailSlice'
import { setProjectId } from './redux/projectIdSlice'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { LogRoute } from '../../setup/routes';

const ProjectDropdown = () => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const dispatch = useDispatch();

    const [projects, setProjects] = useState([]);
    const { selectedProjectDetails } = useSelector((state) => state);
        
    const history = useHistory();

    useEffect(() => {
        setProjects(localStorageService.getProjects());
    }, []);
        
    const handleOnChange = (index) => {        
        const newSelectedproject = projects[index]; 
        localStorage.setItem('selectedProject', JSON.stringify(newSelectedproject));
        dispatch(setSelectedProject(newSelectedproject));
        
        ProjectService.getProjectIdByJobNumber(newSelectedproject.jobNo)
            .then((response) => {
                localStorage.setItem('projectId', response.data.data);
                dispatch(setProjectId(response.data.data))   
                history.push(LogRoute);           
        });        
    };
    
    return (
        <Container>
            <Row>
                <Col>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle} className={classes.dropdown}>                        
                        <DropdownToggle caret>
                            {selectedProjectDetails.jobNo} {selectedProjectDetails.projectTitle || " Select a project ... "}                            
                        </DropdownToggle>
                        <DropdownMenu>
                            {projects.map((item, index) => ( <DropdownItem key={item.projectTitle} onClick={() => handleOnChange(index)} >{item.jobNo} {item.projectTitle}`</DropdownItem> ))}
                        </DropdownMenu>                         
                    </Dropdown>

                    <span className={classes.text}>{projects.length > 0 ? "" : "Unable to retrieve project list from local storage"}</span>
                </Col>                
            </Row>
        </Container>
    )    
}

export default ProjectDropdown;