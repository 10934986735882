import { Button } from 'reactstrap'
import Icon from '../Icon/Icon'

import css from './FAButton.module.css'

const FAButton = ({
  wrapperStyle = {}, wrapperClassName = '',
  buttonStyle = {},
  iconStyle = { marginRight: '7.5px' },
  iconName = '',
  buttonTitle = '',
  color = 'success',
  disabled = false,
  onClick = () => { },
}) => (
  <div style={wrapperStyle} className={wrapperClassName}>
    <Button
      className={css.fabutton}
      color={color}
      disabled={disabled}
      onClick={onClick}
      style={buttonStyle}
    >
      <Icon icon={iconName} style={iconStyle} />
      {buttonTitle}
    </Button>
  </div>
)

export default FAButton
